import React from 'react';
import {CompanyModel} from "../services/company/company.model";

export interface PublicStateContextType {
    setCompany: (v: {pending: boolean; value: CompanyModel | null}) => void;
    company: {pending: boolean, value: CompanyModel | null};
}

const defaultCompanyContext: PublicStateContextType = {
    company: {pending: false, value: null},
    setCompany: (c: {pending: boolean, value: CompanyModel | null}) => {
        console.warn('Context Provider not wrapped around component.');
    }
};

const PublicAuthContext = React.createContext<PublicStateContextType>(defaultCompanyContext);

export default PublicAuthContext;
