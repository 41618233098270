import {ApiService, PaginationRequest} from "../api-service";
import {CompanyModel, CompanyThreatRansomware} from "./company.model";
import {PaginationResult} from "../globals";

export default class CompanyService extends ApiService {
    constructor() {
        super();
    }


    public async getPaginatedCompanies(pagination: PaginationRequest, company_name?: string): Promise<PaginationResult<CompanyModel>> {
        let query_params: any = {
            offset: pagination.page * pagination.rowsPerPage,
            limit: pagination.rowsPerPage,
            name: company_name
        };


        return super.get<PaginationResult<CompanyModel>>(`companies`, query_params);
    }


    public async getCompany(companyID: string): Promise<CompanyModel> {
        return super.get<CompanyModel>(`company/${companyID}`);
    }

    public async getCompanies(): Promise<CompanyModel[]> {
        return super.get<CompanyModel[]>(`company`);
    }

    public async createCompany(companyName: string): Promise<CompanyModel> {
        return super.post<CompanyModel>(`company`, {name: companyName});
    }

    public async updateCompany(company: CompanyModel): Promise<CompanyModel> {
        return super.put<CompanyModel>(`company/${company.id}`, company);
    }

    public async deleteCompany(company_id: string): Promise<void> {
        return super.delete<void>(`company/${company_id}`);
    }

    public async getCompanyRisk(company_id: string): Promise<any[]> {
        return super.get<any[]>(`company/${company_id}/risk`)
    }

    public async getCompanyRansomwareRisk(company_id: string): Promise<CompanyThreatRansomware> {
        return super.get<CompanyThreatRansomware>(`company/${company_id}/ransomware-risk`)
    }
}
