import {CSSObject, styled} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {Theme} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import {mainDrawerWidth} from "../../internal-ui/SideNav/SideNav";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import {Box} from "@mui/system";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;


    nestedDrawerWidth: string;
    nestedDrawerOpen: boolean;
}


const openedMixin = (theme: Theme, nestedDrawerWidth: string): CSSObject => ({
    width: nestedDrawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0
});

export const NestedDrawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth",
})(({theme, open, drawerWidth}: { theme?: Theme, open?: boolean, drawerWidth: string }) => ({
    width: drawerWidth,
    flexShrink: 0,
    ...(open && {
        ...openedMixin(theme!, drawerWidth),
        "& .MuiDrawer-paper": {
            ...openedMixin(theme!, drawerWidth),
            boxSize: 'border-box'
        },
    }),
    ...(!open && {
        ...closedMixin(theme!),
        "& .MuiDrawer-paper": {
            ...closedMixin(theme!),
            width: 0,

        },
    })
}));

export const NestedDrawerContent = styled(Box, {shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth'})<{
    open?: boolean; drawerWidth: string;
}>(({theme, open, drawerWidth}) => ({
    height: `calc(100vh - ${theme.spacing(8)})`,
    marginRight: open ? drawerWidth : 0,
    overflowY: 'auto',
    width: open ? drawerWidth : '100%',
    marginTop: theme.spacing(8),
    transition: theme.transitions.create(['margin', 'right', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));
export const NestedAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth' && prop !== 'nestedDrawerOpen',
})<AppBarProps>(({theme, open, nestedDrawerWidth, nestedDrawerOpen}) => ({
    transition: theme.transitions.create(['margin', 'width', 'right'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),

    // Base styles
    width: '100%', // Set a default width
    right: 0, // Set a default right position

    // When the main drawer is open
    ...(open && nestedDrawerOpen && {
        width: `calc(100% - ${mainDrawerWidth} - ${nestedDrawerWidth})`,
        right: nestedDrawerWidth,
    }),

    ...(open && !nestedDrawerOpen && {
        width: `calc(100% - ${mainDrawerWidth})`,
    }),

    ...(!open && nestedDrawerOpen && {
        width: `calc(100% - calc(${theme.spacing(7)} + 1px) - ${nestedDrawerWidth})`,
        right: nestedDrawerWidth,
    }),

    // When the main drawer is closed
    ...(!open && !nestedDrawerOpen && {
        width: `calc(100% - calc(${theme.spacing(7)} + 1px))`,
    }),
}));


export const AppToolbar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<{ theme?: Theme, open?: boolean }>(({theme, open}) => {
    return ({
        position: 'fixed',
        padding: `0 ${theme.spacing(2)}`,

        ...(open ? {
            width: `calc(100% - ${mainDrawerWidth})`,
            left: `calc(${mainDrawerWidth})`,
            transition: theme.transitions.create(['width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        } : {
            width: `calc(100% - (${theme.spacing(7)} + 1px))`,
            transition: theme.transitions.create(['left', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        })
    })
});


