import React from 'react';
import {AuthProps} from "./AuthContext";

export interface AnonymousStateContextType {
    setToken: (token:{value: string | null, pending: boolean}) => void;
    token: {value: string | null, pending: boolean};
    clearToken: () => void;
}

const defaultAnonymousContext: AnonymousStateContextType = {
    token: {pending: true, value: null},
    setToken: (token) => {
        console.warn('Context Provider not wrapped around component.');
    },
    clearToken: () => {
        console.warn('Context Provider not wrapped around component.');
    }
};

const AnonymousAuthContext = React.createContext<AnonymousStateContextType>(defaultAnonymousContext);

export default AnonymousAuthContext;


