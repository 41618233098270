import {addYears, isAfter, isBefore, isSameDay, isToday} from "date-fns";

export type ValidationFn = (v: string, k: keyof FormType, form: FormType) => null | string;
// Define a type for the form
export type FormType = {
    [key: string]: FormFieldMeta;
};

export type FormFieldMeta = {
    value: string;
    error: null | string;
    initialized: boolean;
    data?: any;
    validators: ValidationFn[];
}

export const handleValidation = (key: keyof FormType, value: any, form: FormType) => {

    let validationError: null | string = null;
    for (let validatorFn of form[key].validators) {
        validationError = validatorFn(value, key, form);

        if (validationError !== null)
            break;
    }

    return validationError;
}


export const maxLengthValidator = (maxLength: number = 255): ValidationFn => (value, k: keyof FormType, form: FormType) => {
    if (value.trim().length > maxLength) {
        return 'Must be less than ' + maxLength + ' characters'
    }
    return null;
};

export const minLengthValidator = (minLength: number = 0): ValidationFn => (value, k: keyof FormType, form: FormType) => {
    if (value.trim().length < minLength) {
        return 'Must be at least ' + minLength + ' characters'
    }
    return null;
};


export const requiredValidator = (value: string, k: keyof FormType, form: FormType): string | null => {
    return value?.trim() === '' ? 'Required' : null
}

export const websiteValidator = (value: string, k: keyof FormType, form: FormType): string | null => {
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (value?.trim() !== "")
        return pattern.test(value) ? null : "Invalid URL"

    return null;
}

export const requiredDateValidator = (value: string, k: keyof FormType, form: FormType): string | null => {
    return value?.trim() === '' || value === '00/00/0000' ? 'Required' : null
}


export const emailValidator = (value: string, k: keyof FormType, form: FormType): string | null => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(value)) {
        return 'Invalid email format';
    }

    return null;
}


