import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {Collapse, IconButton, List, ListItem, ListItemText, Paper, Theme, Typography,} from "@mui/material";
import React, {ReactNode} from "react";
import {lighten, Stack} from "@mui/system";
import ListItemIcon from "@mui/material/ListItemIcon";

interface ViewAnalysisListProps<T> {
    entries: ReportEntry<T>[];
    styles?: ViewListStyles;


    // doesn't display the label or preview text, just the values being passed in
    // as a list. Only triggered if expandable is true
    viewListValuesOnly?: boolean

    onSelect?: (field: ReportEntry<T>) => void;
}

interface ViewAnalysisListState<T> {
    expandableFields: ExpandableFields<T>;
}

export interface ReportEntry<T> {
    label: string;
    labelIcon?: JSX.Element;
    key: keyof T;
    value: any;

    endIcon?: (key: keyof T, value: any) => JSX.Element;

    // is a list
    expandable?: boolean;
    // can collapse list
    disableCollapse?: boolean;

    previewText?: string;
    icon?: ((value: string) => JSX.Element | undefined) | JSX.Element;

    onEntryClicked?: (event: any, value: any) => void;
}

export type ExpandableFields<T> = {
    [K in keyof T]?: boolean;
};

export interface ViewListStyles {
    listSubheaderStyle?: object;
    listItemStyle?: object;
    listItemLabelStyle?: object;
    listBulletPointStyle?: object;
    nestedListStyle?: object;
    nestedListValueStyle?: object;
}

export class ListView<T> extends React.Component<
    ViewAnalysisListProps<T>,
    ViewAnalysisListState<T>
> {
    constructor(props: ViewAnalysisListProps<T>) {
        super(props);

        this.state = {
            expandableFields: {},
        };
    }

    getIcon(entry: ReportEntry<T>, value: string): JSX.Element | undefined {
        if (entry.icon) {
            if (typeof entry.icon === 'function') {
                return entry.icon(value);
            } else {
                return entry.icon;
            }
        }

        return undefined;
    }

    toggleExpandedField(
        event: React.MouseEvent<HTMLElement>,
        entry: ReportEntry<T>
    ): void {
        event.stopPropagation();
        if (entry?.expandable) {
            if (
                (Array.isArray(entry.value) && entry.value?.length > 0) ||
                entry.value
            ) {
                const doesFieldExist = this.state.expandableFields[entry.key];
                this.setState({
                    expandableFields: {
                        ...this.state.expandableFields,
                        [entry.key]: doesFieldExist == undefined ? false : !doesFieldExist,
                    },
                });
            }
        }
    }

    render(): ReactNode {
        return this.props.entries?.map((entry: ReportEntry<T>) => {
            const endIcon = entry.endIcon && entry.endIcon(entry.key, entry.value);
            const opacity = entry.value && entry.value?.toString()?.length > 0 ? 1 : ".6"

            return (
                <ListItem
                    sx={this.props.styles?.listItemStyle}
                    onClick={(e) => {
                        if (!entry.disableCollapse) {
                            this.toggleExpandedField(e, entry)
                        }
                    }}
                    key={entry.key as string}
                    divider
                >
                    {
                        !this.props.viewListValuesOnly &&
                        <ListItemText sx={{...this.props.styles?.listItemLabelStyle, alignSelf: 'flex-start', opacity: 1}}>
                            <Stack direction={'column'} gap={.5}>
                                <Stack sx={{opacity: opacity}}  direction={'row'} alignItems={'center'}>
                                    <Typography color={'text.secondary'}
                                                fontWeight={600}>
                                        {entry.label}
                                    </Typography>

                                    {
                                        entry.labelIcon && entry.labelIcon
                                    }
                                </Stack>

                                {
                                    endIcon && (
                                        <ListItemIcon sx={{alignItems: 'flex-start', justifyContent: 'flex-start', cursor: 'pointer'}}>
                                            {endIcon}
                                        </ListItemIcon>
                                    )
                                }
                            </Stack>
                        </ListItemText>
                    }

                    {!entry?.expandable && (
                        <Paper elevation={entry.value ? 0 : 1} sx={{width: "100%", opacity: opacity}}>
                            <ListItemText sx={{
                                padding: '5px 10px',
                                width: "100%",
                                "&:hover": {
                                    background: (theme: Theme) =>
                                        entry.onEntryClicked ?
                                            lighten(theme.palette.background.default, 0.15) :
                                            ''
                                },
                                cursor: entry.onEntryClicked ? 'copy' : 'auto'
                            }} onClick={(event) => {
                                if (!entry.disableCollapse) {
                                    entry.onEntryClicked && entry.onEntryClicked(event, !entry.value ? entry.previewText : entry.value);
                                }
                            }}>
                                {!entry.value ? entry.previewText : entry.value}
                            </ListItemText>
                        </Paper>
                    )}


                    {
                        !entry.value?.length && this.props.viewListValuesOnly &&
                        <Typography sx={{opacity: opacity}}>No Values</Typography>
                    }

                    {entry?.expandable && (
                        <ListItemText sx={{...this.props.styles?.nestedListValueStyle, opacity: opacity}}>
                            {((!this.state.expandableFields[entry.key] &&
                                    this.state.expandableFields[entry.key] !== undefined) ||
                                entry.value?.length == 0) && (
                                <Typography>{entry.previewText} </Typography>
                            )}
                            <Collapse
                                in={
                                    this.state.expandableFields[entry.key] ||
                                    this.state.expandableFields[entry.key] == undefined
                                }
                                timeout="auto"
                                unmountOnExit
                            >
                                {entry.value?.length > 0 && (
                                    <Paper elevation={0}>
                                        <List disablePadding sx={this.props.styles?.nestedListStyle}>
                                            {entry?.value?.map((row: string, index: number) => {
                                                return (
                                                    <Paper
                                                        key={index}
                                                        sx={{margin: "5px 0"}}
                                                        elevation={2}
                                                    >
                                                        <ListItem
                                                            className={'overflow'}
                                                            sx={{
                                                                ...this.props.styles?.listBulletPointStyle,
                                                                "&:hover": {
                                                                    background: (theme: Theme) =>
                                                                        entry.onEntryClicked ?
                                                                            lighten(theme.palette.background.default, 0.15) :
                                                                            ''
                                                                },
                                                                cursor: entry.onEntryClicked ? 'copy' : 'auto'
                                                            }}

                                                            onClick={(event) => {
                                                                entry.onEntryClicked && entry.onEntryClicked(event, row);
                                                            }}
                                                        >
                                                            <ListItemText
                                                                sx={{
                                                                    padding: "5px 15px",
                                                                    color: (theme: Theme) =>
                                                                        theme.palette.text.primary,
                                                                }}
                                                            >
                                                                {row}
                                                            </ListItemText>

                                                            {
                                                                entry.icon && <ListItemIcon>
                                                                    {
                                                                        this.getIcon(entry, row)
                                                                    }
                                                                </ListItemIcon>
                                                            }
                                                        </ListItem>
                                                    </Paper>
                                                );
                                            })}
                                        </List>
                                    </Paper>
                                )}
                            </Collapse>

                        </ListItemText>
                    )}

                    {entry.expandable && (
                        <ListItemIcon sx={{justifyContent: 'flex-end', alignItems: 'flex-start'}}>
                            <IconButton
                                disabled={entry.value?.length == 0}
                                size="small"
                                color={"secondary"}
                            >
                                {this.state.expandableFields[entry.key] || this.state.expandableFields[entry.key] == undefined ? (
                                    <ExpandLess/>
                                ) : (
                                    <ExpandMore/>
                                )}
                            </IconButton>
                        </ListItemIcon>
                    )}

                </ListItem>
            );
        });
    }
}
