
import {
    MissingAttackerModel,
    MissingVictimModel,
    ReportedAttackModel
} from "../../../../services/ReportedAttacks/reportedAttack.model";
import {InfiniteTableTableColumn} from "../../../Common/TableHelper/InfiniteTable";
import {format} from "date-fns";
import {PaginatedList, PaginatedListRow, PaginatedListText} from "../../../Common/PaginatedList";
import {IconButton, Typography} from "@mui/material";
import toTitleCase from "../../../Common/Utils/AutoCompleteOptions/toTitleCase";
import React from "react";
import {Box, Stack} from "@mui/system";
import {AppChip} from "../../../Common/common";
import LinearProgress from "@mui/material/LinearProgress";
import {useClipboard} from "../../../../hooks/useClipboard";
import {AddBusiness} from "@mui/icons-material";
import {useModal} from "mui-modal-provider";
import {CompanyDialog} from "../../CompanyProfiler/CompanyDialog/CompanyDialog";
import {Company, CompanyModel} from "../../../../services/company/company.model";
import {ErrorDialog} from "../../../Common/ErrorDialog/ErrorDialog";
import SimpleSnackbar from "../../../Common/SnackBar/SnackBar";

interface Props {
    data: {pending: boolean, value: MissingVictimModel[], fetching :boolean}
    pagination: { page: number, rowsPerPage: number, totalRecords: number };
    setPagination: (page: number, rowsPerPage: number) => void;
    loading: boolean;
    onProfiledCompany: (company: MissingVictimModel) => void;
}

export const ReportedAttackVictimTable = (props: Props) => {
    const clipboard = useClipboard();
    const {showModal} = useModal();

    const columns: PaginatedListRow<MissingVictimModel>[] = [
        {
            label: 'Company',
            render: (row: MissingVictimModel) => {
                return (
                    <PaginatedListText className={'overflow'}>{toTitleCase(row.victim_name)}</PaginatedListText>

                )
            },
            width: '70%'
        },
        {
            label: 'Country',
            render: (row: MissingVictimModel) => {
                return (
                    <PaginatedListText>{row.victim_country ? row.victim_country?.toUpperCase() : "------"}</PaginatedListText>
                )
            },
            width: '20%'
        },
        {
            label: 'Btn',
            render: (row: MissingVictimModel) => {
                return (
                    <IconButton onClick={(e) => {
                        e.stopPropagation();
                        const defaultCompany = new Company().toJSON();
                        showModal(CompanyDialog, {
                            company: {
                                ...defaultCompany,
                                name: row.victim_name,
                                x_country_of_origin: row.victim_country || ''
                            },
                            onClose: (company) => {
                                if (company) {

                                    showModal(SimpleSnackbar, {message: 'Company Successfully Profiled!'})
                                }
                            },
                            onError: (err) => {
                                showModal(ErrorDialog, {error: err})
                            }
                        })
                    }} color={'primary'} size={'small'}>
                        <AddBusiness />
                    </IconButton>
                )
            },
            width: '10%'
        }
    ]

    return (
        <Stack height={'100%'} spacing={1} >
            <Stack gap={1}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant={'h6'} fontWeight={600}>Missing Companies</Typography>
                    <AppChip size={'small'} color={'primary'} label={props.pagination.totalRecords.toString() || '0'}/>
                </Stack>
                <LinearProgress value={props.data.pending || props.data.fetching ? 100 : 0}
                                variant={props.data.pending || props.data.fetching ? 'indeterminate' : 'determinate'} color="secondary"/>

            </Stack>
            <PaginatedList
                data={props.data}
                onRowClick={(row) => {
                    clipboard.copy(row)
                }}
                columns={columns}
                loading={props.loading}
                pagination={props.pagination}
                setPagination={props.setPagination}
            />
        </Stack>
    )

}