import React, {useEffect, useState} from "react";
import {CompanyRisk} from "../../../../services/company/company.model";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from "recharts";
import {Stack} from "@mui/system";
import {Fade, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import toTitleCase from "../../../Common/Utils/AutoCompleteOptions/toTitleCase";

interface ThisProps {
    companyRisk: { value: CompanyRisk[], pending: boolean }
}


const COLORS = [
    '#FF6E40',  // Coral
    '#FFD460',  // Golden Poppy
    '#4CAF50',  // Medium Sea Green
    '#00BFFF',  // Deep Sky Blue
    '#FF4081',  // Pink
    '#9575CD',  // Medium Purple
    '#FF9800',  // Orange
    '#E040FB',  // Purple
    '#03A9F4',  // Light Blue
    '#CDDC39'   // Lime
];


interface PieChartLabel {
    name: string;
    value: number;
}


enum ChartView {
    ta_motivation,
    ta_intent,
    ta_sophistication,
    ta_resource_level,
    ta_type
}

const CustomLegend: React.FC<{ payload: any[] }> = ({payload}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginLeft: 20,
            alignItems: 'center',
            marginBottom: '30px'
        }}>
            {payload.map((entry, index) => (
                <div key={`item-${index}`} style={{
                    marginRight: 15, fontSize: '13px',
                }}>
                    <span style={{
                        display: 'inline-block',
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: entry.color
                    }}></span>
                    {toTitleCase(entry.value)}
                </div>
            ))}
        </div>
    );
};


const RiskCharts: React.FC<ThisProps> = (props: ThisProps) => {

    const [threatActorTypes, setThreatActorTypes] = useState<Record<string, number>>({});
    const [resourceLevel, setResourceLevel] = useState<Record<string, number>>({});
    const [intent, setIntent] = useState<Record<string, number>>({});
    const [motives, setMotives] = useState<Record<string, number>>({});
    const [sophistication, setSophistication] = useState<Record<string, number>>({});
    const [currentChartView, setCurrentChartView] = useState<ChartView>(ChartView.ta_type);

    useEffect(() => {
        const tempThreatActorTypes: Record<string, number> = {};
        const tempResourceLevel: Record<string, number> = {};
        const tempIntent: Record<string, number> = {};
        const tempMotives: Record<string, number> = {};
        const tempSophistication: Record<string, number> = {};


        props.companyRisk.value.forEach(record => {

            tempThreatActorTypes[record.threat_actor_type] = (tempThreatActorTypes[record.threat_actor_type] || 0) + 1;

            record.threat_actor_resource_level.forEach(level => {
                tempResourceLevel[level] = (tempResourceLevel[level] || 0) + 1;
            });

            record.threat_actor_intent.forEach(int => {
                tempIntent[int] = (tempIntent[int] || 0) + 1;
            });

            record.threat_actor_motives.forEach(motive => {
                tempMotives[motive] = (tempMotives[motive] || 0) + 1;
            });

            // assuming threat_actor_sophistication is a string and not an array
            tempSophistication[record.threat_actor_sophistication] = (tempSophistication[record.threat_actor_sophistication] || 0) + 1;
        });

        setThreatActorTypes(tempThreatActorTypes);
        setResourceLevel(tempResourceLevel);
        setIntent(tempIntent);
        setMotives(tempMotives);
        setSophistication(tempSophistication);
    }, []);


    const chartDataMap: Record<ChartView, Record<string, number>> = {
        [ChartView.ta_motivation]: motives,
        [ChartView.ta_intent]: intent,
        [ChartView.ta_sophistication]: sophistication,
        [ChartView.ta_resource_level]: resourceLevel,
        [ChartView.ta_type]: threatActorTypes
    };

    const currentData = chartDataMap[currentChartView];
    return (
        <Stack height={'100%'} width={'100%'} spacing={2}>
            <Stack direction={'row'}>
                <Typography flexGrow={1} variant={'h6'}>
                    Adversaries
                </Typography>

                <ToggleButtonGroup value={currentChartView} exclusive color="secondary"
                                   size={'small'} onChange={(e, v) => setCurrentChartView(v)}>
                    <ToggleButton value={ChartView.ta_type}>Type</ToggleButton>
                    <ToggleButton value={ChartView.ta_intent}>Intent</ToggleButton>
                    <ToggleButton value={ChartView.ta_motivation}>Motivation</ToggleButton>
                    <ToggleButton value={ChartView.ta_sophistication}>Sophistication</ToggleButton>
                    <ToggleButton value={ChartView.ta_resource_level}>Resource Level</ToggleButton>
                </ToggleButtonGroup>
            </Stack>

            {
                Object.values(currentData).length > 0 ?
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                            <Pie data={Object.entries(currentData).map(([key, value]) => ({name: key, value}))}

                                 cx={'50%'}
                                 cy={'50%'}
                                 innerRadius={'60%'}
                                 outerRadius={'80%'}
                                 fill="#8884d8"
                                 paddingAngle={5}
                                 dataKey="value"
                                 label={(entry) => `${entry.value}`}
                                 labelLine={false}
                            >
                                {Object.values(currentData).map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                ))}
                            </Pie>
                            <Legend content={CustomLegend as any} layout="horizontal"
                                    align="center"
                                    verticalAlign="top"/>
                        </PieChart>
                    </ResponsiveContainer>
                    :
                    <Stack height={'100%'} width={'100%'} justifyContent={'center'} alignItems={'center'}>
                        <Fade in={true} unmountOnExit>
                            <Typography variant={'h6'} color={'text.secondary'}>No Data</Typography>
                        </Fade>
                    </Stack>
            }


        </Stack>
    );
}

export default RiskCharts;