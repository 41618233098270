import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
}; //this is where your firebase app values you copied will go

firebase.initializeApp(firebaseConfig);



// source
// https://medium.com/geekculture/firebase-auth-with-react-and-typescript-abeebcd7940a

// running locally
//https://firebase.google.com/docs/emulator-suite/install_and_configure

// firebase emulators:start --only Authentication
export const auth = firebase.auth();


