import {
    Box,
    IconButton,
    Paper,
    Stack,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography,
} from "@mui/material";
import React from "react";
import {jsx} from "@emotion/react";
import JSX = jsx.JSX;

interface ThisProps {
    rows: SharableColumn[];
    headers: string[];
    data: any[] | null;

    maxRows?: number;
    height?: number;
}

interface ThisState {
    pagination: { page: number; rowsPerPage: number };
}

export type SharableColumn = StringColumn | IconButtonColumn | PropertyColumn;

/**
 * object accessor value. If an array of objects, a NEW PropertyColumn('path.to.value')
 * can be used.
 */
export class PropertyColumn {
    value: string;
    overrideComponent: ((value: any) => JSX.Element) | any;

    constructor(
        value: string,
        overrideComponent: ((value: any) => JSX.Element) | any = null
    ) {
        this.value = value;
        this.overrideComponent = overrideComponent;
    }
}

/**
 * A value can be set here to be displayed or if no value is passed in,
 * the data from props.data will be used. i.e string[]
 */
export class StringColumn {
    value: string | null;
    formatValue: ((v: string) => string) | null;

    // setting null defaults to data row value
    constructor(
        value: string | null,
        formatValue: ((v: string) => string) | null = null
    ) {
        this.value = value;
        this.formatValue = formatValue;
    }
}

/**
 * Icon button can be used here
 */
export class IconButtonColumn {
    icon: JSX.Element | ((value: any) => undefined | JSX.Element);
    onClick: ((index: any) => void) | null = null
    sx: SxProps<Theme> | {};

    constructor(
        icon: JSX.Element | ((value: any) => undefined | JSX.Element),
        onClick: ((index: number) => void) | null = null,
        sx: SxProps<Theme> | {} = {}
    ) {
        this.icon = icon;
        this.onClick = onClick;
        this.sx = sx;
    }
}

export class AnalysisSharableTable extends React.Component<
    ThisProps,
    ThisState
> {
    constructor(props: ThisProps) {
        super(props);
        this.state = {
            pagination: {page: 0, rowsPerPage: 5},
        };
    }

    handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        this.setState({pagination: {...this.state.pagination, page: newPage}});
    };

    handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        this.setState({
            pagination: {page: 0, rowsPerPage: parseInt(event.target.value, 10)},
        });
    };

    getProperty = (obj: any, prop: PropertyColumn): string | null => {
        const parts = prop.value.split(".");

        if (Array.isArray(parts)) {
            const last: keyof typeof obj = parts.length > 1 ? parts.pop()! : parts[0];
            var l = parts.length,
                i = 1,
                current = parts[0];

            while ((obj = obj[current]) && i < l) {
                current = parts[i];
                i++;
            }

            if (typeof obj === "object") {
                return obj[last];
            }
            return obj;
        } else {
            return null;
        }
    };

    getValue(
        row_definition: StringColumn | PropertyColumn | IconButtonColumn,
        data_row: any[],
        row_index: number
    ) {
        if (row_definition instanceof StringColumn) {
            let value = row_definition.value ? row_definition.value : data_row;

            if (row_definition.formatValue && value) {
                return row_definition.formatValue(value as string);
            } else {
                return value;
            }
        } else if (row_definition instanceof PropertyColumn) {
            const value = this.getProperty(data_row, row_definition);
            if (row_definition.overrideComponent) {
                return row_definition.overrideComponent(value);
            } else {
                return value;
            }
        } else {

            if (typeof row_definition.icon == 'function') {
                return row_definition.icon(data_row)
            } else {
                return (
                    <IconButton
                        sx={row_definition.sx}
                        onClick={(e) => {
                            if (row_definition.onClick) {
                                row_definition.onClick(row_index);
                            }
                        }}
                    >
                        {row_definition.icon}
                    </IconButton>
                )
            }
        }
    }

    render(): React.ReactNode {
        return (
            <React.Fragment>
                <Box>
                    <TableContainer
                        sx={{
                            height: this.props.height ? this.props.height : '300px',
                            overflowY: 'auto'
                        }}
                        component={Paper}
                        elevation={4}
                    >
                        <Table
                            size="small"
                            stickyHeader
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    {this.props.headers.map((header) => (
                                        <TableCell key={Math.random()}>{header}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{
                                // overflowY: 'auto',
                            }}>
                                {this.props.data &&
                                    this.props.data.map((data_row, data_row_index) => (
                                        <TableRow key={data_row_index}>
                                            {this.props.rows.map((def, def_index) => (
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    key={Math.random()}
                                                >
                                                    {this.getValue(def, data_row, data_row_index)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}

                                {this.props.data?.length == 0 && (
                                    <TableRow sx={{width: "100%"}}>
                                        <Stack padding={"50px"} width={"100%"}>
                                            <Typography variant={"h6"} sx={{opacity: ".5"}}>
                                                No Data
                                            </Typography>
                                        </Stack>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/*<TablePagination*/}
                    {/*    rowsPerPageOptions={*/}
                    {/*        this.props.maxRows ? [this.props.maxRows] : [5, 15, 25]*/}
                    {/*    }*/}
                    {/*    component="div"*/}
                    {/*    count={this.props.data ? this.props.data.length : 0}*/}
                    {/*    rowsPerPage={this.state.pagination.rowsPerPage}*/}
                    {/*    page={this.state.pagination.page}*/}
                    {/*    onPageChange={this.handleChangePage}*/}
                    {/*    onRowsPerPageChange={this.handleChangeRowsPerPage}*/}
                    {/*/>*/}
                </Box>
            </React.Fragment>
        );
    }
}
