import React, {useContext, useEffect, useState} from "react";
import {Box, Stack} from "@mui/system";
import {CompanyRisk} from "../../../../services/company/company.model";

import PublicIcon from '@mui/icons-material/Public';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {CrisisAlert} from "@mui/icons-material";
import {InfoCard} from "../HomeCommon";
import {Typography} from "@mui/material";
import {AppChip} from "../../../Common/common";
import {AuthContext} from "../../../../context/AuthContext";

interface ThisProps {
    companyRisk: { value: CompanyRisk[], pending: boolean }
}

const RiskMetrics: React.FC<ThisProps> = (props: ThisProps) => {
    const {user} = useContext(AuthContext);

    const [threatActorTargets, setThreatActorTargets] = useState<{
        country: number,
        region: number,
        sector: number,
        likelihoodOfAttack: number
    }>({
        country: 0,
        region: 0,
        sector: 0,
        likelihoodOfAttack: 0
    })

    const [newThreatsLength, setNewThreatsLength] = useState<number>(0);


    useEffect(() => {
        calculateThreatActorTargets();
    }, []);


    const calculateThreatActorTargets = () => {
        const lastUserLogin = new Date(user!.last_sign_in!);

        const copiedTargets = {
            country: 0,
            region: 0,
            sector: 0,
            likelihoodOfAttack: 0
        };


        let recordCountSinceLastLogin = 0;
        for (let record of props.companyRisk.value) {
            const recordDate = new Date(record.threat_actor_relationship_identified);

            if (record.threat_actor_targets_country) {
                copiedTargets.country += 1;
            }

            if (record.threat_actor_targets_region) {
                copiedTargets.region += 1;
            }

            if (record.threat_actor_targets_sector) {
                copiedTargets.sector += 1;
            }

            if (record.likelihood_of_attack.toLowerCase().includes("high")) {
                copiedTargets.likelihoodOfAttack += 1;
            }


            if (recordDate > lastUserLogin) {
                recordCountSinceLastLogin += 1;
            }

        }

        setNewThreatsLength(recordCountSinceLastLogin);
        setThreatActorTargets(copiedTargets)
    }


    return (
        <Stack gap={1}>
            <Box
                sx={{
                    display: 'flex',
                    gap: theme => theme.spacing(2),
                    padding: theme => theme.spacing(1),
                    width: '100%',
                    position: 'relative'
                }}
            >
                <InfoCard icon={ErrorOutlineIcon} iconColor={'error'} mainText={
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <Typography variant={'h6'} fontWeight={'500'}>
                            {props.companyRisk.value?.length}
                        </Typography>
                        {
                            newThreatsLength > 0 &&
                            <AppChip color={'error'} sx={{fontSize: '.6rem'}} size={'small'}
                                     label={`${newThreatsLength} New`}/>
                        }

                    </Stack>
                }
                          subText="Total Threats"/>
                <InfoCard icon={CrisisAlert} iconColor={'error'} mainText={threatActorTargets.likelihoodOfAttack}
                          subText="Likelihood Of Attack"/>
                <InfoCard icon={BusinessIcon} iconColor={'warning'} mainText={threatActorTargets.sector}
                          subText="Targeting Sector"/> {/* Default number */}
                <InfoCard icon={PublicIcon} iconColor={'warning'} mainText={threatActorTargets.region}
                          subText="Targeting Region"/> {/* Default number */}
                <InfoCard icon={LocationOnIcon} iconColor={'warning'} mainText={threatActorTargets.country}
                          subText="Targeting Country"/> {/* Default number */}
            </Box>
        </Stack>
    );
}

export {RiskMetrics};
