import * as React from 'react';
import {styled} from '@mui/material/styles';
import Tooltip, {tooltipClasses, TooltipProps} from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import {Stack} from "@mui/material";

const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontWeight: 'bold',
        background: theme.palette.error.main,
        fontSize: theme.typography.pxToRem(8),
        border: '1px solid #dadde9',
    },
}));

interface Props {
    children: React.ReactElement;
    message: string | null;
    open: boolean;
}

export default function ErrorToolTip(props: Props) {
    const {children, open, message} = props;
    return (
        <HtmlTooltip
            arrow
            placement="bottom-end"
            open={open}
            title={
                <React.Fragment>
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                        <ReportGmailerrorredIcon/>

                        <Typography color="inherit">{message}</Typography>
                    </Stack>
                </React.Fragment>
            }
        >
            {children}
        </HtmlTooltip>
    );
}