import {ApiService, PaginationRequest} from "../api-service";
import {ThreatActor} from "./threatActor.model";
import {PaginationResult} from "../globals";

export default class ThreatActorService extends ApiService {
    public async getPaginatedThreatActors(pagination: PaginationRequest, adversaryName?: string): Promise<PaginationResult<ThreatActor>> {
        let query_params: any = {
            offset: pagination.page * pagination.rowsPerPage,
            limit: pagination.rowsPerPage,
            name: adversaryName
        };

        return super.get<PaginationResult<ThreatActor>>('threat-actors', query_params);
    }

    public async getThreatActors(): Promise<ThreatActor[]> {
        return super.get<ThreatActor[]>('threat-actors')
    }

    public async groupThreatActors(aliasesActorIds: string[], primaryActorId: string): Promise<void> {
        return super.put<void>('threat-actors/' + primaryActorId, aliasesActorIds)
    }

    public async getThreatActor(id: string): Promise<ThreatActor> {
        return super.get<ThreatActor>('threat-actors/' + id)
    }

    public async getThreatActorByName(name: string): Promise<ThreatActor> {
        return super.get<ThreatActor>('threat-actors/' + name)
    }
}