import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    ListItem,
    ListItemText,
    Stack,
    useTheme
} from "@mui/material";
import List from "@mui/material/List";
import {styled} from "@mui/material/styles";
import {ThreatReportHotKeys} from "../../../../services/ThreatAnalysis/threatAnalysis.model";


const OSLabel = styled(ListItemText)(({theme}) => ({
    width: '30%',
    '& > span': {
        fontWeight: 600
    }
}));

const CommandLabel = styled(ListItemText)(({theme}) => ({
    width: '40%',
    color: theme.palette.text.secondary,
}));

const CommandText = styled(ListItemText)(({theme}) => ({
    width: '30%'

}));

const CommandRow = styled(ListItem)(({theme}) => ({
    ':hover': {
        background: theme.palette.action.hover
    }
}));

const HotKeyHelpDialog = () => {
    const [open, setOpen] = useState(true);

    const theme = useTheme();

    const macSymbol = '⌘';


    return (
        <Dialog open={open} maxWidth={'md'} fullWidth>
            <DialogTitle>
                Report Hot Keys
            </DialogTitle>
            <DialogContent>
                <List>
                    <CommandRow divider>
                        <CommandLabel sx={{'& > span': {color: theme.palette.text.primary, fontWeight: 600}}}>Action</CommandLabel>
                        <OSLabel>Mac</OSLabel>
                        <OSLabel>Windows / Linux</OSLabel>
                    </CommandRow>
                    {
                        Object.values(ThreatReportHotKeys).map((c) => (
                            <CommandRow divider key={c.label}>
                                <CommandLabel>{c.label}</CommandLabel>
                                <CommandText>{`${macSymbol} + ${c.cmd}`}</CommandText>
                                <CommandText>{`Ctrl + ${c.cmd}`}</CommandText>
                            </CommandRow>
                        ))
                    }
                </List>

                <DialogContentText sx={{paddingY: theme.spacing(1)}}>
                    ** Some commands will not run unless the Report is locked by you.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    )


}

export default HotKeyHelpDialog;