import {Add, ClearOutlined, Groups2} from "@mui/icons-material";
import {Box, Button, IconButton, InputAdornment, Stack, TextField, Toolbar,} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import SimpleSnackbar from "../../Common/SnackBar/SnackBar";
import withRouter from "../../Common/Utils/WithRouter/WithRouter";
import {ErrorDetails, ErrorDialog} from "../../Common/ErrorDialog/ErrorDialog";
import {ThreatActor} from "../../../services/ThreatActor/threatActor.model";
import ThreatActorService from "../../../services/ThreatActor/threatActor.service";
import {ThreatActorTable} from "./ThreatActorTable";
import DrawerStateContext, {DrawerStateContextType} from "../../../context/DrawerContext";
import {AppToolbar} from "../../Common/Drawer/NestedDrawer";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {AppTitle} from "../../Common/common";
import {defaultPagination, PaginationRequest} from "../../../services/api-service";
import {useModal} from "mui-modal-provider";
import {DebouncedTextField} from "../../Common/DebouncedTextField";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import {ThreatActorGroupDialog} from "./ThreatActorGroupDialog";

interface ThisState {
    threat_actors: { pending: boolean; value: ThreatActor[] };
    filter: string;
    errorDialog: { opened: boolean; details: ErrorDetails | null };
    snackbar: { opened: boolean, message: string }
    pagination: PaginationRequest

}

interface ThisProps {
    drawerContext?: DrawerStateContextType

}

export const ViewThreatActorComponent = (props: ThisProps) =>  {
    const threatActorService = new ThreatActorService();

    const [threatActors, setThreatActors] = useState<{pending: boolean, value: ThreatActor[], fetching: boolean}>({fetching: false, pending: true, value:[]})
    const [filter, setFilter] = useState('');
    const [pagination, setPagination] = useState<PaginationRequest>(defaultPagination)

    const {showModal} = useModal();
    const drawerContext = useContext(DrawerStateContext);
    const [checkedRows, setCheckedRows] = useState<ThreatActor[]>([]);

    const handleFetchThreatActors = (thisPagination: any): void => {
        setThreatActors({...threatActors, pending: true})

        threatActorService.getPaginatedThreatActors(thisPagination, filter)
            .then((res) => {
                setThreatActors({value: [...threatActors.value, ...res.results], pending: false, fetching: false})
                setPagination({...thisPagination, totalRecords:  res.count})
            })
            .catch((err) => {
                showModal(ErrorDialog, {error: err});
                setThreatActors({...threatActors, pending: false})
            });
    }


    const handleSearchThreatActors = (filter: string): void  => {
        setThreatActors({...threatActors, fetching:true})
        threatActorService
            .getPaginatedThreatActors(pagination, filter)
            .then((res) => {
                setThreatActors({...threatActors, value: res.results, fetching: false})
                if (pagination) {
                    setPagination({...pagination, totalRecords:  res.count, page: 0})
                }
            })
            .catch((err) => {
                showModal(ErrorDialog, {error: err});
                setThreatActors({...threatActors, fetching: false})
            });
    }

    const handleToggleRow = (threatActor: ThreatActor) => {
        if (checkedRows.includes(threatActor)) {
            setCheckedRows(checkedRows.filter(r => r.id !== threatActor.id));
        } else {
            setCheckedRows([...checkedRows, threatActor])
        }
    }

    const handleGroupDialogClose = (res: boolean) => {

    }



    return (
        <Stack display={'flex'} flexDirection={'column'} sx={{overflow: 'hidden'}}>
            <AppToolbar open={drawerContext?.isDrawerOpen}>
                <Toolbar disableGutters>
                    <Stack
                        sx={{width: '100%'}}
                        alignItems={"center"}
                        justifyContent={'space-between'}
                        direction={"row"}
                    >
                        <AppTitle variant={'h5'}>
                            Threat Actors
                        </AppTitle>

                        <Button
                            onClick={() => {showModal(ThreatActorGroupDialog, {selectedAdversaries: checkedRows, onClose: (res: boolean) => handleGroupDialogClose(res)})}}
                            disabled={threatActors.fetching || threatActors.pending || checkedRows.length == 0}
                            color="secondary"
                            variant={'contained'}
                            size={'small'}
                            endIcon={<Groups2/>}
                        >Group</Button>
                    </Stack>
                </Toolbar>
            </AppToolbar>

            <Stack
                sx={{
                    mt: theme => theme.spacing(8),
                    height: theme => `calc(100vh - ${theme.spacing(12)})`
                }}
                spacing={2}>
                <DebouncedTextField showSpinner={threatActors.fetching}
                                    placeholder={'Search Adversaries'}

                                    filter={filter}
                                    onValueChanged={(filter) => setFilter(filter)}

                                    onSearch={(value) => {
                                        if (pagination)
                                            setPagination({...pagination, page: 0})

                                        handleSearchThreatActors(value);
                                    }}
                />
                <ThreatActorTable
                    threat_actors={threatActors}
                    filter={filter}
                    checkedRows={checkedRows}
                    onToggleRow={handleToggleRow}
                    pagination={pagination}
                    setPagination={(page: number, rowsPerPage: number) => {
                        const newPagination = {...pagination, page: page, rowsPerPage: rowsPerPage};
                        handleFetchThreatActors(newPagination);
                    }}
                />

            </Stack>

        </Stack>
    );
}
