import {
    Checkbox, Divider,
    IconButton,
    ListItemIcon,
    ListItemText, Menu,
    MenuItem,
    MenuList,
    Typography,
    useTheme
} from "@mui/material";
import {User, UserGroup} from "../../../services/User/user.model";
import React, {useState} from "react";
import InfiniteTable, {InfiniteTableTableColumn} from "../../Common/TableHelper/InfiniteTable";
import {AppChip} from "../../Common/common";
import {Stack} from "@mui/system";
import {format} from "date-fns";
import {
    AutoGraph,
    Business,
    DeleteOutline,
    EditOutlined,
    Engineering,
    LockReset,
    ManageAccounts, MoreVert,
    NoAccounts
} from "@mui/icons-material";
import {CompanyModel} from "../../../services/company/company.model";

interface Props {
    users: {value: User[], pending: boolean};
    filter: string;
    onEdit: (user: User) => void;
    onDelete: (user: User) => void;
    onResetPassword: (user: User) => void;
}

const GroupColor = {
    [UserGroup.Admin] : "#DD614A",
    [UserGroup.Analyst] : "#F0A202",
    [UserGroup.Entity]: "#454ADA",
    [UserGroup.NonAttributable]: "#7379d3"
}

export const UserTableComponent = ({users, filter, onEdit, onDelete, onResetPassword}: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<{elm: null | HTMLElement, user: User | null}>({elm: null, user: null});
    const handleClick = (event: React.MouseEvent<HTMLElement>, user: User) => {
        setAnchorEl({elm: event.currentTarget, user: user});
    };

    const handleClose = () => {
        setAnchorEl({elm: null, user: null});
    };


    const theme = useTheme();


    const columns: InfiniteTableTableColumn<User>[] = [
        {
            key: "firebase_id",
            label: "Firebase ID",
            width: "15%",
            allowCopy: true
        },

        {
            key: 'email',
            label: 'Name',
            width: '25%',
            allowCopy: true
        },
        {
            key: 'group',
            label: 'Group',
            width: '20%',
            render: (row: User) => {
                if (!row.group)
                    return null;

                let Icon;
                let color;
                if (row.group === UserGroup.Admin) {
                    Icon = ManageAccounts;
                } else if (row.group === UserGroup.Analyst) {
                    Icon = Engineering;
                } else if (row.group === UserGroup.Entity) {
                    Icon = Business
                } else {
                    Icon = NoAccounts;
                }

                return (
                    <AppChip size={'small'} sx={{background: GroupColor[row.group]}}  label={(
                        <Stack alignItems={'center'} gap={1} direction={'row'}>
                            <Typography fontWeight={600} variant={'body2'}>{row.group}</Typography>
                            <Icon />
                        </Stack>
                    )} />
                )
            }
        },

        {
            key: 'company_name',
            label: 'Company',
            width: '25%',
            allowCopy: true
        },

        {
            key: 'created_on',
            label: 'Added On',
            width: '10%',
            render: (row: User) => format(
                new Date(row.created_on),
                "MM/dd/yyyy"
            )
        },

        {
            key: 'delete' as keyof User,
            width: '5%',
            label: '',
            render: (row: User) => (

                <IconButton
                    sx={{padding: 0}}
                    color="secondary"
                    onClick={(event) => {
                        event.stopPropagation();
                        handleClick(event, row)
                    }}
                >
                    <MoreVert/>
                </IconButton>

            ),
        },



    ]

    const applyFilter = () => {
        const filteredValue = filter.trim().toLowerCase();
        return users.value.filter((user) => {
            return user.email?.toLowerCase().includes(filteredValue) ||
                   user.group?.toLowerCase()?.includes(filteredValue) ||
                   user.company_name?.toLowerCase()?.includes(filteredValue)
        })
    }


    return (
        <>
            <InfiniteTable
                data={applyFilter()}
                columns={columns}
                loading={users.pending}
                noDataText={'No Users'}
            />


            <Menu anchorEl={anchorEl.elm} open={anchorEl.elm !== null} onClose={handleClose}>
                <MenuList>
                    <MenuItem onClick={() => {
                        onEdit(anchorEl.user!)
                        handleClose();
                    }}>
                        <ListItemIcon>
                            <EditOutlined color={'primary'} fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Edit Access</ListItemText>
                    </MenuItem>

                    <Divider />


                    <MenuItem onClick={() => {
                        onResetPassword(anchorEl.user!);
                        handleClose();
                    }}>
                        <ListItemIcon>
                            <LockReset color={'primary'} fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Reset Password</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => {
                        onDelete(anchorEl.user!);
                        handleClose();
                    }}>
                        <ListItemIcon >
                            <DeleteOutline color={'error'} fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Remove</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    );
}