import {ThreatActor} from "../../../../services/ThreatActor/threatActor.model";
import React from "react";
import {TabPanel} from "@mui/lab";
import {Stack, Theme, useTheme} from "@mui/material";
import {styled} from "@mui/material/styles";
import ThreatActorOverview from "./ThreatActorOverview/ThreatActorOverview";
import TargetLocations from "./TargetEntities/TargetLocations";
import ThreatActorSectors from "./TargetEntities/ThreatActorSectors";
import {Box} from "@mui/system";

interface ThisProps {
    threatActor: {
        pending: boolean,
        value: ThreatActor
    }
}

const AppTabPanel = styled(TabPanel)<{
    theme?: Theme
}>(({theme}) => ({
    padding: `${theme!.spacing(3)} 0`,
    overflow: 'hidden',
    height: '100%'
}));


const generateColor = () => {
    const h = Math.floor(Math.random() * 360);
    const s = 100; // Keep saturation at 100% to get vibrant colors
    const l = 50;  // Lightness at 50%
    return `hsl(${h},${s}%,${l}%)`;
}


const ThreatActorDialogViews: React.FC<ThisProps> = (props: ThisProps) => {


    const theme = useTheme();
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', gap: 2}}>

            {/* ThreatActorOverview takes its natural height */}
            <Box sx={{maxHeight: '45%', overflow: 'hidden', flexShrink: 0}}>
                <ThreatActorOverview threatActor={props.threatActor.value!}/>
            </Box>

            {/* This Box will take up the remaining height */}
            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'row', gap: 2, overflow: 'hidden'}}>

                {/* TargetLocations will take up 60% of the width */}
                <Box sx={{width: '60%', height: '100%', overflow: 'hidden'}}>
                    <TargetLocations threatActor={props.threatActor.value}/>
                </Box>

                {/* ThreatActorSectors will take up 40% of the width */}
                <Box sx={{width: '40%', overflow: 'hidden'}}>
                    <ThreatActorSectors threatActor={props.threatActor.value}/>
                </Box>
            </Box>
        </Box>
    );
}

export {ThreatActorDialogViews, generateColor}