import {Box, Divider, IconButton, Paper, Stack, TextField, Typography} from "@mui/material";
import React, {ReactNode} from "react";
import {ReportAnalysis} from "../../../../../../../services/ThreatAnalysis/threatAnalysis.model";
import _ from "lodash";
import {Container} from "@mui/system";
import {Add, DeleteOutline, Grading} from "@mui/icons-material";
import {
    AnalysisSharableTable,
    IconButtonColumn,
    StringColumn
} from "../../../../../../Common/TableHelper/SharableTable";

interface ThisProps {
    analysis: ReportAnalysis;
    onUpdate: (key: keyof ReportAnalysis, value: any) => void;
    doesEntryExistInText: (text: string) => boolean;
}

interface ThisState {
    input: string;
}

export class AttackConsequence extends React.Component<ThisProps, ThisState> {
    private _key: keyof ReportAnalysis = "attack_consequences";

    constructor(props: ThisProps) {
        super(props);
        this.state = {input: ""};
    }

    onUpdate(): void {
        this.props.onUpdate(this._key, [
            this.state.input,
            ...this.props.analysis.attack_consequences,
        ]);
        this.setState({input: ""});
    }

    onDelete(row_index: number): void {
        const copy = _.cloneDeep(this.props.analysis.attack_consequences);

        this.props.onUpdate(
            this._key,
            copy.filter((value, index) => index !== row_index)
        );

        this.setState({input: ""});
    }


    render(): ReactNode {
        let error = "";
        if (
            this.props.analysis.attack_consequences.includes(this.state.input)
        ) {
            error = "Mitre Att&ck ID already exist";
        } else if (
            this.state.input.trim() !== "" &&
            !/T\d{4}(\.\d{3})?$/.test(this.state.input)
        ) {
            error = "Invalid Mitre Att&ck ID Format";
        }

        return (
            <Stack spacing={1}>
                <Box sx={{width: "100%", padding: "0 8px"}}>
                    <Stack
                        sx={{mb: "12px"}}
                        direction="row"
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Typography fontSize={"1.25rem"} fontWeight={600}>
                            Add Consequence
                        </Typography>
                        <IconButton
                            sx={{
                                padding: 0,
                                borderRadius: "50%",
                                border: (theme) =>
                                    "1px solid " + theme.palette.secondary.main,
                            }}
                            size="small"
                            color="secondary"
                            disabled={this.state.input.trim() == "" || !!error}
                            onClick={this.onUpdate.bind(this)}
                        >
                            <Add/>
                        </IconButton>
                    </Stack>
                    <TextField
                        fullWidth
                        size={"small"}
                        color={"secondary"}
                        label="Mitre Att&ck ID"
                        helperText={error.trim() !== "" ? error : " "}
                        error={!!error}
                        value={this.state.input}
                        onChange={(event) => this.setState({input: event.target.value})}
                    />
                </Box>
                <Divider/>
                <AnalysisSharableTable
                    headers={["Mitre Attack ID", "Identified In Source", ""]}
                    data={this.props.analysis.attack_consequences}
                    rows={[
                        new StringColumn(null),
                        new IconButtonColumn(
                            (value: string) => {
                                if (this.props.doesEntryExistInText(value)) {
                                    return <Grading color={'success'}/>
                                }
                            },
                        ),
                        new IconButtonColumn(
                            <DeleteOutline color="error"/>,
                            (row_index) => {
                                this.onDelete(row_index);
                            },
                            {float: "right"}
                        ),
                    ]}
                />
            </Stack>
        );
    }
}
