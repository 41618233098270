import React from "react";
import {Box} from "@mui/system";
import {Typography} from "@mui/material";

interface ThisProps {
}

const PublicSettings: React.FC<ThisProps> = () => {
    return (
        <Box>
            <Typography>Help</Typography>
        </Box>
    );
}

export default PublicSettings;