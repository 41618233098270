import React, {useContext, useState} from "react";
import {Box, Button, Card, CardContent, CardHeader, Fade, Stack, Toolbar, Typography, useTheme} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import {DebouncedTextField} from "../Common/DebouncedTextField";
import toTitleCase from "../Common/Utils/AutoCompleteOptions/toTitleCase";
import {useModal} from "mui-modal-provider";
import {AnonymousIncidentReportDialog} from "./AnonymousIncidentReportDialog";
import SimpleSnackbar from "../Common/SnackBar/SnackBar";
import {useNavigate, useNavigation} from "react-router-dom";
import AnonymousAuthContext from "../../context/AnonymousAuthContext";
import {AppTitle} from "../Common/common";


export type CyberObservable =  'autonomous-system'| 'domain-name'| 'email-addr'|  'ipv4-addr'| 'ipv6-addr'| 'mac-addr'



export const AnonymousLandingPage = () => {
    const [filter, setFilter] = useState("");
    const [loading, setLoading] = useState(true);

    const authContext = useContext(AnonymousAuthContext);

    const {showModal} = useModal();

    const navigate = useNavigate();

    const cyberObservables: CyberObservable[] = [
        'autonomous-system', 'domain-name', 'email-addr',  'ipv4-addr', 'ipv6-addr', 'mac-addr'
    ]

    const handleOnExit = () => {
        authContext.clearToken();
        navigate('/')
    }

    const theme = useTheme();
    return (
        <Box height={'100vh'} display={'flex'} flexDirection={'column'}>
            <AppBar>
                <Toolbar>
                    <Stack width={'100%'} alignItems={'center'} justifyContent={'space-between'} direction={"row"}>
                        <AppTitle variant={'h5'}>
                            Report Incident Anonymously
                        </AppTitle>

                        <Button onClick={handleOnExit} sx={{color: theme.palette.text.primary}}>
                            Exit
                        </Button>
                    </Stack>
                </Toolbar>
            </AppBar>

            <Stack
                sx={{
                    mt: theme => theme.spacing(8),
                    padding: theme.spacing(2),
                    height: theme => `calc(100vh - ${theme.spacing(12)})`
                }}
                spacing={2}>
                <DebouncedTextField showSpinner={loading && filter?.trim() !== ''}
                                    placeholder={'Search for incident'}

                                    filter={filter}
                                    onValueChanged={(filter) => setFilter(filter)}

                                    onSearch={(value) => {}}
                />

                <Stack position={'relative'} gap={2} rowGap={3} flexWrap="wrap" direction="row">
                    {cyberObservables.filter((w) => w.toLowerCase().includes(filter.toLowerCase().trim())).map(obs => {
                        return (
                            <Card elevation={4} sx={{
                                height: theme.spacing(15),
                                cursor: 'pointer',
                                transition: 'all ease-in-out 250ms',
                                ':hover': {
                                    transform: 'translateY(-4px)', // Moves the card up by 4px on hover
                                    boxShadow: theme.shadows[10], // Use a higher index for a more prominent shadow if needed
                                    // background: theme.palette.action.hover,
                                },
                                borderTop: '2px solid ' + theme.palette.primary.main,
                                width: `calc(20% - ${theme.spacing(2)})`
                            }} onClick={() => showModal(AnonymousIncidentReportDialog, {type: obs, onClose: (reported: boolean) => reported && showModal(SimpleSnackbar, {message: "Successfully reported incident!"})})}>
                                <CardContent>
                                    <Typography fontWeight={600} variant="body1" fontSize={'1rem'}>{toTitleCase(obs)}</Typography>
                                </CardContent>
                            </Card>
                        )
                    })}
                </Stack>




            </Stack>
        </Box>

    )
}