import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {Stack} from "@mui/system";

interface ThisProps {
    onClose: (confirmed: boolean | null) => void;
}

interface ThisState {
}

export class ConfirmationDialog extends React.Component<ThisProps, ThisState> {
    constructor(props: ThisProps) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Dialog open={true} maxWidth={'sm'} fullWidth>
                <DialogTitle sx={{fontWeight: 600, color: theme => theme.palette.warning.main}}>Delete
                    Confirmation</DialogTitle>
                <DialogContent>
                    <Stack direction={'row'} spacing={1}>

                        <Typography>
                            Once this has been deleted, it is permanent and cannot be undone. Continue?
                        </Typography>

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button sx={{color: theme => theme.palette.text.primary, mr: (theme) => theme.spacing(2)}}
                            onClick={() => this.props.onClose(false)}>
                        Cancel
                    </Button>
                    <Button onClick={() => this.props.onClose(true)} variant={'contained'} color={'error'}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
