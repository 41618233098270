import React, {ReactNode} from "react";
import {Card, CardContent, SvgIconProps, Theme, Typography} from "@mui/material";
import {lighten, Stack} from "@mui/system";

interface InfoCardProps {
    icon: React.ElementType<SvgIconProps>;
    iconColor: "inherit" | "action" | "disabled" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
    mainText: string | number | ReactNode;
    subText: string;

    disabled?: boolean;
    onClick?: (v: any) => void;
}


const onInfoCardClick = (onClick: Function | undefined, e: any, isClickable: boolean) => {
    if (isClickable) {
        onClick!(e)
    }
}


const InfoCard: React.FC<InfoCardProps> = ({icon: Icon, iconColor, mainText, subText, onClick, disabled}) => {
    const isClickable = (): boolean => {
        return !disabled && onClick !== undefined
    }

    return (
        <Card
            onClick={(e) => onInfoCardClick(onClick, e, isClickable())}
            elevation={2}
            sx={{
                flexGrow: 1,
                flexBasis: '25%',
                position: 'relative',
                transition: 'transform .2s', // Transition for smooth animation
                '&:hover': {
                    transform: 'translateY(-5px)',
                    cursor: isClickable() ? 'pointer' : '',
                    background: (theme: Theme) =>
                        isClickable() ? lighten(theme.palette.background.default, 0.15) : ''
                }
            }}
        >
            <CardContent className="custom-card-content"
                         sx={{'&.custom-card-content': {paddingTop: 1, paddingBottom: 2}}}>
                <Stack alignItems={'center'} spacing={3} direction={'row'}>
                    <Icon fontSize={'large'} color={iconColor}/>
                    <Stack sx={{maxWidth: 'calc(100% - 45px)', /* assuming an approximate width for the icon */}}>
                        {
                            typeof mainText == 'object' ?
                                mainText :
                                <Typography variant="h6" fontWeight={500} component="div">
                                    {mainText as string | number}
                                </Typography>
                        }
                        <Typography fontWeight={600} variant={'caption'} className={'overflow'} color="text.secondary">
                            {subText}
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}


const YLabels = ['likelihood_of_attack', 'impact_type', 'operational_risk', 'financial_risk', 'legal_compliance_risk', 'reputational_risk'];


export {InfoCard, YLabels}