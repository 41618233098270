import {ThreatActor} from "../../../../../services/ThreatActor/threatActor.model";
import React from "react";
import {Box, Stack} from "@mui/system";
import {Divider, Paper, Typography} from "@mui/material";
import toTitleCase from "../../../../Common/Utils/AutoCompleteOptions/toTitleCase";
import {LocationOn} from "@mui/icons-material";
import {format} from "date-fns";
import {country} from "iso-3166-2";
import {ThreatActorType} from "../../../../../services/ThreatAnalysis/threatAnalysis.model";

interface ThisProps {
    threatActor: ThreatActor
}

const bull = (
    <Typography component={'div'} variant={'h5'}>
        <Box
            component="span"
            sx={{display: 'inline-block', mx: '2px', transform: 'scale(0.8)'}}
        >
            •
        </Box>
    </Typography>
);

const bannerDetails = (label: string, value: string | undefined | number) => {
    return (
        <Stack>
            <Typography variant={'subtitle2'} color={'text.secondary'}>
                {label}
            </Typography>
            <Typography flexGrow={1} variant={'body1'}>
                {value !== undefined ? toTitleCase(value.toString()) : '-----'}
            </Typography>
        </Stack>
    )
}

const bannerListDetails = (label: string, value: string[] | undefined) => {
    return (
        <Stack>
            <Typography variant={'subtitle2'} color={'text.secondary'}>
                {label}
            </Typography>
            <Stack direction={'row'} alignItems={'center'} spacing={.5}>
                {
                    value?.map((type, index) =>
                        <Stack direction={'row'} alignItems={'center'} spacing={.5}>
                            <Typography variant={'body1'}>
                                {toTitleCase(type)}
                            </Typography>

                            {
                                index !== value.length - 1 && bull
                            }
                        </Stack>
                    )
                }
            </Stack>
        </Stack>
    )
}

const ThreatActorDetailsBanner: React.FC<ThisProps> = (props: ThisProps) => {
    return (
        <Box component={Paper} elevation={3} sx={{height: '100%', overflow: 'auto'}}>
            <Stack sx={{padding: 3, height: '100%'}} justifyContent={'space-around'} spacing={3}>
                <Stack spacing={1}>
                    <Typography variant={'h5'}>
                        {toTitleCase(props.threatActor.name)}
                    </Typography>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <LocationOn fontSize={'small'} color={'info'}/>
                        <Typography variant={'subtitle2'}
                                    color={'text.secondary'}>{props.threatActor.country_of_origin ? country(props.threatActor.country_of_origin!)?.name : "Unknown"}</Typography>
                    </Stack>
                </Stack>

                <Stack direction={'row'} spacing={5}>
                    {
                        bannerDetails('Last Seen', (props.threatActor.created || props.threatActor.modified) ? format(new Date(props.threatActor.modified ? props.threatActor.modified! : props.threatActor.created), "MM/dd/yyyy"
                        ) : ' -----')
                    }

                    <Divider orientation={'vertical'} flexItem/>


                    {
                        bannerDetails('Type', props.threatActor.threat_actor_types)
                    }

                    <Divider orientation={'vertical'} flexItem/>

                    {
                        bannerDetails('Sophistication', props.threatActor.x_sophistication)
                    }


                </Stack>

            </Stack>
        </Box>
    )
}

export default ThreatActorDetailsBanner;