import React from "react";
import {Card, CardContent, Paper, Typography, useTheme} from "@mui/material";
import {Box, lighten, Stack} from "@mui/system";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import toTitleCase from "../../../../../Common/Utils/AutoCompleteOptions/toTitleCase";

interface ThisProps {
    value: string[];
    label: string;
    icon: any;
}

const ThreatActorAttribute: React.FC<ThisProps> = (props: ThisProps) => {
    const theme = useTheme();

    return (
        <Card elevation={3} sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* This is the title that remains fixed at the top */}
            <Paper>
                <Stack direction={'row'} alignItems={'center'}
                       sx={{flexGrow: 0, paddingX: theme.spacing(1), paddingY: theme.spacing(1)}}>
                    <Typography flexGrow={1} variant={'subtitle1'}>{props.label}</Typography>
                    <props.icon color={'primary'}/>
                </Stack>
            </Paper>


            {/* Only this part will be scrollable */}
            <CardContent sx={{flexGrow: 1, overflowY: 'auto', padding: '2px !important'}}>
                <List>
                    {
                        props.value?.map((v, index) =>
                            <ListItem sx={{
                                backgroundColor: index % 2 === 1 ? theme.palette.background.default : lighten(theme.palette.background.paper, .1)
                            }} divider key={index}>
                                <ListItemText>{toTitleCase(v)}</ListItemText>
                            </ListItem>
                        )
                    }
                    {
                        (props.value || []).length == 0 &&
                        <Box sx={{textAlign: 'center', paddingY: 3}}>
                            <Typography color={'text.secondary'}>No Value </Typography>
                        </Box>
                    }
                </List>
            </CardContent>
        </Card>
    );
}


export default ThreatActorAttribute