import * as React from 'react';
import {useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {ThreatActor} from "../../../../services/ThreatActor/threatActor.model";
import ThreatActorService from "../../../../services/ThreatActor/threatActor.service";
import {useModal} from "mui-modal-provider";
import {ErrorDetails, ErrorDialog} from "../../../Common/ErrorDialog/ErrorDialog";
import PublicLoadingScreen from "../../PublicLoadingScreen";
import {ThreatActorDialogViews} from "./ThreatActorDialogViews";
import AppBar from "@mui/material/AppBar";
import {IconButton, Toolbar, Typography, useTheme} from "@mui/material";
import {Close} from "@mui/icons-material";


interface ThisProps {
    cachedActor: ThreatActor | null;
    threatActorID: string | null;
}

function ThreatActorDialog(props: ThisProps) {
    const {showModal} = useModal();
    const [open, setOpen] = React.useState(true);
    const theme = useTheme()

    const [threatActor, setThreatActor] = React.useState<{
        pending: boolean,
        value: ThreatActor | null
    }>({pending: !props.cachedActor, value: props.cachedActor})

    useEffect(() => {
        const threatActorService = new ThreatActorService();

        if (!props.cachedActor) {
            setThreatActor({value: null, pending: true})
            threatActorService.getThreatActor(props.threatActorID!)
                .then(res => {
                    setThreatActor({pending: false, value: res})
                })
                .catch((err: ErrorDetails) => {
                    setThreatActor({pending: false, value: null})
                    showModal(ErrorDialog, {error: err})
                })
        }

    }, [props.threatActorID, props.cachedActor]);


    return (
        <Dialog
            fullWidth
            fullScreen
            open={open}
            onClose={() => setOpen(false)}
        >
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <Typography sx={{ml: 2, flexGrow: 1}} variant="h6" component="div">
                        Threat Actor Overview
                    </Typography>
                    <IconButton
                        edge="start"
                        color="error"
                        onClick={() => setOpen(false)}
                        aria-label="close"
                    >
                        <Close/>
                    </IconButton>

                </Toolbar>
            </AppBar>
            <DialogContent sx={{'overflow': 'auto', height: `calc(100% - ${theme.spacing(8)})`}}>
                {
                    threatActor.pending ?
                        <PublicLoadingScreen text={'Fetching Threat Actor'}/> :
                        <ThreatActorDialogViews threatActor={{...threatActor, value: threatActor.value!}}/>
                }

            </DialogContent>
        </Dialog>
    );
}

export default ThreatActorDialog;