import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, Fade,
    IconButton,
    Paper,
    Typography,
    useTheme
} from "@mui/material";
import React, {useContext, useEffect, useRef, useState} from "react";
import { Web3AuthService } from "../../services/Web3Auth/web3-auth.service";
import { useModal } from "mui-modal-provider";
import { ErrorDialog } from "../Common/ErrorDialog/ErrorDialog";
import {Box, Stack} from "@mui/system";
import {Check, Clear} from "@mui/icons-material";
import { ProofRequestResponse } from "../../services/Web3Auth/web3-auth.model";
import QRCode from 'react-qr-code';
import {LoadingButton} from "@mui/lab";
import {AuthContext} from "../../context/AuthContext";
import AnonymousAuthContext from "../../context/AnonymousAuthContext";
import {useNavigate, useNavigation} from "react-router-dom";

interface Props {
    onClose: (validated: boolean) => void;
}

export const Web3AuthDialog = (props: Props) => {
    const [open, setOpen] = useState(true);
    const { showModal } = useModal();
    const authService = new Web3AuthService();
    const authContext = useContext(AuthContext);
    const anonymousContext = useContext(AnonymousAuthContext)
    const [verifying, setVerifying] = useState(false);

    const [authUrl, setAuthURL] = useState<{
        value: null | ProofRequestResponse;
        pending: boolean;
    }>({ value: null, pending: true });

    useEffect(() => {
        authService
            .getProofRequest()
            .then((res) => {
                setAuthURL({ pending: false, value: res });
            })
            .catch((err) => {
                showModal(ErrorDialog, { error: err });
            });
    }, []); // Ensure this effect runs only once by providing an empty dependency array





    const handleValidate = () => {
        setVerifying(true);
        authService
            .validateProof(authUrl.value!.id)
            .then((res) => {
                anonymousContext.setToken({value: res.token, pending: false});
                handleClose(true)
            })
            .catch((err) => {
                setVerifying(false)
                showModal(ErrorDialog, { error: err });
            });
    }

    const handleClose = (validated: boolean) => {
        setOpen(false);
        props.onClose(validated)
    };


    const theme = useTheme();
    return (
        <Dialog
            open={open}
            maxWidth={"sm"}
            fullWidth
        >
            <Paper sx={{ height: "100%", display: "flex", flexDirection: "column" }} elevation={1}>
                <DialogTitle>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Typography sx={{ml: 'auto', mr: 'auto'}} variant={'h4'}>
                            Proof Request Test
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center' }}>
                    <Typography variant={'body1'}>
                        Scan the QR code with your Wallet below.
                    </Typography>


                    <Fade key={authUrl.value?.id} in={true} mountOnEnter unmountOnExit>
                    <Stack justifyContent={'center'} height={"100%"} sx={{minHeight: 130}}>
                        {authUrl.value ? (
                                <QRCode style={{ width: '100%' }}
                                        value={authUrl.value.url} />
                            ) : <CircularProgress />}
                    </Stack>
                    </Fade>



                    <Typography sx={{fontWeight: 600, color: theme.palette.error.main}} variant={'body1'}>
                        Please click 'Okay' once verified
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Stack sx={{paddingY: theme.spacing(2),}} width={'100%'} alignItems={'center'} justifyContent={'center'} direction={'row'} gap={3}>
                        <Button sx={{color: theme.palette.text.primary}}  color={"secondary"}  size={'small'}  onClick={() => handleClose(false)}>
                            Cancel
                        </Button>
                        <LoadingButton
                            loadingPosition={'end'}
                            variant={'contained'}
                            size={'small'}
                            endIcon={<Check />}
                            color={"secondary"} onClick={handleValidate}>
                            Okay
                        </LoadingButton>
                    </Stack>
                </DialogActions>
            </Paper>
        </Dialog>
    );
};
