import {
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Fade,
    FormControlLabel,
    IconButton,
    TextField,
    Tooltip,
    Typography, useTheme,
} from "@mui/material";
import {Box, Stack} from "@mui/system";
import React, {ChangeEvent, useEffect, useState} from "react";
import {FileType, ReportModel} from "../../../services/ThreatAnalysis/threatAnalysis.model";
import {ErrorDetails, ErrorDialog} from "../../Common/ErrorDialog/ErrorDialog";
import withRouter from "../../Common/Utils/WithRouter/WithRouter";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ClearIcon from "@mui/icons-material/Clear";
import ThreatAnalysisService from "../../../services/ThreatAnalysis/threatAnaylsis.service";
import LoadingButton from "@mui/lab/LoadingButton";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {EditOutlined} from "@mui/icons-material";
import {useModal} from "mui-modal-provider";
import {
    FormType,
    handleValidation, requiredDateValidator,
    requiredValidator,
    websiteValidator
} from "../../Common/FormBuilder/NEW/FormValidators";
import {FormField, FormFields, FormTextField, StyledFormFieldLabel} from "../../Common/FormBuilder/NEW/FormField";
import {addDays, addYears, format} from "date-fns";
import ErrorToolTip from "../../Common/FormBuilder/NEW/TooltipError";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

interface ThreatAnalysisDialogProps {
    onClose: (report: ReportModel | null) => void;

    open: boolean;
    report?: ReportModel | null;
    action: ThreatAnalysisDialogActions;
}

export enum ThreatAnalysisDialogActions {
    RERUN = "RERUN",
    UPDATE = "UPDATE",
    CREATE = "CREATE",
}


const reportNameKey = 'reportNameKey';
const reportDateKey = 'reportDateKey';
const websiteKey = 'websiteKey';
const fileKey = 'fileKey';


const ThreatAnalysisDialog = (props: ThreatAnalysisDialogProps) => {
    const analysisService: ThreatAnalysisService = new ThreatAnalysisService();
    const {showModal} = useModal();

    const [open, setOpen] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [clearCachedContent, setClearCachedContent] = useState(false);

    const [form, setForm] = useState<FormType>({
        [reportNameKey]: {
            value: props.report?.name ? props.report!.name : "",
            error: null,
            initialized: false,
            validators: [requiredValidator]
        },
        [websiteKey]: {
            value: props.report?.website_url ? props.report!.website_url : "",
            error: null,
            initialized: false,
            validators: [websiteValidator]
        },
        [fileKey]: {
            value: props.report?.file_name ? props.report!.file_name : "",
            error: null,
            initialized: false,
            validators: []
        },
        [reportDateKey]: {
            value: props.report?.report_date ? props.report!.report_date : "",
            error: null,
            initialized: false,
            validators: [requiredDateValidator]
        }
    })


    useEffect(() => {
        handleInitialization()
    }, []);


    const handleInitialization = () => {
        const localForm = {...form};
        for (const key of Object.keys(localForm)) {
            localForm[key].error = handleValidation(key, localForm[key].value, localForm);
        }

        setForm(localForm)
    }

    const handleClose = (result: ReportModel | null = null) => {
        props.onClose(result);
        setOpen(false);
    };



    const onSubmit = (): void => {
        setSubmitting(true);
        const payload = {
            _id: props.report ? props.report!._id! : null,
            name: form[reportNameKey].value,
            website_url: form[websiteKey].value,
            file: form[fileKey].data,
            clear_cache: clearCachedContent,
            report_date: format(new Date(form[reportDateKey].value), 'yyyy-MM-dd'),
        }

        if (props.action === ThreatAnalysisDialogActions.UPDATE) {
            const copy = {
                ...props.report!,
                name: payload.name,
                report_date: payload.report_date
            } as ReportModel;
            analysisService.updateReport(copy)
                .then((res) => handleClose(res))
                .catch((err) => {
                    setSubmitting(false);
                    showModal(ErrorDialog, {error: err})
                });

        } else {
            analysisService.createReport(payload)
                .then((res) => handleClose(res))
                .catch((err) => {
                    setSubmitting(false);
                    showModal(ErrorDialog, {error: err})
                });
        }

    }


    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>): void => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        const {name} = file;

        let error = null;
        if (props.report && props.report.file_name !== name) {
            error = "File must have the same name";
        }


        setForm({
            ...form,
            [fileKey]: {
                ...form[fileKey],
                value: name,
                error: error,
                data: file
            },
            [websiteKey]: {
                ...form[websiteKey],
                value: "",
                error: null
            }
        })
    }

    const handleOnClearCache = (e: ChangeEvent<HTMLInputElement>): void => {
        setClearCachedContent(e.target.checked);
        setForm({...form, [fileKey]: {...form[fileKey], value: e.target.checked ? '' : form[fileKey].value, data: null}})
    }

    const isFormValid = () => {
        let isFormValid = true;

        if (
            form[fileKey].value?.trim() === "" &&
            form[websiteKey].value?.trim() === ""
        ) {
            isFormValid = false;
        } else {
            if (
                form[fileKey].error !== null ||
                form[websiteKey].error !== null
            ) {
                isFormValid = false;
            }
        }

        if (form[reportNameKey].error !== null || form[reportNameKey].value?.trim() === "") {
            isFormValid = false;
        }


        if (form[reportDateKey].value.trim() === '') {
            isFormValid = false;
        }

        return isFormValid;
    }

    const handleInputChange = (key: string, value: string) => {
        const validationError = handleValidation(key, value, form)
        setForm({...form, [key]: {...form[key], value: value, error: validationError, initialized: true}})
    }

    const theme = useTheme();
    return (
        <Container>
                <Dialog fullWidth={true} maxWidth={"sm"} open={open}>
                    <DialogTitle>
                        <Typography variant="h6" component="div">
                            {props.action === ThreatAnalysisDialogActions.CREATE
                                ? "Report Analysis"
                                : props.action === ThreatAnalysisDialogActions.UPDATE
                                    ? "Rename Report"
                                    : `Rerun ${
                                        props.report?.website_url ? "Web" : "File"
                                    } Analysis`}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>

                        <Stack gap={2}>
                            <FormFields sx={{paddingX: 0}} gap={3}>
                                <FormField>
                                    <StyledFormFieldLabel>Report Name</StyledFormFieldLabel>
                                    <FormTextField
                                        required={true}
                                        placeholder={'Report Name'}
                                        formKey={reportNameKey}
                                        disabled={submitting || props.action === ThreatAnalysisDialogActions.RERUN}
                                        handleInputChange={(key, value) => handleInputChange(key, value)}
                                        formField={form[reportNameKey]}/>

                                </FormField>

                                <FormField>
                                    <StyledFormFieldLabel>Report Published Date</StyledFormFieldLabel>


                                    <ErrorToolTip message={form[reportDateKey].error}
                                                  open={form[reportDateKey].initialized && !!form[reportDateKey].error}
                                                  children={
                                                      <Box sx={{width: '100%'}}>
                                                          <DatePicker
                                                              sx={{
                                                                  width: '100%',
                                                                  opacity: form[reportDateKey].value.trim() === '' ? .6 : 1,
                                                                  background: theme.palette.background.paper
                                                              }}
                                                              maxDate={new Date()} // Set max date to today + 2 years
                                                              value={(new Date(form[reportDateKey].value.replace('-', '/')))}
                                                              onError={(e) => {
                                                                  if (e === "invalidDate" && form[reportDateKey].value !== '')
                                                                      handleInputChange(reportDateKey, `00/00/0000`);
                                                                  else if (e === 'disablePast')
                                                                      handleInputChange(reportDateKey, `01/22/2000`);
                                                              }}
                                                              slotProps={{textField: {size: 'small'}}}
                                                              disabled={submitting}
                                                              onChange={(value: any) => {
                                                                  if (value instanceof Date) {
                                                                      try {
                                                                          const formattedDate = format(value, 'MM/dd/yyyy');

                                                                          handleInputChange(reportDateKey, formattedDate);
                                                                      } catch (e) {
                                                                          const day = value.getDate();
                                                                          const month = value.getMonth() + 1; // Months are zero-based
                                                                          const year = value.getFullYear();

                                                                          handleInputChange(reportDateKey, `${month}/${day}/${year}`);
                                                                      }
                                                                  }

                                                              }}
                                                          />
                                                      </Box>
                                                  }/>

                                </FormField>
                            </FormFields>


                            <Stack gap={1}>
                                <Stack direction={'row'} alignItems={'center'}>
                                    <Typography variant={'h6'} fontWeight={600}>Sources</Typography>
                                    {props.action === ThreatAnalysisDialogActions.CREATE && (
                                        <Fade
                                            in={
                                                !!form[fileKey].value ||
                                                !!form[websiteKey].value
                                            }
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    setForm({
                                                        ...form,
                                                        [fileKey]: {
                                                            ...form[fileKey],
                                                            value: "",
                                                            error: null,
                                                            data: null
                                                        },
                                                        [websiteKey]: {
                                                            ...form[websiteKey],
                                                            value: "",
                                                            error: null
                                                        }
                                                    })
                                                }}
                                                size={"small"}
                                                color={"error"}
                                            >
                                                <ClearIcon fontSize={"small"}/>
                                            </IconButton>
                                        </Fade>
                                    )}
                                    <Divider sx={{flex: 1, pl: 2}} />
                                </Stack>
                                <Stack gap={1} alignItems={'center'}>



                                    {
                                        !props.report?.file_name &&
                                        <FormField gap={3}>
                                            <StyledFormFieldLabel>Website URL</StyledFormFieldLabel>
                                            <FormTextField
                                                required={true}
                                                placeholder={'URL'}
                                                formKey={websiteKey}
                                                disabled={
                                                    form[fileKey].value.trim() !== '' ||
                                                    submitting ||
                                                    props.action !== ThreatAnalysisDialogActions.CREATE
                                                }
                                                handleInputChange={(key, value) => handleInputChange(key, value)}
                                                formField={form[websiteKey]}/>

                                        </FormField>
                                    }



                                    {
                                        props.action == ThreatAnalysisDialogActions.CREATE && (
                                            <Typography variant="body2" color="text.secondary">
                                                OR
                                            </Typography>
                                        )}

                                    {
                                        !props.report?.website_url && (
                                            <Tooltip
                                                open={form[fileKey].error !== null}
                                                title={
                                                    <Typography variant={"body2"} color={"error"}>
                                                        {form[fileKey].error}
                                                    </Typography>
                                                }
                                                placement={"bottom-end"}
                                                PopperProps={{
                                                    modifiers: [
                                                        {
                                                            name: "offset",
                                                            options: {
                                                                offset: [0, -10],
                                                            },
                                                        },
                                                    ],
                                                }}
                                            >
                                                <Box sx={{width: "100%"}}>
                                                    <Button
                                                        component="label"
                                                        variant="contained"
                                                        size="large"
                                                        color="secondary"
                                                        disabled={
                                                            !!form[websiteKey].value || submitting ||
                                                            !!props.report?.website_url ||
                                                            props.action ==
                                                            ThreatAnalysisDialogActions.UPDATE
                                                        }
                                                        startIcon={<UploadFileIcon/>}
                                                        sx={{marginRight: "1rem", width: "100%"}}
                                                    >
                                                        {props.action ==
                                                        ThreatAnalysisDialogActions.RERUN
                                                            ? "Reupload File"
                                                            : form[fileKey].value?.trim() !== ''
                                                                ? "Change File"
                                                                : "Upload File"}
                                                        <input
                                                            type="file"
                                                            accept=".pdf, .html, text/html"
                                                            hidden
                                                            onChange={handleFileUpload}
                                                        />
                                                    </Button>
                                                    <Typography
                                                        color={'text.secondary'}
                                                        sx={{
                                                            fontSize: ".8rem",
                                                            padding: "5px 2px",
                                                        }}
                                                    >
                                                        {form[fileKey].value?.trim() !== '' ? form[fileKey].value : Object.values(FileType).map((v, index) => {
                                                            let value = v.toString();
                                                            if (index !== Object.values(FileType).length - 1) {
                                                                value = value + ',  '
                                                            }
                                                            return value;
                                                        })}
                                                    </Typography>

                                                </Box>
                                            </Tooltip>
                                        )}
                                </Stack>
                            </Stack>
                        </Stack>
                        <Divider/>
                    </DialogContent>
                    <DialogActions>
                        {props.action == ThreatAnalysisDialogActions.RERUN && (
                            <Stack
                                sx={{mr: "auto", p: "0 10px"}}
                                direction={"row"}
                                alignItems={"center"}
                            >
                                <FormControlLabel
                                    sx={{mr: "2px"}}
                                    control={
                                        <Checkbox
                                            onChange={(e) => handleOnClearCache(e)}
                                            value={clearCachedContent}
                                        />
                                    }
                                    label="Clear cached content"
                                />
                                <Tooltip
                                    title={
                                        <Typography sx={{fontSize: "14px", fontWeight: "500"}}>
                                            Re-extracts the content to be analyzed. Requires file to
                                            be re-uploaded
                                        </Typography>
                                    }
                                >
                                    <HelpOutlineIcon color="warning"/>
                                </Tooltip>
                            </Stack>
                        )}

                        <Box>
                            <Button sx={{mr: 2}} color="primary" onClick={() => handleClose(null)}>
                                Cancel
                            </Button>
                            <LoadingButton
                                disabled={!isFormValid() || submitting}
                                color={"primary"}
                                onClick={(e) => onSubmit()}
                                endIcon={
                                    props.action == ThreatAnalysisDialogActions.UPDATE ? (
                                        <EditOutlined/>
                                    ) : (
                                        <TroubleshootIcon/>
                                    )
                                }
                                loading={submitting}
                                loadingPosition="end"
                                variant="contained"
                            >
                <span>
                  {props.action == ThreatAnalysisDialogActions.CREATE
                      ? "Analyze"
                      : props.action == ThreatAnalysisDialogActions.UPDATE
                          ? "Rename"
                          : "Rerun"}
                </span>
                            </LoadingButton>
                        </Box>
                    </DialogActions>
                </Dialog>
            </Container>
    );
}


export default ThreatAnalysisDialog
