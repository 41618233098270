import ReportedAttackService from "../../../../services/ReportedAttacks/reportedAttack.service";
import React, {useContext, useEffect, useState} from "react";
import {defaultPagination, PaginationRequest} from "../../../../services/api-service";
import {
    MissingAttackerModel,
    MissingVictimModel,
    ReportedAttackModel, ReportedAttackStats
} from "../../../../services/ReportedAttacks/reportedAttack.model";
import {useModal} from "mui-modal-provider";
import {Box, Stack} from "@mui/system";
import {ReportedAttackTable} from "./ReportedAttackTable";
import {ErrorDialog} from "../../../Common/ErrorDialog/ErrorDialog";
import {AppToolbar} from "../../../Common/Drawer/NestedDrawer";
import {Button, Fade, Toolbar, Typography, useTheme} from "@mui/material";
import {AppChip, AppTitle} from "../../../Common/common";
import {Add} from "@mui/icons-material";
import DrawerStateContext from "../../../../context/DrawerContext";
import SimpleSnackbar from "../../../Common/SnackBar/SnackBar";
import {DebouncedTextField} from "../../../Common/DebouncedTextField";
import {ReportedAttackAttackerTable} from "./ReportedAttackAttackerTable";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import {ReportedAttackVictimTable} from "./ReportedAttackVictimTable";
import {ReportedAttackStatsComponent} from "./ReportedAttackStats";
import {color} from "chart.js/helpers";
import {CompanyModel} from "../../../../services/company/company.model";

export const ReportedAttackComponent = () => {
    const [pagination, setPagination] = useState<PaginationRequest>(defaultPagination)
    const [attacks, setAttacks] = useState<{pending: boolean, value: ReportedAttackModel[]}>({pending: true, value: []})

    const [filter, setFilter] = useState('');
    const [view, setView] = useState<'missing' | 'all'>('missing')
    const reportedAttackService = new ReportedAttackService();

    const {showModal} = useModal();
    const drawerContext = useContext(DrawerStateContext);



    const [attackerPagination, setAttackerPagination] = useState<PaginationRequest>(defaultPagination)
    const [missingAttackers, setMissingAttackers] = useState<{pending: boolean, value: string[], fetching: boolean}>({pending: true, value: [], fetching: false})
    const [victimPagination, setMissingPagination] = useState<PaginationRequest>(defaultPagination)
    const [missingVictims, setMissingVictims] = useState<{pending: boolean, fetching: boolean, value: MissingVictimModel[]}>({pending: true, value: [], fetching: false})
    const [attackStats, setAttackStats] = useState<{pending: boolean, value: ReportedAttackStats | null, days: number | null}>({pending: true, value: null, days: null})


    const X = 123;


    const handleFetchReportedAttacks = (thisPagination: any): void => {
        setAttacks({...attacks, pending: true})
        reportedAttackService.getReportedAttacks(thisPagination, attackStats.days)
            .then(res => {
                setAttacks({value: [...attacks.value, ...res.results], pending: false})
                setPagination({...thisPagination, totalRecords:  res.count})
            })
            .catch((err) => {
                showModal(ErrorDialog, {error: err});
                setAttacks({...attacks, pending: false})
            });

    }




    const handleFetchMissingReportedAttacksAttackers = (thisPagination: any): void => {
        setMissingAttackers({...missingAttackers, pending: missingVictims.value?.length === 0, fetching: missingAttackers.value?.length > 0 })
        reportedAttackService.getMissingAttackers(thisPagination)
            .then(res => {

                if (missingAttackers.fetching) {
                    showModal(SimpleSnackbar, {message: "Fetching"})
                }

                setMissingAttackers({value: [...missingAttackers.value, ...res.results], pending: false, fetching: false})
                setAttackerPagination({...thisPagination, totalRecords:  res.count})
            })
            .catch((err) => {
                showModal(ErrorDialog, {error: err});
                setMissingAttackers({...missingAttackers, pending: false, fetching: false})
            });

    }

    const handleFetchMissingReportedAttacksVictims = (thisPagination: any): void => {
        setMissingVictims({...missingVictims, pending: missingVictims.value?.length === 0, fetching: missingVictims.value?.length > 0 })
        reportedAttackService.getMissingCompanies(thisPagination)
            .then(res => {
                if (missingVictims.fetching) {
                    showModal(SimpleSnackbar, {message: "Fetching"})
                }

                setMissingVictims({value: [...missingVictims.value, ...res.results], pending: false, fetching: false})
                setMissingPagination({...thisPagination, totalRecords:  res.count})
            })
            .catch((err) => {
                showModal(ErrorDialog, {error: err});
                setMissingVictims({...missingVictims, pending: false, fetching: false})
            });

    }

    const handleFetchReportStats = (): void => {
        setAttackStats({...attackStats, pending: true})
        reportedAttackService.getReportedAttackStats(attackStats.days)
            .then(res => {
                setAttackStats({value: res, pending: false, days: attackStats.days})
            })
            .catch((err) => {
                showModal(ErrorDialog, {error: err});
                setAttackStats({...attackStats, pending: false, days: attackStats.days})
            });

    }


    useEffect(() => {
        handleFetchReportStats();
        if (view == 'all')
            handleFetchReportedAttacks(pagination);
    }, [attackStats.days]);


    const dayFilters = [
        {
            days: null,
            label: 'All'
        },
        {
            days: 7,
            label: '7 Days'
        },
        {
            days: 31,
            label: '1 Month'
        },
        {
            days: 183,
            label: '6 Months'
        },
        {
            days: 365,
            label: '1 Year'
        },
    ]


    const onProfiledCompany = (c: MissingVictimModel) => {
        setMissingVictims({...missingVictims, value: missingVictims.value.filter(v => v.victim_name !== c.victim_name)})
    }

    const theme = useTheme();
    return (
        <Box height={'100vh'} display={'flex'} flexDirection={'column'}>
            <AppToolbar open={drawerContext?.isDrawerOpen}>
                <Toolbar disableGutters>
                    <Stack width={'100%'} alignItems={'center'} justifyContent={'space-between'} direction={"row"}>
                        <AppTitle variant={'h5'}>
                            Reported Attacks
                        </AppTitle>


                        <Stack direction={'row'} spacing={1}>
                            <Button onClick={() => {
                                setView('missing')

                                setAttackerPagination({...defaultPagination})
                                setMissingPagination({...defaultPagination})

                                setMissingAttackers({pending: true, fetching: false, value: []})
                                setMissingVictims({pending: true, fetching: false, value: []})

                            }} color={'secondary'} variant={view==='missing' ? 'contained' : 'text'} sx={{color: theme.palette.text.primary}} size={'small'}>Missing</Button>
                            <Button onClick={() => {
                                setView('all')
                                setPagination({...defaultPagination})
                                setAttacks({pending: true, value: []})
                            }} color={'secondary'} variant={view==='all' ? 'contained' : 'text'} sx={{color: theme.palette.text.primary}} size={'small'}>All</Button>
                        </Stack>

                        <Stack spacing={1} direction={'row'}>
                            {
                                dayFilters.map((filter) =>
                                    <AppChip
                                        key={filter.label}
                                        onClick={() => setAttackStats({
                                            ...attackStats,
                                            days: filter.days
                                        })}
                                        color={attackStats.days === filter.days ? 'success' : undefined}
                                        variant={attackStats.days === filter.days ? 'filled' : 'outlined'}
                                        label={filter.label}
                                    />
                                )
                            }

                        </Stack>

                    </Stack>
                </Toolbar>
            </AppToolbar>

            <Stack
                sx={{
                    mt: theme => theme.spacing(8),
                    height: theme => `calc(100vh - ${theme.spacing(8)})`,
                }}
                spacing={2}>

                <Grid sx={{height: '100%', overflow: 'hidden' }} container spacing={3}>
                    <Grid xs={12}>
                        <ReportedAttackStatsComponent data={attackStats}/>
                    </Grid>
                    {
                        view === 'missing' ? (
                            <Fade in={true} unmountOnExit>
                                <Grid sx={{overflow: 'hidden', height: `calc(100% - ${theme.spacing(16)})`}} container xs={12} spacing={2}>
                                    <Grid sx={{height: '100%'}}  xs={6}>
                                        <ReportedAttackAttackerTable
                                            data={missingAttackers}
                                            pagination={attackerPagination}
                                            loading={missingVictims.pending || missingAttackers.pending}

                                            setPagination={(page: number, rowsPerPage: number) => {
                                                const newPagination = {...attackerPagination, page: page, rowsPerPage: rowsPerPage};
                                                handleFetchMissingReportedAttacksAttackers(newPagination);
                                            }}/>
                                    </Grid>
                                    <Grid sx={{height: '100%'}} xs={6}>
                                        <ReportedAttackVictimTable
                                            data={missingVictims}
                                            onProfiledCompany={onProfiledCompany}
                                            pagination={victimPagination}
                                            loading={missingVictims.pending || missingAttackers.pending}
                                            setPagination={(page: number, rowsPerPage: number) => {
                                                const newPagination = {...missingVictims, page: page, rowsPerPage: rowsPerPage};
                                                handleFetchMissingReportedAttacksVictims(newPagination);
                                            }}/>
                                    </Grid>
                                </Grid>
                            </Fade>
                        ) :
                            <Fade in={true} unmountOnExit>
                                <Grid sx={{overflow: 'hidden', height: `calc(100% - ${theme.spacing(16)})`}} xs={12} spacing={2}>
                                    <ReportedAttackTable
                                        attacks={attacks}
                                        filter={''}
                                        pagination={pagination}
                                        setPagination={(page: number, rowsPerPage: number) => {
                                            const newPagination = {...pagination, page: page, rowsPerPage: rowsPerPage};
                                            handleFetchReportedAttacks(newPagination);
                                        }}/>
                                </Grid>
                            </Fade>

                    }
                </Grid>

            </Stack>
        </Box>
    )
}