import React from "react";
import {StixModel} from "../../../../../services/stixx/stixx.model";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    InputAdornment,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ConstructionIcon from "@mui/icons-material/Construction";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import IconButton from "@mui/material/IconButton";
import {ClearOutlined} from "@mui/icons-material";
import toTitleCase from "../../../../Common/Utils/AutoCompleteOptions/toTitleCase";
import {Box, Stack} from "@mui/system";
import {JSONTree} from "react-json-tree";

interface ThisProps {
    stix: { [key: string]: StixModel[] } | undefined,
    label: 'Domain Objects' | 'Cyber Objects'
}

interface ThisState {
    filter: string;
    expanded: boolean;
}

const theme = {
    base00: "transparent",
};


export class ViewCompanyStix extends React.Component<ThisProps, ThisState> {
    constructor(props: ThisProps) {
        super(props);
        this.state = {
            filter: '',
            expanded: true
        }
    }

    handleSearch(value: string): void {
        this.setState({filter: value});
    }

    render() {
        return (
            <Accordion expanded={this.state.expanded} elevation={3}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon color={'secondary'}/>}
                    onClick={() =>
                        this.setState({expanded: !this.state.expanded})
                    }
                >
                    <Typography sx={{fontSize: '1rem', fontWeight: '600', mr: 1}}>
                        {this.props.label}
                    </Typography>
                    <ConstructionIcon color={'info'}/>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        (Object.keys(this.props.stix || {}).length > 0) && <TextField
                            fullWidth
                            variant="outlined"
                            onChange={(e) => this.handleSearch(e.target.value)}
                            value={this.state.filter}
                            color="secondary"
                            placeholder="Search for domain objects"

                            size={'small'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlinedIcon color="primary"/>
                                    </InputAdornment>
                                ),
                                endAdornment: this.state.filter && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => this.handleSearch("")}
                                            color="primary"
                                        >
                                            <ClearOutlined/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    }
                    {
                        (Object.keys(this.props.stix || {}).length === 0) &&
                        <Typography fontWeight={500} sx={{opacity: '.6'}}>No {this.props.label}</Typography>
                    }

                    <Box sx={{overflow: 'hidden', overflowY: 'auto', maxHeight: '80vh', mt: 2}}>
                        {
                            this.props.stix && Object.keys(this.props.stix).map((stix_type, index) =>
                                <Accordion sx={{borderLeft: (theme) => '2px solid ' + theme.palette.secondary.main}}
                                           defaultExpanded={true} key={stix_type + index}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon color={'secondary'}/>}
                                    >
                                        <Typography sx={{fontSize: '1rem', fontWeight: '500', mr: 1}}>
                                            {toTitleCase(stix_type)}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Stack spacing={2}>
                                            {
                                                this.props.stix![stix_type].filter(stix => {
                                                    return stix_type.toLowerCase().includes(this.state.filter) || JSON.stringify(stix).includes(this.state.filter.toLowerCase().trim())
                                                }).map((stix, index) => {
                                                    return (
                                                        <Card
                                                            elevation={4}
                                                            key={stix.id}
                                                            // onClick={(e) => this.props.onSelectTool(tool)}
                                                            sx={{
                                                                borderLeft: (theme) => '3px solid ' + theme.palette.primary.main
                                                            }}>
                                                            <CardContent>
                                                                <Paper elevation={3}>
                                                                    <JSONTree
                                                                        shouldExpandNodeInitially={(x) => false}
                                                                        getItemString={(type, data, itemType, itemString, keyPath) =>
                                                                            <span>{stix.id}</span>}
                                                                        theme={theme} data={stix}/>
                                                                </Paper>
                                                            </CardContent>
                                                        </Card>
                                                    )
                                                })
                                            }
                                        </Stack>

                                    </AccordionDetails>
                                </Accordion>
                            )
                        }
                    </Box>


                </AccordionDetails>
            </Accordion>
        )
    }

}