import {Add, DeleteOutline} from "@mui/icons-material";
import {Autocomplete, Box, Checkbox, Divider, IconButton, Stack, TextField, Typography,} from "@mui/material";
import React from "react";
import {ReportAnalysis, ThreatActorIntent,} from "../../../../../../../services/ThreatAnalysis/threatAnalysis.model";
import {
    AnalysisSharableTable,
    IconButtonColumn,
    StringColumn,
} from "../../../../../../Common/TableHelper/SharableTable";
import _ from "lodash";

interface ThisProps {
    analysis: ReportAnalysis;
    onUpdate: (value: any) => void;
}

interface ThisState {
    selectedItems: ThreatActorIntent[]
}

export class ThreatActorIntentForm extends React.Component<
    ThisProps,
    ThisState
> {
    private _key: keyof ReportAnalysis = "threat_actor_intent";

    constructor(props: ThisProps) {
        super(props);

        this.state = {
            selectedItems: []
        };
    }

    onUpdate(): void {
        this.props.onUpdate({
            ...this.props.analysis,
            [this._key]: [
                ...this.state.selectedItems,
                ...this.props.analysis.threat_actor_intent,
            ],
        });
        this.setState({selectedItems: []});
    }

    onDelete(row_index: number): void {
        const copy = _.cloneDeep(this.props.analysis.threat_actor_intent);

        this.props.onUpdate({
            ...this.props.analysis,
            [this._key]: copy.filter((value, index) => index !== row_index),
        });
    }

    render(): React.ReactNode {
        return (
            <React.Fragment>
                <Stack spacing={2}>
                    <Box sx={{width: "100%"}}>
                        <Stack
                            sx={{mb: "12px"}}
                            direction="row"
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Typography fontSize={"1.25rem"} fontWeight={600}>
                                Add Threat Actor Intent
                            </Typography>
                            <IconButton
                                sx={{
                                    padding: 0,
                                    borderRadius: "50%",
                                    border: (theme) =>
                                        "1px solid " + theme.palette.secondary.main,
                                }}
                                size="small"
                                color="secondary"
                                disabled={this.state.selectedItems.length == 0}
                                onClick={(e) => {
                                    this.onUpdate();
                                }}
                            >
                                <Add/>
                            </IconButton>
                        </Stack>
                        <Autocomplete
                            disablePortal
                            disableClearable
                            disableCloseOnSelect
                            multiple
                            value={this.state.selectedItems}
                            size={"small"}
                            onChange={(event, value) =>
                                this.setState({
                                    selectedItems: value as ThreatActorIntent[],
                                })
                            } //
                            options={Object.values(ThreatActorIntent).filter(
                                (v) => !this.props.analysis?.threat_actor_intent.includes(v)
                            )}
                            fullWidth
                            renderOption={(props, option) => {
                                return (
                                    <Box key={option}>
                                        <li {...props}>
                                            <Checkbox
                                                style={{marginRight: 8}}
                                                checked={this.state.selectedItems.includes(option)}
                                            />
                                            {option}
                                        </li>
                                        <Divider/>
                                    </Box>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField
                                    color={"secondary"}
                                    {...params}
                                />
                            )}
                        />
                    </Box>
                    <Divider/>
                    <AnalysisSharableTable
                        headers={["Threat Actor Intent", ""]}
                        data={this.props.analysis.threat_actor_intent}
                        rows={[
                            new StringColumn(null),
                            new IconButtonColumn(
                                <DeleteOutline color="error"/>,
                                (row_index) => {
                                    this.onDelete(row_index);
                                },
                                {float: "right"}
                            ),
                        ]}
                    />
                </Stack>
            </React.Fragment>
        );
    }
}
