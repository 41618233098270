import React, {useEffect, useState} from "react";
import {
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Paper,
    Stack,
    TextField,
    Typography, useTheme
} from "@mui/material";
import {
    emailValidator,
    FormType,
    handleValidation,
    requiredValidator
} from "../../Common/FormBuilder/NEW/FormValidators";
import {
    FormAutocompleteField,
    FormField,
    FormFields,
    FormRow,
    FormTextField,
    StyledFormFieldLabel
} from "../../Common/FormBuilder/NEW/FormField";
import {User, UserGroup} from "../../../services/User/user.model";
import {CompanyModel} from "../../../services/company/company.model";
import CompanyService from "../../../services/company/company.service";
import {useModal} from "mui-modal-provider";
import {ErrorDialog} from "../../Common/ErrorDialog/ErrorDialog";
import {LoadingButton} from "@mui/lab";
import {Add} from "@mui/icons-material";
import UserService from "../../../services/User/user.service";
import firebase from "firebase/compat";
import {defaultPagination} from "../../../services/api-service";

interface Props {
    onClose: (users: User | null) => void;
    user?: User
}

const groupKey = 'groupKey';
const emailKey = 'emailKey';
const companyKey = 'companyKey';






export const AddUserDialog = ({onClose, user}: Props) => {
    const companyService = new CompanyService();
    const userService = new UserService();

    const [submitting, setSubmitting] = useState(false);
    const [orgMap, setOrgMap] = useState<{
        [key: string]: CompanyModel
    }>({})
    const {showModal} = useModal();
    const [open, setOpen] = useState(true)
    const [form, setForm] = useState<FormType>({
        [emailKey]: {
            value: user?.email || '',
            error: null,
            initialized: false,
            validators: [requiredValidator, emailValidator]
        },
        [groupKey]: {
            value: user?.group || '',
            error: null,
            initialized: false,
            validators: [requiredValidator]
        },
        [companyKey]: {
            value: user?.company_name || '',
            error: null,
            initialized: false,
            validators: []
        }
    })

    const [companies, setCompanies] = useState<{pending: boolean, value: CompanyModel[]}>({pending: true, value: []})
    const [companyInputValue, setCompanyInputValue] = React.useState('');

    useEffect(() => {
        fetchCompanies();
    }, [companyInputValue]);

    useEffect(() => {
        handleInitialization()
        fetchCompanies();
    }, []);

    const fetchCompanies = () => {
        companyService.getPaginatedCompanies(defaultPagination, companyInputValue)
            .then(res => {
                setCompanies({pending: false, value: res.results})

                const map: {[key: string]: CompanyModel} = {}
                for (let org of (res.results || [])) {
                    map[org.name] = org;
                }

                setOrgMap(map);

            })
            .catch(err => {
                setCompanies({...companies, pending: false});
                showModal(ErrorDialog, {error: err})
            })
    }



    const handleInitialization = () => {
        const localForm = {...form};
        for (const key of Object.keys(localForm)) {
            localForm[key].error = handleValidation(key, localForm[key].value, localForm);
        }

        setForm(localForm)
    }

    const handleClose = (result: User | null = null) => {
        onClose(result);
        setOpen(false);
    };

    const handleInputChange = (key: string, value: string) => {
        const validationError = handleValidation(key, value, form)
        setForm({...form, [key]: {...form[key], value: value, error: validationError, initialized: true}})
    }

    const handleOrganizationUserGroupValidation = () => {
        if (![UserGroup.Entity, UserGroup.NonAttributable].includes(form[groupKey].value as UserGroup)) {
            setForm({...form, [companyKey]: {...form[companyKey], value: ''}})
        } else if (form[companyKey].value.trim() === '') {
            setForm({...form, [companyKey]: {...form[companyKey], error: 'Required'}})
        }
    }


    useEffect(() => {
        handleOrganizationUserGroupValidation();
    }, [form[companyKey].value, form[groupKey].value,]);




    const onSubmit = () => {
        setSubmitting(true);

        let companyID = null;
        if (orgMap.hasOwnProperty(form[companyKey].value)) {
            companyID = orgMap[form[companyKey].value].id;
        }

        if (user) {
            const updatedUser = {
                firebase_id: user.firebase_id,
                email: form[emailKey].value,
                group: form[groupKey].value as UserGroup,
                company: companyID
            };

            userService.updateUser(updatedUser as User)
                .then((res) => {
                    handleClose(res);
                })
                .catch((err) => {
                    showModal(ErrorDialog, {error: err});
                    setSubmitting(false);
                });
        } else {
            userService.createUser(form[emailKey].value, form[groupKey].value as UserGroup, companyID)
                .then((res) => {
                    handleClose(res);
                })
                .catch((err) => {
                    showModal(ErrorDialog, {error: err});
                    setSubmitting(false);
                });
        }
    }

    const theme = useTheme();
    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth>
            <DialogTitle>Add User</DialogTitle>


            <DialogContent sx={{paddingY: theme.spacing(1)}} dividers>
                {
                    !user && (
                        <DialogContentText sx={{paddingY: theme.spacing(1), textAlign: 'center'}}>
                        User email cannot be modified after creation.
                        </DialogContentText>
                    )}
                <FormFields gap={3}>
                    <FormField>
                        <StyledFormFieldLabel>Email</StyledFormFieldLabel>
                        <FormTextField
                            required={true}
                            placeholder={'jon.doe@service.com'}
                            formKey={emailKey}
                            disabled={!!user || submitting}
                            handleInputChange={(key, value) => handleInputChange(key, value)}
                            formField={form[emailKey]}/>

                    </FormField>
                    <FormField>
                        <StyledFormFieldLabel>User Group</StyledFormFieldLabel>
                        <FormAutocompleteField
                            options={Object.values(UserGroup)}
                            placeholder={UserGroup.Analyst}
                            formKey={groupKey}
                            required={true}
                            disabled={submitting}
                            handleInputChange={(key, value) => handleInputChange(key, value)}
                            formField={form[groupKey]}/>
                    </FormField>
                    {
                        (form[groupKey].value === UserGroup.Entity || form[groupKey].value === UserGroup.NonAttributable) &&
                        <FormField>
                            <StyledFormFieldLabel>Company</StyledFormFieldLabel>
                            <FormAutocompleteField
                                required={true}
                                options={Object.keys(orgMap)}
                                inputValueValidation={(companyName) => {
                                    return orgMap.hasOwnProperty(companyName);
                                }}
                                inputValue={companyInputValue}
                                setInputValue={setCompanyInputValue}
                                freeSolo={true}
                                disabled={submitting}
                                // onSelection={(value: string) => {
                                //     const newCompany = companies.value?.find(co => co.name.toLowerCase().trim() === value.toLowerCase().trim());
                                //
                                //     handleInputChange(co)
                                // }}
                                placeholder={'Organization'}
                                formKey={companyKey}
                                loading={companies.pending}
                                handleInputChange={(key, value) => handleInputChange(key, value)}
                                formField={form[companyKey]}/>
                        </FormField>
                    }

                </FormFields>
            </DialogContent>
            <DialogActions>

                <Stack gap={2} direction={'row'}>
                    <Button
                        onClick={() => handleClose()}
                        sx={{color: theme.palette.text.primary}}
                        size={'small'}>
                        Cancel
                    </Button>
                    <LoadingButton
                        onClick={onSubmit}
                        endIcon={<Add />}
                        loading={submitting}
                        loadingPosition={'end'}
                        variant={'contained'}
                        disabled={Object.values(form).map(v => v.error).filter((err) => err !== null).length > 0}
                        color={'secondary'}
                        size={'small'}>
                        {user ? 'Update' : 'Add'}
                    </LoadingButton>
                </Stack>

            </DialogActions>

        </Dialog>
    );

}