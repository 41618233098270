import React, {useState} from "react";
import {CompanyRisk} from "../../../../services/company/company.model";
import {Box, Stack} from "@mui/system";
import {Accordion, AccordionDetails, AccordionSummary, Paper, Tab, Theme, Typography, useTheme} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {format} from "date-fns";
import {styled} from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import toTitleCase from "../../../Common/Utils/AutoCompleteOptions/toTitleCase";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {CheckCircleOutline, Clear} from "@mui/icons-material";
import {TabContext, TabList, TabPanel} from "@mui/lab";

interface ThisProps {
    threat: CompanyRisk

}

const AppListItem = styled(ListItem)<{ theme?: Theme }>(({theme}) => ({
    '& .MuiPaper-root': {width: '100%'}
}));


const ListLabel = styled(ListItemText)<{ theme?: Theme }>(({theme}) => ({
    '& .MuiTypography-root': {fontSize: '.85rem !important'},
    width: '50%',
    paddingRight: theme.spacing(1),
    opacity: '.7'
}));

const ListValue = styled(ListItemText)<{ theme?: Theme }>(({theme}) => ({
    '& .MuiTypography-root': {fontSize: '.85rem !important'},
    width: '50%',
    paddingLeft: theme.spacing(1)
}));


// const riskMetric = (label: string, value: string, DisplayIcon: any, color?: 'error') => {
//     return (
//         <Stack paddingX={3} direction={'row'} alignItems={'center'}>
//             <DisplayIcon fontSize={'large'} color={color ? color : 'primary'}/>
//             <Stack sx={{ml: 'auto'}}>
//                 <Typography variant={'body1'} fontSize={'1rem'}>{value}</Typography>
//                 <Typography variant={'subtitle2'} color={'text.secondary'}>{label}</Typography>
//             </Stack>
//         </Stack>
//     )
// }

const riskMetric = (label: string, value: string, DisplayIcon: any, color?: 'error') => {
    return (
        <Stack paddingX={3} direction={'row'} alignItems={'center'}>
            <DisplayIcon fontSize={'large'} color={color ? color : 'primary'}/>
            <Stack>
                <Typography variant={'body1'} fontSize={'1rem'}>{value}</Typography>
                <Typography variant={'subtitle2'} color={'text.secondary'}>{label}</Typography>
            </Stack>
        </Stack>
    )
}

const RiskThreatView: React.FC<ThisProps> = (props: ThisProps) => {
    const [threatActorDetailsOpened, setThreatActorDetailsOpened] = useState(true);
    const [motivationOpened, setMotivationOpened] = useState(true);
    const [intentOpened, setIntentOpened] = useState(true);
    const [resourceLevelOpened, setResourceLevelOpened] = useState(true);
    const [riskOpened, setRiskOpened] = useState(true);

    const theme = useTheme();

    const [tabValue, setTabValue] = useState("1");
    return (
        <Box padding={1}>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(e, newValue) => setTabValue(newValue)}>
                        <Tab label="Threat Actor" value="1" />
                        <Tab label="Risk Scores" value="2" />
                    </TabList>
                </Box>
                <TabPanel sx={{paddingX: 0}} value="1">
                    <Box >
                        <Accordion expanded={threatActorDetailsOpened}
                                   onChange={() => setThreatActorDetailsOpened(!threatActorDetailsOpened)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography color={'text.secondary'} fontWeight={'500'}>
                                    Details
                                </Typography> </AccordionSummary>
                            <AccordionDetails>
                                <Paper elevation={6}>
                                    <List>
                                        <AppListItem divider>
                                            <ListLabel>Last Active</ListLabel>
                                            <ListValue>{props.threat.threat_actor_last_seen ? format(new Date(props.threat.threat_actor_last_seen), 'MM/dd/yyyy') : '--------'}</ListValue>
                                        </AppListItem>
                                        <AppListItem divider>
                                            <ListLabel>Type</ListLabel>
                                            <ListValue>{toTitleCase(props.threat.threat_actor_type)}</ListValue>
                                        </AppListItem>
                                        <AppListItem divider>
                                            <ListLabel>Sophistication</ListLabel>
                                            <ListValue>{toTitleCase(props.threat.threat_actor_sophistication)}</ListValue>
                                        </AppListItem>
                                    </List>
                                </Paper>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion  expanded={motivationOpened}
                                   onChange={() => setMotivationOpened(!motivationOpened)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography color={'text.secondary'} fontWeight={'500'}>
                                    Motives
                                </Typography> </AccordionSummary>
                            <AccordionDetails>
                                <Paper elevation={6}>
                                    <List>
                                        {
                                            props.threat.threat_actor_motives.map((v, index) =>
                                                <AppListItem key={index} divider>
                                                    <ListValue>{v}</ListValue>
                                                </AppListItem>
                                            )
                                        }
                                    </List>
                                </Paper>
                            </AccordionDetails>
                        </Accordion>



                        <Accordion  expanded={resourceLevelOpened}
                                   onChange={() => setResourceLevelOpened(!resourceLevelOpened)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography color={'text.secondary'} fontWeight={'500'}>
                                    Resource Level
                                </Typography> </AccordionSummary>
                            <AccordionDetails>
                                <Paper elevation={6}>
                                    <List>
                                        {
                                            props.threat.threat_actor_resource_level.map((v, index) =>
                                                <AppListItem key={index} divider>
                                                    <ListValue>{v}</ListValue>
                                                </AppListItem>
                                            )
                                        }

                                    </List>
                                </Paper>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion  expanded={intentOpened}
                                   onChange={() => setIntentOpened(!intentOpened)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography color={'text.secondary'} fontWeight={'500'}>
                                    Intent
                                </Typography> </AccordionSummary>
                            <AccordionDetails>
                                <Paper elevation={6}>
                                    <List>
                                        {
                                            props.threat.threat_actor_intent.map((v, index) =>
                                                <AppListItem divider key={index}>
                                                    <ListValue>{v}</ListValue>
                                                </AppListItem>
                                            )
                                        }

                                    </List>
                                </Paper>
                            </AccordionDetails>
                        </Accordion>

                    </Box>
                </TabPanel>
                <TabPanel sx={{paddingX: 0}} value="2">
                    <Stack sx={{height: '100%'}}>
                        <Accordion  expanded={riskOpened}
                                   onChange={() => setRiskOpened(!riskOpened)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography color={'text.secondary'} fontWeight={'500'}>
                                    Risk Details
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Paper elevation={6}>
                                    <List>
                                        <AppListItem divider>
                                            <ListLabel>Risk Identified</ListLabel>
                                            <ListValue>{props.threat.threat_actor_relationship_identified ? format(new Date(props.threat.threat_actor_relationship_identified), 'MM/dd/yyyy') : '--------'}</ListValue>
                                        </AppListItem>
                                        <AppListItem divider>
                                            <ListLabel>Targets Sector</ListLabel>
                                            <ListValue>{props.threat.threat_actor_targets_sector ? <CheckCircleOutline color={'success'} /> : <Clear color={'error'}/>}</ListValue>
                                        </AppListItem>
                                        <AppListItem divider>
                                            <ListLabel>Targets Country</ListLabel>
                                            <ListValue>{props.threat.threat_actor_targets_country ? <CheckCircleOutline color={'success'} /> : <Clear color={'error'}/>}</ListValue>
                                        </AppListItem>
                                        <AppListItem divider>
                                            <ListLabel>Targets Region</ListLabel>
                                            <ListValue>{props.threat.threat_actor_targets_region ? <CheckCircleOutline color={'success'} /> : <Clear color={'error'}/>}</ListValue>
                                        </AppListItem>
                                        <AppListItem divider>
                                            <ListLabel>Likelihood of Attack</ListLabel>
                                            <ListValue>{toTitleCase(props.threat.likelihood_of_attack)}</ListValue>
                                        </AppListItem>
                                        <AppListItem divider>
                                            <ListLabel>Impact</ListLabel>
                                            <ListValue>{toTitleCase(props.threat.impact_type)}</ListValue>
                                        </AppListItem>
                                        <AppListItem divider>
                                            <ListLabel>Operational Risk</ListLabel>
                                            <ListValue>{toTitleCase(props.threat.operational_risk)}</ListValue>
                                        </AppListItem>
                                        <AppListItem divider>
                                            <ListLabel>Financial Risk</ListLabel>
                                            <ListValue>{toTitleCase(props.threat.financial_risk)}</ListValue>
                                        </AppListItem>
                                        <AppListItem divider>
                                            <ListLabel>Legal Compliance Risk</ListLabel>
                                            <ListValue>{toTitleCase(props.threat.legal_compliance_risk)}</ListValue>
                                        </AppListItem>
                                        <AppListItem divider>
                                            <ListLabel>Reputational Risk</ListLabel>
                                            <ListValue>{toTitleCase(props.threat.reputational_risk)}</ListValue>
                                        </AppListItem>
                                    </List>
                                </Paper>

                            </AccordionDetails>
                        </Accordion>
                    </Stack>
                </TabPanel>
            </TabContext>
        </Box>
    );
}

export default RiskThreatView;