import {Autocomplete, Box, Checkbox, Divider, IconButton, Stack, TextField, Typography,} from "@mui/material";
import {Add, DeleteOutline} from "@mui/icons-material";
import React, {ReactNode} from "react";
import {AnalysisSharableTable, IconButtonColumn, StringColumn,} from "../TableHelper/SharableTable";
import {
    TargetRegionData
} from "../../internal-ui/ThreatAnalysis/ViewReport/ReportAnalysis/Dialogs/TargetEntites/TargetEntitesForm";
import {StixRegions} from "../../../services/stixx/stixx.model";


interface ThisProps {
    choices: TargetRegionData[];
    regions: string[];
    helperText?: string;

    onUpdate: (value: any[]) => void;
    onDelete: (index: any) => void;
    error?: string | null;
}

interface ThisState {
    reset: string;
    selectedItems: StixRegions[]
}

export class RegionSelector extends React.Component<ThisProps, ThisState> {
    constructor(props: ThisProps) {
        super(props);
        this.state = {
            selectedItems: [],
            // easiest way to programatically clear the auto
            // complete.
            reset: new Date().toISOString(),
        };
    }

    render(): ReactNode {
        return (
            <React.Fragment>
                <Stack spacing={1}>
                    <Box sx={{width: "100%"}}>
                        <Stack
                            sx={{mb: "12px"}}
                            direction="row"
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Typography fontSize={"1.25rem"} fontWeight={600}>
                                Region Lookup
                            </Typography>
                            <IconButton
                                sx={{
                                    padding: 0,
                                    borderRadius: "50%",
                                    border: (theme) =>
                                        "1px solid " + theme.palette.secondary.main,
                                }}
                                disabled={this.state.selectedItems.length == 0}
                                size="small"
                                color="secondary"
                                onClick={(e) => {
                                    this.props.onUpdate(this.state.selectedItems);
                                    this.setState({
                                        reset: new Date().toISOString(),
                                        selectedItems: []
                                    });
                                }}
                            >
                                <Add/>
                            </IconButton>
                        </Stack>
                        {this.props.helperText && (
                            <Typography sx={{opacity: ".6", mb: 1}} variant="body2">
                                {this.props.helperText}
                            </Typography>
                        )}
                        <Autocomplete
                            fullWidth
                            size={"small"}
                            disableListWrap
                            key={this.state.reset}
                            disableClearable
                            disableCloseOnSelect
                            multiple

                            options={Object.values(StixRegions).filter(opt => {
                                return !this.props.regions?.includes(opt);
                            })}
                            onChange={(event, value) =>
                                this.setState({
                                    selectedItems: value as StixRegions[],
                                })
                            } // prints the selected value
                            renderInput={(params) => (
                                <TextField
                                    error={!!this.props.error}
                                    helperText={this.props.error ? this.props.error : " "}
                                    color={"secondary"}
                                    {...params}
                                    label="Region"
                                />
                            )}
                            renderOption={(props, option) => {
                                return (
                                    <Box key={option}>
                                        <li {...props}>
                                            <Checkbox
                                                style={{marginRight: 8}}
                                                checked={this.state.selectedItems.includes(option)}
                                            />
                                            {option}
                                        </li>
                                        <Divider/>
                                    </Box>
                                )
                            }}
                            // TODO: Post React 18 update - validate this conversion, look like a hidden bug
                            renderGroup={(params) => params as unknown as React.ReactNode}
                        />
                    </Box>

                    <Divider/>

                    <AnalysisSharableTable
                        headers={["Region", ""]}
                        data={this.props.regions}
                        rows={[
                            new StringColumn(null),
                            // NEW StringColumn(null, (v: string) => {
                            //   const region = this.props.choices.find(
                            //     (region) => v == region.region_iso_code
                            //   );
                            //
                            //   return region ? region.region : "INVALID ISO CODE";
                            // }),
                            new IconButtonColumn(
                                <DeleteOutline color="error"/>,
                                (row_index) => {
                                    this.props.onDelete(row_index);
                                },
                                {float: "right"}
                            ),
                        ]}
                    />
                </Stack>
            </React.Fragment>
        );
    }
}
