import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Import the functions you need from the SDKs you need
import {AuthProvider} from './provider/AuthProvider';
import { LocalizationProvider } from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {AnonymousAuthProvider} from "./provider/AnonymousAuthProvider";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // REMOVE STRICT MODE IN PROD
    // <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AuthProvider>
            <AnonymousAuthProvider>
                <App/>
            </AnonymousAuthProvider>
        </AuthProvider>
    </LocalizationProvider>

// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
