import {Checkbox, IconButton, Typography,} from "@mui/material";
import {format} from "date-fns";
import React from "react";
import {ThreatActor} from "../../../services/ThreatActor/threatActor.model";
import {Stack} from "@mui/system";
import {OpenInNew} from "@mui/icons-material";
import MitreAttackService from "../../../services/mitre-attack.service";
import InfiniteTable, {InfiniteTableTableColumn} from "../../Common/TableHelper/InfiniteTable";

interface ThisProps {
    threat_actors: { pending: boolean; value: ThreatActor[] };
    filter: string;
    pagination: { page: number, rowsPerPage: number, totalRecords: number };
    setPagination: (page: number, rowsPerPage: number) => void;
    checkedRows: ThreatActor[];
    onToggleRow: (threatActor: ThreatActor) => void;
}

interface ThisState {
}

export class ThreatActorTable extends React.Component<
    ThisProps,
    ThisState
> {

    ms = new MitreAttackService();

    handleQueryInput = (value: ThreatActor): boolean => {
        const query = this.props.filter.trim().toLocaleLowerCase();
        if (query !== "") {
            if (
                value.name.toLocaleLowerCase().includes(query) ||
                value.id.includes(query) ||
                value.alias_to_name?.includes(query)
            )
                return true;

            return false;
        }

        return true;
    };

    render(): React.ReactNode {

        const columns: InfiniteTableTableColumn<ThreatActor>[] = [
            // {
            //     label: '',
            //     width: '5%',
            //     render: (row) => (
            //         <Checkbox
            //             color="primary"
            //             checked={this.props.checkedRows.includes(row)}
            //             onChange={(e) => this.props.onToggleRow(row)}
            //         />
            //     )
            // },
            {
                key: 'name',
                label: 'Name',
                width: '30%',
            },
            {
                key: 'x_mitre_attack_id',
                label: 'Mitre Attack ID',
                width: '30%',
                render: (row: ThreatActor) => {
                    if (row.x_mitre_attack_id) {
                        return (
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography
                                    variant={'body2'}>{row.x_mitre_attack_id}</Typography>
                                <IconButton size={'small'} color={'secondary'}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.ms.routeToGroupPage(row.x_mitre_attack_id!)
                                            }}>
                                    <OpenInNew/>
                                </IconButton>
                            </Stack>
                        );
                    }

                    return null;
                }
            },
            {
                key: 'created',
                label: 'Added On',
                width: '10%',
                render: (row: ThreatActor) => {
                    return format(
                        new Date(row?.created as string),
                        "MM/dd/yyyy"
                    );
                }
            }
        ]


        const filteredRows = this.props.threat_actors.value
            .filter((v) => this.handleQueryInput(v))

        return (
            <InfiniteTable

                data={filteredRows}
                columns={columns}
                pagination={this.props.pagination}
                setPagination={this.props.setPagination}
                loading={this.props.threat_actors.pending}
                noDataText={'No Threat Actors'}
            />
        );
    }
}
