import React, {useEffect, useState} from "react";
import {Box, Stack} from "@mui/system";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper, styled, Theme,
    Typography, useTheme
} from "@mui/material";
import SiteService from "../../services/site-service";
import {useModal} from "mui-modal-provider";
import {ErrorDialog} from "../Common/ErrorDialog/ErrorDialog";
import {CSSObject} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Grid from "@mui/material/Unstable_Grid2";

interface ThisProps {
}


 const HelpDocLink = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'theme' && prop !== 'selected',
})<{ theme?: Theme, selected: boolean }>(({theme, selected}) => ({
     fontSize: '16px',
     opacity: .75,
     fontWeight: 500,

     ':hover': {
         opacity: 1,
         cursor: 'pointer'
     },

     ...selected && {
         fontWeight: 600,
         opacity: 1,
         textDecoration: 'underline',
         textDecorationColor: theme.palette.primary.main, // Set underline color
         textDecorationThickness: '2px', // Set underline thickness,
         textUnderlineOffset: theme.spacing(1), // Adjusts the spacing between the text and the underline

     },

     transition: 'opacity 250ms ease-in-out'
}));


type Doc = {[key: string]: string} | null;

const PublicHelp: React.FC<ThisProps> = () => {
    const [open, setOpen] = useState(true);
    const [docs, setDocs] = useState<{pending: boolean, value: Doc, selectedKey: string | null}>({pending: true, value: null, selectedKey: null})
    const siteService = new SiteService();


    const {showModal} = useModal();
    useEffect(() => {
        loadDocs();
    }, []);

    const onClose = () => {
        setOpen(false);
    }


    const loadDocs = () => {
        siteService.getPublicHelpDoc()
            .then(res => {
                setDocs({pending: false, value: res, selectedKey: Object.keys(res)[0]})

            })
            .catch(err => {
                setDocs({pending: false, value: null, selectedKey: null})
                showModal(ErrorDialog, {error: err})
            })
    }

    const theme = useTheme();
    return (
        <Dialog open={open} fullWidth maxWidth={'lg'}>
            <DialogTitle variant={'h6'} fontWeight={600}>SWARM Help</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>

                <Grid container spacing={2} sx={{ flex: '1 0 auto' }}>
                    <Grid xs={4}>

                        <Paper sx={{height: '100%', borderRadius: '15px'}}>
                            <Stack spacing={3} alignItems={'start'} justifyContent={'center'}
                                   paddingY={theme.spacing(2)}
                                   paddingX={theme.spacing(3)}>
                                {
                                    docs.value && Object.keys(docs.value).map((key, index) =>
                                        <HelpDocLink key={index} selected={key === docs.selectedKey} onClick={() => {
                                            setDocs({...docs, selectedKey: key})
                                        }}>
                                            {key}
                                        </HelpDocLink>
                                    )
                                }
                            </Stack>
                        </Paper>

                    </Grid>
                    <Grid xs={8} sx={{ overflowY: 'auto', height: `calc(100vh - ${theme.spacing(32)})`}}>
                        {
                            docs.pending ?
                                <Stack sx={{paddingY: theme.spacing(3)}} width='100%' alignItems='center' justifyContent='center'>
                                    <CircularProgress />
                                </Stack> :
                                <Paper  elevation={6}>
                                    <Box  paddingY={theme.spacing(2)}
                                         paddingX={theme.spacing(3)}>
                                        <Typography paragraph dangerouslySetInnerHTML={{__html: docs.value![docs.selectedKey as keyof Doc]}}/>
                                    </Box>
                                </Paper>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button size={'small'} onClick={onClose} variant={'contained'}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default PublicHelp;