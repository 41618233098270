import {ApiService} from "../api-service";
import {ProofRequestResponse} from "./web3-auth.model";

export class Web3AuthService extends ApiService {
    getProofRequest = () => {
        return super.get<ProofRequestResponse>('web3-auth/')
    }

    validateProof = (proofId: number) => {
        return super.post<{ token: string }>('web3-auth/', {id: proofId})
    }
}