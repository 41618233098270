import React from "react";
import {CompanyModel} from "../../../../services/company/company.model";
import {CompanyParameterModel, CompanyParameterXrefModel} from "../../../../services/company/company-parameter.model";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import {ErrorDetails} from "../../../Common/ErrorDialog/ErrorDialog";
import {Box, Stack} from "@mui/system";
import CompanyParameterService from "../../../../services/company/company-parameter.service";
import {LoadingButton} from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import {CompanyParameterSelector} from "../../CompanyParameters/CompanyParameterSelector";
import {FormStyles} from "../CompanyDialog/CompanyDialog";
import {InfoOutlined} from "@mui/icons-material";


interface ThisProps {
    parameterXref?: CompanyParameterXrefModel | null;

    company: CompanyModel;

    onClose: (param: null | CompanyParameterXrefModel) => void;
    onError: (error: ErrorDetails) => void;

}

interface ThisState {
    parameterSelectorOpened: boolean;
    errors: { parameter: null | string; value: null | string; };
    submitting: boolean;
    parameters: { value: CompanyParameterModel[], pending: boolean }

    parameter: CompanyParameterModel | null;
    value: string;
}

export class CompanyParameterXrefDialog extends React.Component<ThisProps, ThisState> {
    parameterService: CompanyParameterService = new CompanyParameterService();
    private styles: FormStyles = {
        form_field_label: {fontWeight: "500", mb: "5px", opacity: ".9"},
    };


    constructor(props: ThisProps) {
        super(props);

        this.state = {
            parameterSelectorOpened: false,
            submitting: false,
            errors: {parameter: null, value: null},
            parameters: {value: [], pending: true},

            value: props.parameterXref?.value ? props.parameterXref.value : '',
            parameter: null,
        };
    }

    componentDidMount() {
        this.handleFetchParameters();
    }

    handleFetchParameters() {
        const existingIDS: string[] = (this.props.company.parameters || []).map(p => p.parameter);
        this.parameterService.getParameters()
            .then(res => this.setState({
                parameters: {
                    pending: false,
                    value: res.filter(p => !existingIDS.includes(p._id!))
                },
                parameter: this.props.parameterXref ? res.find(p => p._id == this.props.parameterXref?.parameter)! : null
            }), () => this.validate())
            .catch(err => this.props.onError(err));
    }

    handleOnSelectParameter(param: CompanyParameterModel): void {
        this.setState({
            parameter: param,
            parameterSelectorOpened: false
        }, () => this.validate())
    }

    validate(): void {
        let errors: any = {...this.state.errors}
        const existingParameterIndex = this.props.company.parameters?.findIndex(p => p.parameter == this.state.parameter?._id && this.props.parameterXref?._id !== p._id)

        errors = Object.keys(errors).map(key => errors[key] = null);
        if (this.state.value?.trim() == '' && this.state.parameter?.default_value?.trim() == '') {
            errors.value = 'Required when default value is empty';
        }

        if (existingParameterIndex !== -1) {
            errors.parameter = 'Company already has this parameter'
        }

        if (!this.state.parameter) {
            errors.parameter = ' ';
        }

        this.setState({errors: errors})
    }


    handleOnSubmit(): void {
        this.setState({submitting: true})
        let apicall;

        if (this.props.parameterXref) {
            apicall = this.parameterService.updateCompanyParameter({
                _id: this.props.parameterXref._id,
                value: this.state.value,
                parameter: this.state.parameter!._id!,
                company: this.props.company.id
            })
        } else {
            apicall = this.parameterService.createCompanyParameter({
                value: this.state.value,
                parameter: this.state.parameter!._id!,
                company: this.props.company.id
            })
        }

        apicall
            .then(res => this.props.onClose(res))
            .catch(err => {
                this.props.onError(err);
                this.setState({submitting: false})
            })
    }


    render() {
        return (
            <Dialog open={true} maxWidth={"md"} fullWidth>
                <DialogTitle>{this.props.parameterXref ? "Update" : "Add"} Parameter</DialogTitle>
                <DialogContent>
                    <Stack padding={'15px 0'} spacing={2.5}>

                        <Stack width={'100%'} direction={'row'} alignItems={'space-between'} spacing={2}>
                            <Stack width={'100%'}>
                                <Typography sx={this.styles.form_field_label}>
                                    Parameter <span style={{color: "red"}}>*</span>
                                </Typography>
                                <Paper>
                                    <TextField
                                        fullWidth
                                        required
                                        color={"secondary"}
                                        placeholder="Organization ID"
                                        value={this.state.parameter ? this.state.parameter?.name : ''}
                                        onClick={() => this.setState({parameterSelectorOpened: true})}
                                        error={!!this.state.errors["parameter"]}
                                        helperText={
                                            this.state.errors["parameter"] !== " "
                                                ? this.state.errors["parameter"]
                                                : ""
                                        }
                                    />
                                </Paper>
                            </Stack>

                            <Stack width={'100%'}>
                                <Typography sx={this.styles.form_field_label}>
                                    Value
                                    {
                                        !this.state.parameter?.default_value &&
                                        <span style={{color: "red"}}>*</span>
                                    }
                                </Typography>
                                <Paper>
                                    <TextField
                                        fullWidth
                                        required
                                        color={"secondary"}
                                        placeholder="123"
                                        value={this.state.value}
                                        onChange={(event) => {
                                            this.setState({value: event.target.value}, () => {
                                                this.validate();
                                            })
                                        }}
                                        error={!!this.state.errors.value}
                                        helperText={
                                            this.state.errors.value !== " "
                                                ? this.state.errors.value
                                                : ""
                                        }
                                    />
                                </Paper>
                            </Stack>
                        </Stack>

                        <Divider/>
                        <Stack spacing={1} direction={'row'} alignItems={'center'}>
                            <Typography variant={'h6'} fontWeight={'600'}>
                                Parameter Info
                            </Typography>
                            <InfoOutlined color={'info'}/>
                        </Stack>

                        {
                            this.state.parameter &&
                            <Stack spacing={3}>
                                <Stack>
                                    <Typography sx={this.styles.form_field_label}>
                                        Default Value
                                    </Typography>
                                    <Paper>
                                        <TextField
                                            fullWidth
                                            required
                                            color={"secondary"}
                                            placeholder={this.state.parameter?.default_value ? this.state.parameter.default_value : 'No Default Value'}
                                            inputProps={{
                                                readOnly: true,
                                            }}
                                            value={this.state.parameter ? this.state.parameter?.default_value : ''}
                                        />
                                    </Paper>
                                </Stack>

                                <Stack>
                                    <Typography sx={this.styles.form_field_label}>
                                        Description
                                    </Typography>
                                    <Paper>
                                        <TextField
                                            fullWidth
                                            required
                                            color={"secondary"}
                                            placeholder="American Registry for Internet Numbers Organization Identifer"
                                            value={this.state.parameter ? this.state.parameter?.description : ''}
                                            multiline
                                            rows={5}
                                            inputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Paper>
                                </Stack>
                            </Stack>
                        }

                        {
                            !this.state.parameter && (
                                <Paper sx={{padding: 15}}>
                                    <Box sx={this.styles.form_field_label}>
                                        <Typography>Select Parameter</Typography>
                                    </Box>
                                </Paper>
                            )
                        }

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onClose(null)} sx={{mr: 1}}>
                        Cancel
                    </Button>
                    <LoadingButton
                        size="small"
                        color="primary"
                        onClick={this.handleOnSubmit.bind(this)}
                        loading={this.state.submitting}
                        loadingPosition="end"
                        endIcon={<SaveIcon/>}
                        variant="contained"
                        disabled={
                            Object.keys(
                                this.state.errors
                            ).filter((key) => {
                                return (
                                    this.state.errors[key as keyof object] !== null
                                );
                            }).length > 0
                            ||
                            this.state.submitting
                        }
                    >
                        <span>Save</span>
                    </LoadingButton>
                </DialogActions>

                {
                    this.state.parameterSelectorOpened && <CompanyParameterSelector
                        onClose={() => this.setState({parameterSelectorOpened: false})}
                        parameters={this.state.parameters.value}
                        onRowSelect={(row) => this.handleOnSelectParameter(row)}/>
                }

            </Dialog>
        )
    }

}