import React, {ReactNode} from "react";
import {DataCollection} from "../../../../../services/company/company.model";
import {DataCollectionSelector} from "../../../../Common/Selectors/DataCollectionSelector";
import {CommonChildProps} from "../CompanyDialog";

interface ThisProps extends CommonChildProps {
}

interface ThisState {
}

export class DataCollectionForm extends React.Component<ThisProps, ThisState> {
    constructor(props: ThisProps) {
        super(props);

        this.state = {};
    }

    componentDidMount(): void {
        this.validate();
    }

    validate = () => {
        let error = null;
        if (this.props.company.x_information_resources.length == 0) {
            error = "Atleast one data resource is required";
        }

        this.props.onError({
            ...this.props.errors,
            x_information_resources: error,
        });
    };

    render(): ReactNode {
        return (
            <DataCollectionSelector
                resources={this.props.company.x_information_resources}
                error={this.props.errors.x_information_resources}
                helperText={
                    '"Information Resources" represents the different types of data that the organization collects and maintains'
                }
                onUpdate={(value: DataCollection[]) => {
                    const company = {...this.props.company};
                    company.x_information_resources = [
                        ...value,
                        ...company.x_information_resources,
                    ];

                    this.props.onUpdate(company, this.validate);
                }}
                onDelete={(index: number) => {
                    const company = {...this.props.company};

                    company.x_information_resources.splice(index, 1);

                    this.props.onUpdate(company, this.validate);
                }}
            />
        );
    }
}
