import React, {useState} from 'react';
import {CompanyModel} from "../services/company/company.model";
import PublicAuthContext from "../context/PublicAuthContext";

interface DrawerStateProviderProps {
    children: React.ReactNode; // Define children as a prop
}

export const PublicAuthProvider: React.FC<DrawerStateProviderProps> = ({children}) => {
    const [company, setCompany] = useState<{pending: boolean, value: CompanyModel | null}>({pending: false, value: null})

    return (
        <PublicAuthContext.Provider value={{company, setCompany}}>
            {children} {/* Render children */}
        </PublicAuthContext.Provider>
    );
};
