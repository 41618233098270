import React from "react";
import {CompanyCommonProps} from "./ViewCompany";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardContent,
    InputAdornment,
    TextField,
    Theme,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {lighten, Stack} from "@mui/system";
import IconButton from "@mui/material/IconButton";
import {Add, ClearOutlined, ControlCameraOutlined, DeleteOutlineOutlined} from "@mui/icons-material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {CompanyParameterXrefModel} from "../../../../services/company/company-parameter.model";

interface ThisProps extends CompanyCommonProps {
    handleModifyCompanyParameter: (param: CompanyParameterXrefModel | null) => void;
    handleDeleteCompanyParameter: (param: CompanyParameterXrefModel) => void;
}

interface ThisState {
    parametersExpanded: boolean;
    filter: string;
}

export class ViewCompanyParameters extends React.Component<ThisProps, ThisState> {
    constructor(props: ThisProps) {
        super(props);

        this.state = {
            parametersExpanded: true,
            filter: '',
        }
    }

    handleSearch(value: string): void {
        this.setState({filter: value});
    }

    render() {
        return (
            <Accordion expanded={this.state.parametersExpanded} elevation={3}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon color={'secondary'}/>}
                    onClick={() =>
                        this.setState({parametersExpanded: !this.state.parametersExpanded})
                    }
                >
                    <Typography sx={{fontSize: '1.125rem', fontWeight: '600', mr: 1}}>
                        Parameters
                    </Typography>
                    <ControlCameraOutlined color={'info'}/>

                    <Button size={'small'}
                            variant={'contained'}
                            sx={{ml: 'auto', mr: 3}} color={'secondary'}
                            endIcon={<Add/>}
                            onClick={(e) => {
                                e.stopPropagation();
                                // this.props.handleCreateTool();
                                this.props.handleModifyCompanyParameter(null)
                            }}>
                        Add
                    </Button>

                    {/*<AppChip size={'small'} sx={{marginLeft: 'auto', marginRight: '10px'}} color={'secondary'}*/}
                    {/*      onClick={(e) => {*/}
                    {/*          e.stopPropagation();*/}
                    {/*          // this.props.handleCreateTool();*/}
                    {/*          this.props.handleModifyCompanyParameter(null)*/}
                    {/*      }} label={'Add'}/>*/}
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={2}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            onChange={(e) => this.handleSearch(e.target.value)}
                            value={this.state.filter}
                            color="secondary"
                            placeholder="Search for parameter"
                            size={'small'}
                            disabled={this.props.company.pending}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlinedIcon color="primary"/>
                                    </InputAdornment>
                                ),
                                endAdornment: this.state.filter && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => this.handleSearch("")}
                                            color="primary"
                                        >
                                            <ClearOutlined/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Stack spacing={2}>
                            {
                                this.props.company.value?.parameters?.length == 0 && (
                                    <Card sx={{borderLeft: (theme) => '2px solid ' + theme.palette.primary.main}}>
                                        <CardContent sx={{opacity: '.75'}}>
                                            <Typography>No Parameters</Typography>
                                        </CardContent>
                                    </Card>
                                )
                            }
                            {
                                this.props.company.value?.parameters?.filter(parameter => {
                                    const lowercaseFilter = this.state.filter.toLowerCase().trim();

                                    if (parameter.name?.trim().toLowerCase().includes(lowercaseFilter) ||
                                        parameter.value.trim().toLowerCase().includes(lowercaseFilter) ||
                                        parameter.default_value?.trim().toLowerCase().includes(lowercaseFilter)
                                    ) {
                                        return true
                                    }
                                }).map(parameter => {
                                    return (
                                        <Card
                                            elevation={1}
                                            key={parameter._id}
                                            onClick={(e) => this.props.handleModifyCompanyParameter(parameter)}
                                            sx={{
                                                cursor: "pointer",
                                                "&:hover": {
                                                    background: (theme: Theme) =>
                                                        lighten(theme.palette.background.default, 0.15),
                                                },
                                                borderLeft: (theme) => '3px solid ' + theme.palette.primary.main
                                            }}>

                                            <CardContent>
                                                <Stack direction={'row'}>
                                                    <Stack spacing={.5} width={'40%'}>
                                                        <Typography>{parameter.name}</Typography>
                                                        <Typography color={'primary'} fontWeight={500} variant={'body2'}
                                                                    sx={{opacity: '.8'}}>name</Typography>
                                                    </Stack>

                                                    <Stack spacing={.5}>
                                                        <Typography>{parameter.value ? parameter.value : parameter.default_value}</Typography>
                                                        <Typography color={'primary'} fontWeight={500} variant={'body2'}
                                                                    sx={{opacity: '.8'}}>value</Typography>
                                                    </Stack>

                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.props.handleDeleteCompanyParameter(parameter)
                                                        }}
                                                        sx={{ml: 'auto'}}>
                                                        <DeleteOutlineOutlined color={'error'}/>
                                                    </IconButton>
                                                </Stack>

                                            </CardContent>


                                        </Card>
                                    )
                                })
                            }
                        </Stack>
                    </Stack>
                </AccordionDetails>
            </Accordion>

        )
    }
}