import {ContentCopy, ExpandMore, WarningAmber,} from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    IconButton,
    Slide,
    Stack,
    Typography,
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import React from "react";

interface Props {
    error: ErrorDetails;
    onClose?: () => void;
}

interface State {
    open: boolean;
}

export interface ErrorDetails {
    requestID?: string;
    errorCode?: number;
    id?: string;
    message: string;
    details?: ErrorDetails[];
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class ErrorDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: true,
        };
    }

    handleCopyToClipBoard(event: any, entity: string) {
        event.stopPropagation();
        navigator.clipboard.writeText(entity);
    }

    handleOnClose() {
        this.setState({open: false})
        if (this.props.onClose)
            this.props.onClose!();
    }

    render(): React.ReactNode {
        return (
            <Dialog
                open={this.state.open}
                maxWidth={"xl"}
                fullWidth
                PaperProps={{sx: {position: "fixed", bottom: 10}}}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleOnClose.bind(this)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText
                        component={"div"}
                        sx={{display: "flex", alignItems: "center"}}
                    >
                        <Stack gap={3} direction={"row"} alignItems={"center"}>
                            <Stack alignItems={"center"}>
                                <WarningAmber fontSize="large" color="error"/>
                                <Typography component="div">ERROR</Typography>
                            </Stack>
                        </Stack>

                        <Box sx={{margin: "0 20px", width: "100%"}}>
                            {this.generateErrorMessage(
                                this.props.error as unknown as ErrorDetails,
                                "root"
                            )}
                        </Box>

                        <Stack
                            alignItems={"center"}
                            gap={3}
                            direction="row"
                            sx={{ml: "auto"}}
                        >
                            <Button
                                onClick={this.handleOnClose.bind(this)}
                                sx={{ml: "auto"}}
                                color="error"
                            >
                                close
                            </Button>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }

    generateErrorMessage(error: ErrorDetails, key: string) {
        return (
            <Accordion
                variant="outlined"
                disableGutters
                sx={{
                    width: "100%",
                    borderTop: "transparent",
                    borderRight: "transparent",
                    "&.MuiPaper-root": {background: "transparent"},
                }}
                key={key}
            >
                <AccordionSummary
                    sx={{"&.Mui-disabled": {opacity: 1}}}

                    expandIcon={
                        <ExpandMore
                            sx={{opacity: error.details?.length == 0 ? "0" : "1"}}
                        />
                    }
                >
                    <Stack sx={{width: "100%"}}>
                        <Stack sx={{width: "100%", mb: "5px"}} direction={"column"}>
                            {error.requestID && (
                                <Stack direction={"row"} alignItems="center">
                                    <Typography
                                        sx={{color: (theme) => theme.palette.text.primary}}
                                        variant="body1"
                                        component={"div"}
                                    >
                    <span style={{opacity: ".7", marginRight: "10px"}}>
                      requestID:
                    </span>
                                        {error.requestID}
                                    </Typography>
                                    <IconButton
                                        color="secondary"
                                        onClick={(event) =>
                                            this.handleCopyToClipBoard(event, error.requestID!)
                                        }
                                    >
                                        <ContentCopy/>
                                    </IconButton>
                                </Stack>
                            )}

                            {error.errorCode && (
                                <Typography
                                    sx={{color: (theme) => theme.palette.text.primary}}
                                    variant="body1"
                                    component={"div"}
                                >
                  <span style={{opacity: ".7", marginRight: "10px"}}>
                    errorCode:{" "}
                  </span>{" "}
                                    {error.errorCode}
                                </Typography>
                            )}

                            {error.id && (
                                <Typography
                                    sx={{color: (theme) => theme.palette.text.primary}}
                                    variant="body1"
                                    component={"div"}
                                >
                  <span style={{opacity: ".7", marginRight: "10px"}}>
                    id:{" "}
                  </span>{" "}
                                    {error.id}
                                </Typography>
                            )}
                        </Stack>
                        <Typography
                            sx={{color: (theme) => theme.palette.text.primary}}
                            variant="body1"
                            component={"div"}
                        >
                            <Typography
                                sx={{color: (theme) => theme.palette.text.primary}}
                                variant="body1"
                                component={"div"}
                            >
                <span style={{opacity: ".7", marginRight: "10px"}}>
                  message:{" "}
                </span>{" "}
                                {error.message}
                            </Typography>
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{padding: "10px"}}>
                    {error?.details?.map((detail, index) => {
                        return this.generateErrorMessage(detail, key + "-" + index);
                    })}
                </AccordionDetails>
            </Accordion>
        );
    }
}
