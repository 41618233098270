import React from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";

export default function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        let [searchParams] = useSearchParams();

        const openInNewTab = (path: string) => {
            const url = window.location.origin + path; // Construct full URL
            const win = window.open(url, '_blank');
            if (win) {
                win.focus();
            }
        };

        return (
            <Component
                {...props}
                router={{location, navigate, params, searchParams, openInNewTab}}
            />
        );
    }

    return ComponentWithRouterProp;
}
