import {Box, Container, Tab, Tabs, Typography} from "@mui/material";
import React from "react";
import {ReportAnalysis,} from "../../../../../../../services/ThreatAnalysis/threatAnalysis.model";
import {AttackConsequence} from "./AttackConsequence";
import {AttackMitigation} from "./AttackMitigation";
import {ThreatActorCapabilities} from "./Capabilities";
import {Vulnerabilities} from "./Vulnerabilities";
import {SharedAnalysisFormProps} from "../AnalysisFormWrapperDialog";
import toTitleCase from "../../../../../../Common/Utils/AutoCompleteOptions/toTitleCase";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{width: "100%", m: 0, marginTop: "20px"}}>{children}</Box>
            )}
        </div>
    );
}

interface ThisProps extends SharedAnalysisFormProps {
    onUpdate: (analysis: ReportAnalysis) => void;
    doesEntryExistInText: (t: string) => boolean;
}

interface ThisState {
    tabIndex: number;
}

export class AMCForm extends React.Component<ThisProps, ThisState> {
    constructor(props: ThisProps) {
        super(props);
        this.state = {
            tabIndex: 0,
        };
    }

    handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        this.setState({tabIndex: newValue});
    };

    onUpdate(key: keyof ReportAnalysis, value: any): void {
        this.props.onUpdate({...this.props.analysis, [key]: value});
    }

    render(): React.ReactNode {
        return (


                <Box>
                    {
                        this.props.fieldKey === 'attack_mitigations' &&
                            <AttackMitigation
                                analysis={this.props.analysis}
                                onUpdate={this.onUpdate.bind(this)}
                                doesEntryExistInText={this.props.doesEntryExistInText.bind(this)}
                            />
                    }

                    {
                        this.props.fieldKey === 'attack_consequences' &&
                            <AttackConsequence
                                analysis={this.props.analysis}
                                onUpdate={this.onUpdate.bind(this)}
                                doesEntryExistInText={this.props.doesEntryExistInText.bind(this)}
                            />
                    }

                    {
                        this.props.fieldKey === 'threat_actor_capabilities' &&
                            <ThreatActorCapabilities
                                analysis={this.props.analysis}
                                onUpdate={this.onUpdate.bind(this)}
                                doesEntryExistInText={this.props.doesEntryExistInText.bind(this)}
                            />
                    }
                    {
                        this.props.fieldKey === "vulnerabilities" &&
                        <Vulnerabilities
                            analysis={this.props.analysis}
                            onUpdate={this.onUpdate.bind(this)}
                            doesEntryExistInText={this.props.doesEntryExistInText.bind(this)}
                        />
                    }
                </Box>
        );
    }
}
