import {Stack} from "@mui/system";
import {ThreatActor} from "../../../../../../services/ThreatActor/threatActor.model";
import React from "react";
import {InfoCard} from "../../../HomeCommon";
import {BugReport, NearbyError} from "@mui/icons-material";
import {useModal} from "mui-modal-provider";
import ThreatActorCapabilitiesDialog from "./ThreatActorCapabilitiesDialog";
import MitreAttackService from "../../../../../../services/mitre-attack.service";

interface ThisProps {
    threatActor: ThreatActor
}

const ThreatActorCapabilities: React.FC<ThisProps> = (props: ThisProps) => {
    const {showModal} = useModal();
    const ms = new MitreAttackService();

    const viewMitreAttackID = (id: string) => {
        if (id.toLowerCase().startsWith('t')) {
            ms.routeToTacticPage(id)
        } else if (id.toLowerCase().startsWith('m')) {
            ms.routeToMitigationPage(id);
        }
    }

    const viewCVE = (id: string) => {
        let baseURL = 'https://nvd.nist.gov/vuln/detail/';

        window.open(baseURL + id?.toUpperCase(), '_blank')
    }

    return (
        <Stack spacing={1}>
            <Stack direction={'row'} spacing={2}>
                <InfoCard
                    icon={NearbyError}
                    iconColor={'error'}
                    mainText={props.threatActor.x_capabilities?.length ?? 0}
                    disabled={props.threatActor.x_capabilities?.length == 0}
                    subText={'Capabilities'}
                    onClick={() => {
                        showModal(ThreatActorCapabilitiesDialog, {
                                data: props.threatActor.x_capabilities ?? [],
                                headers: ['Capabilities', ''],
                                title: "Threat Actor Capabilities",
                                onClick: viewMitreAttackID
                            }
                        )
                    }}/>
                <InfoCard icon={BugReport}
                          iconColor={'error'}
                          mainText={props.threatActor.vulnerabilities?.length ?? 0}
                          disabled={props.threatActor.vulnerabilities?.length == 0}
                          subText={'Vulnerabilities'}
                          onClick={() => {
                              showModal(ThreatActorCapabilitiesDialog, {
                                      data: props.threatActor.vulnerabilities ?? [],
                                      headers: ['Vulnerabilities', ''],
                                      title: "Threat Actor Vulnerabilities",
                                      onClick: viewCVE
                                  }
                              )
                          }}/>
            </Stack>
            <Stack direction={'row'} spacing={2}>
                <InfoCard
                    icon={NearbyError}
                    iconColor={'error'}
                    mainText={props.threatActor.attack_consequences?.length ?? 0}
                    disabled={props.threatActor.attack_consequences?.length == 0}
                    subText={'Techniques'}
                    onClick={() => {
                        showModal(ThreatActorCapabilitiesDialog, {
                                data: props.threatActor.attack_consequences ?? [],
                                headers: ['Consequences', ''],
                                title: "Threat Actor Consequences",
                                onClick: viewMitreAttackID
                            }
                        )
                    }}/>
                <InfoCard icon={BugReport}
                          iconColor={'error'}
                          mainText={props.threatActor?.attack_mitigations?.length ?? 0}
                          disabled={props.threatActor.attack_mitigations?.length == 0}
                          subText={'Mitigations'}
                          onClick={() => {
                              showModal(ThreatActorCapabilitiesDialog, {
                                      data: props.threatActor.attack_mitigations ?? [],
                                      headers: ['Mitigations', ''],
                                      title: "Threat Actor Mitigations",
                                      onClick: viewMitreAttackID
                                  }
                              )
                          }}/>
            </Stack>
        </Stack>


    )
}

export default ThreatActorCapabilities;