import {Add} from "@mui/icons-material";
import {Box, Button, Stack, Toolbar,} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {Company, CompanyModel} from "../../../services/company/company.model";
import CompanyService from "../../../services/company/company.service";
import SimpleSnackbar from "../../Common/SnackBar/SnackBar";
import withRouter from "../../Common/Utils/WithRouter/WithRouter";
import CompanyProfilerTable from "./CompanyProfilerTable";
import {ErrorDetails, ErrorDialog} from "../../Common/ErrorDialog/ErrorDialog";
import {CompanyDialog} from "./CompanyDialog/CompanyDialog";
import {AppToolbar} from "../../Common/Drawer/NestedDrawer";
import DrawerStateContext from "../../../context/DrawerContext";
import {AppTitle} from "../../Common/common";
import {DebouncedTextField} from "../../Common/DebouncedTextField";
import {AddCompanyDialog} from "./AddCompanyDialog";
import {usePagination} from "@mui/lab";
import {useModal} from "mui-modal-provider";
import {DeleteCompanyDialog} from "./DeleteCompanyDialog";
import {defaultPagination, PaginationRequest} from "../../../services/api-service";

interface CompanyProfilerState {
    _companies: { pending: boolean; companies: CompanyModel[] };
    filter: string;
    dialog: { opened: boolean; company: null | CompanyModel };
    snackbar: { open: boolean; message: string };
    errorDialog: { opened: boolean; details: ErrorDetails | null };


    fetchingCompanies: boolean;
    pagination: {
        page: number,
        rowsPerPage: number,
        totalRecords: number
    }
}

export default function CompanyProfiler () {
    const companyService = new CompanyService();
    const drawerContext = useContext(DrawerStateContext);
    const {showModal} = useModal();

    const [fetchingCompanies, setFetchingCompanies] = useState(false)
    const [filter, setFilter] = useState("");
    const [companies, setCompanies] = useState<{value: CompanyModel[], pending: boolean}>({value: [], pending: true})
    const [pagination, setPagination] = useState<PaginationRequest>(defaultPagination)



    const handleSearchCompanies = (filter: string): void  => {
        setFetchingCompanies(true)


        companyService
            .getPaginatedCompanies(pagination, filter)
            .then((res) => {
                setCompanies({value: res.results, pending: false})
                setFetchingCompanies(false)
                if (pagination) {
                    setPagination({...pagination, totalRecords:  res.count, page: 0})
                }
            })
            .catch((err) => {
                showModal(ErrorDialog, {error: err});
                setFetchingCompanies(false)
                setCompanies({...companies, pending: false})
            });
    }


    const handleFetchCompanies = (thisPagination: any): void => {
        setCompanies({...companies, pending: true})

        companyService.getPaginatedCompanies(thisPagination, filter)
            .then(res => {
                setCompanies({value: [...companies.value, ...res.results], pending: false})
                setPagination({...thisPagination, totalRecords:  res.count})
            })
            .catch((err) => {
                showModal(ErrorDialog, {error: err});
                setCompanies({...companies, pending: false})
            });
    }


    const handleOnDelete = (companyId: string): void => {
        showModal(DeleteCompanyDialog, {companyId: companyId, onClose: (removed) => {
            if (removed) {
                showModal(SimpleSnackbar, {message: "Deleted Company!"});
                setCompanies({...companies, value: companies.value.filter(c => c.id !== companyId)})
            }
        }})
    }

    const handleAddCompany = () => {
        showModal(AddCompanyDialog, {onClose: (company) => {
            if (company) {
                showModal(SimpleSnackbar, {message: "Added Company!"});
                setCompanies({...companies, value: [company, ...companies.value]})
            }
        }})
    }

    return (
        <Box display={'flex'} flexDirection={'column'} sx={{overflow: 'hidden'}}>
            <AppToolbar open={drawerContext?.isDrawerOpen}>
                <Toolbar disableGutters>
                    <Stack width={'100%'} alignItems={'center'} justifyContent={'space-between'} direction={"row"}>
                        <AppTitle variant={'h5'}>
                            Companies
                        </AppTitle>

                        <Button
                            onClick={handleAddCompany}
                            size={'small'}
                            disabled={companies.pending}
                            color="secondary"
                            variant={'contained'}
                            endIcon={<Add/>}
                        > Add </Button>
                    </Stack>
                </Toolbar>
            </AppToolbar>


            <Stack
                sx={{
                    mt: theme => theme.spacing(8),
                    height: theme => `calc(100vh - ${theme.spacing(12)})`
                }}
                spacing={2}>
                <DebouncedTextField showSpinner={fetchingCompanies && filter?.trim() !== ''}
                                    placeholder={'Search for companies'}

                                    filter={filter}
                                    onValueChanged={(filter) => setFilter(filter)}

                                    onSearch={(value) => {
                                        if (pagination)
                                            setPagination({...pagination, page: 0})

                                        handleSearchCompanies(value);
                                    }}
                />


                <CompanyProfilerTable
                    _companies={companies}
                    filter={filter}
                    onDelete={handleOnDelete}
                    setPagination={(page: number, rowsPerPage: number) => {
                        const newPagination = {...pagination, page: page, rowsPerPage: rowsPerPage};
                        handleFetchCompanies(newPagination);
                    }}
                    fetchingCompanies={fetchingCompanies}
                    pagination={pagination}
                />

            </Stack>

        </Box>
    );
}
