import React, {useContext, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme} from "@mui/material";
import {useModal} from "mui-modal-provider";
import {Lock} from "@mui/icons-material";
import {Stack} from "@mui/system";

interface Props {
    userEmail: string;
}


const ReportLockStatusChangeDialog = ({userEmail}: Props) => {
    const [open, setOpen] = useState(true)
    const {showModal} = useModal();

    const handleClose = () => {
        setOpen(false);
    }

    const theme = useTheme();
    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth>

            <DialogTitle>
                Report Locked!
            </DialogTitle>

            <DialogContent>
                <Stack sx={{paddingY: theme.spacing(2)}} alignItems={'center'} direction={'row'} gap={2}>
                    <Lock sx={{fontSize: '5em'}} color={'error'}/>
                    <DialogContentText>
                        {`This report has been locked by '${userEmail}'.`}
                    </DialogContentText>
                </Stack>
            </DialogContent>

            <DialogActions>

                <Button size={'small'} sx={{color: theme.palette.text.primary}} onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}


export default ReportLockStatusChangeDialog