import {useContext, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme} from "@mui/material";
import {ReportModel} from "../../../../services/ThreatAnalysis/threatAnalysis.model";
import {AuthContext} from "../../../../context/AuthContext";
import {LoadingButton} from "@mui/lab";
import ThreatAnalysisService from "../../../../services/ThreatAnalysis/threatAnaylsis.service";
import {LockOpen} from "@mui/icons-material";
import {useModal} from "mui-modal-provider";
import {ErrorDialog} from "../../../Common/ErrorDialog/ErrorDialog";

interface Props {
    report: ReportModel;
    onClose: (locked: boolean) => void;
}


const ReportLockDialog = ({report, onClose}: Props) => {
    const reportService = new ThreatAnalysisService();

    const [open, setOpen] = useState(true)
    const [submitting, setSubmitting] = useState(false);
    const {user} = useContext(AuthContext);
    const {showModal} = useModal();

    const revokeStatus = user?.firebase_id !== report.locked_by && report.locked_by !== null;


    const handleConfirm = () => {
        setSubmitting(true);


        if (revokeStatus) {
            reportService.acquireThreatReportLock(report._id)
                .then(() => handleClose(true))
                .catch(err => {
                    showModal(ErrorDialog, {error: err})
                    setSubmitting(false);
                })
        } else {
            reportService.lockThreatReport(report._id)
                .then(() => handleClose(true))
                .catch(err => {
                    showModal(ErrorDialog, {error: err})
                    setSubmitting(false);
                })
        }


    }

    const handleClose = (locked: boolean) => {
        setOpen(false);
        onClose(locked);
    }

    const theme = useTheme();
    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth>

            <DialogTitle>
                {
                    revokeStatus && "Unlock Report & Edit?"
                }

                {
                    !revokeStatus && "Lock Report & Edit?"
                }

            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    {
                        revokeStatus && `This report is currently locked by ${user?.email}. Please consult with them before unlocking it. If you release the lock from them, 
                         they will be unable to make further updates until they re-establish the lock themselves.`
                    }
                    {
                        !revokeStatus && `Locking a report prevents others from making updates. Ensure you unlock the report before exiting the application.`
                    }
                </DialogContentText>
            </DialogContent>

            <DialogActions>


                <Button size={'small'} sx={{color: theme.palette.text.primary}} onClick={() => handleClose(false)}>Cancel</Button>
                <LoadingButton size={'small'} variant={'contained'} sx={{ml: 1}} loading={submitting} disabled={submitting} loadingPosition={"end"} endIcon={<LockOpen />} color={'primary'} onClick={handleConfirm}>Confirm</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}


export default ReportLockDialog