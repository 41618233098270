import {Add, DeleteOutline, Grading} from "@mui/icons-material";
import {Autocomplete, Box, Checkbox, Divider, IconButton, Stack, TextField, Typography,} from "@mui/material";
import React, {ReactNode} from "react";
import {StixSector} from "../../../services/stixx/stixx.model";

import {AnalysisSharableTable, IconButtonColumn, PropertyColumn,} from "../TableHelper/SharableTable";
import {AppChip} from "../common";

interface ThisProps {
    choices: { pending: boolean; value: StixSector[] };
    sectors: StixSector[];
    onUpdate: (value: any[]) => void;
    onDelete: (row_index: any) => void;

    includeAdhoc: boolean;
    helperText?: string;
    error?: string | null;

    doesEntryExistInText?: (text: string) => boolean;
}

interface ThisState {
    reset: string;
    selectedItems: StixSector[]
}

export class SectorSelector extends React.Component<ThisProps, ThisState> {
    constructor(props: ThisProps) {
        super(props);
        this.state = {
            reset: new Date().toISOString(),

            selectedItems: []
        };
    }

    render(): ReactNode {
        const table_rows = [
            new PropertyColumn("name"),
            new IconButtonColumn(
                <DeleteOutline color="error"/>,
                (row_index) => {
                    this.props.onDelete(row_index);
                },
                {float: "right"}
            ),
        ];

        const table_headers: string[] = ["Sector", ""]

        if (this.props.includeAdhoc) {
            table_rows.splice(
                1,
                0,
                new PropertyColumn("adhoc", (isAdhoc: boolean) => {
                    return (
                        isAdhoc && <AppChip size={"small"} color={"error"} label={"Adhoc"}/>
                    );
                })
            );

            table_headers.splice(1, 0, 'Adhoc')
        }

        if (this.props.doesEntryExistInText) {
            table_rows.splice(
                1,
                0,
                new IconButtonColumn(
                    (value: StixSector) => {
                        if (this.props.doesEntryExistInText!(value.name)) {
                            return <Grading color={'success'}/>
                        }
                    },
                ),
            );

            table_headers.splice(1, 0, 'Identified in Source')
        }

        return (
            <React.Fragment>
                <Stack spacing={1}>
                    <Box sx={{width: "100%"}}>
                        <Stack
                            sx={{mb: "12px"}}
                            direction="row"
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Typography fontSize={"1.25rem"} fontWeight={600}>
                                Sector Lookup
                            </Typography>
                            <IconButton
                                sx={{
                                    padding: 0,
                                    borderRadius: "50%",
                                    border: (theme) =>
                                        "1px solid " + theme.palette.secondary.main,
                                }}
                                size="small"
                                color="secondary"
                                disabled={this.state.selectedItems.length == 0}
                                onClick={(e) => {
                                    this.props.onUpdate(this.state.selectedItems);
                                    this.setState({
                                        reset: new Date().toISOString(),
                                        selectedItems: []
                                    });
                                }}
                            >
                                <Add/>
                            </IconButton>
                        </Stack>
                        {this.props.helperText && (
                            <Typography sx={{opacity: ".6", mb: 1}} variant="body2">
                                {this.props.helperText}
                            </Typography>
                        )}
                        <Autocomplete
                            multiple
                            disablePortal
                            disableClearable
                            size={"small"}
                            key={this.state.reset}
                            disableCloseOnSelect
                            onChange={(event, value) =>
                                this.setState({
                                    selectedItems: value as StixSector[],
                                })
                            }
                            options={this.props.choices.value.filter(
                                (sector) =>
                                    !this.props.sectors.map((is) => is.id).includes(sector.id)
                            )}
                            renderOption={(props, option) => (
                                <Box key={option.id} padding={"2px 0"}>
                                    <li {...props}>
                                        <Checkbox
                                            style={{marginRight: 8}}
                                            checked={this.state.selectedItems.includes(option)}
                                        />
                                        {option.name}
                                    </li>
                                    <Divider/>
                                </Box>
                            )}
                            getOptionLabel={(option: StixSector) => {
                                return `${option.name}`;
                            }}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    color={"secondary"}
                                    {...params}
                                    error={!!this.props.error}
                                    helperText={this.props.error ? this.props.error : " "}
                                    label="Industry Sector"
                                />
                            )}
                        />
                    </Box>
                    <Divider/>

                    {this.props.includeAdhoc && (
                        <Typography variant="body2" sx={{opacity: ".6"}}>
                            The "adhoc" sectors in this report were not previously stored
                            sectors. They were created during the analysis process. This adhoc
                            status can be updated on the Stix Sectors Configuration page.
                        </Typography>
                    )}
                    <AnalysisSharableTable
                        headers={table_headers}
                        data={this.props.sectors}
                        rows={table_rows}
                    />
                </Stack>
            </React.Fragment>
        );
    }
}
