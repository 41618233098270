import React, {useContext} from "react";
import CompanyParameterService from "../../../services/company/company-parameter.service";
import {CompanyParameterModel} from "../../../services/company/company-parameter.model";
import {ErrorDetails, ErrorDialog} from "../../Common/ErrorDialog/ErrorDialog";
import {Box, Button, IconButton, InputAdornment, Stack, TextField, Toolbar} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {Add, ClearOutlined} from "@mui/icons-material";
import SimpleSnackbar from "../../Common/SnackBar/SnackBar";
import CompanyParametersTable from "./CompanyParametersTable";
import CompanyParametersDialog from "./CompanyParametersDialog";
import {AppToolbar} from "../../Common/Drawer/NestedDrawer";
import withRouter from "../../Common/Utils/WithRouter/WithRouter";
import DrawerStateContext, {DrawerStateContextType} from "../../../context/DrawerContext";
import {AppTitle} from "../../Common/common";

interface ThisProps {
    // This entire view is used a parameter selector when tying a company
    // to a parameter.

    asSelector?: boolean;

    // when user clicks on the row
    onRowSelect?: (row: CompanyParameterModel) => void;

    // hide edit/delete/create buttons on table rows & main ui
    disableActionButtons?: boolean;

    // pass in parameters
    parameters?: CompanyParameterModel[]

    // how to handle closing this view if used as selector
    onClose?: () => void;

    drawerContext?: DrawerStateContextType;
}

interface ThisState {
    parameters: { pending: boolean, value: CompanyParameterModel[] }
    parameterDialog: { opened: boolean, value: null | CompanyParameterModel }
    errorDialog: { opened: boolean, details: ErrorDetails | null }
    snackbar: { opened: boolean, message: string }
    filter: string;
}

class CompanyParametersViewComponent extends React.Component<ThisProps, ThisState> {
    companyParameterService: CompanyParameterService = new CompanyParameterService();

    constructor(props: ThisProps) {
        super(props);
        this.state = {
            parameters: {pending: false, value: []},
            errorDialog: {opened: false, details: null},
            parameterDialog: {opened: false, value: null},
            snackbar: {opened: false, message: ''},
            filter: ''
        }
    }

    componentDidMount() {
        this.handleFetchParameters();
    }

    handleFetchParameters() {
        if (this.props.parameters) {
            this.setState({parameters: {value: this.props.parameters, pending: false}})
        } else {
            this.companyParameterService.getParameters()
                .then(res => this.setState({parameters: {value: res, pending: false}}))
                .catch(err => this.setState({
                    parameters: {value: [], pending: false},
                    errorDialog: {opened: true, details: err}
                }))
        }

    }

    handleError(error: ErrorDetails): void {
        this.setState({errorDialog: {opened: true, details: error}});
    }

    handleParameterDialogClose(param: CompanyParameterModel | null): void {
        let params = [...this.state.parameters.value];
        let snackbar = {...this.state.snackbar}
        if (param) {
            snackbar.opened = true;

            const index = params.findIndex((p) => p._id === param._id);
            if (index !== -1) {
                // Replace the company at the correct index
                params[index] = param;
                snackbar.message = 'Updated parameter!'
            } else {
                // Add the NEW company to the beginning of the list
                params = [param, ...params];
                snackbar.message = "Added parameter!";
            }
        }

        this.setState({
            parameters: {...this.state.parameters, value: params},
            snackbar: {...snackbar},
            parameterDialog: {opened: false, value: null}
        })
    }

    handleSearch(value: string): void {
        this.setState({filter: value});
    }

    handleEdit(param: CompanyParameterModel): void {
        this.setState({parameterDialog: {opened: true, value: param}})
    }

    handleDelete(param_id: string): void {
        this.companyParameterService.deleteParameter(param_id)
            .then(res => this.setState({
                parameters: {
                    pending: false,
                    value: this.state.parameters.value.filter(param => param._id !== param_id)
                }
            }))
            .catch(err => this.setState({
                errorDialog: {opened: true, details: err}
            }))

    }

    render(): React.ReactNode {
        return (
            <Box>
                {
                    !this.props.asSelector &&
                    <AppToolbar open={this.props.drawerContext?.isDrawerOpen}>
                        <Toolbar disableGutters>
                            <Stack width={'100%'} alignItems={'center'} justifyContent={'space-between'}
                                   direction={"row"}>
                                <AppTitle variant={'h5'}>
                                    Company Parameters
                                </AppTitle>


                                {
                                    !this.props.disableActionButtons &&
                                    <Button
                                        onClick={() =>
                                            this.setState({parameterDialog: {opened: true, value: null}})
                                        }
                                        size={'small'}
                                        variant={'contained'}
                                        disabled={this.state.parameters.pending}
                                        color="secondary"
                                        endIcon={<Add/>}
                                    > Add</Button>
                                }
                                {
                                    this.props.onClose && <IconButton color={'primary'}
                                                                      onClick={() => this.props.onClose!()}><ClearOutlined/></IconButton>
                                }
                            </Stack>

                        </Toolbar>
                    </AppToolbar>
                }


                <Box sx={{mt: this.props.asSelector ? 0 : 8}}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size={'small'}
                        onChange={(e) => this.handleSearch(e.target.value)}
                        value={this.state.filter}
                        color="primary"
                        placeholder="Search for parameter"
                        disabled={this.state.parameters.pending}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlinedIcon color="primary"/>
                                </InputAdornment>
                            ),
                            endAdornment: this.state.filter && (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => this.handleSearch("")}
                                        color="primary"
                                    >
                                        <ClearOutlined/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CompanyParametersTable
                        parameters={this.state.parameters}
                        filter={this.state.filter}
                        onDelete={this.handleDelete.bind(this)}
                        onEdit={this.handleEdit.bind(this)}


                        disableActionButtons={this.props.disableActionButtons}
                        onClick={(row: CompanyParameterModel) => {
                            if (this.props.onRowSelect) {
                                this.props.onRowSelect(row);
                            }
                        }}
                    />
                </Box>

                <SimpleSnackbar
                    onClose={() =>
                        this.setState({snackbar: {message: "", opened: false}})
                    }
                    open={this.state.snackbar.opened}
                    message={this.state.snackbar.message}
                />

                {this.state.parameterDialog.opened && (
                    <CompanyParametersDialog
                        parameter={this.state.parameterDialog.value}
                        existingParameters={this.state.parameters}
                        onClose={this.handleParameterDialogClose.bind(this)}
                        onError={this.handleError.bind(this)}
                    />
                )}

                {this.state.errorDialog.opened && (
                    <ErrorDialog
                        error={this.state.errorDialog.details!}
                        onClose={() =>
                            this.setState({errorDialog: {opened: false, details: null}})
                        }
                    />
                )}
            </Box>
        );
    }
}

const WrappedComponent = withRouter(CompanyParametersViewComponent);
export default function CompanyParametersView(props: ThisProps) {
    const appContext = useContext(DrawerStateContext);
    return <WrappedComponent {...props} drawerContext={appContext}/>
};