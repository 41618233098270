import React from "react";

import {Autocomplete, Box, Divider, Paper, Stack, TextField, Typography,} from "@mui/material";
import {
    ReportAnalysis,
    ThreatActorSophistication, ThreatActorType,
} from "../../../../../../../services/ThreatAnalysis/threatAnalysis.model";
import {TargetCountryData} from "../TargetEntites/TargetEntitesForm";
import toTitleCase from "../../../../../../Common/Utils/AutoCompleteOptions/toTitleCase";

interface ThisState {
    threat_actor_input: string;
    country_of_origin_input: string;
    // mitre_attack_id_input: string;
    threat_actor_type_input: string;
    sophestication_input: string;
}

interface ThisProps {
    fieldKey: keyof ReportAnalysis;
    analysis: ReportAnalysis;
    onUpdate: (analysis: ReportAnalysis) => void;
    countries: TargetCountryData[];
    threatActors: { pending: boolean, value: string[] }
}

export class ThreatActorDetails extends React.Component<ThisProps, ThisState> {
    constructor(props: ThisProps) {
        super(props);
        this.state = {
            threat_actor_input: props.analysis.threat_actor
                ? props.analysis.threat_actor
                : "",
            country_of_origin_input: props.analysis.country_of_origin
                ? props.analysis.country_of_origin
                : "",
            threat_actor_type_input: props.analysis.threat_actor_type
                ? props.analysis.threat_actor_type
                : "",
            // mitre_attack_id_input: props.analysis.mitre_attack_id ? props.analysis.mitre_attack_id : '',
            sophestication_input: props.analysis.threat_actor_sophistication
                ? props.analysis.threat_actor_sophistication
                : "",
        };
    }

    onUpdate(_key: keyof ReportAnalysis, value: string): void {
        this.props.onUpdate({
            ...this.props.analysis,
            [_key]: value,
        });
    }

    render(): React.ReactNode {
        let threat_actor_exist = this.props.analysis.aliases.filter(
            (n) =>
                n.toLocaleLowerCase().trim() ==
                this.state.threat_actor_input.toLocaleLowerCase().trim()
        );

        return (
            <Stack spacing={3}>
                <Typography fontSize={"1.25rem"} sx={{mb: "12px"}} fontWeight={600}>
                    Update {toTitleCase(this.props.fieldKey.replace("_", " "))}
                </Typography>

                {
                    this.props.fieldKey === 'threat_actor' &&
                    <Paper elevation={1}>
                        <Autocomplete
                            disablePortal
                            disableClearable
                            fullWidth
                            options={this.props.threatActors.value.filter(v => v.toLocaleLowerCase() !== this.props.analysis.threat_actor.toLowerCase())}
                            freeSolo
                            value={this.props.analysis.threat_actor}
                            onChange={(event, value) =>
                                this.onUpdate("threat_actor", value)
                            }
                            onInputChange={(event, newInputValue, reason) => {
                                this.onUpdate("threat_actor", newInputValue)
                            }}
                            renderInput={(params) => (
                                <TextField error={threat_actor_exist.length > 0}
                                           color={"secondary"}
                                           required
                                           {...params} />
                            )}
                        />
                    </Paper>
                }

                {
                    this.props.fieldKey === 'country_of_origin' &&
                    <Paper elevation={1}>
                        <Autocomplete
                            disablePortal
                            value={this.props.countries.find(
                                (c) => c.iso_code == this.props.analysis.country_of_origin
                            )}
                            onChange={(event, value) =>
                                this.onUpdate("country_of_origin", value ? value.iso_code : "")
                            } //
                            options={this.props.countries.filter(
                                (iso) =>
                                    !this.props.analysis.target_countries.includes(iso.iso_code)
                            )}
                            renderOption={(props, option) => (
                                <Box key={option.iso_code} padding={"2px 0"}>
                                    <li
                                        {...props}
                                    >{`${option?.country} (${option?.iso_code})`}</li>
                                    <Divider/>
                                </Box>
                            )}
                            getOptionLabel={(option: {
                                country: string;
                                iso_code: string;
                            }) => {
                                return `${option?.country} (${option?.iso_code})`;
                            }}
                            fullWidth
                            renderInput={(params) => (
                                <TextField color={"secondary"} {...params} />
                            )}
                        />
                    </Paper>
                }

                {
                    this.props.fieldKey === 'threat_actor_type' &&
                    <Paper elevation={1}>
                        <Autocomplete
                            disablePortal
                            value={
                                this.props.analysis?.threat_actor_type
                                    ? this.props.analysis!.threat_actor_type
                                    : ""
                            }
                            disableClearable
                            onChange={(event, value) =>
                                this.onUpdate(
                                    "threat_actor_type",
                                    value ? (value as ThreatActorType) : ""
                                )
                            } //
                            options={Object.values(ThreatActorType)}
                            fullWidth
                            renderInput={(params) => (
                                <TextField color={"secondary"} {...params} />
                            )}
                        />
                    </Paper>
                }

                {
                    this.props.fieldKey === 'threat_actor_sophistication' &&
                    <Paper elevation={1}>
                        <Autocomplete
                            disablePortal
                            value={
                                this.props.analysis?.threat_actor_sophistication
                                    ? this.props.analysis!.threat_actor_sophistication
                                    : ""
                            }
                            disableClearable
                            onChange={(event, value) =>
                                this.onUpdate(
                                    "threat_actor_sophistication",
                                    value ? (value as ThreatActorSophistication) : ""
                                )
                            } //
                            options={Object.values(ThreatActorSophistication)}
                            fullWidth
                            renderInput={(params) => (
                                <TextField color={"secondary"} {...params} />
                            )}
                        />
                    </Paper>
                }
            </Stack>
        );
    }
}
