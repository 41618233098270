import {
    Button,
    CssBaseline,
    Divider,
    FormControl,
    Input,
    InputAdornment, Snackbar,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import Box from '@mui/material/Box';
import './Login.css'
import React, {ChangeEvent, useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import {auth} from "../../firebase";
import GoogleIcon from '@mui/icons-material/Google';
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    UserCredential,
} from "firebase/auth";
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import {useNavigate} from "react-router-dom";
import {useModal} from "mui-modal-provider";
import {Web3AuthDialog} from "../Web3Auth/Web3Auth";
import {Stack} from "@mui/system";
import {Wallet} from "@mui/icons-material";
import {UserGroup} from "../../services/User/user.model";
import UserNotAssignedDialog from "./UserNotAssignedDialog";
import {ResetPassword} from "./ResetPassword";
import SimpleSnackbar from "../Common/SnackBar/SnackBar";

export function Login() {
    const {user, userPending} = useContext(AuthContext);
    const emailRef = useRef<HTMLInputElement>()
    const passwordRef = useRef<HTMLInputElement>();
    const navigate = useNavigate();

    const {showModal} = useModal();



    const [emailError, setEmailError] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)

    const [errorMessage, setErrorMessage] = useState<string | null>(null);



    useEffect(() => {
        if (user) {
            if ([UserGroup.Admin, UserGroup.Analyst].includes(user.group as UserGroup)) {
                navigate('/')
            } else if ([UserGroup.Entity, UserGroup.NonAttributable].includes(user.group as UserGroup) && user.company) {
                navigate('/company-view')
            } else {
                showModal(UserNotAssignedDialog)
                setLoading(false);
            }
        }
    }, [user]);

    const signIn = async () => {
        setLoading(true);
        setEmailError(null);
        setErrorMessage(null);
        if (emailRef.current !== null && passwordRef !== null) {

            const emailRegex = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");

            if (emailRef.current!.value.includes('@')) {
                try {
                    const result = await auth.signInWithEmailAndPassword(
                        emailRef.current!.value,
                        passwordRef.current!.value
                    );

                } catch (err) {
                    setLoading(false);
                    setErrorMessage("Unable to find your account")
                }
            } else {
                setLoading(false);
                setEmailError("Please enter a valid email.")
            }
        }
    };

    const signInWithGoogle = async () => {
        const googleProvider = new GoogleAuthProvider();
        try {
            const res = await signInWithPopup(auth, googleProvider);
        } catch (err: any) {
            setErrorMessage("Unable to find your account")
        }
    };

    const signInWithWeb3 = () => {
        showModal(Web3AuthDialog, {onClose: (validated: boolean) => validated && navigate('/anonymous-view')})
    }

    const onRequestPasswordReset = () => {
        showModal(ResetPassword, {email: emailRef.current!.value, onClose: (wasReset) => {
            showModal(SimpleSnackbar, {message: 'Email was sent!'})
        }});
    }

    const theme = useTheme();
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', height: '100%'}}>
            <Stack gap={{xs: 1, sm: 1, md: 15}}  alignItems={'center'} direction={{sm: 'column', md: 'row'}}>
                <Stack alignItems={'center'}>
                    <img style={{height: '200px', width: '200px'}} src={'/logos/et-isac_maroon.png'} />
                    <h2 className="login-title">SWARM Login</h2>
                </Stack>
                <Divider orientation="vertical" sx={{borderLeft: 1, height: '30%', display: {xs: 'none', sm: 'none', md: 'flex'}}} />
                <Box className="login-form-content">
                    <div>
                        <h4 className='login-form-labels'>Email</h4>
                        <TextField disabled={loading || userPending} error={emailError !== null} helperText={emailError} required inputRef={emailRef} className="login-form-inputs" color="secondary"
                                   InputProps={{
                                       endAdornment: (
                                           <InputAdornment position="end">
                                               <MailOutlineRoundedIcon/>
                                           </InputAdornment>)
                                   }}>
                        </TextField>
                    </div>
                    <Box>
                        <h4 className='login-form-labels'>Password</h4>
                        <Stack gap={.5}>
                            <TextField disabled={loading || userPending} error={errorMessage !== null} helperText={errorMessage} inputRef={passwordRef}
                                       type="password" required className="login-form-inputs" color="secondary"

                                       InputProps={{
                                           endAdornment: (
                                               <InputAdornment position="end">
                                                   <VpnKeyRoundedIcon/>
                                               </InputAdornment>)
                                       }}>
                            </TextField>

                            <Typography onClick={onRequestPasswordReset} sx={{opacity: '.5', transition: '250ms opacity ease-in-out', ':hover': {opacity: 1, cursor: 'pointer'}}} variant={'caption'}>
                                Forgot Password?
                            </Typography>
                        </Stack>
                    </Box>
                    <Button disabled={loading || userPending} onClick={() => signIn()} variant="outlined" color='secondary'
                            sx={{width: '100%', marginTop: '25px'}}>Login</Button>

                    <Typography sx={{m: theme.spacing(2)}} fontWeight={500}>
                        Or login with
                    </Typography>

                    <Stack gap={2} width={'100%'}>
                        <Button disabled={loading || userPending} onClick={() => signInWithGoogle()} variant="contained" sx={{width: '100%'}}>
                            <GoogleIcon></GoogleIcon>
                            <p style={{margin: '0 0 0 10px '}}>Google</p>
                        </Button>

                        <Button disabled={loading || userPending} color={'secondary'} onClick={() => signInWithWeb3()} variant="contained" sx={{width: '100%'}}>
                            <Wallet></Wallet>
                            <p style={{margin: '0 0 0 10px '}}>Anonymous Auth</p>
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );
}



