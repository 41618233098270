import {ApiService, PaginationRequest} from "../api-service";
import {PaginationResult} from "../globals";
import {
    MissingAttackerModel,
    MissingVictimModel,
    ReportedAttackModel,
    ReportedAttackStats
} from "../ReportedAttacks/reportedAttack.model";
import {cy} from "date-fns/locale";
import {auth} from "../../firebase";
import {ReportedIncidentModel} from "./reportedIncident.model";


export default class ReportIncidentService extends ApiService {

    public async reportIncident(cyberObservableType: string, cyberObservable: object): Promise<void> {
        return super.post<void>(`report-incident/${cyberObservableType}/`, cyberObservable);
    }

    public async getReportedIncidents(pagination: PaginationRequest): Promise<PaginationResult<ReportedIncidentModel>> {
        let query_params: any = {
            offset: pagination.page * pagination.rowsPerPage,
            limit: pagination.rowsPerPage
        };

        return super.get<PaginationResult<ReportedIncidentModel>>(`reported-incidents/`, query_params);
    }
}
