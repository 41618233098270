import React, {useEffect, useState} from "react";
import {
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Paper,
    Stack,
    TextField,
    Typography, useTheme
} from "@mui/material";
import {
    emailValidator,
    FormType,
    handleValidation,
    requiredValidator
} from "../../Common/FormBuilder/NEW/FormValidators";
import {
    FormAutocompleteField,
    FormField,
    FormFields,
    FormRow,
    FormTextField,
    StyledFormFieldLabel
} from "../../Common/FormBuilder/NEW/FormField";
import {User, UserGroup} from "../../../services/User/user.model";
import {CompanyModel} from "../../../services/company/company.model";
import CompanyService from "../../../services/company/company.service";
import {useModal} from "mui-modal-provider";
import {ErrorDialog} from "../../Common/ErrorDialog/ErrorDialog";
import {LoadingButton} from "@mui/lab";
import {Add} from "@mui/icons-material";
import UserService from "../../../services/User/user.service";
import firebase from "firebase/compat";

interface Props {
    onClose: (company: CompanyModel | null) => void;
}



export const AddCompanyDialog = ({onClose}: Props) => {
    const companyNameKey = 'companyName';
    const companyService = new CompanyService();
    const [submitting, setSubmitting] = useState(false);

    const {showModal} = useModal();
    const [open, setOpen] = useState(true)
    const [form, setForm] = useState<FormType>({
        [companyNameKey]: {
            value: '',
            error: null,
            initialized: false,
            validators: [requiredValidator]
        }
    })


    useEffect(() => {
        handleInitialization()
    }, []);


    const handleInitialization = () => {
        const localForm = {...form};
        for (const key of Object.keys(localForm)) {
            localForm[key].error = handleValidation(key, localForm[key].value, localForm);
        }

        setForm(localForm)
    }

    const handleClose = (result: CompanyModel | null = null) => {
        onClose(result);
        setOpen(false);
    };

    const handleInputChange = (key: string, value: string) => {
        const validationError = handleValidation(key, value, form)
        setForm({...form, [key]: {...form[key], value: value, error: validationError, initialized: true}})
    }



    const onSubmit = () => {
        setSubmitting(true);
        companyService.createCompany(form[companyNameKey].value)
            .then((res) => {
                handleClose(res);
            })
            .catch((err) => {
                showModal(ErrorDialog, {error: err});
                setSubmitting(false);
            });
    }

    const theme = useTheme();
    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth>
            <DialogTitle>Add Company</DialogTitle>


            <DialogContent sx={{paddingY: theme.spacing(1)}} dividers>
                <FormFields gap={3}>
                    <FormField>
                        <StyledFormFieldLabel>Company Name</StyledFormFieldLabel>
                        <FormTextField
                            required={true}
                            placeholder={'University of Arkansas at Little Rock'}
                            formKey={companyNameKey}
                            disabled={submitting}
                            handleInputChange={(key, value) => handleInputChange(key, value)}
                            formField={form[companyNameKey]}/>

                    </FormField>
                </FormFields>
            </DialogContent>
            <DialogActions>

                <Stack gap={2} direction={'row'}>
                    <Button
                        onClick={() => handleClose()}
                        sx={{color: theme.palette.text.primary}}
                        size={'small'}>
                        Cancel
                    </Button>
                    <LoadingButton
                        onClick={onSubmit}
                        endIcon={<Add />}
                        loading={submitting}
                        loadingPosition={'end'}
                        variant={'contained'}
                        disabled={Object.values(form).map(v => v.error).filter((err) => err !== null).length > 0}
                        color={'secondary'}
                        size={'small'}>
                        {'Add'}
                    </LoadingButton>
                </Stack>

            </DialogActions>

        </Dialog>
    );

}