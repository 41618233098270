import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {Add, DeleteOutline, LockReset} from "@mui/icons-material";
import {Stack} from "@mui/system";
import {useModal} from "mui-modal-provider";
import {ErrorDetails, ErrorDialog} from "../Common/ErrorDialog/ErrorDialog";
import {emailValidator, FormType, handleValidation, requiredValidator} from "../Common/FormBuilder/NEW/FormValidators";
import {FormField, FormFields, FormTextField, StyledFormFieldLabel} from "../Common/FormBuilder/NEW/FormField";
import UserService from "../../services/User/user.service";

interface Props {
    email: string | null;
    onClose: (wasReset: boolean) => void;
}

const emailKey = 'emailKey';
export const ResetPassword = ({email, onClose}: Props) => {
    const [resetting, setResetting] = useState(false)
    const [open, setOpen] = useState(true)
    const userService = new UserService()
    const {showModal} = useModal();

    const [form, setForm] = useState<FormType>({
        [emailKey]: {
            value: email || '',
            error: null,
            initialized: false,
            validators: [requiredValidator, emailValidator]
        }
    })


    useEffect(() => {
        handleInitialization()
    }, []);


    const handleClose = (wasReset: boolean) => {
        onClose(wasReset)
        setOpen(false);

    }


    const onReset = () => {
        setResetting(true);
        userService.resetPasswordByEmail(form[emailKey].value)
            .then(() => handleClose(true))
            .catch((err: ErrorDetails) => {
                showModal(ErrorDialog, {error: err})
                setResetting(false);
            })

    }

    const handleInitialization = () => {
        const localForm = {...form};
        for (const key of Object.keys(localForm)) {
            localForm[key].error = handleValidation(key, localForm[key].value, localForm);
        }

        setForm(localForm)
    }

    const handleInputChange = (key: string, value: string) => {
        const validationError = handleValidation(key, value, form)
        setForm({...form, [key]: {...form[key], value: value, error: validationError, initialized: true}})
    }


    const theme = useTheme();

    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle>Reset Password?</DialogTitle>
            <DialogContent>
                <FormFields gap={3}>
                    <FormField>
                        <StyledFormFieldLabel>Email</StyledFormFieldLabel>
                        <FormTextField
                            required={true}
                            placeholder={'jon.doe@service.com'}
                            formKey={emailKey}
                            disabled={resetting}
                            handleInputChange={(key, value) => handleInputChange(key, value)}
                            formField={form[emailKey]}/>

                    </FormField>
                </FormFields>


                <DialogContentText sx={{paddingY: theme.spacing(3)}}>
                    If an account is associated with this email, you will receive an email with instructions to reset your password.
                    If you don't receive the email within a few minutes, please check your spam or junk folder.
                </DialogContentText>

            </DialogContent>

            <DialogActions>
                <Stack direction={'row'} gap={2}>
                    <Button onClick={() => handleClose(false)} size={'small'} sx={{color: theme.palette.text.primary}} > Cancel</Button>
                    <LoadingButton
                        onClick={onReset}
                        endIcon={<LockReset />}
                        loading={resetting}
                        disabled={form[emailKey].error !== null}
                        loadingPosition={'end'}
                        variant={'contained'}
                        color={'secondary'}
                        size={'small'}>
                        Send
                    </LoadingButton>
                </Stack>


            </DialogActions>
        </Dialog>
    )

}