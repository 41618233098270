import {Add, DeleteOutline} from "@mui/icons-material";
import {Autocomplete, Box, Checkbox, Divider, IconButton, Stack, TextField, Typography,} from "@mui/material";
import React from "react";
import {ReportAnalysis} from "../../../../../../../services/ThreatAnalysis/threatAnalysis.model";
import {
    AnalysisSharableTable,
    IconButtonColumn,
    StringColumn,
} from "../../../../../../Common/TableHelper/SharableTable";
import {TargetCountryData} from "./TargetEntitesForm";

interface ThisProps {
    analysis: ReportAnalysis;
    countries: TargetCountryData[];

    onUpdate: (key: keyof ReportAnalysis, value: any[]) => void;
    onDelete: (key: keyof ReportAnalysis, value: any) => void;
}

interface ThisState {
    selectedItems: TargetCountryData[]
}

export class TargetCountries extends React.Component<ThisProps, ThisState> {
    private _key: keyof ReportAnalysis = "target_countries";

    constructor(props: ThisProps) {
        super(props);

        this.state = {
            selectedItems: []
        };
    }

    render(): React.ReactNode {
        return (
            <React.Fragment>
                <Stack spacing={2}>
                    <Box sx={{width: "100%", padding: "8px"}}>
                        <Stack
                            sx={{mb: "12px"}}
                            direction="row"
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Typography fontSize={"1.25rem"} fontWeight={600}>
                                Add Country
                            </Typography>
                            <IconButton
                                sx={{
                                    padding: 0,
                                    borderRadius: "50%",
                                    border: (theme) =>
                                        "1px solid " + theme.palette.secondary.main,
                                }}
                                size="small"
                                color="secondary"
                                disabled={this.state.selectedItems.length == 0}
                                onClick={(e) => {
                                    this.props.onUpdate(this._key, this.state.selectedItems.map(v => v.iso_code));
                                    this.setState({selectedItems: []});
                                }}
                            >
                                <Add/>
                            </IconButton>
                        </Stack>
                        <Autocomplete
                            disablePortal
                            disableClearable
                            size={"small"}
                            value={this.state.selectedItems}
                            disableCloseOnSelect
                            multiple
                            onChange={(event, value) =>
                                this.setState({
                                    selectedItems: value as TargetCountryData[],
                                })
                            } //
                            options={this.props.countries.filter(
                                (iso) =>
                                    !this.props.analysis.target_countries.includes(iso.iso_code)
                            )}
                            renderOption={(props, option) => {
                                return (
                                    <Box key={option.iso_code}>
                                        <li {...props}>
                                            <Checkbox
                                                style={{marginRight: 8}}
                                                checked={this.state.selectedItems.includes(option)}
                                            />
                                            {`${option.country} (${option.iso_code})`}
                                        </li>
                                        <Divider/>
                                    </Box>
                                )
                            }}
                            getOptionLabel={(option: {
                                country: string;
                                iso_code: string;
                            }) => {
                                return `${option.country} (${option.iso_code})`;
                            }}
                            fullWidth
                            renderInput={(params) => (
                                <TextField color={"secondary"} {...params} label="Country"/>
                            )}
                        />
                    </Box>
                    <Divider/>
                    <AnalysisSharableTable
                        headers={["ISO 3166-1", "Country", ""]}
                        data={this.props.analysis.target_countries}
                        rows={[
                            new StringColumn(null),
                            new StringColumn(null, (v: string) => {
                                const country_name = this.props.countries.find(
                                    (country) => v == country.iso_code
                                );

                                return country_name ? country_name.country : "INVALID ISO CODE";
                            }),
                            new IconButtonColumn(
                                <DeleteOutline color="error"/>,
                                (row_index) => {
                                    this.props.onDelete(this._key, row_index);
                                },
                                {float: "right"}
                            ),
                        ]}
                    />
                </Stack>
            </React.Fragment>
        );
    }
}
