import axios from "axios";
import {auth} from "../firebase";

export interface PaginationRequest {
    page: number,
    rowsPerPage: number,
    totalRecords: number
}

export const defaultPagination: PaginationRequest = {
    page: 0,
    rowsPerPage: 0,
    totalRecords: 0
}

export abstract class ApiService {
    private api_url: string | undefined = process.env.REACT_APP_ET_ISAC_API_URL;
    private isAnonymous: boolean;

    constructor(isAnonymous: boolean = false) {
        this.isAnonymous = isAnonymous
    }

    protected async get<T>(endpoint: string, params: any | null = null): Promise<T> {
        try {
            return (await axios.get(`${this.api_url}/${endpoint}`, {
                headers: await this.generateHeader(),
                params: params
            })).data;
        } catch (e: any) {
            throw e.response.data;
        }
    }

    protected async delete<T>(endpoint: string): Promise<T> {
        try {
            return (await axios.delete(`${this.api_url}/${endpoint}`, {headers: await this.generateHeader()})).data;
        } catch (e: any) {
            throw e.response.data;
        }
    }

    protected async post<T>(endpoint: string, body: any, headers: object | null = null): Promise<T> {
        try {
            return (await axios.post(`${this.api_url}/${endpoint}`, body, {headers: await this.generateHeader(headers)})).data;

        } catch (e: any) {
            throw e.response.data;
        }
    }

    protected async put<T>(endpoint: string, body: any): Promise<T> {
        try {
            return (await axios.put(`${this.api_url}/${endpoint}`, body, {headers: await this.generateHeader()})).data;
        } catch (e: any) {
            throw e.response.data;
        }
    }


    private generateHeader = async (headerOverrides: object | null = null): Promise<object> => {

        let headers: object = {
            'Authorization': 'Bearer ' + await this.getToken(),
            'Content-Type': 'application/json'
        }


        if (headerOverrides !== null) {
            headers = {...headers, ...headerOverrides}
        }

        return headers
    }

    private async getToken(): Promise<string | undefined> {
        if (this.isAnonymous) {
            return localStorage.getItem('token')!;
        }

       return await auth.currentUser?.getIdToken()
    }
}
