import {Autocomplete, Paper, Stack, TextField, Typography,} from "@mui/material";
import React, {ReactNode} from "react";
import {getOptionLabelForHML, TenPointScaleNumerical} from "../../../../../services/globals";
import {CommonChildProps} from "../CompanyDialog";

interface ThisProps extends CommonChildProps {
}

interface ThisState {
}

export class OperationalOverviewForm extends React.Component<
    ThisProps,
    ThisState
> {
    constructor(props: ThisProps) {
        super(props);

        this.state = {};
    }

    render(): ReactNode {
        return (
            <React.Fragment>
                <Stack gap={2}>
                    <Stack sx={{width: "100%"}}>
                        <Typography sx={this.props.styles.form_field_label}>
                            Strategic Importance <span style={{color: "red"}}>*</span>
                        </Typography>
                        <Typography variant={"body2"} sx={{mb: 1, opacity: ".6"}}>
                            Indicate the initiative's strategic importance to society or the
                            nation: 'High' for major impact, 'Medium' for notable importance,
                            'Low' for minor impact.
                        </Typography>
                        <Paper>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                value={this.props.company.x_strategic_importance}
                                getOptionLabel={(option: TenPointScaleNumerical) => getOptionLabelForHML(option)}
                                onChange={(event, value: TenPointScaleNumerical | null) => {
                                    if (value !== null) {
                                        this.props.onUpdate(
                                            {
                                                ...this.props.company,
                                                x_strategic_importance: value,
                                            },
                                            () => {
                                            }
                                        )
                                    }
                                }}
                                options={Object.values(TenPointScaleNumerical).filter((value): value is TenPointScaleNumerical => typeof value === "number")}
                                renderInput={(params) => (
                                    <TextField  placeholder={'High'} required color={"secondary"} {...params} />
                                )}
                                fullWidth
                            />
                        </Paper>

                    </Stack>

                    <Stack sx={{width: "100%"}}>
                        <Typography sx={this.props.styles.form_field_label}>
                            Public Profile <span style={{color: "red"}}>*</span>
                        </Typography>
                        <Typography variant={"body2"} sx={{mb: 1, opacity: ".6"}}>
                            Indicate the public attention received: 'High' for extensive,
                            'Medium' for moderate, 'Low' for limited
                        </Typography>
                        <Paper>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                value={this.props.company.x_public_profile}
                                getOptionLabel={(option: TenPointScaleNumerical) => getOptionLabelForHML(option)}
                                onChange={(event, value: TenPointScaleNumerical | null) => {
                                    if (value !== null) {
                                        this.props.onUpdate(
                                            {
                                                ...this.props.company,
                                                x_public_profile: value,
                                            },
                                            () => {
                                            }
                                        )
                                    }
                                }}
                                options={Object.values(TenPointScaleNumerical).filter((value): value is TenPointScaleNumerical => typeof value === "number")}
                                renderInput={(params) => (
                                    <TextField placeholder={'High'} required color={"secondary"} {...params} />
                                )}
                                fullWidth
                            />
                        </Paper>
                    </Stack>

                    <Stack sx={{width: "100%"}}>
                        <Typography sx={this.props.styles.form_field_label}>
                            Regulatory Environment <span style={{color: "red"}}>*</span>
                        </Typography>
                        <Typography variant={"body2"} sx={{mb: 1, opacity: ".6"}}>
                            Indicate regulatory frequency: 'High' for often, 'Medium' for
                            occasionally, 'Low' for seldom
                        </Typography>
                        <Paper>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                value={this.props.company.x_regulatory_environment}
                                getOptionLabel={(option: TenPointScaleNumerical) => getOptionLabelForHML(option)}
                                onChange={(event, value: TenPointScaleNumerical | null) => {
                                    if (value !== null) {
                                        this.props.onUpdate(
                                            {
                                                ...this.props.company,
                                                x_regulatory_environment: value,
                                            },
                                            () => {
                                            }
                                        )
                                    }
                                }}
                                options={Object.values(TenPointScaleNumerical).filter((value): value is TenPointScaleNumerical => typeof value === "number")}
                                renderInput={(params) => (
                                    <TextField placeholder={'High'} required color={"secondary"} {...params} />
                                )}
                                fullWidth
                            />
                        </Paper>
                    </Stack>

                    <Stack sx={{width: "100%"}}>
                        <Typography sx={this.props.styles.form_field_label}>
                            Security Posture <span style={{color: "red"}}>*</span>
                        </Typography>
                        <Typography variant={"body2"} sx={{mb: 1, opacity: ".6"}}>
                            Reflect on system security level: 'High' for tight, 'Medium' for
                            average, 'Low' for loose.
                        </Typography>
                        <Paper>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                value={this.props.company.x_security_posture}
                                getOptionLabel={(option: TenPointScaleNumerical) => getOptionLabelForHML(option)}
                                onChange={(event, value: TenPointScaleNumerical | null) => {
                                    if (value !== null) {
                                        this.props.onUpdate(
                                            {
                                                ...this.props.company,
                                                x_security_posture: value,
                                            },
                                            () => {
                                            }
                                        )
                                    }
                                }}
                                options={Object.values(TenPointScaleNumerical).filter((value): value is TenPointScaleNumerical => typeof value === "number")}
                                renderInput={(params) => (
                                    <TextField placeholder={'High'} required color={"secondary"} {...params} />
                                )}
                                fullWidth
                            />
                        </Paper>
                    </Stack>
                </Stack>
            </React.Fragment>
        );
    }
}
