import {Assessment, DeleteOutline, EditOutlined, MoreVert} from "@mui/icons-material";
import {
    Box,
    Button,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Paper,
    Stack,
    Toolbar
} from "@mui/material";
import React, {useContext} from "react";
import CompanyService from "../../../../services/company/company.service";
import withRouter from "../../../Common/Utils/WithRouter/WithRouter";
import {CompanyModel} from "../../../../services/company/company.model";
import {ErrorDetails, ErrorDialog} from "../../../Common/ErrorDialog/ErrorDialog";
import SimpleSnackbar from "../../../Common/SnackBar/SnackBar";
import {ViewCompanyDetails} from "./ViewCompanyDetails";
import {ViewCompanyParameters} from "./ViewCompanyParameters";
import {ViewCompanySideNav} from "./CompanySideNav/ViewCompanySideNav";
import {CompanyDialog} from "../CompanyDialog/CompanyDialog";
import {CompanyParameterXrefModel} from "../../../../services/company/company-parameter.model";
import {CompanyParameterXrefDialog} from "./CompanyParameterXrefDialog";
import CompanyParameterService from "../../../../services/company/company-parameter.service";
import DrawerStateContext, {DrawerStateContextType} from "../../../../context/DrawerContext";
import {NestedAppBar, NestedDrawer} from "../../../Common/Drawer/NestedDrawer";
import {CONTENT_PADDING} from "../../SideNav/SideNav";
import {AppTitle} from "../../../Common/common";


interface ThisState {
    company: { pending: boolean, value: CompanyModel | null },
    companyDialogOpened: boolean;
    errorDialog: { opened: boolean, details: null | ErrorDetails },
    snackbar: { opened: boolean, message: string }
    parameterDialog: { opened: boolean, parameter: null | CompanyParameterXrefModel }

    reportMenu: {
        anchorElm: any;
        open: boolean;
    };
}

interface ThisProps {
    router?: any;
    drawerContext?: DrawerStateContextType
}

export interface CompanyCommonProps {
    company: { pending: boolean, value: CompanyModel | null }
}

class ViewCompanyComponent extends React.Component<ThisProps, ThisState> {

    private companyService: CompanyService = new CompanyService();
    private parameterService: CompanyParameterService = new CompanyParameterService();

    constructor(props: ThisProps) {
        super(props);

        this.state = {
            company: {pending: true, value: null},
            errorDialog: {opened: false, details: null},
            snackbar: {opened: false, message: ''},
            companyDialogOpened: false,
            parameterDialog: {opened: false, parameter: null},
            reportMenu: {
                anchorElm: null,
                open: false,
            },
        }
    }

    componentDidMount(): void {
        this.handleFetchCompany();
    }

    handleCloseReportMenu(): void {
        this.setState({reportMenu: {open: false, anchorElm: null}});
    }

    handleFetchCompany(): void {
        this.companyService.getCompany(this.props.router.params['companyID'])
            .then((company) => this.setState({
                company: {
                    pending: false,
                    value: company
                }
            }))
            .catch((err) => this.setState({
                errorDialog: {opened: true, details: err},
                company: {pending: false, value: null}
            }))

    }


    handleNavigateBack = (): void => {
        this.props.router.navigate(-1);
    }


    handleGenerateReport(): void {
        this.handleCloseReportMenu();
        // todo: BE hasn't enabled this yet
    }

    handleEditCompany(): void {
        this.handleCloseReportMenu();
        this.setState({companyDialogOpened: true});
    }

    handleCompanyDialogClosed(company: CompanyModel | null): void {
        const newState: any = {
            companyDialogOpened: false
        }
        if (company) {

            newState['company'] = {
                ...this.state.company,
                value: company
            };

            newState['snackbar'] = {
                message: 'Updated company!',
                opened: true
            }
        }

        this.setState({...this.state, ...newState})
    }

    handleDeleteCompany(): void {
        this.handleCloseReportMenu();
        this.companyService.deleteCompany(this.state.company.value!.id)
            .then(res => this.handleNavigateBack())
            .catch(err => this.handleError(err));
    }

    handleError(error: ErrorDetails): void {
        this.setState({errorDialog: {opened: true, details: error}});
    }

    handleOnParameterDialogClosed(parameter: CompanyParameterXrefModel | null): void {
        const newState = {
            parameterDialog: {opened: false, parameter: null},
            snackbar: {...this.state.snackbar},
            company: {...this.state.company}
        }

        if (parameter) {
            newState.snackbar.opened = true;

            let parameters = [...this.state.company.value!.parameters!];
            const index = parameters.findIndex(p => p._id == parameter._id);
            if (index == -1) {
                newState.snackbar.message = 'Added parameter!'
                parameters = [parameter, ...parameters]
            } else {
                newState.snackbar.message = 'Updated parameter value!'
                parameters[index] = parameter;
            }

            newState.company.value!.parameters = parameters
        }

        this.setState(newState)
    }

    handleModifyParameter(parameter: CompanyParameterXrefModel | null) {
        this.setState({parameterDialog: {opened: true, parameter: parameter}})
    }

    handleDeleteCompanyParameter(param: CompanyParameterXrefModel) {
        this.parameterService.deleteCompanyParameter(param)
            .then((res) => this.setState({
                company: {
                    pending: false,
                    value: {
                        ...this.state.company.value!,
                        parameters: this.state.company.value?.parameters?.filter(p => p._id !== param._id)!
                    }
                },
                snackbar: {
                    opened: true,
                    message: 'Deleted parameter!'
                }
            }))
            .catch((err) => this.handleError(err));
    }

    render(): React.ReactNode {
        const thisDrawerWidth = `calc(35vw - ${CONTENT_PADDING * 2}rem)`;

        return (
            <Box padding={0}>
                <NestedAppBar nestedDrawerWidth={thisDrawerWidth}
                              nestedDrawerOpen={true}
                              open={this.props.drawerContext?.isDrawerOpen}
                              sx={{mb: "15px"}} position="fixed">
                    <Toolbar sx={{ml: 3}} disableGutters={true}>
                        <Stack alignItems={'center'} width={"100%"} direction={"row"}>
                            <Button color={'secondary'} size={'small'} onClick={this.handleNavigateBack.bind(this)}>
                                back
                            </Button>
                            <AppTitle
                                sx={{
                                    marginLeft: "25px",
                                    color: (theme) => theme.palette.text.primary,
                                }}
                                variant="h5"
                            >
                                {this.state.company.value?.name}
                            </AppTitle>

                            <IconButton
                                sx={{ml: 'auto'}}
                                color={"secondary"}
                                onClick={(e: React.MouseEvent<HTMLElement>) =>
                                    this.setState({
                                        reportMenu: {open: true, anchorElm: e.currentTarget},
                                    })
                                }
                            >
                                <MoreVert/>
                            </IconButton>
                        </Stack>
                    </Toolbar>
                </NestedAppBar>

                <NestedDrawer
                    drawerWidth={thisDrawerWidth}
                    anchor="right"
                    variant="permanent"
                    open={true}
                    sx={{
                        width: thisDrawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: thisDrawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                >
                    <Paper sx={{height: "100%", overflow: "auto"}} elevation={1}>
                        <ViewCompanySideNav company={this.state.company}/>
                    </Paper>
                </NestedDrawer>


                <Box sx={{marginRight: `${thisDrawerWidth}`, mt: (theme) => `${theme.spacing(8)}`}}>

                    <ViewCompanyDetails company={this.state.company}/>
                    <ViewCompanyParameters company={this.state.company}
                                           handleDeleteCompanyParameter={this.handleDeleteCompanyParameter.bind(this)}
                                           handleModifyCompanyParameter={this.handleModifyParameter.bind(this)}/>
                </Box>


                {this.state.errorDialog.opened && (
                    <ErrorDialog
                        error={this.state.errorDialog.details!}
                        onClose={() =>
                            this.setState({errorDialog: {opened: false, details: null}})
                        }
                    />
                )}

                {this.state.companyDialogOpened && (
                    <CompanyDialog
                        company={this.state.company.value}
                        onClose={this.handleCompanyDialogClosed.bind(this)}
                        onError={this.handleError.bind(this)}
                    />
                )}

                {
                    this.state.parameterDialog.opened &&
                    <CompanyParameterXrefDialog company={this.state.company.value!}
                                                parameterXref={this.state.parameterDialog.parameter}
                                                onClose={this.handleOnParameterDialogClosed.bind(this)}
                                                onError={this.handleError.bind(this)}/>
                }

                <SimpleSnackbar
                    onClose={() =>
                        this.setState({snackbar: {message: "", opened: false}})
                    }
                    open={this.state.snackbar.opened}
                    message={this.state.snackbar.message}
                />

                <Menu
                    anchorEl={this.state.reportMenu.anchorElm}
                    open={this.state.reportMenu.open}
                    onClose={this.handleCloseReportMenu.bind(this)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <MenuList>
                        <MenuItem disabled={this.state.company.pending} onClick={this.handleEditCompany.bind(this)}>
                            <ListItemIcon>
                                <EditOutlined color="secondary" fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Edit</ListItemText>
                        </MenuItem>
                        <Divider/>
                        <MenuItem
                            disabled={true}
                            onClick={this.handleGenerateReport.bind(this)}
                        >
                            <ListItemIcon>
                                <Assessment color="secondary" fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Generate Report</ListItemText>
                        </MenuItem>
                        <Divider/>
                        <MenuItem
                            disabled={this.state.company.pending}
                            onClick={this.handleDeleteCompany.bind(this)}
                        >
                            <ListItemIcon>
                                <DeleteOutline color={"error"} fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Box>

        );
    }


}

const ViewCompanyWithRouter = withRouter(ViewCompanyComponent);
export default function ViewCompany(props: ThisProps) {
    const appContext = useContext(DrawerStateContext);
    return <ViewCompanyWithRouter {...props} drawerContext={appContext}/>
};

