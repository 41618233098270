import React, {useState} from "react";
import {Button, Dialog, DialogContent, DialogTitle, Paper, TextField, Typography} from "@mui/material";
import {ReportModel} from "../../../../../../services/ThreatAnalysis/threatAnalysis.model";
import {Stack} from "@mui/system";
import {LoadingButton} from "@mui/lab";
import {Save} from "@mui/icons-material";
import ThreatAnalysisService from "../../../../../../services/ThreatAnalysis/threatAnaylsis.service";
import {useModal} from "mui-modal-provider";
import _ from "lodash";
import {ErrorDialog} from "../../../../../Common/ErrorDialog/ErrorDialog";


interface ThisProps {
    report: ReportModel;
    onClose: (r?: ReportModel | undefined) => void;
}

const UpdateReportContentDialog: React.FC<ThisProps> = (props: ThisProps) => {
    const [content, setContent] = useState(props.report.content)
    const [open, setOpen] = useState(true)
    const [submitting, setSubmitting] = useState(false);
    const {showModal} = useModal();

    const onSubmit = () => {
        const reportService = new ThreatAnalysisService();
        const report = _.cloneDeep(props.report)

        report.content = content;
        reportService.updateReport(report)
            .then(res => {
                onClose(res)
            })
            .catch((error) => {
                showModal(ErrorDialog, {error: error})
            })
            .finally(() =>
                setSubmitting(false)
            )

    }

    const onClose = (result?: ReportModel) => {
        setOpen(false)
        props.onClose(result);
    }

    return (
        <Dialog open={open} fullWidth maxWidth={'lg'}>
            <DialogTitle>
                <Stack direction={'row'} alignItems={'center'}>
                    <Typography variant={'h6'} fontWeight={500}>Modify Report Content</Typography>
                    <Stack direction={'row'} sx={{ml: 'auto'}} spacing={2}>
                        <Button onClick={(e) => onClose()}>cancel</Button>
                        <LoadingButton endIcon={<Save/>}
                                       loadingPosition="end"
                                       loading={submitting} variant={'contained'}
                                       onClick={(e) => onSubmit()}>save </LoadingButton>
                    </Stack>
                </Stack>
            </DialogTitle>


            <DialogContent>
                <Paper elevation={3}>
                    <TextField fullWidth multiline variant={'outlined'} value={content}
                               onChange={(e) => setContent(e.target.value)}/>
                </Paper>
            </DialogContent>
        </Dialog>
    )
}

export default UpdateReportContentDialog;