import {ApiService} from "../api-service";
import {User, UserGroup} from "./user.model";

export default class UserService extends ApiService {
    public async getUsers(): Promise<User[]> {
        return super.get<User[]>("users/");
    }

    public async getUser(userId: string): Promise<User> {
        return super.get<User>(`users/${userId}/`);
    }

    public async createUser(email: string, group: UserGroup, company: string | null): Promise<User> {
        return super.post<User>("users/", {email, group, company});
    }

    public async updateUser(user: User): Promise<User> {
        return super.put<User>(`users/${user.firebase_id}/`, user);
    }

    public async deleteUser(userId: string): Promise<void> {
        return super.delete<void>(`users/${userId}/`);
    }

    public async resetPasswordById(userId: string): Promise<void> {
        return super.get<void>(`users/${userId}/password-reset/`);
    }

    public async resetPasswordByEmail(userEmail: string): Promise<void> {
        return super.post<void>(`password-reset/`, {email: userEmail});
    }

}
