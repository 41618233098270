import {
    AutoGraph,
    ChevronLeft,
    ChevronRight,
    DeleteOutline,
    EditOutlined, Keyboard,
    LockOpen,
    MoreVert,
    RestartAlt,
} from "@mui/icons-material";
import {
    Button,
    Divider,
    Fab,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Paper,
    Stack,
    Theme,
    Toolbar, Typography,
    useTheme,
} from "@mui/material";
import {Box} from "@mui/system";
import React, {ReactNode, useContext, useEffect, useRef, useState} from "react";
import Zoom from '@mui/material/Zoom';
import {
    ProccessingReportStatuses,
    ReportAnalysis,
    ReportModel,
    ReportStatus, ThreatReportHotKeyActions, ThreatReportHotKeys,
} from "../../../../services/ThreatAnalysis/threatAnalysis.model";
import ThreatAnalysisService from "../../../../services/ThreatAnalysis/threatAnaylsis.service";
import {ErrorDetails, ErrorDialog,} from "../../../Common/ErrorDialog/ErrorDialog";
import withRouter from "../../../Common/Utils/WithRouter/WithRouter";
import ViewRawReport from "./ReportSideNav";
import SimpleSnackbar from "../../../Common/SnackBar/SnackBar";

import {CONTENT_PADDING} from "../../SideNav/SideNav";
import ThreatAnalysisDialog, {ThreatAnalysisDialogActions,} from "../ThreatAnalysisDialog";
import DrawerStateContext, {DrawerStateContextType} from "../../../../context/DrawerContext";
import {NestedAppBar, NestedDrawer, NestedDrawerContent} from "../../../Common/Drawer/NestedDrawer";
import {AppTitle} from "../../../Common/common";
import {ConfirmationDialog} from "../../../Common/ConfirmationDialog/ConfirmationDialog";
import UpdateReportContentDialog from "./ReportAnalysis/Dialogs/UpdateReportContentDialog";
import {LoadingButton} from "@mui/lab";
import LockIcon from "@mui/icons-material/Lock";
import {AuthContext} from "../../../../context/AuthContext";
import {User} from "../../../../services/User/user.model";
import {useModal} from "mui-modal-provider";
import ReportLockDialog from "./ReportLockDialog";
import ReportLockStatusChangeDialog from "./ReportAnalysis/ReportLockStatusChangeDialog";
import ViewAnalysisWrapper from "./ViewAnalysisWrapper";
import {ShowFn} from "mui-modal-provider/dist/types";
import HotKeyHelpDialog from "./HotKeyHelpDialog";
import debounce from "lodash.debounce";
import {ThreatActor} from "../../../../services/ThreatActor/threatActor.model";
import ThreatActorService from "../../../../services/ThreatActor/threatActor.service";

interface ViewReportProps {
    router?: any;
    user?: User | null;
    showModal?: ShowFn
    drawerContext?: DrawerStateContextType
}

interface ViewReportState {
    report: {
        pending: boolean;
        value: ReportModel | null
    };
    errorDialog: {
        opened: boolean;
        details: ErrorDetails | null
    };
    snackbar: {
        open: boolean;
        message: string;
    };
    reportMenu: {
        anchorElm: any;
        open: boolean;
    };
    timer_id: NodeJS.Timeout | null;
    threatAnalysisDialog: {
        opened: boolean;
        action: ThreatAnalysisDialogActions | null;
    };
    deleteConfirmationDialog: boolean;
    selectedAnalysis: ReportAnalysis | null;
    approvalDialog: {
        opened: boolean;
        status: ReportStatus | null;
        message: null;
    };
    removingLock: boolean;
    updateReportContentDialog: boolean;
    runningPartialReport: boolean;
    shouldWiggleLockBtn: boolean;
    restoringReport: boolean;
    selectedAnalysisAttributes: {
        label: string,
        attributes: (keyof ReportAnalysis)[]
    }[],
    reportSideNavOpen: boolean;
    adversary: {
        pending: boolean,
        value: ThreatActor | null
    }
}


// todo make this a Function Component
class ReportView extends React.Component<ViewReportProps, ViewReportState> {
    private analysisService: ThreatAnalysisService = new ThreatAnalysisService();
    private threatActorService: ThreatActorService = new ThreatActorService();

    constructor(props: ViewReportProps) {
        super(props);

        this.state = {
            report: {pending: true, value: null},
            adversary: {pending: true, value: null},
            errorDialog: {opened: false, details: null},
            approvalDialog: {opened: false, status: null, message: null},
            snackbar: {open: false, message: ""},
            reportMenu: {
                anchorElm: null,
                open: false,
            },
            timer_id: null,
            threatAnalysisDialog: {
                opened: false,
                action: null,
            },
            shouldWiggleLockBtn: false,
            removingLock: false,
            updateReportContentDialog: false,
            selectedAnalysis: null,
            deleteConfirmationDialog: false,
            selectedAnalysisAttributes: [],
            runningPartialReport: false,
            restoringReport: false,
            reportSideNavOpen: true
        };
    }

    componentDidMount(): void {
        this.handleFetchReport();
    }

    componentWillUnmount(): void {
        this.handleRemoveLock();
        this.clearTimer();
    }

    fetchAdversary(): void {
        const adversaryName = this.state.selectedAnalysis!.threat_actor;
        this.setState({adversary: {...this.state.adversary, pending: true}});
        this.threatActorService.getThreatActorByName(adversaryName)
            .then(res => this.setState({adversary: {value: res, pending: false}}))
            .catch((err) => this.setState({adversary: {value: null, pending: false}, errorDialog: {opened: true, details: err}}))
    }


    clearTimer = () => {
        const {timer_id} = this.state;
        if (timer_id) {
            clearTimeout(timer_id);
            this.setState({timer_id: null});
        }
    };

    showHotKeysDialog = () => {
        this.props.showModal!(HotKeyHelpDialog);
    }


    handleFetchReport(): void {
        this.analysisService
            .getReport(this.props.router.params["analysis_id"])
            .then((res) => {
                this.setState(
                    {
                        report: {pending: false, value: res},
                        selectedAnalysis: res.analysis[0],
                    },
                    () => {
                        const runningStatuses = [
                            ...ProccessingReportStatuses,
                            ReportStatus.SUBMITTED,
                        ];
                        if (
                            runningStatuses.includes(res.status) &&
                            this.state.timer_id == null
                        ) {
                            const timer_id = setInterval(
                                () => this.handleFetchReport(),
                                5000
                            );
                            this.setState({timer_id: timer_id});
                        } else if (
                            !runningStatuses.includes(res.status)
                        ) {
                            this.clearTimer();
                            if (res.analysis?.length && res.analysis[0].threat_actor?.trim() !== '') {
                                this.fetchAdversary();
                            }
                        }
                    }
                );
            })
            .catch((err) =>
                this.setState({errorDialog: {opened: true, details: err}})
            );
    }

    handleNavigateBack = () => {
        this.props.router.navigate(-1);
    };

    handleCopyToClipBoard(event: any, entity: any) {
        event.stopPropagation();
        if (entity instanceof Array || entity instanceof Object)
            entity = JSON.stringify(entity);

        navigator.clipboard.writeText(entity);
        this.setState({
            snackbar: {open: true, message: "Copied to clipboard!"},
        });
    }

    handleCloseReportMenu(): void {
        this.setState({reportMenu: {open: false, anchorElm: null}});
    }

    handleUpdateReport(): void {
        if (this.state.report.value?.locked_by === this.props.user?.firebase_id) {

                this.setState({
                threatAnalysisDialog: {
                    opened: true,
                    action: ThreatAnalysisDialogActions.UPDATE,
                },
            });
            this.handleCloseReportMenu();
        } else {
            this.setShouldWiggleLockBtn();
        }
    }

    handleRerunReport(): void {
        if (this.state.report.value?.locked_by === this.props.user?.firebase_id) {
            this.setState({
                threatAnalysisDialog: {
                    opened: true,
                    action: ThreatAnalysisDialogActions.RERUN,
                },
            });
            this.handleCloseReportMenu();
        } else {
            this.setShouldWiggleLockBtn();
        }
    }



    handleRemoveLock = (exiting: boolean = true) => {
        // exiting meaning leaving report page

        if (!exiting) {
            this.setState({removingLock: true})
        }

        this.analysisService.removeThreatReportLock(this.state.report.value!._id)
            .then(() => {
                if (!exiting) {
                    this.setState({
                        removingLock: false,
                        snackbar: {open: true, message: "Report has been unlocked!"},
                        report: {
                        ...this.state.report,
                            value: {
                            ...this.state.report.value!, locked_by: null, locked_by_display_name: null, locked_on: null
                        }
                    }})
                }
            })
            .catch((err) => {
                if (!exiting)
                    this.setState({
                        removingLock: false,
                        errorDialog: {
                            opened: true,
                            details: err
                        }
                    })
            })
    }


    handleLockReport = (report?: ReportModel) => {
        this.setState({
            snackbar: {open: !report, message: "Report has been locked!"},
            report: {
                ...this.state.report,
                value: {
                    ...this.state.report.value!,
                    locked_by: report ? report.locked_by : this.props.user!.firebase_id,
                    locked_by_display_name: report? report.locked_by_display_name : this.props.user!.email,
                    locked_on: report? report.locked_on : new Date().toISOString()
                }
            }}, () => {
            if (this.state.report.value?.locked_by === this.props.user!.firebase_id && !this.state.report.value?.report_date) {
                this.handleUpdateReport();
            }
        })
    }

    handleRerunPartialReport(): void {
        this.setState({runningPartialReport: true})

        const combinedAttributes = this.state.selectedAnalysisAttributes.reduce((acc, curr) => {
            return acc.concat(curr.attributes);
        }, [] as (keyof ReportAnalysis)[]);

        const request = {
            report_id: this.state.report.value?._id!,
            analysis_id: this.state.selectedAnalysis!._id,
            attributes: combinedAttributes.join(',')
        };


        this.analysisService.runPartialReport(request)
            .then((res) => {
                this.setState({
                    snackbar: {message: "Successfully started report!", open: true},
                    selectedAnalysisAttributes: [],
                    runningPartialReport: false
                }, () => {
                    this.handleFetchReport();
                })
            })
            .catch((err) =>
                this.setState({
                    errorDialog: {details: err, opened: true},
                    runningPartialReport: false
                })
            );


    }


    handleOnAnalsisUpdate(analysis: ReportAnalysis): void {
        let index: number = 0;
        const copy = [...this.state.report.value!.analysis];
        copy.forEach((a, i) => {
            if (a._id === analysis._id) {
                index = i;
            }
        });

        const shouldFetchAdversary = this.state.selectedAnalysis?.threat_actor !== analysis.threat_actor;

        copy[index] = analysis;
        this.setState({
            report: {
                ...this.state.report,
                value: {...this.state.report.value!, analysis: copy},
            },
            selectedAnalysis:
                analysis._id == this.state.selectedAnalysis?._id
                    ? analysis
                    : this.state.selectedAnalysis,
            snackbar: {open: true, message: "Updated Analysis!"},
        }, () => shouldFetchAdversary && this.fetchAdversary());
    }

    afterThreatAnalysisDialogClosed(report: ReportModel | null): void {
        const new_state: ViewReportState = {
            ...this.state,
            threatAnalysisDialog: {opened: false, action: null},
        };

        let shouldUpdate: boolean = false;

        if (report) {
            if (
                this.state.threatAnalysisDialog.action ==
                ThreatAnalysisDialogActions.RERUN
            ) {
                new_state["snackbar"] = {
                    open: true,
                    message: "Restarted threat analysis!",
                };
                new_state["report"] = {
                    pending: false,
                    value: {...this.state.report.value!, report_date: report.report_date},
                };
                shouldUpdate = true;
            } else {
                new_state["snackbar"] = {open: true, message: "Updated the report!"};
                new_state["report"] = {
                    pending: false,
                    value: {...this.state.report.value!, name: report.name, report_date: report.report_date},
                };
            }
        }

        this.setState(new_state, () => {
            if (shouldUpdate) {
                this.handleFetchReport();
            }
        });
    }

    handleSelectAnalysis(analysis: ReportAnalysis): void {
        this.setState({selectedAnalysis: analysis});
    }

    restoreAnalysis(): void {
        this.setState({restoringReport: true})
        // analysis failed during rerun analysis, just reset status.
        this.analysisService.updateReport(this.state.report.value!)
            .then((res) => {
                this.setState({
                    snackbar: {open: true, message: "Restored the report!"},
                    restoringReport: false,
                    report: {
                        value: {...res},
                        pending: false
                    }
                })
            })
            .catch((err) =>
                this.setState({
                    restoringReport: false,
                    errorDialog: {details: err, opened: true},
                })
            );
    }

    toggleSideNav(): void {
        this.setState({reportSideNavOpen: !this.state.reportSideNavOpen});
    }

    handleDeleteReport(): void {
        if (this.state.report.value?.locked_by === this.props.user?.firebase_id) {
            this.setState({deleteConfirmationDialog: true})
        } else {
            this.setShouldWiggleLockBtn();
        }
    }

    setShouldWiggleLockBtn(): void {
        this.setState({shouldWiggleLockBtn: true}, () => {
            setTimeout(() => {
                this.setState({shouldWiggleLockBtn: false})
            }, 1000);
        })
    }

    render(): ReactNode {
        const drawerWidth = `calc(42vw - ${CONTENT_PADDING * 2}rem)`;

        return (
            <Box display={'flex'} flexDirection={'column'} sx={{overflow: 'hidden'}}>
                <NestedAppBar nestedDrawerWidth={drawerWidth}
                              nestedDrawerOpen={this.state.reportSideNavOpen}
                              open={this.props.drawerContext?.isDrawerOpen}
                              position="fixed">
                    <Toolbar sx={{ml: 3}} disableGutters={true}>
                        {this.state.report.value && (
                            <Stack alignItems={'center'} width={"100%"} direction={"row"}>


                                <Button color={'secondary'} size={'small'} onClick={this.handleNavigateBack.bind(this)}>
                                    back
                                </Button>
                                <AppTitle
                                    className={'overflow'}
                                    sx={{
                                        marginLeft: "25px",
                                        color: (theme: Theme) => theme.palette.text.primary,
                                    }}
                                    variant="h5"
                                >
                                    {this.state.report.value?.name}
                                </AppTitle>

                                <Stack
                                    direction="row"
                                    sx={{ml: "auto"}}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    spacing={1}
                                >

                                    {/*<Fade in={this.state.report.value?.status == ReportStatus.FAILED_TO_REANALYZE}>*/}
                                    {/*    <LoadingButton*/}
                                    {/*        onClick={(e) => this.restoreAnalysis()} size={'small'}*/}
                                    {/*        endIcon={<Restore/>}*/}
                                    {/*        loading={this.state.restoringReport}*/}
                                    {/*        loadingPosition="end"*/}
                                    {/*        variant="contained"*/}
                                    {/*        color={'error'}*/}
                                    {/*    >*/}
                                    {/*        <span>Restore Analysis</span>*/}
                                    {/*    </LoadingButton>*/}
                                    {/*</Fade>*/}
                                    {
                                        this.state.selectedAnalysisAttributes.length > 0 &&
                                        <LoadingButton
                                            onClick={(e) => this.handleRerunPartialReport()}
                                            size={'small'}
                                            endIcon={<AutoGraph/>}
                                            loading={this.state.runningPartialReport}
                                            loadingPosition="end"
                                            variant="contained"
                                            color={'secondary'}
                                            disabled={this.state.selectedAnalysisAttributes.length === 0 || true}
                                        >
                                            <span>Rerun selected attributes</span>
                                        </LoadingButton>
                                    }
                                    <IconButton
                                        color={"secondary"}
                                        onClick={(e: React.MouseEvent<HTMLElement>) =>
                                            this.setState({
                                                reportMenu: {open: true, anchorElm: e.currentTarget},
                                            })
                                        }
                                    >
                                        <MoreVert/>
                                    </IconButton>
                                </Stack>
                            </Stack>
                        )}
                    </Toolbar>
                </NestedAppBar>

                <NestedDrawer
                    drawerWidth={drawerWidth}
                    anchor="right"
                    variant="permanent"
                    open={this.state.reportSideNavOpen}
                >
                    <Paper sx={{height: "100%", overflow: "auto"}} elevation={1}>
                        <ViewRawReport
                            analysis={this.state.report}
                            adversary={this.state.adversary}
                            selectedAnalysis={this.state.selectedAnalysis}
                            setShouldWiggle={this.setShouldWiggleLockBtn.bind(this)}
                            lockedByUser={this.props.user?.firebase_id === this.state.report.value?.locked_by}
                            onAnalysisUpdate={this.handleOnAnalsisUpdate.bind(this)}
                            onCopy={this.handleCopyToClipBoard.bind(this)}

                            onUpdateReportContent={() => {
                                this.setState({updateReportContentDialog: true})
                            }}

                            onError={(details: ErrorDetails) => {
                                this.setState({
                                    errorDialog: {opened: true, details: details},
                                });
                            }}
                        />
                    </Paper>

                </NestedDrawer>

                <NestedDrawerContent drawerWidth={`calc(42vw - ${CONTENT_PADDING}rem)`} open={this.state.reportSideNavOpen}>
                    <ViewAnalysisWrapper
                        isMetadataExpanded={this.state.reportSideNavOpen}
                        lockedByUser={this.props.user?.firebase_id === this.state.report.value?.locked_by}
                        report={this.state.report}
                        onError={(details: ErrorDetails) => {
                            this.setState({
                                errorDialog: {opened: true, details: details},
                            });
                        }}


                        selectedAnalysisAttributes={{
                            value: this.state.selectedAnalysisAttributes,
                            setAttributes: (label: string, attributes: (keyof ReportAnalysis)[]) => {
                                const existing = this.state.selectedAnalysisAttributes.findIndex(v => v.label == label);
                                if (existing == -1) {
                                    const existing_attributes = this.state.selectedAnalysisAttributes;
                                    existing_attributes.push({label: label, attributes: attributes})

                                    this.setState({selectedAnalysisAttributes: existing_attributes})
                                } else {
                                    const existing_attributes = [...this.state.selectedAnalysisAttributes]
                                    existing_attributes.splice(existing, 1);
                                    this.setState({selectedAnalysisAttributes: existing_attributes})
                                }
                            }
                        }}
                        onCopy={this.handleCopyToClipBoard.bind(this)}
                        onUpdate={this.handleOnAnalsisUpdate.bind(this)}
                        selectedAnalysis={this.state.selectedAnalysis}
                        onSelectAnalysis={this.handleSelectAnalysis.bind(this)}
                    />
                </NestedDrawerContent>

                {this.state.errorDialog.opened && (
                    <ErrorDialog
                        error={this.state.errorDialog.details!}
                        onClose={() =>
                            this.setState({errorDialog: {opened: false, details: null}})
                        }
                    />
                )}

                {
                    this.state.updateReportContentDialog &&
                    <UpdateReportContentDialog report={this.state.report.value!} onClose={(report) => {
                        if (report) {
                            this.setState({
                                updateReportContentDialog: false,
                                report: {...this.state.report, value: report!},
                                snackbar: {open: true, message: "Updated Report Content"}
                            })
                        } else {
                            this.setState({updateReportContentDialog: false})
                        }
                    }}/>
                }

                {this.state.threatAnalysisDialog.opened && (
                    <ThreatAnalysisDialog
                        open={this.state.threatAnalysisDialog.opened}
                        report={this.state.report.value}
                        action={this.state.threatAnalysisDialog.action!}
                        onClose={(report: ReportModel | null) =>
                            this.afterThreatAnalysisDialogClosed(report)
                        }
                    />
                )}
                {
                    this.state.deleteConfirmationDialog && (
                        <ConfirmationDialog onClose={(confirmed) => {
                            const newState = {deleteConfirmationDialog: false}
                            if (confirmed) {
                                this.analysisService
                                    .deleteAnalysis(this.state.report.value?._id!)
                                    .then(() => {
                                        this.setState(
                                            {snackbar: {open: true, message: "Deleted report!"}, ...newState},
                                            () => {
                                                this.handleNavigateBack();
                                            }
                                        );
                                    })
                                    .catch((err) =>
                                        this.setState({errorDialog: {opened: true, details: err}, ...newState})
                                    );

                            } else {
                                this.setState(newState)
                            }

                            this.handleCloseReportMenu();
                        }}/>
                    )
                }

                <SimpleSnackbar
                    onClose={() =>
                        this.setState({snackbar: {message: "", open: false}})
                    }
                    open={this.state.snackbar.open}
                    message={this.state.snackbar.message}
                />

                <Menu
                    sx={{ width: 320, maxWidth: '100%' }}
                    anchorEl={this.state.reportMenu.anchorElm}
                    open={this.state.reportMenu.open}
                    onClose={this.handleCloseReportMenu.bind(this)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <MenuList>
                        <MenuItem onClick={() => {
                            this.toggleSideNav();
                            this.handleCloseReportMenu();
                        }}>
                            <ListItemIcon>
                                {this.state.reportSideNavOpen ? <ChevronRight /> : <ChevronLeft  />}
                            </ListItemIcon>
                            <ListItemText>{this.state.reportSideNavOpen ? 'Hide Metadata' : 'Show Metadata'}</ListItemText>
                        </MenuItem>
                        <Divider />

                        <MenuItem disabled={this.state.report.value?.locked_by !== this.props.user?.firebase_id} onClick={() => this.showHotKeysDialog()}>
                            <ListItemIcon>
                                <Keyboard fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Hot Keys</ListItemText>
                        </MenuItem>

                        <Divider/>
                        <MenuItem disabled={this.state.report.value?.locked_by !== this.props.user?.firebase_id} onClick={this.handleUpdateReport.bind(this)}>
                            <ListItemIcon>
                                <EditOutlined fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Rename Report</ListItemText>
                        </MenuItem>
                        <Divider/>
                        <MenuItem
                            disabled={this.state.report.value?.locked_by !== this.props.user?.firebase_id || [
                                ...ProccessingReportStatuses,
                                ReportStatus.SUBMITTED,
                            ].includes(this.state.report.value?.status!)}
                            onClick={this.handleRerunReport.bind(this)}
                        >
                            <ListItemIcon>
                                <RestartAlt fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Rerun Report</ListItemText>
                        </MenuItem>
                        <Divider/>
                        <MenuItem
                            disabled={this.state.report.value?.locked_by !== this.props.user?.firebase_id || [
                                ...ProccessingReportStatuses,
                                ReportStatus.SUBMITTED,
                            ].includes(this.state.report.value?.status!)}
                            onClick={() => this.handleDeleteReport()}
                        >
                            <ListItemIcon>
                                <DeleteOutline color={"error"} fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Menu>

                {
                    this.state.report.value &&
                    <LockReportBtn
                        removingLock={this.state.removingLock}
                        shouldWiggleBtn={this.state.shouldWiggleLockBtn}
                        onShowHotKeyDialog={() => this.showHotKeysDialog()}
                        onRemoveLock={() => this.handleRemoveLock(false)}
                        onLock={(report) => this.handleLockReport(report)}
                        onNavigateBack={() => this.handleNavigateBack()}
                        onEditReport={() => this.handleUpdateReport()}
                        onToggleSideNav={() => this.toggleSideNav()}
                        onRerunReport={() => this.handleRerunReport()}
                        onDeleteReport={() => this.handleDeleteReport()}
                        report={this.state.report.value!}/>
                }

            </Box>
        );
    }
}


const ReportViewWithRouter = withRouter(ReportView);
export default function ViewReport(props: ViewReportProps) {
    const appContext = useContext(DrawerStateContext);
    const {showModal} = useModal();
    const {user} = useContext(AuthContext)
    return <ReportViewWithRouter {...props} drawerContext={appContext} user={user} showModal={showModal}/>
};



interface LockReportBtnProps{
    report: ReportModel,

    removingLock: boolean;
    shouldWiggleBtn: boolean;

    onLock: (report?: ReportModel) => void;
    onShowHotKeyDialog: () => void;
    onRemoveLock: () => void;
    onNavigateBack: () => void;
    onToggleSideNav: () => void;
    onEditReport: () => void;
    onRerunReport: () => void;
    onDeleteReport: () => void;
}

const LockReportBtn = ({report, onRemoveLock, onLock, onNavigateBack, removingLock, onShowHotKeyDialog, onEditReport, onToggleSideNav, onRerunReport, onDeleteReport, shouldWiggleBtn}: LockReportBtnProps) => {
    const analysisService = new ThreatAnalysisService();
    const theme = useTheme();
    const {user} = useContext(AuthContext)
    const {showModal, state} = useModal();

    const lockedByUser = report.locked_by !== null && report.locked_by === user?.firebase_id
    const lockedByOtherUser = report.locked_by !== null && report.locked_by !== user?.firebase_id
    const notLocked = report.locked_by == null;
    const [timerId, setTimerId] = useState<any | null>(null)

    const [cooldownTimer, setCooldownTimer] = useState<any | null>(null);

    const hotkeyRef = useRef<Function>(() => {});
    const cooldownRef = useRef(false);

    const handleShowReportLockDialog = () => {
        showModal(ReportLockDialog, {report: report, onClose: (locked) => {
            locked && onLock();
        }})
    }

    // Update the current logic in handleHotKeysRef based on dependencies
    useEffect(() => {
        hotkeyRef.current = (event: KeyboardEvent) => {
            const hotKeyEnabled = !cooldownRef.current && !event.repeat;
            if (ThreatReportHotKeys[ThreatReportHotKeyActions.ToggleReportLock].keySequence(event)) {
                event.preventDefault();
                hotKeyEnabled && onClick();

                cooldownRef.current = true;
            }

            else if (ThreatReportHotKeys[ThreatReportHotKeyActions.GoToReports].keySequence(event)) {
                event.preventDefault();
                hotKeyEnabled && onNavigateBack();

                cooldownRef.current = true;
            }

            else if (ThreatReportHotKeys[ThreatReportHotKeyActions.ShowHelpDialog].keySequence(event)) {
                event.preventDefault();
                hotKeyEnabled && onShowHotKeyDialog();

                cooldownRef.current = true;
            }

            else if (ThreatReportHotKeys[ThreatReportHotKeyActions.EditReport].keySequence(event)) {
                event.preventDefault();
                hotKeyEnabled && onEditReport();

                cooldownRef.current = true;
            }

            else if (ThreatReportHotKeys[ThreatReportHotKeyActions.ToggleMetadata].keySequence(event)) {
                event.preventDefault();
                hotKeyEnabled && onToggleSideNav();

                cooldownRef.current = true;
            }

            else if (ThreatReportHotKeys[ThreatReportHotKeyActions.RerunReport].keySequence(event)) {
                event.preventDefault();
                hotKeyEnabled && onRerunReport();

                cooldownRef.current = true;
            }

            else if (ThreatReportHotKeys[ThreatReportHotKeyActions.DeleteReport].keySequence(event)) {
                event.preventDefault();
                hotKeyEnabled && onDeleteReport();

                cooldownRef.current = true;
            }


            if (cooldownTimer || cooldownRef.current) {
                cooldownTimer && clearTimeout(cooldownTimer);
                const timeout = setTimeout(() => {
                    cooldownRef.current = false;
                    setCooldownTimer(null);
                }, 250);

                setCooldownTimer(timeout);
            }

        }}, [lockedByUser, lockedByOtherUser, notLocked, cooldownRef?.current, cooldownTimer]);


    useEffect(() => {

        // Approval Status is in the ReportSideNav View
        const handleKeyDown = (event: KeyboardEvent) => {
            hotkeyRef.current(event);
        };

        document.addEventListener('keydown', handleKeyDown, true);
        // hotkeyRef.current
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);



    useEffect(() => {
        if (![...ProccessingReportStatuses, ReportStatus.SUBMITTED].includes(report.status) && timerId === null) {

            const newTimerId = setInterval(
                () => handlePollReportLockStatus(),
                10000
            );

            setTimerId(newTimerId)
        }


        // Cleanup function to clear the interval
        return () => {
            if (timerId !== null) {
                clearInterval(timerId);
                setTimerId(null); // Ensure the timer ID is reset
            }
        };

    }, [report.status, timerId, report.locked_by]);

    const handlePollReportLockStatus = (): void => {
        analysisService.pollReportLock(report._id)
            .then(res => {
                if (report.locked_by !== res.locked_by && res.locked_by !== user?.firebase_id && res.locked_by !== null) {
                    showModal(ReportLockStatusChangeDialog, {userEmail: res.locked_by_display_name!})
                    onLock(res)
                }
            })
            .catch(err => {
                // I don't think it's necessary to display if this fails
                console.error(err);
            })
    }

    const onClick = () => {
        if (lockedByUser) {
            onRemoveLock()
        } else {
            handleShowReportLockDialog()
        }
    }


    return (
        <Zoom in={true} unmountOnExit>
            <Fab disabled={removingLock} onClick={onClick}
                 sx={{
                     position: 'absolute',
                     bottom: 16,
                     right: 16,
                     zIndex: theme.zIndex.drawer + 1,
                     animation: shouldWiggleBtn ? 'wiggle 1s infinite' : 'none', // Apply wiggle animation if lockedByOtherUser is true
                     bgcolor: (lockedByOtherUser || shouldWiggleBtn) ? 'error.main' : lockedByUser ? 'secondary.main' : 'primary.main', // Turn background color red if lockedByOtherUser is true
                 }}
                 color={lockedByOtherUser ? 'error' : lockedByUser ? 'secondary' : 'primary'}
                 variant="extended">

                {
                    lockedByUser &&
                    <>
                        <LockOpen  sx={{ mr: 1 }} />
                        Remove Lock
                    </>
                }

                {
                    lockedByOtherUser &&
                    <>
                        <LockIcon sx={{ mr: 1 }} />
                        Locked
                    </>
                }

                {
                    notLocked &&
                    <>
                        <LockIcon sx={{ mr: 1 }} />
                        Lock & Edit Report
                    </>
                }

            </Fab>
        </Zoom>
    )
}