import {Box, Container, Tab, Tabs,} from "@mui/material";
import {data} from "iso-3166-2";
import _ from "lodash";
import React from "react";
import {StixSector} from "../../../../../../../services/stixx/stixx.model";
import ReportingService, {INTERFACE_TYPES,} from "../../../../../../../services/stixx/stix-service";
import {ISICClassifier, ReportAnalysis,} from "../../../../../../../services/ThreatAnalysis/threatAnalysis.model";
import {ErrorDetails,} from "../../../../../../Common/ErrorDialog/ErrorDialog";
import {TargetCountries} from "./TargetCountries";
import {TargetRegions} from "./TargetRegions";
import {TargetSectors} from "./TargetSectors";
import {getData as SIC_DATA} from "sic-codes";
import {SharedAnalysisFormProps} from "../AnalysisFormWrapperDialog";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{width: "100%", m: 0, marginTop: "20px"}}>{children}</Box>
            )}
        </div>
    );
}

interface TargetEntitiesFormProps extends SharedAnalysisFormProps {
    onUpdate: (analysis: ReportAnalysis) => void;
    onError: (error: ErrorDetails) => void;
    doesEntryExistInText: (text: string) => boolean;
}

interface TargetEntitiesFormState {
    tabIndex: number;
    populations: TargetSIC[];
    regions: TargetRegionData[];
    countries: TargetCountryData[];
    sectors: { pending: boolean; value: StixSector[] };
}

export interface TargetSIC {
    code: string;
    description: string;
    industry: string;
}

export interface TargetCountryData {
    country: string;
    iso_code: string;
}

export interface TargetRegionData {
    country: string;
    country_iso_code: string;
    region: string;
    region_iso_code: string;
}

export class TargetEntitiesForm extends React.Component<
    TargetEntitiesFormProps,
    TargetEntitiesFormState
> {
    private stixService: ReportingService = new ReportingService();

    constructor(props: TargetEntitiesFormProps) {
        super(props);
        this.state = {
            tabIndex: 0,
            countries: [],
            regions: [],
            populations: [],
            sectors: {pending: true, value: []},
        };
    }

    componentDidMount(): void {
        this.handleGenerateSICCodes();
        this.generateCountries();
        this.handleFetchIndustrialSectors();
    }

    handleGenerateSICCodes(): void {
        const values: TargetSIC[] = [];
        const classifier = new ISICClassifier();
        for (let code_description of SIC_DATA()) {
            const code = code_description[0];
            const description = code_description[1];
            // const code = code_description[0];


            values.push({
                code: code,
                description: description,
                industry: classifier.getClassification(code.slice(0, 2))!,
            });
        }

        this.setState({populations: values});
    }

    handleFetchIndustrialSectors(): void {
        this.stixService
            .getStixInterfaces<StixSector[]>(INTERFACE_TYPES.sectors)
            .then((res) => this.setState({sectors: {value: res, pending: false}}))
            .catch((err) => {
                this.setState({sectors: {value: [], pending: false}});
                this.props.onError(err);
            });
    }

    handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        this.setState({tabIndex: newValue});
    };

    generateCountries(): void {
        const countries = [];
        const regions = [];
        for (let country_iso_code of Object.keys(data)) {
            countries.push({
                country: data[country_iso_code].name,
                iso_code: country_iso_code,
            });
            for (let sub_iso_code of Object.keys(data[country_iso_code]["sub"])) {
                regions.push({
                    country: data[country_iso_code].name,
                    country_iso_code: country_iso_code,
                    region: data[country_iso_code].sub[sub_iso_code].name,
                    region_iso_code: sub_iso_code.split("-")[1],
                });
            }
        }

        this.setState({countries: countries, regions: regions});
    }

    onAddEntry(key: keyof ReportAnalysis, value: any[]): void {
        const copy = _.cloneDeep(this.props.analysis);
        let current_value = copy[key];
        if (Array.isArray(current_value)) {
            current_value = [...value, ...current_value];
        } else {
            current_value = value;
        }

        this.props.onUpdate({...copy, [key]: current_value});
    }

    onDeleteEntry(key: keyof ReportAnalysis, index: number): void {

        const copy = _.cloneDeep(this.props.analysis);
        let current_value = copy[key];

        if (Array.isArray(current_value)) {
            current_value.splice(index, 1);
            this.props.onUpdate({...copy, [key]: current_value});
        }
    }

    render(): React.ReactNode {
        return (

                <Box>
                    {
                        this.props.fieldKey === 'target_countries' &&
                        <TargetCountries
                            analysis={this.props.analysis}
                            countries={this.state.countries}
                            onDelete={(key, value) => this.onDeleteEntry(key, value)}
                            onUpdate={this.onAddEntry.bind(this)}
                        />
                    }

                    {
                        this.props.fieldKey === 'target_regions' &&
                        <TargetRegions
                            analysis={this.props.analysis}
                            regions={this.state.regions}
                            onDelete={(key, value) => this.onDeleteEntry(key, value)}
                            onUpdate={this.onAddEntry.bind(this)}
                        />
                    }

                    {
                        this.props.fieldKey === 'target_industry_sectors' &&
                        <TargetSectors
                            analysis={this.props.analysis}
                            sectors={this.state.sectors}
                            onDelete={(key, value) => this.onDeleteEntry(key, value)}
                            onUpdate={this.onAddEntry.bind(this)}
                            doesEntryExistInText={this.props.doesEntryExistInText}
                        />
                    }


                </Box>
        );
    }
}
