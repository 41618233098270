import React from "react";
import {CompanyCommonProps} from "./ViewCompany";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider,
    Paper,
    Tab,
    Tabs,
    Theme,
    Typography
} from "@mui/material";
import {
    AnalyticsOutlined,
    CorporateFareOutlined,
    DataObjectOutlined,
    InfoOutlined,
    MapsHomeWorkOutlined,
    SouthAmericaOutlined
} from "@mui/icons-material";
import {ListView, ViewListStyles} from "../../../Common/ListView/ListView";
import {getData} from 'sic-codes';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {getOptionLabelForHML} from "../../../../services/globals";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{width: "100%", m: 0, marginTop: "20px"}}>{children}</Box>
            )}
        </div>
    );
}

interface ThisProps extends CompanyCommonProps {

}

interface ThisState {
    tabIndex: number;
    detailsExpanded: boolean;
}

export class ViewCompanyDetails extends React.Component<ThisProps, ThisState> {
    private isicCodeLookup: { [key: string]: string } = {};
    private listSubheaderStyle = {
        fontSize: "1.175rem",
        fontWeight: "600",
        color: (theme: any) => theme.palette.text.primary,
        display: "flex",
        alignItems: "center",
    };

    private listItemLabelStyle = {
        width: '35%',
        fontWeight: '600 !important',
        alignSelf: 'flex-start !important',
    };

    private listItemStyle = {
        opacity: '.8'
    };

    private listBulletPointStyle = {
        padding: "4px",
        display: "list-item",
        color: (theme: Theme) => theme.palette.primary.main,
    };

    private nestedListStyle = {
        padding: 1,
        // maxHeight: "175px",
        maxHeight: '100%',
        overflow: "hidden",
        overflowY: "auto",
    };

    private nestedListValueStyle = {
        width: '75%'
    }

    private styles: ViewListStyles = {
        // listSubheaderStyle: this.listSubheaderStyle,
        listItemLabelStyle: this.listItemLabelStyle,
        listItemStyle: this.listItemStyle,
        listBulletPointStyle: this.listBulletPointStyle,
        nestedListStyle: this.nestedListStyle,
        nestedListValueStyle: this.nestedListValueStyle
    };

    private accordianStyle = {
        borderTop: (theme: Theme) => '3px solid ' + theme.palette.primary.main
    }


    constructor(props: ThisProps) {
        super(props);

        this.state = {
            detailsExpanded: true,
            tabIndex: 0,
        }


        for (let code__name of getData()) {
            this.isicCodeLookup[code__name[0]] = code__name[1]
        }
    }

    handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        this.setState({tabIndex: newValue});
    };


    render() {
        const tabs = [
            {
                label: 'General Info',
                viewListValuesOnly: false,
                icon: <InfoOutlined color={'info'}/>,
                values: [
                    // {label: 'ID', key: 'id', value: this.props.company.value?.id},
                    {
                        label: 'Country of Origin',
                        key: 'x_country_of_origin',
                        value: this.props.company.value?.x_country_of_origin?.toUpperCase()
                    },
                    {
                        label: 'Contact Information',
                        key: 'contact_information',
                        value: this.props.company.value?.contact_information
                    },
                    {
                        label: 'Company Type',
                        key: 'x_organization_type',
                        value: this.props.company.value?.x_organization_type
                    },
                    {
                        label: 'Employee Count',
                        key: 'x_employee_count',
                        value: this.props.company.value?.x_employee_count || '0'
                    },
                    {
                        label: 'Annual Revenue',
                        key: 'x_annual_revenue',
                        value: `$${this.props.company.value?.x_annual_revenue}`
                    },
                    {
                        label: 'Asset Value',
                        key: 'x_asset_value',
                        value: `${getOptionLabelForHML(this.props.company.value?.x_asset_value)}`
                    },
                    {
                        label: 'Notes',
                        key: 'description',
                        value: this.props.company.value?.description
                    },
                ]
            },
            {
                label: "Industry Sectors",
                icon: <MapsHomeWorkOutlined color={'info'}/>,
                viewListValuesOnly: true,
                values: [
                    {
                        label: '',
                        key: 'sectors',
                        value: this.props.company.value?.sectors.map(s => s.name),
                        expandable: true,
                        previewText: `${this.props.company.value?.sectors?.length} Industry Sectors`,
                        disableCollapse: true
                    },
                ]
            },
            {
                label: "Regions",
                icon: <SouthAmericaOutlined color={'info'}/>,
                viewListValuesOnly: true,
                values: [
                    {
                        label: 'Region(s)',
                        key: 'x_regions',
                        value: this.props.company.value?.x_regions,
                        expandable: true,
                        disableCollapse: true
                    },
                ]
            },
            // {
            //     label: 'Populations',
            //     viewListValuesOnly: true,
            //     icon: <CorporateFareOutlined color={'info'}/>,
            //     values: [
            //         {
            //             label: 'Populations(s)',
            //             key: 'x_populations',
            //             value: this.props.company.value?.x_populations?.map(v => `${this.isicCodeLookup[v]} (${v})`),
            //             expandable: true,
            //             disableCollapse: true
            //         },
            //     ]
            // },
            {
                label: 'Data Collection',
                viewListValuesOnly: true,
                icon: <DataObjectOutlined color={'info'}/>,
                values: [
                    {
                        label: 'Populations(s)',
                        key: 'x_information_resources',
                        value: this.props.company.value?.x_information_resources,
                        expandable: true,
                        disableCollapse: true
                    },
                ]
            },
            {
                label: 'Impact Assessment',
                viewListValuesOnly: false,
                icon: <AnalyticsOutlined color={'info'}/>,
                values: [
                    {
                        label: 'Strategic Importance',
                        key: 'x_strategic_importance',
                        value: getOptionLabelForHML(this.props.company.value?.x_strategic_importance)
                    },
                    {
                        label: 'Public Profile',
                        key: 'x_public_profile',
                        value: getOptionLabelForHML(this.props.company.value?.x_public_profile)
                    },
                    {
                        label: 'Regulatory Environment',
                        key: 'x_regulatory_environment',
                        value: getOptionLabelForHML(this.props.company.value?.x_regulatory_environment)
                    },
                    {
                        label: 'Security Posture',
                        key: 'x_security_posture',
                        value: getOptionLabelForHML(this.props.company.value?.x_security_posture)
                    }

                ]
            },
        ]

        return (
            <Accordion expanded={this.state.detailsExpanded} elevation={3}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon color={'secondary'}/>}
                    onClick={() =>
                        this.setState({detailsExpanded: !this.state.detailsExpanded})
                    }
                >
                    <Typography sx={{fontSize: '1.125rem', fontWeight: '600', mr: 1}}>
                        Details
                    </Typography>
                    <InfoOutlined color={'info'}/>
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <Tabs value={this.state.tabIndex} onChange={this.handleTabChange}>
                            {
                                tabs.map((tab, index) => <Tab key={tab.label}
                                                              label={tab.label} {...a11yProps(index)} />)
                            }
                        </Tabs>

                        <Divider/>
                        {
                            !this.props.company.pending && (
                                <Paper elevation={3}>
                                    <Box sx={{height: '400px', overflow: 'hidden', overflowY: 'auto'}}>
                                        {
                                            tabs.map((tab, index) =>
                                                <TabPanel key={index} index={index} value={this.state.tabIndex}>
                                                    <ListView entries={tab.values}
                                                              viewListValuesOnly={tab.viewListValuesOnly}
                                                              styles={this.styles}/>
                                                </TabPanel>
                                            )
                                        }
                                    </Box>
                                </Paper>
                            )
                        }
                    </Box>
                </AccordionDetails>
            </Accordion>
        );
    }
}