import React, {useContext} from 'react';
import './App.css';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Backdrop, CircularProgress, PaletteMode} from '@mui/material';
import {grey} from '@mui/material/colors';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import {Login} from './components/Login/Login';
import ProtectedRoute from './components/ProtectedRoute';
import {AuthContext} from './context/AuthContext';
import {SideNavView} from "./components/internal-ui/SideNav/SideNav";
import {DrawerStateProvider} from "./provider/DrawerProvider";
import PublicContentLandingPage from "./components/public-ui/PublicContentLandingPage";
import PublicHome from "./components/public-ui/PublicHome";
import PublicSettings from "./components/public-ui/PublicSettings";
import PublicHelp from "./components/public-ui/PublicHelp";
import ModalProvider from "mui-modal-provider";
import {PublicAuthProvider} from "./provider/PublicAuthProvider";
import {auth} from "./firebase";
import AnonymousAuthContext from "./context/AnonymousAuthContext";
import {AnonymousAuthProvider} from "./provider/AnonymousAuthProvider";
import {AnonymousLandingPage} from "./components/anonymous-ui/AnonymousLandingPage";
import AnonProtectedRoute from "./AnonProtectedRoute";


const ColorModeContext = React.createContext({
    toggleColorMode: () => {
    }
});


// look at using @preact/signals -> https://preactjs.com/guide/v10/signals/
// will get rid of deeply nested states
// https://www.youtube.com/watch?v=SO8lBVWF2Y8

export default function ToggleColorMode() {
    // const systemPreference = useMediaQuery('(prefers-color-scheme: dark)');
    // const prefersDarkMode = localStorage.getItem('darkMode') ? localStorage.getItem('darkMode') : systemPreference;
    //

    const prefersDarkMode = 'dark'
    const [mode, setMode] = React.useState<'light' | 'dark'>(prefersDarkMode === 'dark' ? 'dark' : 'light');


    const getDesignTokens = (mode: PaletteMode) => ({
        typography: {
            fontFamily: [
                'Montserrat',
                'sans-serif',
            ].join(','),
        },
        palette: {
            mode,
            ...(mode === 'light'
                ? {
                    // palette values for light mode
                    primary: {
                        main: '#007BFF', // Bright Red
                    },
                    secondary: {
                        main: '#00FFFF', // Bright Blue
                    },
                    info: {
                        main: '#33E0FF', // Bright Cyan for informational content
                    },
                    warning: {
                        main: '#FFAB00', // Vivid Amber for cautionary messages
                    },
                    error: {
                        main: '#D50000', // Strong Red for errors and critical messages, slightly darker than primary for distinction
                    },

                    background: {
                        default: '#FFFFFF',
                        // paper:  '#a4a4a5'
                        paper: '#e6e6e6'
                    },
                    text: {
                        primary: '#000',
                        secondary: grey[800],
                    }
                }
                : {
                    // palette values for dark mode
                    primary: {
                        // main: '#49796B', // Bright Cyan for informational content

                        main: '#FFD700', // Bright Red

                    },
                    secondary: {
                        main: '#0F52BA', // Bright Cyan for informational content
                    },
                    info: {
                        main: '#1AC7E6', // Bright Blue

                    },
                    warning: {
                        main: '#FFBF00', // Vivid Amber for cautionary messages
                    },
                    error: {
                        main: '#D50000', // Strong Red for errors and critical messages, slightly darker than primary for distinction
                    },
                    success: {
                        main: '#269A26'
                    },


                    background: {
                        default: '#000000',
                        // paper: "#2c2c2f"
                        paper: "#030304"
                    },
                    text: {
                        primary: '#fff',
                        secondary: grey[500],
                    },
                }),
        },
    });

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => {
                    const mode = prevMode === 'light' ? 'dark' : 'light'
                    localStorage.setItem('darkMode', mode)
                    return (mode);
                });
            },
        }),
        [],
    );

    const anonContext = useContext(AnonymousAuthContext)
    const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
    const user = useContext(AuthContext);
    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <ModalProvider>
                    <main style={{height: '100%'}}>

                        <CssBaseline/>
                        <Router>
                            <Routes>
                                <Route path="/company-view"
                                       element={
                                           <ProtectedRoute>
                                               {
                                                   auth.currentUser ?
                                                       // we have a user, proceed
                                                       <PublicAuthProvider>
                                                           <PublicContentLandingPage/>
                                                       </PublicAuthProvider>

                                                       :
                                                       // user not loaded
                                                       <Backdrop
                                                           sx={{
                                                               color: '#fff',
                                                               zIndex: (theme) => theme.zIndex.drawer + 1
                                                           }}
                                                           open={true}>
                                                           <CircularProgress color="primary"/>
                                                       </Backdrop>
                                               }


                                           </ProtectedRoute>
                                       }>
                                </Route>
                                <Route path="/anonymous-view"
                                       element={
                                           <AnonProtectedRoute>
                                               {/*{*/}

                                               {/*    !anonContext.token.pending ?*/}
                                               {/*        <AnonymousLandingPage />*/}
                                               {/*        :*/}
                                               {/*        <Backdrop*/}
                                               {/*            sx={{*/}
                                               {/*                color: '#fff',*/}
                                               {/*                zIndex: (theme) => theme.zIndex.drawer + 1*/}
                                               {/*            }}*/}
                                               {/*            open={true}>*/}
                                               {/*            <CircularProgress color="primary"/>*/}
                                               {/*        </Backdrop>*/}
                                               {/*}*/}

                                               <AnonymousLandingPage />


                                           </AnonProtectedRoute>
                                       }>
                                </Route>
                                <Route path='*' element={
                                    <ProtectedRoute>
                                        {
                                            auth.currentUser ?
                                                // we have a user, proceed
                                                <DrawerStateProvider>
                                                    <SideNavView loading={true}
                                                                 theme={theme}/>
                                                </DrawerStateProvider>

                                                :
                                                // user not loaded
                                                <Backdrop
                                                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                                    open={true}>
                                                    <CircularProgress color="primary"/>
                                                </Backdrop>
                                        }


                                    </ProtectedRoute>
                                }/>

                                <Route path='/login' element={<Login/>}/>
                            </Routes>
                        </Router>
                    </main>
                </ModalProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
