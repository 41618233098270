import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Typography,
    useTheme
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Stack} from "@mui/system";
import {ThreatActor} from "../../../services/ThreatActor/threatActor.model";
import {PaginatedList, PaginatedListRow, PaginatedListText} from "../../Common/PaginatedList";
import {Clear, Forward} from "@mui/icons-material";
import ThreatActorService from "../../../services/ThreatActor/threatActor.service";
import {defaultPagination} from "../../../services/api-service";
import {ErrorDialog} from "../../Common/ErrorDialog/ErrorDialog";
import {useModal} from "mui-modal-provider";
import {
    FormType,
    handleValidation,
} from "../../Common/FormBuilder/NEW/FormValidators";
import {
    FormAutocompleteField,
    FormField,
    StyledFormFieldLabel
} from "../../Common/FormBuilder/NEW/FormField";
import {LoadingButton} from "@mui/lab";

interface Props {
    selectedAdversaries: ThreatActor[],
    onClose: (updated: boolean) => void;
}

const threatActorKey = 'threatActorKey';

export const ThreatActorGroupDialog = ({selectedAdversaries, onClose}: Props) => {
    const threatActorService = new ThreatActorService();
    const {showModal} = useModal();

    const [form, setForm] = useState<FormType>({

        [threatActorKey]: {
            value: '',
            error: null,
            initialized: false,
            validators: []
        }
    })


    const [threatActors, setThreatActors] = useState<{pending: boolean, value: ThreatActor[]}>({pending: true, value: []})
    const [threatActorInputValue, setThreatActorInputValue] = React.useState('');

    const [open, setOpen] = useState(true)
    const [copyOfSelectedAdversaries, setCopyOfSelectedAdversaries] = useState([...selectedAdversaries])
    const [threatActorLookup, setThreatActorLookup] = useState<{
        [key: string]: ThreatActor
    }>({})
    const [submitting, setSubmitting] = useState(false);

    const columns: PaginatedListRow<ThreatActor>[] = [
        {
            label: "Threat Actor",
            render: (row: any) => <PaginatedListText>{row.name}</PaginatedListText>,
            width: "90%"
        },
        {
            label: "",
            render: (row: ThreatActor) => (
                <IconButton onClick={() => setCopyOfSelectedAdversaries(copyOfSelectedAdversaries.filter(r => r.id !== row.id))} color={'error'} sx={{padding: 0}} size={'small'}>
                    <Clear />
                </IconButton>
            ),
            width: "5%"
        }
    ]



    useEffect(() => {
        fetchAdversaries();
    }, [threatActorInputValue]);

    useEffect(() => {
        handleInitialization();
        fetchAdversaries();
    }, []);


    const handleInitialization = () => {
        const localForm = {...form};
        for (const key of Object.keys(localForm)) {
            localForm[key].error = handleValidation(key, localForm[key].value, localForm);
        }

        setForm(localForm)
    }

    const handleInputChange = (key: string, value: string) => {
        const validationError = handleValidation(key, value, form)
        setForm({...form, [key]: {...form[key], value: value, error: validationError, initialized: true}})
    }


    const fetchAdversaries = () => {
        threatActorService.getPaginatedThreatActors(defaultPagination, threatActorInputValue)
            .then(res => {
                setThreatActors({pending: false, value: res.results})

                const map: {[key: string]: ThreatActor} = {}
                for (let org of (res.results || [])) {
                    map[org.name] = org;
                }

                setThreatActorLookup(map);

            })
            .catch(err => {
                setThreatActors({...threatActors, pending: false});
                showModal(ErrorDialog, {error: err})
            })
    }


    const handleClose = (success: boolean = false) => {
        onClose(success);
        setOpen(false);
    }

    const handleSubmit = () => {
        setSubmitting(true);

        let adversaryId = null;
        if (threatActorLookup.hasOwnProperty(form[threatActorKey].value)) {
            adversaryId = threatActorLookup[form[threatActorKey].value].id;
        }

        threatActorService.groupThreatActors(copyOfSelectedAdversaries.map(a => a.id), adversaryId!)
            .then(res => handleClose(true))
            .catch(err => {
                setSubmitting(false);
                showModal(ErrorDialog, {error: err})
            })


    }


    const theme = useTheme();
    return (
        <Dialog open={open} maxWidth={'md'} fullWidth>
            <DialogTitle>Group Threat Actors</DialogTitle>
            <DialogContent>
                <Stack gap={5}>
                    <DialogContentText>
                        {
                            submitting ? "This may take a moment..." : "                        This action will merge all reports for the selected threat actors under the chosen threat actor. Please note that this cannot be undone."
                        }
                    </DialogContentText>


                    <Stack gap={2} alignItems={'center'} direction={'row'}>

                        <Stack  gap={1} width={'50%'}>
                            <Typography variant={'body1'} fontWeight={'600'}>
                                Selected Actors
                            </Typography>
                            <PaginatedList
                                data={{pending: false, value: copyOfSelectedAdversaries}}
                                columns={columns}
                                loading={false}
                                onRowClick={(row) => {
                                }} />
                        </Stack>

                        <Forward color={'primary'} fontSize={'large'} />

                        <FormField sx={{width: '50%'}}>
                            <StyledFormFieldLabel>Adversary</StyledFormFieldLabel>
                            <FormAutocompleteField
                                required={true}
                                options={Object.keys(threatActorLookup)}
                                inputValueValidation={(threatActorName) => {
                                    return threatActorLookup.hasOwnProperty(threatActorName);
                                }}
                                inputValue={threatActorInputValue}
                                setInputValue={setThreatActorInputValue}
                                freeSolo={true}
                                disabled={submitting}
                                placeholder={'Adversary'}
                                formKey={threatActorKey}
                                loading={threatActors.pending}
                                handleInputChange={(key, value) => handleInputChange(key, value)}
                                formField={form[threatActorKey]}/>
                        </FormField>

                    </Stack>

                    <Stack justifyContent={'center'} width={'100%'} direction={'row'} gap={2}>
                        <Button onClick={() => handleClose()} sx={{color: theme.palette.text.primary}} size={'small'}>
                            cancel
                        </Button>

                        <LoadingButton loadingPosition={'end'} loading={submitting} onClick={() => handleSubmit()} color={'secondary'} variant={'contained'} size={'small'}>
                            group
                        </LoadingButton>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )



}