import {Skeleton, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import React, {ReactNode} from "react";


interface TableSkeletonProps {
    totalRows: number;
    totalColumns: number;
    columnStyles: any[]
    rowHeight?: number
}

interface TableNoDataProps {
    message: string;
}

class TableSkeleton extends React.Component<TableSkeletonProps, any> {
    render = (): ReactNode => {
        return (
            <TableBody>
                {
                    [...new Array(this.props.totalRows)]
                        .map((row, rowIndex) => {
                            return (
                                <TableRow
                                    key={'row-' + rowIndex}
                                    hover
                                    sx={{height: this.props.rowHeight ?? 53}}
                                    tabIndex={-1}>
                                    {
                                        [...new Array(this.props.totalColumns)].map((row, colIndex) =>
                                            <TableCell key={`row-${rowIndex}-${colIndex}`}
                                                       sx={{width: this.props.columnStyles[colIndex]}}><Skeleton/></TableCell>
                                        )
                                    }
                                </TableRow>
                            )
                        })
                }
            </TableBody>
        )
    }
}


class TableNoData extends React.Component<TableNoDataProps, any> {
    render(): ReactNode {
        return (
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Typography>{this.props.message}</Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        )
    }
}


export {
    TableSkeleton,
    TableNoData
}