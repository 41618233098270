import {StixIndustrySectors} from "./stixx.sdo.model";

export interface StixSector {
    id: string;
    name: StixIndustrySectors;
}


export interface StixModel {
    id: string;
}

export enum StixRegions {
// Main Regions
    AFRICA = 'africa',
    AMERICAS = 'americas',
    ASIA = 'asia',
    EUROPE = 'europe',
    OCEANIA = 'oceania',

// Africa
    EASTERN_AFRICA = 'eastern-africa',
    MIDDLE_AFRICA = 'middle-africa',
    NORTHERN_AFRICA = 'northern-africa',
    SOUTHERN_AFRICA = 'southern-africa',
    WESTERN_AFRICA = 'western-africa',

// Americas
    CARIBBEAN = 'caribbean',
    CENTRAL_AMERICA = 'central-america',
    LATIN_AMERICA_CARIBBEAN = 'latin-america-caribbean',
    NORTHERN_AMERICA = 'northern-america',
    SOUTH_AMERICA = 'south-america',

// Asia
    CENTRAL_ASIA = 'central-asia',
    EASTERN_ASIA = 'eastern-asia',
    SOUTHERN_ASIA = 'southern-asia',
    SOUTH_EASTERN_ASIA = 'south-eastern-asia',
    WESTERN_ASIA = 'western-asia',

// Europe
    EASTERN_EUROPE = 'eastern-europe',
    NORTHERN_EUROPE = 'northern-europe',
    SOUTHERN_EUROPE = 'southern-europe',
    WESTERN_EUROPE = 'western-europe',

// Oceania
    ANTARCTICA = 'antarctica',
    AUSTRALIA_NEW_ZEALAND = 'australia-new-zealand',
    MELANESIA = 'melanesia',
    MICRONESIA = 'micronesia',
    POLYNESIA = 'polynesia',

    MIDDLE_EAST = 'middle-east'
}