import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    IconButton,
    Stack,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import React, {ReactNode} from "react";
import {ReportAnalysis, ReportModel, ReportStatus,} from "../../../../../services/ThreatAnalysis/threatAnalysis.model";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ViewThreatActor from "./Views/ViewThreatActor";
import ViewAMC from "./Views/ViewAnalysisAMC";
import ViewSources from "./Views/ViewAnalysisSources";
import TuneIcon from "@mui/icons-material/Tune";
import {ReportEntry, ViewListStyles} from "../../../../Common/ListView/ListView";
import {AutoGraph, Block, EditOutlined, Grading, ManageAccounts} from "@mui/icons-material";

type EntryType = {
    label: string,
    entries: ReportEntry<ReportAnalysis>[],
    Component: ReactNode
}

type EntriesType = {
    [key in AnalysisInterfaces]: EntryType
}


function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{width: "100%", m: 0, marginTop: "20px"}}>{children}</Box>
            )}
        </div>
    );
}


export interface SharedViewAnalysisChildrenProps {
    selectedAnalysisAttributes: {
        value: {
            label: string,
            attributes: (keyof ReportAnalysis)[],
        }[],
        setAttributes: (label: string, attributes: (keyof ReportAnalysis)[]) => void
    }
    analysis: ReportAnalysis;
    styles: ViewListStyles;
    onCopy: (event: any, value: any) => void;

    entries: ReportEntry<ReportAnalysis>[];
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface ViewReportAnalysisProps {
    lockedByUser: boolean;
    report: {
        value: ReportModel | null;
        pending: boolean;
    };
    analysis: ReportAnalysis;
    handleEditAnalysis: (name: AnalysisInterfaces, key: keyof ReportAnalysis) => void;
    onCopy: (event: any, value: any) => void;
    doesEntryExistInText: (value: any) => boolean;

    selectedAnalysisAttributes: {
        value: {
            label: string,
            attributes: (keyof ReportAnalysis)[],
        }[],
        setAttributes: (label: string, attributes: (keyof ReportAnalysis)[]) => void
    },
    isMetadataExpanded: boolean;
}

interface ViewReportAnalysisState {
    threatActorExpanded: boolean;
    scopeOfAttackExpanded: boolean;
    amcExpanded: boolean;
    sourcesExpanded: boolean;
}


export enum AnalysisInterfaces {
    ThreatActor = "threatActor",
    ScopeOfAttack = "scopeOfAttack",
    Amc = "amc",
    Sources = "sources",
}


export default class ViewAnalysis extends React.Component<
    ViewReportAnalysisProps,
    ViewReportAnalysisState
> {
    private listSubheaderStyle = {
        fontSize: "1.175rem",
        fontWeight: "600",
        color: (theme: any) => theme.palette.text.primary,
        display: "flex",
        alignItems: "center",
    };


    private listItemLabelStyle = {
        flex: `0 0 200px`,
        opacity: ".7",
    };

    private listItemStyle = {
        display: "flex",
        borderRadius: "5px",
        // cursor: "pointer",
        alignItems: "center",

        fontWeight: "500",
        // "&:hover": {
        //   background: (theme: Theme) =>
        //     lighten(theme.palette.background.default, 0.2),
        // },
    };

    private listBulletPointStyle = {
        padding: "4px",
        color: (theme: Theme) => theme.palette.primary.main,
    };

    private nestedListStyle = {
        // listStyleType: "disc",
        // pl: 2,
        padding: 1,
        flex: 1,
        maxHeight: "175px",
        overflow: "hidden",
        overflowY: "auto",
    };

    private styles: ViewListStyles = {
        listSubheaderStyle: this.listSubheaderStyle,
        listItemLabelStyle: this.listItemLabelStyle,
        listItemStyle: this.listItemStyle,
        listBulletPointStyle: this.listBulletPointStyle,
        nestedListStyle: this.nestedListStyle,
    };

    constructor(props: ViewReportAnalysisProps) {
        super(props);

        // @ts-ignore
        this.state = {
            [`${AnalysisInterfaces.ThreatActor}Expanded`]: true,
            [`${AnalysisInterfaces.ScopeOfAttack}Expanded`]: false,
            [`${AnalysisInterfaces.Amc}Expanded`]: false,
            [`${AnalysisInterfaces.Sources}Expanded`]: false,
        };
    }

    toggle(key: keyof ViewReportAnalysisState): void {
        const isExpanded = this.state[key];

        if (!isExpanded) {
            const expandableItems = Object.keys(this.state).filter((v) =>
                v.endsWith("Expanded")
            );
            const new_state: any = {};
            for (let _key of expandableItems) {
                if (_key !== key) {
                    new_state[_key] = false;
                }
            }

            this.setState({...new_state, [key]: true});
        } else {
            this.setState({
                [key as keyof ViewReportAnalysisState]: false,
            } as unknown as Pick<ViewReportAnalysisState, keyof ViewReportAnalysisState>);
        }
    }

    handleEditAnalysis(
        event: React.MouseEvent<HTMLButtonElement>,
        name: AnalysisInterfaces,
        key: keyof ReportAnalysis
    ): void {
        event.stopPropagation();

        this.props.handleEditAnalysis(name, key);
    }


    getRationaleIcon = (key: string) => {
        for (let changelog of this.props.analysis.change_log) {
            if (changelog.change?.field === key) {
                return (
                    <Tooltip title={(
                        <Stack direction={'row'} gap={1}>
                            <Typography variant={'body2'} sx={{fontWeight: 600}}>
                                MANUAL UPDATE -
                            </Typography>
                            <Typography variant={'body2'}>{changelog.change.rationale}</Typography>
                        </Stack>
                    )}>
                        <ManageAccounts fontSize={'small'} color={'info'}/>
                    </Tooltip>
                )
            }
        }

        return (
        <Tooltip title={( <Typography variant={'body2'}>{this.props.analysis.rationale[key]}</Typography>)}>
            <AutoGraph fontSize={'small'} color={'info'}/>
        </Tooltip>

        )
    }


    getRationale(key: keyof ReportAnalysis, value: any, view: AnalysisInterfaces, inTextSupport: boolean = false): JSX.Element {

        return (
            <Stack direction={'row'} alignItems={'center'} gap={1}>
                {
                    this.props.analysis.rationale && Object.hasOwn(this.props.analysis.rationale, key) ?
                        this.getRationaleIcon(key)
                        :
                        <Tooltip sx={{cursor: 'pointer'}} title={<Typography variant={'body2'}>No rationale provided</Typography>}>
                            <Block fontSize={'small'} color={'error'}/>
                        </Tooltip>
                }

                <IconButton disabled={this.props.report.value?.status !== ReportStatus.COMPLETE || !this.props.lockedByUser}
                            size={'small'} sx={{padding: 0}}
                            color={'primary'}
                            onClick={(e) => {
                                this.handleEditAnalysis(e, view, key)
                            }}>
                    <EditOutlined fontSize={'small'}
                    />
                </IconButton>


                {
                    inTextSupport && (
                    <Tooltip title={'Supports source identification'}>
                        <Grading fontSize={'small'} color={'success'}/>
                    </Tooltip>
                    )
                }
            </Stack>
        )
    }

    render(): React.ReactNode {
        const threatActorEntries: ReportEntry<ReportAnalysis>[] = [
            {
                label: "Name",
                key: "threat_actor",
                value: this.props.analysis?.threat_actor,
                endIcon: (key, value) => this.getRationale(key, value,AnalysisInterfaces.ThreatActor),
                previewText: "No value",
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },
            {
                label: "Country of Origin",
                key: "country_of_origin",
                value: this.props.analysis?.country_of_origin,
                endIcon: (key, value) => this.getRationale(key, value,AnalysisInterfaces.ThreatActor),
                previewText: "No value",
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },
            {
                label: "Sophistication",
                key: "threat_actor_sophistication",
                value: this.props.analysis?.threat_actor_sophistication,
                endIcon: (key, value) => this.getRationale(key, value,AnalysisInterfaces.ThreatActor),
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },
            {
                label: "Type",
                key: "threat_actor_type",
                value: this.props.analysis?.threat_actor_type,
                endIcon: (key, value) => this.getRationale(key, value,AnalysisInterfaces.ThreatActor),
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },
            {
                label: "Motivation",
                key: "threat_actor_motives",
                expandable: true,
                value: this.props.analysis?.threat_actor_motives,
                previewText: `${this.props.analysis?.threat_actor_motives?.length} values`,
                endIcon: (key, value) => this.getRationale(key, value,AnalysisInterfaces.ThreatActor),
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },
            {
                label: "Intent",
                key: "threat_actor_intent",
                value: this.props.analysis?.threat_actor_intent,
                expandable: true,
                previewText: `${this.props.analysis?.threat_actor_intent?.length} values`,
                endIcon: (key, value) => this.getRationale(key, value,AnalysisInterfaces.ThreatActor),
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },
            {
                label: "Resources",
                key: "threat_actor_resources",
                value: this.props.analysis?.threat_actor_resources,
                previewText: `${this.props.analysis?.threat_actor_resources?.length} values`,
                expandable: true,
                endIcon: (key, value) => this.getRationale(key, value,AnalysisInterfaces.ThreatActor),
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },
            {
                label: "Aliases",
                key: "aliases",
                value: this.props.analysis?.aliases,
                expandable: true,
                previewText: `${this.props.analysis?.aliases?.length} aliases`,
                endIcon: (key, value) => this.getRationale(key,  value,AnalysisInterfaces.ThreatActor),
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },
        ];
        const amcEntries: ReportEntry<ReportAnalysis>[] = [
            {
                label: "Mitigation",
                key: "attack_mitigations",
                value: this.props.analysis?.attack_mitigations,
                endIcon: (key, value) => this.getRationale(key, value, AnalysisInterfaces.Amc, true),
                previewText: `${this.props.analysis?.attack_mitigations?.length} mitigations`,
                expandable: true,
                icon: (value: string) => {
                    if (this.props.doesEntryExistInText(value)) {
                        return <Grading color={'success'}/>
                    }
                },
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },
            {
                label: "Consequences",
                key: "attack_consequences",
                value: this.props.analysis?.attack_consequences,
                endIcon: (key, value) => this.getRationale(key, value, AnalysisInterfaces.Amc, true),
                previewText: `${this.props.analysis?.attack_consequences?.length} consequences`,
                expandable: true,
                icon: (value: string) => {
                    if (this.props.doesEntryExistInText(value)) {
                        return <Grading color={'success'}/>
                    }
                },
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },
            {
                label: "Capabilities",
                key: "threat_actor_capabilities",
                endIcon: (key, value) => this.getRationale(key, value, AnalysisInterfaces.Amc, true),
                value: this.props.analysis?.threat_actor_capabilities,
                expandable: true,
                previewText: `${this.props.analysis?.threat_actor_capabilities?.length} capabilities`,
                icon: (value: string) => {
                    if (this.props.doesEntryExistInText(value)) {
                        return <Grading color={'success'}/>
                    }
                },
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },
            {
                label: "Vulnerabilities",
                key: "vulnerabilities",
                value: this.props.analysis?.vulnerabilities,
                endIcon: (key, value) => this.getRationale(key, value, AnalysisInterfaces.Amc,true),
                icon: (value: string) => {
                    if (this.props.doesEntryExistInText(value)) {
                        return <Grading color={'success'}/>
                    }
                },
                expandable: true,
                previewText: `${this.props.analysis?.vulnerabilities?.length} vulnerabilities`,
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },
        ];
        const targetEntityEntries: ReportEntry<ReportAnalysis>[] = [
            {
                label: "Countries",
                key: "target_countries",
                endIcon: (key, value) => this.getRationale(key, value, AnalysisInterfaces.ScopeOfAttack),
                value: this.props.analysis?.target_countries,
                expandable: true,
                previewText: `${this.props.analysis?.target_countries?.length} countries`,
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },

            {
                label: "Regions",
                key: "target_regions",
                value: this.props.analysis?.target_regions,
                endIcon: (key, value) => this.getRationale(key, value, AnalysisInterfaces.ScopeOfAttack),
                expandable: true,
                previewText: `${this.props.analysis?.target_regions?.length} regions`,
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },

            {
                label: "Sectors",
                endIcon: (key, value) => this.getRationale(key, value, AnalysisInterfaces.ScopeOfAttack),
                key: "target_industry_sectors",
                value: this.props.analysis?.target_industry_sectors.map(
                    (tis) => tis.name
                ),
                expandable: true,
                previewText: `${this.props.analysis?.target_industry_sectors?.length} industry sectors`,
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },
        ];
        const sourceEntries: ReportEntry<ReportAnalysis>[] = [
            {
                label: "Sources",
                endIcon: (key, value) => this.getRationale(key, value, AnalysisInterfaces.Sources),
                key: "sources",
                value: this.props.analysis?.sources,
                expandable: true,
                previewText: `${this.props.analysis?.sources.length} sources`,
                onEntryClicked: (entry, value) => this.props.onCopy(entry, value)
            },
        ];


        const entries: EntriesType = {
            [AnalysisInterfaces.ThreatActor]: {
                entries: threatActorEntries,
                label: 'Threat Actor Details',
                Component: (<ViewThreatActor
                    analysis={this.props.analysis}
                    entries={threatActorEntries}
                    selectedAnalysisAttributes={this.props.selectedAnalysisAttributes}
                    styles={this.styles}
                    onCopy={this.props.onCopy.bind(this)}
                />)
            },
            [AnalysisInterfaces.ScopeOfAttack]: {
                entries: targetEntityEntries,
                label: 'Scope of Attack',
                Component: (
                    <ViewThreatActor
                        analysis={this.props.analysis}
                        selectedAnalysisAttributes={this.props.selectedAnalysisAttributes}
                        styles={this.styles}
                        entries={targetEntityEntries}
                        onCopy={this.props.onCopy.bind(this)}
                    />
                )
            },
            [AnalysisInterfaces.Amc]: {
                entries: amcEntries,
                label: 'Threat Insight',
                Component: (<ViewAMC onCopy={this.props.onCopy.bind(this)}
                                     entries={amcEntries}
                                     selectedAnalysisAttributes={this.props.selectedAnalysisAttributes}
                                     doesEntryExistInText={this.props.doesEntryExistInText.bind(this)}
                                     analysis={this.props.analysis}
                                     styles={this.styles}/>)
            },
            [AnalysisInterfaces.Sources]: {
                entries: sourceEntries,
                label: 'Sources',
                Component: (
                    <ViewSources onCopy={this.props.onCopy.bind(this)}
                                 entries={sourceEntries}
                                 selectedAnalysisAttributes={this.props.selectedAnalysisAttributes}
                                 doesEntryExistInText={this.props.doesEntryExistInText.bind(this)}
                                 analysis={this.props.analysis} styles={this.styles}/>
                )
            }
        }


        return (
            <Stack sx={{height: '100%'}} spacing={3}>
                {
                    (Object.entries(entries) as [keyof typeof AnalysisInterfaces, EntryType][]).map(([key, value]) => {

                        let view = key as AnalysisInterfaces;
                        return (
                            <Accordion
                                key={key}
                                expanded={this.state[`${view}Expanded`]}
                                onClick={() => this.toggle(`${view}Expanded`)}
                            >
                                <AccordionSummary expandIcon={<ExpandMore color={"secondary"}/>}>
                                    <Typography sx={this.listSubheaderStyle}>
                                        {value.label}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        overflow: "hidden",
                                        maxHeight: "80vh",
                                        overflowY: "auto",
                                        paddingBottom: 5,
                                    }}
                                >
                                    {value.Component}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
            </Stack>
        );
    }
}
