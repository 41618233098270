import {LoadingButton} from "@mui/lab";
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import {
    handleGetReportApprovalStatusColor,
    ReportAnalysis,
    ReportApprovalStatus,
} from "../../../../services/ThreatAnalysis/threatAnalysis.model";
import ThreatAnalysisService from "../../../../services/ThreatAnalysis/threatAnaylsis.service";
import {ErrorDetails,} from "../../../Common/ErrorDialog/ErrorDialog";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import SaveIcon from "@mui/icons-material/Save";
import {AppChip} from "../../../Common/common";

interface ReportApprovalProps {
    status: ReportApprovalStatus;
    message: string | null;
    analysis: ReportAnalysis;
    onClose: (
        status: ReportApprovalStatus | null,
        message: string | null
    ) => void;
    onError: (error: ErrorDetails) => void;
}

interface ReportApprovalState {
    message: {
        value: string;
        error: null | string;
    };
    status: {
        value: ReportApprovalStatus;
        error: null | string;
    };
    submitting: boolean;
    open: boolean;
}

export class ReportApprovalDialog extends React.Component<
    ReportApprovalProps,
    ReportApprovalState
> {
    reportService: ThreatAnalysisService = new ThreatAnalysisService();

    constructor(props: ReportApprovalProps) {
        super(props);
        this.state = {
            message: {
                value: props.message ? props.message : "",
                error: null,
            },
            open: true,
            status: {
                value: props.status,
                error: null,
            },
            submitting: false,
        };
    }

    handleClose(status: ReportApprovalStatus | null, message: string | null): void {
        this.props.onClose(status, message)
        this.setState({open: false})
    }

    handleOnSubmit(): void {
        this.setState({submitting: true})
        this.reportService
            .toggleApprovalStatus(
                this.props.analysis._id,
                this.state.message.value,
                this.state.status.value
            )
            .then((res) => {
                this.setState({submitting: false})
                this.handleClose(this.state.status.value, this.state.message.value);
            })
            .catch((err) => {
                this.setState({submitting: false})
                this.props.onError(err);
            });
    }

    handleToggleStatus(status: ReportApprovalStatus): void {
        const newState: ReportApprovalState = {
            ...this.state,
            message: {...this.state.message, value: ''},
            status: {value: status, error: null},
        };
        if (status == ReportApprovalStatus.REJECTED && !this.state.message.value) {
            newState.message.error = ' ';
        } else if (
            status == ReportApprovalStatus.APPROVED &&
            this.state.message.error
        ) {
            newState.message.error = null;
        }

        this.setState(newState);
    }

    render(): React.ReactNode {
        return (
            <Container>
                <Dialog fullWidth={true} maxWidth={"sm"} open={this.state.open}>
                    <DialogTitle>
                        <Typography variant="h6" component="div">
                            Review Report
                            <AppChip
                                sx={{ml: "10px"}}
                                size={"small"}
                                label={this.props.analysis.approval_status}
                                color={handleGetReportApprovalStatusColor(this.props.analysis)}
                            />
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <form
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "20px 0",
                                gap: "30px",
                            }}
                        >
                            <Stack direction="row" spacing={1}>
                                <Button
                                    sx={{width: "50%"}}
                                    startIcon={<ThumbUpOffAltIcon/>}
                                    size={"small"}
                                    color={"success"}
                                    onClick={() =>
                                        this.handleToggleStatus(ReportApprovalStatus.APPROVED)
                                    }
                                    variant={
                                        this.state.status.value == ReportApprovalStatus.APPROVED
                                            ? "contained"
                                            : "outlined"
                                    }
                                >
                                    {this.state.status.value == ReportApprovalStatus.APPROVED
                                        ? "approved"
                                        : "approve"}
                                </Button>

                                <Divider flexItem orientation="vertical"/>

                                <Button
                                    sx={{width: "50%"}}
                                    startIcon={<ThumbDownOffAltIcon/>}
                                    size={"small"}
                                    color={"error"}
                                    onClick={() =>
                                        this.handleToggleStatus(ReportApprovalStatus.REJECTED)
                                    }
                                    variant={
                                        this.state.status.value == ReportApprovalStatus.REJECTED
                                            ? "contained"
                                            : "outlined"
                                    }
                                >
                                    {this.state.status.value == ReportApprovalStatus.REJECTED
                                        ? "reject"
                                        : "rejected"}
                                </Button>
                            </Stack>

                            <TextField
                                name="name"
                                color={"secondary"}
                                required={
                                    this.state.status.value == ReportApprovalStatus.REJECTED
                                }
                                value={this.state.message.value}
                                error={!!this.state.message.error}
                                rows={6}
                                multiline
                                label={"Reason for Decision"}
                                variant="outlined"
                                helperText={
                                    this.state.message.error ? this.state.message.error : " "
                                }
                                onChange={(event) =>
                                    this.setState({
                                        message: {
                                            value: event.target.value,
                                            error:
                                                !event.target.value &&
                                                this.state.status.value == ReportApprovalStatus.REJECTED
                                                    ? " "
                                                    : null,
                                        },
                                    })
                                }
                            />
                        </form>
                        <Divider/>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.handleClose(null, null);
                            }}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            disabled={
                                (this.state.status.value ==
                                    this.props.analysis.approval_status &&
                                    this.state.message.value ==
                                    this.props.analysis.approval_message) ||
                                (this.state.status.value == ReportApprovalStatus.REJECTED &&
                                    !this.state.message.value)
                            }
                            color={"primary"}
                            onClick={this.handleOnSubmit.bind(this)}
                            endIcon={<SaveIcon/>}
                            loading={this.state.submitting}
                            loadingPosition="end"
                            variant="contained"
                        >
                            <span>Ok</span>
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </Container>
        );
    }
}
