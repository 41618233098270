import {Edit} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    TextField, Typography,
} from "@mui/material";
import _ from "lodash";
import React, {useState} from "react";
import {ReportAnalysis} from "../../../../../../services/ThreatAnalysis/threatAnalysis.model";
import ThreatAnalysisService from "../../../../../../services/ThreatAnalysis/threatAnaylsis.service";
import {ErrorDetails} from "../../../../../Common/ErrorDialog/ErrorDialog";
import {AnalysisInterfaces} from "../ViewAnalysis";
import {AMCForm} from "./AMC/AMCForm";
import {ReportSources} from "./Sources/ReportSources";
import {TargetEntitiesForm} from "./TargetEntites/TargetEntitesForm";
import {ThreatActorForm} from "./ThreatActor/ThreatActorForm";
import toTitleCase from "../../../../../Common/Utils/AutoCompleteOptions/toTitleCase";
import {Stack} from "@mui/system";
import {FormMuliTextField} from "../../../../../Common/FormBuilder/NEW/FormField";
import {ValidationFn} from "../../../../../Common/FormBuilder/NEW/FormValidators";

interface AnalysisWrapperDialogProps {
    interfaceType: AnalysisInterfaces;
    analysis: ReportAnalysis;
    onClose: (res: ReportAnalysis | undefined) => void;
    onError: (error: ErrorDetails) => void;
    fieldKey: keyof ReportAnalysis;
    doesEntryExistInText: (value: any) => boolean;
}

interface AnalysisWrapperDialogState {
    open: boolean;
    submitting: boolean;
    analysis: ReportAnalysis;
}

export interface SharedAnalysisFormProps {
    analysis: ReportAnalysis;
    fieldKey: keyof ReportAnalysis;
    doesEntryExistInText: (value: any) => boolean;
}

export const AnalysisWrapperDialog = (props: AnalysisWrapperDialogProps) => {
    const rationalKey= 'rationaleKey';
    const [analysis, setAnalysis] = useState(props.analysis)
    const [open, setOpen] = useState(true)
    const [submitting, setSubmitting] = useState(false);
    const reportService: ThreatAnalysisService = new ThreatAnalysisService();
    const [rationale, setRationale] = useState({opened: false, value: ''});

    const handleUpdateAnalysis = (): void => {
        setSubmitting(true);

        reportService
            .updateAnalysis(analysis, rationale.value)
            .then((res) => {
                props.onClose(res);
                setOpen(false);

            } )
            .catch((err) => {
                props.onError(err);
                setSubmitting(false);
            });
    }

    return (
        <Dialog fullWidth={true} maxWidth={"sm"} open={open}>
            <DialogContent>
                {
                    !rationale.opened ? (
                        <React.Fragment>
                            {props.interfaceType === AnalysisInterfaces.ScopeOfAttack && (
                                <TargetEntitiesForm
                                    fieldKey={props.fieldKey}
                                    doesEntryExistInText={props.doesEntryExistInText}
                                    analysis={analysis}
                                    onError={props.onError}
                                    onUpdate={(a) => setAnalysis(a)}
                                />
                            )}
                            {props.interfaceType === AnalysisInterfaces.Sources && (
                                <ReportSources
                                    fieldKey={props.fieldKey}
                                    doesEntryExistInText={props.doesEntryExistInText}
                                    analysis={analysis}
                                    onUpdate={(a) => setAnalysis(a)}
                                />
                            )}
                            {props.interfaceType === AnalysisInterfaces.Amc && (
                                <AMCForm
                                    fieldKey={props.fieldKey}
                                    doesEntryExistInText={props.doesEntryExistInText}
                                    analysis={analysis}
                                    onUpdate={(a) => setAnalysis(a)}
                                />
                            )}
                            {props.interfaceType === AnalysisInterfaces.ThreatActor && (
                                <ThreatActorForm
                                    fieldKey={props.fieldKey}
                                    doesEntryExistInText={props.doesEntryExistInText}
                                    analysis={analysis}
                                    onError={props.onError}
                                    onUpdate={(a) => setAnalysis(a)}
                                />
                            )}
                        </React.Fragment>
                    ) : (
                        <Stack gap={2}>

                            <Typography fontSize={"1.25rem"} fontWeight={600}>
                                Update Rationale
                            </Typography>

                            <DialogContentText>

                                Please provide a rationale for the update, explicitly stating the source of the information from within the text.
                                If the update was based on inference by the language model, please explain why the inference was incorrect.
                            </DialogContentText>

                            <FormMuliTextField
                                formKey={rationalKey}
                                handleInputChange={(key, value) => setRationale({...rationale, value: value})}
                                formField={{
                                    value: rationale.value,
                                    error: rationale.value.trim() === '' ? " " : "",
                                    initialized: true,
                                    validators: []
                                }}

                            />



                        </Stack>
                    )
                }

                <Divider flexItem/>
            </DialogContent>
            <DialogActions sx={{padding: "15px"}}>
                <Button onClick={() => {
                    if (rationale.opened) {
                        setRationale({...rationale, opened: false})
                    } else {
                        props.onClose(undefined);
                        setOpen(false)
                    }
                }}>{rationale.opened ? "back" : "cancel"}</Button>
                <LoadingButton
                    disabled={_.isEqual(props.analysis, analysis) || (rationale.opened && rationale.value.trim() === '')}
                    onClick={() => {
                        if (!rationale.opened) {
                            setRationale({...rationale, opened: true})
                        } else {
                            handleUpdateAnalysis();
                        }
                    }}
                    loading={submitting}
                    loadingPosition="end"
                    variant="contained"
                    endIcon={<Edit/>}
                >
                    <span>{submitting ? "Updating" : "Okay"}</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}


