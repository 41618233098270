export interface User{
    firebase_id: string;
    email: string;
    group: UserGroup | null;
    company: string | null;
    company_name: string | null;
    created_on: number;
    last_sign_in: number | null;
}

export enum UserGroup {
    Admin = 'Admin',
    Analyst = 'Analyst',
    Entity = 'Entity',
    NonAttributable = 'Non-Attributable'
}

