import React from "react";
import {ThreatActor} from "../../../../../../services/ThreatActor/threatActor.model";
import {Box} from "@mui/system";
import ThreatActorAttribute from "./ThreatActorAttribute";
import {AttachMoney, CenterFocusStrong, EmojiEvents, Group} from "@mui/icons-material";

interface ThisProps {
    threatActor: ThreatActor
}

const ThreatActorAttributes: React.FC<ThisProps> = (props: ThisProps) => {
    return (
        <Box display={'flex'} flexDirection="row" sx={{gap: 2, paddingLeft: 2, width: '100%', height: '100%'}}>

            <ThreatActorAttribute value={props.threatActor.aliases ? props.threatActor.aliases : []} label={'Aliases'}
                                  icon={Group}/>
            <ThreatActorAttribute value={props.threatActor.x_motivation} label={'Motivation'} icon={EmojiEvents}/>
            <ThreatActorAttribute value={props.threatActor.x_intent} label={'Intent'} icon={CenterFocusStrong}/>
            <ThreatActorAttribute value={props.threatActor.x_resources} label={'Resource Level'} icon={AttachMoney}/>
        </Box>
    )
}

export default ThreatActorAttributes