import {Autocomplete, Box, DialogContentText, Divider, Paper, Stack, TextField, Typography,} from "@mui/material";
import React from "react";
import {CommonChildProps, CompanyModelKey,} from "../CompanyDialog";
import {
    TargetCountryData
} from "../../../ThreatAnalysis/ViewReport/ReportAnalysis/Dialogs/TargetEntites/TargetEntitesForm";
import {CompanyModel, CompanyType,} from "../../../../../services/company/company.model";
import {getOptionLabelForHML, TenPointScaleNumerical} from "../../../../../services/globals";

interface ThisProps extends CommonChildProps {
    countries: TargetCountryData[];
}

interface ThisState {
}


const companyNameKey = 'companyNameKey';
const companyTypeKey = 'companyTypeKey';
const contactEmailKey = 'contactEmailKey';
const countryOfOriginKey = 'countryOfOriginKey';

export class GeneralInfoForm extends React.Component<ThisProps, ThisState> {
    constructor(props: ThisProps) {
        super(props);

        this.state = {};
    }

    componentDidMount(): void {
        this.validate();
    }

    validate = () => {
        const errors: Partial<Record<CompanyModelKey, string | null>> = {};
        const keys: CompanyModelKey[] = [
            "name",
            "contact_information",
            "x_employee_count",
            "x_country_of_origin",
            "x_annual_revenue",
            "x_asset_value",
            "x_organization_type"
        ];



        for (let key of keys) {
            let error = null;

            if (!Array.isArray(this.props.company[key]!)) {
                // will never happen but typescript being typescript
                const value = this.props.company[key] as string;

                if (value?.toString().trim() === "" || value === null) {
                    error = " ";
                }

                if ((key === 'x_employee_count' || key === 'x_annual_revenue') && (parseInt(value) <= 0 || !value)) {
                    error = "Must be >= 0";
                }

                errors[key] = error;
            }
        }

        this.props.onError({...this.props.errors, ...errors});
    };

    handleUpdateField = (key: keyof CompanyModel, value: any) => {
        const company: CompanyModel = {...this.props.company};

        if (!Array.isArray(company[key])) {
            // will never happen but typescript being typescript
            (company[key] as string) = value;
        }

        this.props.onUpdate(company, this.validate);
    };

    render(): React.ReactNode {
        return (
            <React.Fragment>

                {
                    this.props.company.is_incomplete &&
                    <DialogContentText sx={{pt: 2, pb: 4}}>
                        Please complete the form below with details about your company.
                        This information will be used to assess your potential cyber threats, including ransomware.
                        If you have any questions, feel free to reach out.
                    </DialogContentText>
                }

                <form style={{display: "flex", flexDirection: "column", gap: "30px"}}>
                    <Stack direction="row" justifyContent={"space-between"} gap={3}>
                        <Stack sx={{width: "100%"}}>
                        <Typography sx={this.props.styles.form_field_label}>
                            Company Name <span style={{color: "red"}}>*</span>
                        </Typography>
                        <Paper>
                            <TextField
                                fullWidth
                                required
                                color={"secondary"}
                                placeholder="University of Arkansas at Little Rock"
                                value={this.props.company.name}
                                onChange={(event) => {
                                    this.handleUpdateField("name", event.target.value);
                                }}
                                error={!!this.props.errors["name"]}
                                helperText={
                                    this.props.errors["name"] !== " "
                                        ? this.props.errors["name"]
                                        : ""
                                }
                            />
                        </Paper>
                    </Stack>

                        <Stack sx={{width: "100%"}}>
                            <Typography sx={this.props.styles.form_field_label}>
                                Company Type <span style={{color: "red"}}>*</span>
                            </Typography>
                            <Paper>
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    value={this.props.company.x_organization_type}
                                    onChange={(event, value) =>
                                        this.handleUpdateField(
                                            "x_organization_type",
                                            value ? value : ""
                                        )
                                    } //
                                    options={Object.values(CompanyType)}
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField
                                            placeholder="For-Profit"
                                            required
                                            error={!!this.props.errors["x_organization_type"]}
                                            color={"secondary"}
                                            {...params}
                                        />
                                    )}
                                />
                            </Paper>
                        </Stack>

                    </Stack>

                    <Stack direction="row" justifyContent={"space-between"} gap={3}>
                        <Stack sx={{width: "100%"}}>
                            <Typography sx={this.props.styles.form_field_label}>
                                Contact Email Address <span style={{color: "red"}}>*</span>
                            </Typography>
                            <Paper>
                                <TextField
                                    fullWidth
                                    required
                                    color={"secondary"}
                                    placeholder="john.doe@gmail.com"
                                    value={this.props.company.contact_information}
                                    error={!!this.props.errors["contact_information"]}
                                    onChange={(event) => {
                                        this.handleUpdateField(
                                            "contact_information",
                                            event.target.value
                                        );
                                    }}
                                />
                            </Paper>
                        </Stack>

                        <Stack sx={{width: "100%"}}>
                            <Typography sx={this.props.styles.form_field_label}>
                                Country of Origin <span style={{color: "red"}}>*</span>
                            </Typography>
                            <Paper>
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    value={this.props.countries.find(
                                        (c) => c.iso_code.toLowerCase() === this.props.company.x_country_of_origin?.toLowerCase()
                                    )}
                                    onChange={(event, value) =>
                                        this.handleUpdateField(
                                            "x_country_of_origin",
                                            value ? value.iso_code : ""
                                        )
                                    } //
                                    options={this.props.countries}
                                    renderOption={(props, option) => (
                                        <Box key={option.iso_code} padding={"2px 0"}>
                                            <li
                                                {...props}
                                            >{`${option?.country} (${option?.iso_code})`}</li>
                                            <Divider/>
                                        </Box>
                                    )}
                                    getOptionLabel={(option: {
                                        country: string;
                                        iso_code: string;
                                    }) => {
                                        return `${option?.country} (${option?.iso_code})`;
                                    }}
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField
                                            placeholder="United States (US)"
                                            required
                                            error={!!this.props.errors["x_country_of_origin"]}
                                            color={"secondary"}
                                            {...params}
                                        />
                                    )}
                                />
                            </Paper>
                        </Stack>
                    </Stack>

                    <Stack justifyContent={"space-between"} direction={"row"} gap={3}>
                        <Stack sx={{width: "100%"}}>
                            <Typography sx={this.props.styles.form_field_label}>
                                Employee Count <span style={{color: "red"}}>*</span>
                            </Typography>
                            <Paper>
                                <TextField
                                    fullWidth
                                    required
                                    type={'number'}
                                    color={"secondary"}
                                    placeholder="156"
                                    value={this.props.company.x_employee_count || ""}
                                    onChange={(event) => {
                                        this.handleUpdateField("x_employee_count", event.target.value);
                                    }}
                                    error={!!this.props.errors["x_employee_count"]}
                                    helperText={
                                        this.props.errors["x_employee_count"] !== " "
                                            ? this.props.errors["x_employee_count"]
                                            : ""
                                    }
                                />
                            </Paper>
                        </Stack>

                        <Stack sx={{width: "100%"}}>
                            <Typography sx={this.props.styles.form_field_label}>
                                Annual Revenue <span style={{color: "red"}}>*</span>
                            </Typography>
                            <Paper>
                                <TextField
                                    fullWidth
                                    required
                                    type={'number'}
                                    color={"secondary"}
                                    placeholder="500000"
                                    value={this.props.company.x_annual_revenue}
                                    onChange={(event) => {
                                        this.handleUpdateField("x_annual_revenue", event.target.value);
                                    }}
                                    error={!!this.props.errors["x_annual_revenue"]}
                                    helperText={
                                        this.props.errors["x_annual_revenue"] !== " "
                                            ? this.props.errors["x_annual_revenue"]
                                            : ""
                                    }
                                />
                            </Paper>
                        </Stack>
                        <Stack sx={{width: "100%"}}>
                            <Typography sx={this.props.styles.form_field_label}>
                                Asset Value <span style={{color: "red"}}>*</span>
                            </Typography>
                            <Paper>
                                <Autocomplete
                                    disablePortal
                                    disableClearable

                                    value={this.props.company.x_asset_value}
                                    getOptionLabel={(option: TenPointScaleNumerical) => getOptionLabelForHML(option)}
                                    onChange={(event, value: TenPointScaleNumerical | null) => {
                                        if (value !== null) {
                                            this.handleUpdateField("x_asset_value", value);
                                        }
                                    }}

                                    options={Object.values(TenPointScaleNumerical).filter((value): value is TenPointScaleNumerical => typeof value === "number")}
                                    renderInput={(params) => (
                                        <TextField    error={!!this.props.errors["x_asset_value"]}
                                                      helperText={
                                                          this.props.errors["x_asset_value"] !== " "
                                                              ? this.props.errors["x_asset_value"]
                                                              : ""
                                                      } required placeholder={'High'} color={"secondary"} {...params} />
                                    )}
                                    fullWidth
                                />
                            </Paper>
                        </Stack>
                    </Stack>
                </form>
            </React.Fragment>
        );
    }
}
