import React, {useContext, useEffect, useState} from "react";
import {Box, Stack} from "@mui/system";
import PublicAuthContext from "../../context/PublicAuthContext";
import CompanyService from "../../services/company/company.service";
import {useModal} from "mui-modal-provider";
import {ErrorDetails, ErrorDialog} from "../Common/ErrorDialog/ErrorDialog";
import {CompanyModel, CompanyRisk, CompanyThreatRansomware} from "../../services/company/company.model";
import {Button, Divider, IconButton, Paper, styled, Tab, Typography, useTheme} from "@mui/material";
import Drawer from '@mui/material/Drawer';
import PublicLoadingScreen from "./PublicLoadingScreen";
import toTitleCase from "../Common/Utils/AutoCompleteOptions/toTitleCase";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from '@mui/material/Unstable_Grid2';
import {RiskMetrics} from "./PublicHomeComponents/Overview/RiskMetrics";
import RiskScoreBreakdownChart from "./PublicHomeComponents/Overview/RiskScoreBreakdownChart";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import RiskCharts from "./PublicHomeComponents/Overview/RiskCharts";
import {RiskThreats} from "./PublicHomeComponents/Threats/RiskThreats";
import RiskThreatView from "./PublicHomeComponents/Threats/RiskThreatView";
import {AuthContext} from "../../context/AuthContext";
import ThreatActorDialog from "./PublicHomeComponents/ThreatActorDialog/ThreatActorDialog";
import {TopRansomwareGroupsComponent} from "./PublicHomeComponents/TopRansomwareGroups/TopRansomwareGroups";
import {ThreatActor} from "../../services/ThreatActor/threatActor.model";
import {CommunityIncidentsView} from "./PublicHomeComponents/CommunityIncidents/CommunityIncidentsView";
import ReportIncidentService from "../../services/ReportedIncident/reportIncident.service";
import {defaultPagination, PaginationRequest} from "../../services/api-service";
import {MissingVictimModel} from "../../services/ReportedAttacks/reportedAttack.model";
import SimpleSnackbar from "../Common/SnackBar/SnackBar";
import {ReportedIncidentModel} from "../../services/ReportedIncident/reportedIncident.model";

interface ThisProps {
}


const drawerWidth = 500;


const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

const Main = styled('div', {shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
}>(({theme, open}) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: 'relative',
}));

const PublicHome: React.FC<ThisProps> = () => {
    const {showModal} = useModal();
    const companyService = new CompanyService();
    const reportIncidentService = new ReportIncidentService();

    const theme = useTheme();

    const {company} = useContext(PublicAuthContext)
    const [tab, setTab] = React.useState('1');


    const [incidentFilter, setIncidentFilter] = useState('');

    const [incidentPagination, setIncidentPagination] = useState<PaginationRequest>(defaultPagination)
    const [incidents, setIncidents] = useState<{pending: boolean, fetching: boolean, value: ReportedIncidentModel[]}>({pending: true, value: [], fetching: false})


    const [ransomwareRisk, setRansomwareRisk] = useState<{ pending: boolean, value: CompanyThreatRansomware | null }>({
        pending: false,
        value: null
    })

    const [selectedCompanyRisk, setSelectedCompanyRisk] = useState<CompanyRisk | null>(null);
    const [companyRisk, setCompanyRisk] = useState<{ pending: boolean, value: CompanyRisk[] }>({
        pending: false,
        value: []
    })


    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (company.value && !company.value.is_incomplete) {
            fetchCompanyMetrics(company.value);
            fetchCompanyRansomwareMetrics(company.value)
        }
    }, [company.value]);



    useEffect(() => {
        if (!companyRisk.pending && !ransomwareRisk.pending) {
            setLoading(false);
        } else if (companyRisk.pending || ransomwareRisk.pending) {
            setLoading(true);
        }

    }, [companyRisk.pending, ransomwareRisk.pending]);


    const fetchCompanyMetrics = (company: CompanyModel) => {
        setCompanyRisk({...companyRisk, pending: true})
        if (company) {
            companyService.getCompanyRisk(company.id)
                .then(res => {
                    setCompanyRisk({pending: false, value: res})
                })
                .catch((err: ErrorDetails) => {
                    setCompanyRisk({pending: false, value: []})
                    showModal(ErrorDialog, {
                        error: err, onClose: () => {
                        }
                    })
                })
        }
    }

    const fetchCompanyRansomwareMetrics = (company: CompanyModel) => {
        setRansomwareRisk({...ransomwareRisk, pending: true})
        if (company) {
            companyService.getCompanyRansomwareRisk(company.id)
                .then(res => {
                    setRansomwareRisk({pending: false, value: res})
                })
                .catch((err: ErrorDetails) => {
                    setRansomwareRisk({pending: false, value: null})
                    showModal(ErrorDialog, {
                        error: err, onClose: () => {
                        }
                    })
                })
        }
    }


    const fetchCommunityIncidents = (thisPagination: PaginationRequest) => {
        reportIncidentService.getReportedIncidents(thisPagination)
            .then(res => {
                if (incidents.fetching) {
                    showModal(SimpleSnackbar, {message: "Fetching"})
                }

                setIncidents({value: [...incidents.value, ...res.results], pending: false, fetching: false})
                setIncidentPagination({...thisPagination, totalRecords:  res.count})
            })
            .catch((err) => {
                showModal(ErrorDialog, {error: err});
                setIncidents({...incidents, pending: false, fetching: false})
            });
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
        setSelectedCompanyRisk(null)
        setIncidents({value: [], pending: true, fetching: false})
        setIncidentPagination(defaultPagination)
    };


    return (
        <Box sx={{display: 'flex', height: '100%', overflow: 'hidden'}}>


        <Main>
                {
                    loading ?
                        <PublicLoadingScreen text={company.pending ? 'Fetching Profile' : 'Fetching Threats'}/> :
                        <Box height={'100%'}>
                            <TabContext value={tab}>
                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                    <TabList onChange={handleChange}>
                                        <Tab label="Dashboard" value="1"/>
                                        <Tab label="Your Threats" value="2"/>
                                        <Tab label="Community Incidents" value="3"/>

                                    </TabList>
                                </Box>

                                <TabPanel sx={{padding: 0}} value="1">
                                    <Grid sx={{height: '100%'}} container paddingY={2} spacing={5}>

                                        <Grid xs={12}>
                                            <RiskMetrics companyRisk={companyRisk}/>
                                        </Grid>

                                        <Grid xs={12}>
                                            <Divider/>
                                        </Grid>

                                        {/* Charts */}
                                        <Grid container xs={12} spacing={3} sx={{flexGrow: 1, height: theme => `calc(100vh - ${theme.spacing(38)})`}}>

                                            {/* RiskScoreBreakdownChart */}
                                            <Grid sx={{height: '100%', overflow: 'hidden', display: 'flex', alignItems: 'center'}} xs={6}>
                                                <Box sx={{height: '100%', flex: 1}}>
                                                    <TopRansomwareGroupsComponent risk={ransomwareRisk} />
                                                </Box>


                                                <Divider sx={{height: '100%', ml: 3}} orientation={'vertical'} flexItem/>
                                            </Grid>


                                            {/* RiskCharts */}
                                            <Grid sx={{height: '100%', overflow: 'hidden'}} xs={6}>
                                                <RiskCharts companyRisk={companyRisk}/>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </TabPanel>
                                <TabPanel sx={{padding: 0, height: '100%', overflow: 'auto'}} value="2">
                                    <Box paddingY={2}>
                                        <RiskThreats companyRisk={companyRisk}
                                                     selectedCompanyRisk={selectedCompanyRisk}
                                                     setSelectedCompanyRisk={setSelectedCompanyRisk}/>
                                    </Box>
                                </TabPanel>

                                <TabPanel sx={{padding: 0, height: '100%'}} value="3">
                                    <Box height={'100%'} paddingY={2}>
                                        <CommunityIncidentsView
                                            data={incidents}
                                            pagination={incidentPagination}
                                            filter={{value: incidentFilter, setFilter: setIncidentFilter}}
                                              loading={incidents.pending || incidents.pending}
                                              setPagination={(page: number, rowsPerPage: number) => {
                                                  const newPagination = {...incidentPagination, page: page, rowsPerPage: rowsPerPage};
                                                  fetchCommunityIncidents(newPagination);
                                              }} />
                                    </Box>
                                </TabPanel>


                            </TabContext>


                        </Box>
                }
            </Main>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    mt: 8, // This sets the top margin. Adjust as needed.
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        mt: 8,  // Make sure to set the margin here as well for the actual drawer paper
                    },
                }}
                anchor="right"
                open={selectedCompanyRisk !== null}
                onClose={(e) => setSelectedCompanyRisk(null)}
            >
                {
                    selectedCompanyRisk !== null &&
                    <Paper elevation={5}>
                        <DrawerHeader>
                            <Typography
                                className={'overflow'}
                                sx={{maxWidth: '50%'}}
                                variant={'h6'}
                                fontWeight={500}>{toTitleCase(selectedCompanyRisk!.threat_actor)}</Typography>
                            <Button sx={{marginRight: 'auto'}} size={'small'} color={'secondary'}
                                    onClick={() => showModal(ThreatActorDialog, {
                                        threatActorID: selectedCompanyRisk!.threat_actor_id,
                                        cachedActor: null
                                    })}>more</Button>
                            <IconButton color={'error'}
                                        onClick={() => setSelectedCompanyRisk(null)}><ClearIcon/></IconButton>
                        </DrawerHeader>
                    </Paper>
                }
                <Divider/>

                <Box sx={{height: `calc(100% - ${theme.spacing(20)})`, overflow: 'hidden', overflowY: 'auto'}}>
                    {
                        selectedCompanyRisk &&
                        <RiskThreatView threat={selectedCompanyRisk!}/>
                    }
                </Box>
            </Drawer>

        </Box>
    );

}

export default PublicHome;