import React, {useEffect, useState} from "react"
import {ComposableMap, Geographies, Geography} from "react-simple-maps"
import {ThreatActor} from "../../../../../services/ThreatActor/threatActor.model";

import 'iso-3166-2';
import {darken, Fade, useTheme} from "@mui/material";


interface ThisProps {
    threatActor: ThreatActor,
    setCurrentView: () => void;
    data: Map<string, { name: string, id: string }[]>
    selectedRegion: string | null
}

const geoUrl = "/maps/country_map.json";  // Assuming you've placed it under the maps folder in public

const ThreatActorTargetRegions: React.FC<ThisProps> = (props: ThisProps) => {
    const [filter, setFilter] = useState<string>('');
    const [selectedRegion, setSelectedRegion] = useState<string | null>(null)

    const theme = useTheme();

    useEffect(() => {

    }, []);

    return (
        <Fade in={true} unmountOnExit>
            <ComposableMap projectionConfig={{
                scale: 125,
                center: [0, -30]
            }}>
                <Geographies geography={geoUrl}>
                    {/*@ts-ignore*/}
                    {({geographies}) =>
                        geographies.map((geo: any) => {
                            const countryData = props.threatActor.target_regions!.find(c => {
                                const countries = props.data.get(c);
                                const doesExist = countries?.map(r => r.id).includes(geo.id);
                                if (doesExist && props.selectedRegion !== null) {
                                    return c == props.selectedRegion;
                                }

                                return doesExist;

                            });

                            let fillColor = "gray"; // Default color


                            if (props.selectedRegion) {
                                if (props.data.get(props.selectedRegion)!.map(r => r.id).includes(geo.id)) {
                                    fillColor = darken(theme.palette.primary.main, .5)
                                }
                            } else {
                                if (countryData) {
                                    // const opacity = calculateOpacity(countryData);
                                    fillColor = `rgba(0, 0, 255, 1)`; // Using RGBA to set opacity
                                }
                            }

                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill={fillColor}
                                    stroke="#EAEAEC"
                                    strokeWidth={0.5}
                                />
                            );
                        })
                    }
                </Geographies>

            </ComposableMap>

        </Fade>

    );

}

export default ThreatActorTargetRegions