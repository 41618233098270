import InfiniteTable, {InfiniteTableTableColumn} from "../../../Common/TableHelper/InfiniteTable";
import {AppChip} from "../../../Common/common";
import {Stack} from "@mui/system";
import {format} from "date-fns";
import React from "react";
import {ReportedAttackModel} from "../../../../services/ReportedAttacks/reportedAttack.model";
import {ReportStatus} from "../../../../services/ThreatAnalysis/threatAnalysis.model";

interface Props {
    attacks: {pending: boolean, value: ReportedAttackModel[]}
    pagination: { page: number, rowsPerPage: number, totalRecords: number };
    setPagination: (page: number, rowsPerPage: number) => void;
    filter: string;
}

export const ReportedAttackTable = (props: Props) => {
    const columns: InfiniteTableTableColumn<ReportedAttackModel>[] = [
        {
            key: 'attacker_name',
            label: 'Adversary',
            width: '35%'
        },
        {
            key: 'victim_name',
            label: 'Company',
            width: '40%',
        },
        {
            key: 'discovered_on',
            label: 'Reported On',
            width: '20%',
            render: (row) => format(
                new Date(row?.discovered_on as string),
                "MM/dd/yyyy"
            )
        }

    ]


    return (
        <InfiniteTable
            data={props.attacks.value}
            columns={columns}
            pagination={props.pagination}
            setPagination={props.setPagination}
            loading={props.attacks.pending}
            onClick={(id) => {}}
            onDelete={() => {}}
            noDataText={'No Reported Attacks'}
        />
    )

}