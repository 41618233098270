import React from 'react';
import {Fade, Typography, useTheme} from "@mui/material";
import {Box} from "@mui/system";


interface Props {
    text: string;
}

function PublicLoadingScreen(props: Props) {
    const theme = useTheme();

    return (
        <Fade in={true} unmountOnExit>
            <Box
                display="flex"
                flexDirection='column'
                justifyContent="center"
                alignItems="center"
                height={'100%'}
            >
                <Box display="flex" alignItems="flex-end" height="50px">
                    <Box
                        width="20px"
                        height="30px"
                        mx={0.5}
                        bgcolor={theme.palette.primary.main}
                        sx={{
                            animation: "upDown 1s infinite ease-in-out",
                            animationDelay: '100ms',
                        }}/>
                    <Box
                        width="20px"
                        height="30px"
                        mx={0.5}
                        bgcolor={theme.palette.primary.main}
                        sx={{
                            animation: "upDown 1s infinite ease-in-out",
                            animationDelay: '250ms',
                        }}
                    />
                    <Box
                        width="20px"
                        height="30px"
                        mx={0.5}
                        bgcolor={theme.palette.primary.main}

                        sx={{
                            animation: "upDown 1s infinite ease-in-out",
                            animationDelay: '350ms',
                        }}
                    />
                </Box>
                <Typography sx={{mt: 2}} variant={'h6'} color={'text.secondary'}>
                    {props.text}
                </Typography>
            </Box>
        </Fade>
    );
}

export default PublicLoadingScreen;


