import {ReportEntry, ViewListStyles} from "./ListView/ListView";
import {ReportedAttackModel} from "../../services/ReportedAttacks/reportedAttack.model";
import {useClipboard} from "../../hooks/useClipboard";
import React, {useEffect} from "react";
import {Card, CardContent, Divider, Paper, Skeleton, TableCell, TableRow, Typography, useTheme} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import {Box, Stack} from "@mui/system";
import List from "@mui/material/List";
import {InfiniteTableTableColumn} from "./TableHelper/InfiniteTable";
import {TableSkeleton} from "./TableHelper/TableHelper";
import {styled} from "@mui/material/styles";





export interface PaginatedListRow <T> {
    label: string;
    render: (row: any) => React.ReactNode;
    width?: string;
    allowCopy?: boolean;
}

interface ScrollableListProps<T> {
    data: {pending: boolean, value: T[]}
    columns: PaginatedListRow<T>[];

    loading: boolean
    pagination?: { page: number, rowsPerPage: number, totalRecords: number };
    setPagination?: (page: number, rowsPerPage: number) => void;

    onRowClick: (row: T) => void
}

export const PaginatedListText = styled(Typography)(({theme}) => ({
    fontSize: '.875rem',
    fontWeight: 500
}));



export const PaginatedList = <T extends unknown>(props: ScrollableListProps<T>) => {

    const rowHeight = 53;  // this is based on your empty rows height setting
    const defaultRowsPerPage = 10;

    useEffect(() => {
        // Get height of container and row
        const container = document.querySelector(".paginated-list") as HTMLElement;
        const containerHeight = container?.offsetHeight;

        // Calculate number of rows that can fit
        const numRows = Math.floor(containerHeight / rowHeight);


        // Now you have the numRows, you can adjust your pagination or fetch mechanism to get this number
        setPagination(0, numRows);
    }, []);


    const setPagination =(pageNumber: number, numRows: number) =>  {
        if (props.pagination  && props.setPagination) {
            props.setPagination(pageNumber, numRows);
        }
    }

    const handleChangePage = (
        // event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPagination(newPage, props.pagination?.rowsPerPage || defaultRowsPerPage);
    };

    const handleScroll = (e: any) => {
        let bottom =false;
        if (Math.ceil(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight || Math.floor(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight) {
            bottom = true;
        }

        if (bottom && props.pagination) {
            const totalPages = Math.ceil(props.pagination.totalRecords / props.pagination.rowsPerPage);



            if (props.pagination.page < totalPages - 1) { // Subtract 1 because page index starts at 0
                handleChangePage(props.pagination.page + 1);
            }
        }
    }

    const theme = useTheme();
    return (
        <Box className={'paginated-list'} onScroll={handleScroll} component={Paper} elevation={2} sx={{height: '100%', overflowY: 'auto', width: '100%'}}>
            <Box sx={{width: '100%'}} >
                {
                    !props.loading && props.data.value?.length ? props.data.value.map((row, index) => {
                    return (
                        <Card onClick={() => props.onRowClick(row)} sx={{":hover": {cursor: 'copy', background:  theme.palette.background.paper},background: index % 2 ? theme.palette.action.hover : undefined}} key={index}>
                            <Box   sx={{ display: 'flex', alignItems: 'center', width: '100%', paddingY: theme.spacing(2), paddingX: theme.spacing(1)}}>
                                {
                                    props.columns.map((col, index) => {
                                        const value = col.render(row)
                                        return (
                                            <Box key={`${col.label}-${index}`} sx={{width: col.width, paddingX: theme.spacing(1)}}>
                                                {value}
                                            </Box>
                                        );
                                    })
                                }

                            </Box>

                        </Card>
                    )
                }) :
                        <Card>
                            <Box sx={{ justifyContent: 'center', display: 'flex', width: '100%', paddingY: theme.spacing(2), paddingX: theme.spacing(1)}}>
                                <Typography color={'text.secondary'}>No Records</Typography>
                            </Box>
                        </Card>
                }

                {
                    props.loading &&
                    <Box sx={{paddingY: theme.spacing(2), paddingX: theme.spacing(1)}}>
                        {
                            [...new Array(props.pagination?.rowsPerPage || defaultRowsPerPage)]
                                .map((row, rowIndex) => {
                                    return (
                                        <Skeleton key={rowIndex} width={'100%'} height={60}/>
                                    )
                                })
                        }
                    </Box>





                }

            </Box>
        </Box>
    );
};