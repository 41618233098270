import React from 'react';

export interface DrawerStateContextType {
    isDrawerOpen: boolean;
    toggleDrawer: () => void;
    contentPadding: number;
}

const defaultDrawerState: DrawerStateContextType = {
    isDrawerOpen: true,
    contentPadding: .85,
    toggleDrawer: () => {
        console.warn('Context Provider not wrapped around component.');
    }
};

const DrawerStateContext = React.createContext<DrawerStateContextType>(defaultDrawerState);

export default DrawerStateContext;
