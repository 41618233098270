import {User} from "../../../services/User/user.model";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme} from "@mui/material";
import React, {useState} from "react";
import UserService from "../../../services/User/user.service";
import {LoadingButton} from "@mui/lab";
import {Add, DeleteOutline} from "@mui/icons-material";
import {Stack} from "@mui/system";
import {useModal} from "mui-modal-provider";
import {ErrorDialog} from "../../Common/ErrorDialog/ErrorDialog";
import CompanyService from "../../../services/company/company.service";
import {CompanyModel} from "../../../services/company/company.model";

interface Props {
    companyId: string;
    onClose: (removed: boolean) => void
}

export const DeleteCompanyDialog = ({companyId, onClose}: Props) => {
    const [deleting, setDeleting] = useState(false)
    const [open, setOpen] = useState(true)
    const companyService = new CompanyService()
    const {showModal} = useModal();

    const handleClose = (deleted: boolean = false) => {
        setOpen(false);
        onClose(deleted)
    }


    const onDelete = () => {
        setDeleting(true);

        companyService.deleteCompany(companyId)
            .then(() => handleClose(true))
            .catch((err) => {
                showModal(ErrorDialog, {error: err})
                setDeleting(false);
            })

    }


    const theme = useTheme();

    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle>Delete Company?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Deleting this company will also delete all related data and prevent the users tied to the company from logging into the application. Once deleted, this action cannot be undone.
                </DialogContentText>

            </DialogContent>

            <DialogActions>
                <Stack direction={'row'} gap={2}>
                    <Button onClick={() => handleClose()} size={'small'} sx={{color: theme.palette.text.primary}} > Cancel</Button>
                    <LoadingButton
                        onClick={onDelete}
                        endIcon={<DeleteOutline />}
                        loading={deleting}
                        loadingPosition={'end'}
                        variant={'contained'}
                        color={'error'}
                        size={'small'}>
                        Confirm
                    </LoadingButton>
                </Stack>


            </DialogActions>
        </Dialog>
    )

}