import React, {ReactNode} from "react";
import {RegionSelector} from "../../../../Common/Selectors/RegionSelector";
import {
    TargetRegionData,
} from "../../../ThreatAnalysis/ViewReport/ReportAnalysis/Dialogs/TargetEntites/TargetEntitesForm";
import {CommonChildProps} from "../CompanyDialog";

interface ThisProps extends CommonChildProps {
    regions: TargetRegionData[];
}

interface ThisState {
}

export class RegionsForm extends React.Component<ThisProps, ThisState> {
    constructor(props: ThisProps) {
        super(props);

        this.state = {};
    }

    componentDidMount(): void {
        this.validate();
    }

    validate = () => {
        let error = null;
        if (this.props.company.x_regions?.length === 0 || this.props.company.x_regions == null) {
            error = "Atleast one region is required";
        }

        this.props.onError({...this.props.errors, x_regions: error});
    };

    onDelete = (index: number) => {
        const company = {...this.props.company};
        company.x_regions.splice(index, 1);
        this.props.onUpdate(company, this.validate);
    };

    onUpdate = (value: string[]) => {
        const company = {...this.props.company};

        company.x_regions = [...value, ...(company.x_regions || [])];
        this.props.onUpdate(company, this.validate);
    };

    render(): ReactNode {
        return (
            <RegionSelector
                regions={this.props.company.x_regions}
                choices={this.props.regions.filter(
                    // Doing this for time being, a filter by button country
                    // would be nice here
                    (value) => value.country_iso_code.toLowerCase() == "us"
                )}
                onUpdate={(sic_ids) => this.onUpdate(sic_ids)}
                onDelete={(index) => this.onDelete(index)}
                error={this.props.errors.x_regions}
                helperText={
                    '"Regions" refer to the specific geographical areas identified by Region ISO codes where the organization conducts its operations or has significant influence.'
                }
            />
        );
    }
}
