import {ApiService} from "../api-service";

export enum INTERFACE_TYPES {
    sectors = 'sectors'
};

export default class StixxService extends ApiService {
    constructor() {
        super();
    }

    public getStixInterfaces<T>(interface_type: INTERFACE_TYPES): Promise<T> {
        return super.get<T>(`stix/sectors/`);
    }





}