import {CSSObject, styled, Theme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import RadarOutlinedIcon from "@mui/icons-material/RadarOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import React, {useContext} from "react";
import "./SideNav.css";
import {
    ControlCameraOutlined, FlagCircle, Group,
    Lightbulb,
    LightbulbCircleRounded,
    Public,
    RecentActorsOutlined,
    UpdateOutlined
} from "@mui/icons-material";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {Navigate, NavLink, Route, Routes} from "react-router-dom";
import {auth} from "../../../firebase";
import ViewCompany from "../../internal-ui/CompanyProfiler/ViewCompany/ViewCompany";
import CompanyProfiler from "../../internal-ui/CompanyProfiler/CompanyProfiler";
import ViewAPIToolJob from "../NetworkAnalysis/ViewAPIToolJob/ViewAPIToolJob";
import ThreatAnalysis from "../../internal-ui/ThreatAnalysis/ThreatAnalysis";
import SettingsIcon from "@mui/icons-material/Settings";
import CompanyParametersView from "../CompanyParameters/CompanyParametersView";
import ViewNetworkAnalysisTool from "../NetworkAnalysis/ViewSuite/ViewSuite";
import NetworkAnalysisView from "../NetworkAnalysis/NetworkAnalysisView";
import PreviousScans from "../NetworkAnalysis/PreviousScans/PreviousScans";
import RunToolChain from "../NetworkAnalysis/ToolChains/RunToolChain/ViewToolChain";
import ViewToolChains from "../NetworkAnalysis/ToolChains/ViewToolChains";
import DrawerStateContext, {DrawerStateContextType} from "../../../context/DrawerContext";
import ViewReport from "../../internal-ui/ThreatAnalysis/ViewReport/ViewReport";
import ViewActiveLearning from "../../internal-ui/ActiveLearning/ActiveLearningView";
import {lighten} from "@mui/system";
import {Stack, Tooltip} from "@mui/material";
import {UserViewComponent} from "../Users/UserView";
import {ReportedAttackComponent} from "../ThreatAnalysis/ReportedAttacks/ReportedAttack";
import ViewThreatActor from "../ThreatAnalysis/ViewReport/ReportAnalysis/Views/ViewThreatActor";
import {ViewThreatActorComponent} from "../ThreatActor/ViewThreatActor";

export const mainDrawerWidth = "250px";


const openedMixin = (theme: Theme): CSSObject => ({
    width: mainDrawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    placeItems: "space-between",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
    width: mainDrawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const bull = (
    <Typography component={'div'} variant={'h5'}>
        <Box
            component="span"
            sx={{display: 'inline-block', mr: '5px', transform: 'scale(1.5)'}}
        >
            •
        </Box>
    </Typography>
);

const Main = styled('div', {shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
}>(({theme, open}) => ({
    flexGrow: 1,
    padding: `${CONTENT_PADDING}rem`,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${open ? mainDrawerWidth : "calc(${theme.spacing(7)} + '1px')"}`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));


export enum AppLinks {
    USERS = 'users',
    COMPANY_PROFILER = "company-profiler",
    COMPANY_PARAMETER = "company-profiler/parameters",

    SOCIAL_MEDIA = "social-media",
    DARK_WEB = "dark-web",
    NETWORK_ANALYSIS = "network-analysis",
    NETWORK_ANALYSIS_HISTORY = "network-analysis/history",
    NETWORK_ANALYSIS_CHAINS = "network-analysis/toolchains",

    THREAT_ANALYSIS = "threat-analysis",
    REPORTED_ATTACK = "reported-attacks",
    REPORTED_INCIDENT = "reported-incident",

    THREAT_ACTOR = 'threat-actor',

    ACTIVE_LEARNING = 'active-learning',
    //   ARTIFACT_ANALYSIS = "artifact_analysis",
}

export const CONTENT_PADDING = 0.85;

interface SideNavLink {
    label: string;
    icon: any;
    url: AppLinks;
    children: SideNavLink[];
}

interface ThisProps {
    loading: boolean;
    theme: Theme;
    drawerContext?: DrawerStateContextType
}


// <props, state>
class SideNav extends React.Component<ThisProps,
    { open: boolean }
> {

    private iconColor: 'primary' | 'warning' | 'secondary' | undefined = undefined
    routes: SideNavLink[] = [
        {
            label: "Users",
            url: AppLinks.USERS,
            icon: <Group/>,
            children: [
                {
                    label: "Users",
                    url: AppLinks.USERS,
                    icon: <Group fontSize={'small'} color={this.iconColor}/>,
                    children: [],
                },
                // {
                //     label: "Parameters",
                //     url: AppLinks.COMPANY_PARAMETER,
                //     icon: <ControlCameraOutlined fontSize={'small'} color={this.iconColor}/>,
                //     children: [],
                // },
            ],
        },
        {
            label: "Company Profiler",
            url: AppLinks.COMPANY_PROFILER,
            icon: <LocationCityOutlinedIcon/>,
            children: [
                {
                    label: "Companies",
                    url: AppLinks.COMPANY_PROFILER,
                    icon: <LocationCityOutlinedIcon fontSize={'small'} color={this.iconColor}/>,
                    children: [],
                },
                // {
                //     label: "Parameters",
                //     url: AppLinks.COMPANY_PARAMETER,
                //     icon: <ControlCameraOutlined fontSize={'small'} color={this.iconColor}/>,
                //     children: [],
                // },
            ],
        },
        // {
        //     label: "Network Analysis",
        //     url: AppLinks.NETWORK_ANALYSIS,
        //     icon: <RadarOutlinedIcon/>,
        //     children: [
        //         {
        //             label: "Tool Suites",
        //             url: AppLinks.NETWORK_ANALYSIS,
        //             icon: <RadarOutlinedIcon fontSize={'small'} color={this.iconColor}/>,
        //             children: [],
        //         },
        //         {
        //             label: "Toolchains",
        //             url: AppLinks.NETWORK_ANALYSIS_CHAINS,
        //             icon: <LinkOutlinedIcon fontSize={'small'} color={this.iconColor}/>,
        //             children: [],
        //         },
        //         {
        //             label: "History",
        //             url: AppLinks.NETWORK_ANALYSIS_HISTORY,
        //             icon: <UpdateOutlined fontSize={'small'} color={this.iconColor}/>,
        //             children: [],
        //         },
        //     ],
        // },
        {
            label: "Threat Analysis",
            url: AppLinks.THREAT_ANALYSIS,
            icon: <BugReportOutlinedIcon/>,
            children: [
                {
                    label: "Threat Reports",
                    url: AppLinks.THREAT_ANALYSIS,
                    icon: <AssessmentIcon fontSize={'small'} color={this.iconColor}/>,
                    children: [],
                },
                {
                    label: "Ransomware Attacks",
                    url: AppLinks.REPORTED_ATTACK,
                    icon: <BugReportOutlinedIcon fontSize={'small'} color={this.iconColor}/>,
                    children: [],
                },
                {
                    label: "Reported Incidents",
                    url: AppLinks.REPORTED_INCIDENT,
                    icon: <FlagCircle fontSize={'small'} color={this.iconColor}/>,
                    children: [],
                }
            ],
        },
        {
            label: "Threat Actor",
            url: AppLinks.THREAT_ACTOR,
            icon: <RecentActorsOutlined/>,
            children: [
                {
                    label: "Threat Actors",
                    url: AppLinks.THREAT_ACTOR,
                    icon: <RecentActorsOutlined fontSize={'small'} color={this.iconColor}/>,
                    children: [],
                },
            ],
        },

        // {
        //     label: "Machine Learning",
        //     url: AppLinks.ACTIVE_LEARNING,
        //     icon: <LightbulbCircleRounded/>,
        //     children: [
        //         {
        //             label: "Active Learning",
        //             url: AppLinks.ACTIVE_LEARNING,
        //             icon: <Lightbulb fontSize={'small'} color={this.iconColor}/>,
        //             children: []
        //         }
        //     ]
        // }
    ];

    constructor(props: ThisProps) {
        super(props);
        this.state = {
            open: true,
        };
    }


    generateNavLink = (route: SideNavLink, isOpen: boolean | undefined) => {
        return (
            <ListItem key={route.label} disablePadding sx={{display: "block", margin: "2px"}}>
                <NavLink
                    end

                    className={({isActive}) => "nav-link" + (isActive ? "-active" : "")}
                    style={({isActive}) => ({
                        color: this.props.theme.palette.text.primary,
                    })}
                    to={route.url}
                    children={({isActive}) => {
                        return (
                            <Tooltip title={isOpen ? '' : route.label} placement="right">

                                <ListItemButton
                                    className="nav-link-button"
                                    sx={{
                                        minHeight: 40,
                                        justifyContent: isOpen ? "initial" : "center",
                                        borderRadius: '5px',
                                        background: isActive ? lighten(this.props.theme.palette.background.paper, .18) : 'initial',
                                        mx: this.props.theme.spacing(1)
                                    }}
                                >
                                    <ListItemIcon
                                        className="nav-link-icon"
                                        sx={{
                                            color: this.props.theme.palette.text.primary,
                                            minWidth: 0,
                                            mr: isOpen ? 3 : "auto",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {route.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        className="heavy-text"
                                        primary={route.label}
                                        sx={{opacity: isOpen ? 1 : 0}}
                                    />
                                </ListItemButton>

                            </Tooltip>
                        );
                    }}
                />
            </ListItem>
        );
    };

    render() {
        return (
            <Box sx={{display: "flex"}}>
                <Drawer variant="permanent" open={this.props.drawerContext?.isDrawerOpen
                }>
                    <DrawerHeader>
                        {this.props.drawerContext?.isDrawerOpen
                            && (
                                <Stack gap={1} width={'100%'} alignItems={'center'} direction={'row'}>
                                    <img style={{height: '50px'}} src={'/logos/et-isac_maroon.png'}/>
                                    <h3 className="et-isac-title">SWARM</h3>

                                </Stack>
                            )}
                        <IconButton
                            sx={{
                                color: this.props.theme.palette.text.primary,
                            }}
                            onClick={() => this.props.drawerContext?.toggleDrawer()}
                        >
                            {this.props.drawerContext?.isDrawerOpen
                                ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                        </IconButton>
                    </DrawerHeader>
                    <Divider/>
                    <List>
                        {this.routes.map((route, index) => (
                            <React.Fragment key={route.url}>
                                {/*{this.generateNavLink(route)}*/}
                                {this.props.drawerContext?.isDrawerOpen
                                    && (
                                        <ListItem
                                            sx={{
                                                m: this.props.theme.spacing(.5),
                                            }}
                                        >


                                            <Typography color={'text.secondary'} fontSize={'.8rem'} fontWeight={600}>
                                                {route.label}
                                            </Typography>

                                        </ListItem>
                                    )}
                                {route.children.map((nested_route) => (
                                    // <List sx={{borderLeft: '1px solid grey'}} disablePadding key={nested_route.url}>
                                    this.generateNavLink(nested_route, this.props.drawerContext?.isDrawerOpen)
                                    // )}
                                    // </List>
                                ))}
                            </React.Fragment>
                        ))}
                    </List>

                    <footer className="footer">
                        <List>
                            <Divider/>


                            <ListItem disablePadding sx={{display: "block", margin: "2px"}} divider>
                                <NavLink
                                    end
                                    className={({isActive}) => "nav-link" + (isActive ? "-active" : "")}
                                    style={{
                                        color: this.props.theme.palette.text.primary,
                                    }}
                                    to={'/company-view'}
                                >
                                    <ListItemButton
                                        className="nav-link-button"
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: this.state.open ? "initial" : "center",
                                            // px: 2.5,
                                            // ml: this.state.open ? 2 : 0,
                                        }}
                                    >
                                        <ListItemIcon
                                            className="nav-link-icon"
                                            sx={{
                                                color: this.props.theme.palette.text.primary,
                                                minWidth: 0,
                                                mr: this.state.open ? 3 : "auto",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {<Public/>}
                                        </ListItemIcon>
                                        <ListItemText
                                            className="heavy-text"
                                            primary={'Public'}
                                            sx={{opacity: this.state.open ? 1 : 0}}
                                        />
                                    </ListItemButton>
                                </NavLink>
                            </ListItem>
                            <ListItem disablePadding sx={{display: "block"}} divider>
                                <ListItemButton
                                    className="nav-link-button"
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: this.props.drawerContext?.isDrawerOpen
                                            ? "initial" : "center",
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemText
                                        primary={"Settings"}
                                        className="heavy-text"
                                        sx={{
                                            opacity: this.props.drawerContext?.isDrawerOpen
                                                ? 1 : 0,
                                        }}
                                    />
                                    <ListItemIcon
                                        className="nav-link-icon"
                                        sx={{
                                            color: this.props.theme.palette.text.primary,
                                            minWidth: 0,
                                            mr: this.props.drawerContext?.isDrawerOpen
                                                ? 3 : "auto",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <SettingsIcon/>
                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                            <ListItem divider disablePadding sx={{display: "block"}}>
                                <ListItemButton
                                    onClick={() => auth.signOut()}
                                    className="nav-link-button"
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: this.props.drawerContext?.isDrawerOpen
                                            ? "initial" : "center",
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemText
                                        primary="Logout"
                                        className="heavy-text"
                                        sx={{
                                            opacity: this.props.drawerContext?.isDrawerOpen
                                                ? 1 : 0,
                                        }}
                                    />
                                    <ListItemIcon
                                        className="nav-link-icon"
                                        sx={{
                                            color: this.props.theme.palette.text.primary,
                                            minWidth: 0,
                                            mr: this.props.drawerContext?.isDrawerOpen
                                                ? 3 : "auto",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {<LogoutOutlinedIcon/>}
                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </footer>
                </Drawer>
                <Main open={this.props.drawerContext?.isDrawerOpen
                }>
                    <DrawerHeader/>
                    <Routes>
                        <Route path="/" element={<Navigate to={`/${AppLinks.USERS}`} />}/>

                        <Route
                            path={`/${AppLinks.USERS}`}
                            element={<UserViewComponent/>}
                        />

                        <Route
                            path={`/${AppLinks.COMPANY_PROFILER}`}
                            element={<CompanyProfiler/>}
                        />
                        <Route
                            path={`/${AppLinks.COMPANY_PROFILER}/:companyID`}
                            element={<ViewCompany/>}
                        />

                        {/*<Route*/}
                        {/*    path={`/${AppLinks.COMPANY_PARAMETER}`}*/}
                        {/*    element={<CompanyParametersView/>}*/}
                        {/*/>*/}

                        {/*<Route*/}
                        {/*    path={`/${AppLinks.SOCIAL_MEDIA}`}*/}
                        {/*    element={<Typography paragraph>Social Media Works</Typography>}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*    path={`/${AppLinks.DARK_WEB}`}*/}
                        {/*    element={<Typography paragraph>Dark Web Works</Typography>}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*    path={`/${AppLinks.NETWORK_ANALYSIS}`}*/}
                        {/*    element={<NetworkAnalysisView/>}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*    path={`/${AppLinks.NETWORK_ANALYSIS}/history`}*/}
                        {/*    element={<PreviousScans/>}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*    path={`/${AppLinks.NETWORK_ANALYSIS}/toolchains`}*/}
                        {/*    element={<ViewToolChains/>}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*    path={`/${AppLinks.NETWORK_ANALYSIS}/toolchains/view`}*/}
                        {/*    element={<RunToolChain/>}*/}
                        {/*/>*/}

                        {/*<Route*/}
                        {/*    path={`/${AppLinks.NETWORK_ANALYSIS}/history/:job_id`}*/}
                        {/*    element={<ViewAPIToolJob/>}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*    path={`/${AppLinks.NETWORK_ANALYSIS}/:suiteID`}*/}
                        {/*    element={<ViewNetworkAnalysisTool/>}*/}
                        {/*/>*/}


                        <Route
                            path={`/${AppLinks.THREAT_ANALYSIS}`}
                            element={<ThreatAnalysis/>}
                        />
                        <Route
                            path={`/${AppLinks.THREAT_ANALYSIS}/:analysis_id`}
                            element={<ViewReport/>}
                        />

                        <Route
                            path={`/${AppLinks.REPORTED_ATTACK}`}
                            element={<ReportedAttackComponent/>}
                        />

                        <Route
                            path={`/${AppLinks.THREAT_ACTOR}`}
                            element={<ViewThreatActorComponent/>}
                        />

                        {/*<Route*/}
                        {/*    path={`/${AppLinks.ACTIVE_LEARNING}`}*/}
                        {/*    element={<ViewActiveLearning/>}*/}
                        {/*/>*/}
                    </Routes>
                </Main>
            </Box>
        );
    }
}

export function SideNavView(props: ThisProps) {
    const appContext = useContext(DrawerStateContext);
    return <SideNav {...props} drawerContext={appContext}/>;
};
