import {ApiService} from "../api-service";
import {CompanyParameterModel, CompanyParameterXrefModel} from "./company-parameter.model";

export default class CompanyParameterService extends ApiService {
    constructor() {
        super();
    }


    public async getParameters(): Promise<CompanyParameterModel[]> {
        return super.get<CompanyParameterModel[]>(`company-parameter`);
    }

    public async createParameter(parameter: CompanyParameterModel): Promise<CompanyParameterModel> {
        return super.post<CompanyParameterModel>(`company-parameter`, parameter);
    }

    public async updateParameter(parameter: CompanyParameterModel): Promise<CompanyParameterModel> {
        return super.put<CompanyParameterModel>(`company-parameter/${parameter._id}`, parameter);
    }

    public async deleteParameter(parameter_id: string): Promise<void> {
        return super.delete<void>(`company-parameter/${parameter_id}`);
    }

    public async createCompanyParameter(parameter: CompanyParameterXrefModel): Promise<CompanyParameterXrefModel> {
        return super.post<CompanyParameterXrefModel>(`company/${parameter.company}/parameter/${parameter.parameter}`, parameter);
    }

    public async updateCompanyParameter(parameter: CompanyParameterXrefModel): Promise<CompanyParameterXrefModel> {
        return super.put<CompanyParameterXrefModel>(`company/${parameter.company}/parameter/${parameter.parameter}`, parameter);
    }

    public async deleteCompanyParameter(parameter: CompanyParameterXrefModel): Promise<CompanyParameterXrefModel> {
        return super.delete<CompanyParameterXrefModel>(`company/${parameter.company}/parameter/${parameter.parameter}`);
    }
}
