import React from "react";
import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip
} from "@mui/material";
import {DeleteOutline, EditOutlined} from "@mui/icons-material";
import {TableNoData, TableSkeleton} from "../../Common/TableHelper/TableHelper";
import {CompanyParameterModel} from "../../../services/company/company-parameter.model";

interface ThisProps {
    parameters: { pending: boolean; value: CompanyParameterModel[] };
    filter: string;
    onDelete: (id: string) => void;
    onEdit: (c: CompanyParameterModel) => void;
    onClick?: (c: CompanyParameterModel) => void;
    disableActionButtons?: boolean;
}

interface ThisState {
    pagination: { page: number, rowsPerPage: number }
}

export default class CompanyParametersTable extends React.Component<ThisProps, ThisState> {
    private nameColumnStyle = {width: "25%"};
    private descriptionColumn = {width: "35%"};
    private defaultValueStyle = {width: "30%"};
    private deleteStyle = {width: "5%"};

    private overflow = {
        whiteSpace: 'nowrap',
        maxWidth: 100,  // Adjust this value according to your needs
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };


    constructor(props: ThisProps) {
        super(props);
        this.state = {
            pagination: {page: 0, rowsPerPage: 10}
        }
    }


    handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        this.setState({pagination: {...this.state.pagination, page: newPage}});
    };

    handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        this.setState({
            pagination: {page: 0, rowsPerPage: parseInt(event.target.value, 10)},
        });
    };

    handleTableRowClick = (
        event: React.MouseEvent<unknown>,
        row: CompanyParameterModel
    ) => {
        //    this.props.router.navigate(row.id);
        if (this.props.onClick) {
            this.props.onClick(row);
        }
    };

    handleQueryInput = (value: CompanyParameterModel): boolean => {
        const query = this.props.filter.trim().toLocaleLowerCase();
        if (query !== "") {
            if (
                value.name.toLocaleLowerCase().includes(query) ||
                value._id?.includes(query) ||
                value.description.includes(query)
            )
                return true;

            return false;
        }

        return true;
    };

    render(): React.ReactNode {
        const filteredRows = this.props.parameters.value
            .filter((v) => this.handleQueryInput(v))
            .slice(
                this.state.pagination.page * this.state.pagination.rowsPerPage,
                this.state.pagination.page * this.state.pagination.rowsPerPage +
                this.state.pagination.rowsPerPage
            );

        // Avoid a layout jump when reaching the last page with empty rows.
        const emptyRows =
            this.state.pagination.page > 0
                ? Math.max(
                    0,
                    (1 + this.state.pagination.page) *
                    this.state.pagination.rowsPerPage -
                    this.props.parameters.value.length
                )
                : 0;

        return (
            <React.Fragment>
                <Box>
                    <TableContainer elevation={2} component={Paper}>
                        <Table stickyHeader sx={{minWidth: 700}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={this.nameColumnStyle}>Name</TableCell>
                                    <TableCell sx={this.descriptionColumn}>Description</TableCell>
                                    <TableCell sx={this.defaultValueStyle}>Default Value</TableCell>
                                    {
                                        !this.props.disableActionButtons &&
                                        <TableCell sx={this.deleteStyle}></TableCell>
                                    }
                                    {
                                        !this.props.disableActionButtons &&
                                        <TableCell sx={this.deleteStyle}></TableCell>
                                    }
                                </TableRow>
                            </TableHead>

                            {!this.props.parameters.pending && (
                                <TableBody>
                                    {filteredRows.map((row, index) => {
                                        return (
                                            <TableRow
                                                sx={{cursor: this.props.onClick ? 'pointer' : 'auto'}}
                                                key={"row-" + index}
                                                hover
                                                onClick={(event) =>
                                                    this.handleTableRowClick(event, row)
                                                }
                                                tabIndex={-1}
                                            >
                                                <Tooltip title={row.name}>
                                                    <TableCell
                                                        className={'overflow'}
                                                        sx={this.nameColumnStyle}>{row.name}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={row.description}>
                                                    <TableCell
                                                        className={'overflow'}
                                                        sx={this.descriptionColumn}>

                                                        {row.description}

                                                    </TableCell>
                                                </Tooltip>
                                                <Tooltip title={row.default_value}>
                                                    <TableCell
                                                        className={'overflow'}
                                                        sx={this.defaultValueStyle}>{row.default_value}</TableCell>
                                                </Tooltip>

                                                {
                                                    !this.props.disableActionButtons &&
                                                    <TableCell>

                                                        <IconButton
                                                            color="secondary"
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                this.props.onEdit(row);
                                                            }}
                                                        >
                                                            <EditOutlined/>
                                                        </IconButton>
                                                    </TableCell>
                                                }

                                                {
                                                    !this.props.disableActionButtons &&

                                                    <TableCell>
                                                        <IconButton
                                                            color="error"
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                this.props.onDelete(row._id!);
                                                            }}
                                                        >
                                                            <DeleteOutline/>
                                                        </IconButton>
                                                    </TableCell>

                                                }
                                            </TableRow>
                                        );
                                    })}

                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6}/>
                                        </TableRow>
                                    )}
                                </TableBody>
                            )}

                            {filteredRows.length == 0 && !this.props.parameters.pending && (
                                <TableNoData message={"No Parameters"}/>
                            )}

                            {this.props.parameters.pending && (
                                <TableSkeleton
                                    totalRows={8}
                                    totalColumns={6}
                                    columnStyles={[
                                        this.nameColumnStyle,
                                        this.descriptionColumn,
                                        this.defaultValueStyle,
                                        this.deleteStyle,
                                    ]}
                                />
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={this.props.parameters.value.length}
                        rowsPerPage={this.state.pagination.rowsPerPage}
                        page={this.state.pagination.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </Box>
            </React.Fragment>
        );
    }
}
