import React from "react";
import {CompanyParameter, CompanyParameterModel} from "../../../services/company/company-parameter.model";
import {ErrorDetails} from "../../Common/ErrorDialog/ErrorDialog";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, TextField, Typography} from "@mui/material";
import {Stack} from "@mui/system";
import {FormStyles} from "../CompanyProfiler/CompanyDialog/CompanyDialog";
import {LoadingButton} from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import CompanyParameterService from "../../../services/company/company-parameter.service";


interface ThisProps {
    parameter: null | CompanyParameterModel
    onClose: (param: null | CompanyParameterModel) => void;
    onError: (error: ErrorDetails) => void;
    existingParameters: { pending: boolean, value: CompanyParameterModel[] }
}

interface ThisState {
    parameter: CompanyParameterModel
    errors: { [key in keyof CompanyParameter]?: string; }
    submitting: boolean;
}


export default class CompanyParametersDialog extends React.Component<ThisProps, ThisState> {
    private companyParameterService: CompanyParameterService = new CompanyParameterService();
    private styles: FormStyles = {
        form_field_label: {fontWeight: "500", mb: "5px", opacity: ".9"},
    };

    constructor(props: ThisProps) {
        super(props);

        let errors = new CompanyParameter().to_json();
        for (let key in errors) {
            (errors as any)[key] = null;
        }

        this.state = {
            parameter: props.parameter ? {...props.parameter} : new CompanyParameter().to_json(),
            errors: errors,
            submitting: false
        }
    }

    handleUpdateField(key: keyof CompanyParameterModel, value: string) {
        let error = null;
        if (key == "name") {
            if (value.trim() == '') {
                error = ' ';
            } else if (value.includes(" ")) {
                error = 'Spaces not permitted'
            } else {
                const matchingParameters = this.props.existingParameters.value.filter(p => p.name == value && p._id !== this.props.parameter?._id)
                if (matchingParameters.length > 0) {
                    error = 'Parameter name must be unique'
                }
            }
        }

        this.setState({
            parameter: {...this.state.parameter, [key]: value},
            errors: {...this.state.errors, [key]: error}
        })
    }

    handleOnSubmit() {
        let apiCall;
        if (this.props.parameter) {
            const parameter = {...this.props.parameter, ...this.state.parameter}
            apiCall = this.companyParameterService.updateParameter(parameter);
        } else {
            apiCall = this.companyParameterService.createParameter({...this.state.parameter})
        }

        apiCall
            .then((res) => {
                this.props.onClose(res);
            })
            .catch((err) => {
                this.props.onError(err);
                this.setState({submitting: false});
            });
    }

    render() {

        return (
            <Dialog open={true} maxWidth={"sm"} fullWidth>
                <DialogTitle>{this.props.parameter ? "Update" : "Create"} Parameter</DialogTitle>
                <DialogContent>
                    <Stack padding={'15px 0'} spacing={4}>
                        <Stack>
                            <Typography sx={this.styles.form_field_label}>
                                Parameter Name <span style={{color: "red"}}>*</span>
                            </Typography>
                            <Paper>
                                <TextField
                                    fullWidth
                                    required
                                    color={"secondary"}
                                    placeholder="Organization ID"
                                    value={this.state.parameter.name}
                                    onChange={(event) => {
                                        this.handleUpdateField("name", event.target.value);
                                    }}
                                    error={!!this.state.errors["name"]}
                                    helperText={
                                        this.state.errors["name"] !== " "
                                            ? this.state.errors["name"]
                                            : ""
                                    }
                                />
                            </Paper>
                        </Stack>

                        <Stack>
                            <Typography sx={this.styles.form_field_label}>
                                Default Value
                            </Typography>
                            <Paper>
                                <TextField
                                    fullWidth
                                    required
                                    color={"secondary"}
                                    placeholder="123"
                                    value={this.state.parameter.default_value}
                                    onChange={(event) => {
                                        this.handleUpdateField("default_value", event.target.value);
                                    }}
                                    error={!!this.state.errors["default_value"]}
                                    helperText={
                                        this.state.errors["default_value"] !== " "
                                            ? this.state.errors["default_value"]
                                            : ""
                                    }
                                />
                            </Paper>
                        </Stack>

                        <Stack>
                            <Typography sx={this.styles.form_field_label}>
                                Parameter Description
                            </Typography>
                            <Paper>
                                <TextField
                                    fullWidth
                                    required
                                    color={"secondary"}
                                    placeholder="American Registry for Internet Numbers Organization Identifer"
                                    value={this.state.parameter.description}
                                    onChange={(event) => {
                                        this.handleUpdateField("description", event.target.value);
                                    }}
                                    multiline
                                    rows={5}
                                    error={!!this.state.errors["description"]}
                                    helperText={
                                        this.state.errors["description"] !== " "
                                            ? this.state.errors["description"]
                                            : ""
                                    }
                                />
                            </Paper>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onClose(null)} sx={{mr: 1}}>
                        Cancel
                    </Button>
                    <LoadingButton
                        size="small"
                        color="primary"
                        onClick={this.handleOnSubmit.bind(this)}
                        loading={this.state.submitting}
                        loadingPosition="end"
                        endIcon={<SaveIcon/>}
                        variant="contained"
                        disabled={
                            Object.keys(
                                this.state.errors as { [key in keyof CompanyParameter]: any }
                            ).filter((key) => {
                                return (
                                    this.state.errors[key as keyof CompanyParameter] !== null
                                );
                            }).length > 0
                            ||
                            this.state.submitting
                        }
                    >
                        <span>Save</span>
                    </LoadingButton>
                </DialogActions>
            </Dialog>)
    }

}