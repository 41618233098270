import React, {useEffect} from 'react';
import {
    Box,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell, TableCellProps,
    TableContainer,
    TableHead,
    Typography, useTheme,
} from "@mui/material";
import {TableSkeleton} from "./TableHelper";
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import {useClipboard} from "../../../hooks/useClipboard";
import LinearProgress from '@mui/material/LinearProgress';
import {PaginationRequest} from "../../../services/api-service";

// Define the shape of a column
export interface InfiniteTableTableColumn <T> {
    key?: keyof T;
    label: string;
    render?: (row: any) => JSX.Element | string | null;
    width?: string;
    allowCopy?: boolean;
}

interface ReusableTableProps {
    data: any[];
    columns: InfiniteTableTableColumn<any>[];
    onDelete?: (id: string) => void;
    onClick?: (row: any) => void;
    pagination?: PaginationRequest;
    setPagination?: (page: number, rowsPerPage: number) => void;
    noDataText: string;
    loading?: boolean
}

// Extend TableRowProps with your custom prop
interface StyledTableRowProps extends TableRowProps {
    clickable?: boolean;
}

interface StyledTableCellProps extends TableCellProps {
    copyable?: boolean;
}


const StyledTableRow = styled(TableRow, {
    shouldForwardProp: (prop) => prop !== 'clickable',
})<StyledTableRowProps>(({ theme, clickable }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    cursor: clickable ? 'pointer' : 'default', // Use 'default' instead of undefined for clarity
}));



const StyledTableCell = styled(TableCell, {
    shouldForwardProp: (prop) => prop !== 'riskLevel' && prop !== 'copyable',
})<StyledTableCellProps>(({theme, copyable}) => {

    return {
        fontSize: 14,
        '&.MuiTableCell-head': {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },

        cursor: copyable ? 'copy' : 'inherit'
    }
});


const InfiniteTable = (props: ReusableTableProps) =>   {
    const clipboard = useClipboard();
    const rowHeight = 53;  // this is based on your empty rows height setting
    const defaultRowsPerPage = 10;

    useEffect(() => {
        // Get height of container and row
        const container = document.querySelector(".MuiTableContainer-root") as HTMLElement;
        const containerHeight = container?.offsetHeight;

        // Calculate number of rows that can fit
        const numRows = Math.floor(containerHeight / rowHeight);

        // Now you have the numRows, you can adjust your pagination or fetch mechanism to get this number
        setPagination(0, numRows);
    }, []);


    const setPagination =(pageNumber: number, numRows: number) =>  {
        if (props.pagination  && props.setPagination) {
            props.setPagination(pageNumber, numRows);
        }
    }

    const handleChangePage = (
        // event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPagination(newPage, props.pagination?.rowsPerPage || defaultRowsPerPage);
    };

    const handleScroll = (e: any) => {
        let bottom =false;
        if (Math.ceil(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight || Math.floor(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight) {
            bottom = true;
        }

        if (bottom && props.pagination) {
            const totalPages = Math.ceil(props.pagination.totalRecords / props.pagination.rowsPerPage);



            if (props.pagination.page < totalPages - 1) { // Subtract 1 because page index starts at 0
                handleChangePage(props.pagination.page + 1);
            }
        }
    }

    const theme = useTheme();

    return (
        <Box component={Paper} elevation={2} sx={{flexGrow: 1, height: '100%', overflowY: 'auto'}}>
            <LinearProgress value={props.loading ? 100 : 0}
                            variant={props.loading ? 'indeterminate' : 'determinate'} color="secondary"/>
            <TableContainer sx={{height: '100%'}} component={Paper} onScroll={handleScroll.bind(this)}>
                <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
                    <TableHead>
                        <TableRow>
                            {props.columns.map(col => {
                                return (
                                    <TableCell sx={{width: col.width}} key={col.key as string}>
                                        {col.label}
                                    </TableCell>
                                )

                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.data.length == 0 && !props.loading ?
                                <TableRow>
                                    <TableCell colSpan={props.columns.length} align="center">
                                        <Typography variant="body1" color="textSecondary">
                                            {props.noDataText}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                : props.data.map((row, index) => (
                                    <StyledTableRow  clickable={props.onClick !== undefined}  key={index} hover onClick={() => props.onClick && props.onClick(row)}>
                                        {props.columns.map(col => {
                                            const value = (col.render ? col.render(row) : col.key ? row[col.key] : <Typography>error</Typography>)
                                            const canCopy = col.allowCopy && value;

                                            return (
                                                <StyledTableCell
                                                    copyable={canCopy}
                                                    key={col.key as string}
                                                    onClick={() => canCopy && clipboard.copy(value)}
                                                    className={'overflow'}
                                                    sx={{width: col.width, paddingX: theme.spacing(1)}} >
                                                    {
                                                        value
                                                        ??
                                                        <Typography color={'text.secondary'}>-------------</Typography>
                                                    }
                                                </StyledTableCell>
                                            )
                                        })}
                                    </StyledTableRow>
                                ))
                        }
                    </TableBody>

                    {
                        props.loading && props.data.length === 0 &&
                        <TableSkeleton
                            totalRows={props.pagination?.rowsPerPage || defaultRowsPerPage}
                            totalColumns={props.columns.length}
                            columnStyles={props.columns.map(c => c.width)}
                        />

                    }
                </Table>
            </TableContainer>


        </Box>
    );
}


export default InfiniteTable;