import {ApiService} from "./api-service";


export default class SiteService extends ApiService {
    public async getPublicHelpDoc(): Promise<{[key: string]: string}> {
        return super.get<{[key: string]: string}>("site/help");
    }



}
