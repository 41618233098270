import React from "react";
import {CompanyCommonProps} from "../ViewCompany";
import {Box, Stack} from "@mui/system";
import {Typography} from "@mui/material";
import {ViewCompanyStix} from "./ViewCompanyStix";

interface ThisProps extends CompanyCommonProps {
}

interface ThisState {

}


export class ViewCompanySideNav extends React.Component<ThisProps, ThisState> {
    constructor(props: ThisProps) {
        super(props);

        this.state = {}
    }

    handleSearchDomainObjects(value: string): void {
        this.setState({filterDomainObject: value});
    }

    render() {
        return (
            <Box padding={'20px'}>
                <Stack spacing={2}>
                    <Typography variant={'h6'}>
                        Stix Attributes
                    </Typography>
                    <Stack>
                        <ViewCompanyStix stix={this.props.company.value?.stix_attributes?.domain_objects}
                                         label={'Domain Objects'}/>
                        <ViewCompanyStix stix={this.props.company.value?.stix_attributes?.cyber_objects}
                                         label={'Cyber Objects'}/>

                    </Stack>
                </Stack>
            </Box>
        )
    }
}