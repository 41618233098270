import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    DialogContent,
    Divider,
    Step,
    StepButton,
    Stepper, Typography,
} from "@mui/material";
import {data} from "iso-3166-2";
import {
    TargetCountryData,
    TargetRegionData,
    TargetSIC,
} from "../../ThreatAnalysis/ViewReport/ReportAnalysis/Dialogs/TargetEntites/TargetEntitesForm";
import {Company, CompanyModel} from "../../../../services/company/company.model";
import CompanyService from "../../../../services/company/company.service";
import {ErrorDetails} from "../.../../../../Common/ErrorDialog/ErrorDialog";
import {ISICClassifier} from "../../../../services/ThreatAnalysis/threatAnalysis.model";
import {getData as SIC_DATA} from "sic-codes";
import ReportingService, {INTERFACE_TYPES,} from "../../../../services/stixx/stix-service";
import {StixSector} from "../../../../services/stixx/stixx.model";
import {GeneralInfoForm} from "./Forms/GeneralInfoForm";
import {OperationalOverviewForm} from "./Forms/OperationalOverviewForm";
import {RegionsForm} from "./Forms/RegionsForm";
import {SectorForm} from "./Forms/SectorForm";
import {DataCollectionForm} from "./Forms/DataCollectionForm";

import LocationCityIcon from "@mui/icons-material/LocationCity";
import DomainIcon from "@mui/icons-material/Domain";
import PublicIcon from "@mui/icons-material/Public";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DataObjectIcon from "@mui/icons-material/DataObject";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import {LoadingButton} from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import {Stack} from "@mui/system";
import {CheckCircleOutline, ErrorOutline} from "@mui/icons-material";

interface ThisProps {
    onError: (err: ErrorDetails) => void;
    onClose: (company: CompanyModel | null) => void;

    company: CompanyModel | null;
}

interface ThisState {
    activeStep: number;
    open: boolean;

    countries: TargetCountryData[];
    populations: TargetSIC[];
    regions: TargetRegionData[];

    errors: Record<keyof CompanyModel, string | null>;
    company: CompanyModel;
    existingCompanies: { pending: boolean; value: CompanyModel[] };
    sectors: { pending: boolean; value: StixSector[] };

    submitting: boolean;
}

export type CompanyModelKey = keyof CompanyModel;

export enum CompanyProfilerSteps {
    GeneralInfo,
}

export interface FormStyles {
    form_field_label: object;
}

type ValidationFn = () => void;

export interface CommonChildProps {
    onUpdate: (company: CompanyModel, validationFn: ValidationFn) => void;
    onError: (error: Record<keyof CompanyModel, string | null>) => void;
    errors: Record<keyof CompanyModel, string | null>;
    styles: FormStyles;
    company: CompanyModel;
}

export class CompanyDialog extends React.Component<ThisProps, ThisState> {
    private companyService: CompanyService = new CompanyService();
    private stixService: ReportingService = new ReportingService();
    private styles: FormStyles = {
        form_field_label: {fontWeight: "500", mb: "5px", opacity: ".9"},
    };

    constructor(props: ThisProps) {
        super(props);

        const errors: any = {};
        for (let key of Object.keys(new Company().toJSON())) {
            errors[key] = null;
        }

        this.state = {
            activeStep: 0,
            countries: [],
            errors: errors,
            company: props.company ? props.company : new Company().toJSON(),
            existingCompanies: {pending: true, value: []},
            regions: [],
            populations: [],
            sectors: {pending: true, value: []},
            submitting: false,
            open: true
        };
    }

    componentDidMount(): void {
        this.generateCountries();
        // this.handleGenerateSICCodes();
        this.handleFetchExistingCompanies();
        this.handleFetchSectors();
    }

    handleFetchSectors(): void {
        this.stixService
            .getStixInterfaces<StixSector[]>(INTERFACE_TYPES.sectors)
            .then((res) => this.setState({sectors: {value: res, pending: false}}))
            .catch((err) => {
                this.setState({sectors: {value: [], pending: false}});
                this.props.onError(err);
            });
    }


    generateCountries(): void {
        const countries = [];
        const regions = [];
        for (let country_iso_code of Object.keys(data)) {
            countries.push({
                country: data[country_iso_code].name,
                iso_code: country_iso_code,
            });
            for (let sub_iso_code of Object.keys(data[country_iso_code]["sub"])) {
                regions.push({
                    country: data[country_iso_code].name,
                    country_iso_code: country_iso_code,
                    region: data[country_iso_code].sub[sub_iso_code].name,
                    region_iso_code: sub_iso_code.split("-")[1],
                });
            }
        }

        this.setState({countries: countries, regions: regions});
    }

    handleNext = () => {
        this.setState({
            activeStep: this.state.activeStep + 1,
        });
    };

    handleBack = () => {
        this.setState({activeStep: this.state.activeStep - 1});
    };

    handleUpdate = (company: CompanyModel, validationFn: ValidationFn): void => {
        this.setState({company: company}, () => {
            validationFn();
        });
    };

    handleError = (errors: Record<keyof CompanyModel, string | null>): void => {
        this.setState({errors: {...errors}});
    };

    handleReset = () => {
        this.setState({activeStep: 0});
    };

    handleFetchExistingCompanies(): void {
        this.setState({existingCompanies: {pending: false, value: []}})
    }

    handleOnSubmit(): void {
        this.setState({submitting: true});


        if (!this.state.company?.id) {
            this.companyService.createCompany(this.state.company?.name)
                .then((res) => {
                    this.setState({company: {...this.state.company, id: res.id}}, () => this.handleUpdateCompany())
                })
                .catch((err) => {
                    this.props.onError(err);
                    this.setState({submitting: false});
                });
        } else {
            this.handleUpdateCompany();
        }



    }


    handleUpdateCompany(): void {
        this.companyService.updateCompany({...this.state.company})
            .then((res) => {
                this.props.onClose(res);
                this.setState({open: false})
            })
            .catch((err) => {
                this.props.onError(err);
                this.setState({submitting: false});
            });

    }




    isGeneralFormValid = () => {
        const keys: CompanyModelKey[] = [
            "name",
            "contact_information",
            "x_country_of_origin",
            "x_employee_count",
            "x_organization_type",
            "x_annual_revenue",
            "x_asset_value",
        ];


        return (
            keys.filter(
                (key) =>
                    this.state.errors[key as keyof typeof this.state.errors] !==
                    null
            ).length === 0
        );
    }

    render(): React.ReactNode {
        const steps = [
            {
                label: "General Info",
                component: GeneralInfoForm,
                props: {
                    countries: this.state.countries,
                },
                icon: LocationCityIcon,
                valid: this.isGeneralFormValid(),
            },
            {
                label: "Sectors",
                component: SectorForm,
                props: {sectors: this.state.sectors},
                icon: DomainIcon,
                valid: this.state.company.sectors?.length > 0,
            },
            {
                label: "Data Resources",
                component: DataCollectionForm,
                props: {},
                icon: DataObjectIcon,
                valid: this.state.company.x_information_resources?.length > 0,
            },
            {
                label: "Regions",
                component: RegionsForm,
                props: {regions: this.state.regions},
                icon: PublicIcon,
                valid: this.state.company.x_regions?.length > 0,
            },
            {
                label: "Impact Assessment",
                component: OperationalOverviewForm,
                props: {},
                icon: AssessmentIcon,
                valid: true,
            },
        ];

        const formHeight = "550px";

        const ActiveComponent = steps[this.state.activeStep]
            .component as React.ElementType;
        const activeProps = steps[this.state.activeStep].props;

        const isLoading =
            this.state.existingCompanies.pending || this.state.sectors.pending;

        const {state} = this;

        return (
            <Container>
                <Box>
                    <Dialog open={this.state.open} maxWidth={"lg"} fullWidth>
                        <DialogTitle sx={{mb: 2}}>Company Profile</DialogTitle>

                        <DialogContent>
                            <Stepper
                                sx={{mb: 3}}
                                nonLinear
                                activeStep={this.state.activeStep}
                            >
                                {steps.map((s, index) => {
                                    return (
                                        <Step sx={{width: "100%"}} key={s.label}>
                                            <StepButton
                                                color="inherit"
                                                disabled={!steps[index].valid || isLoading}
                                                onClick={(e) => this.setState({activeStep: index})}
                                            >
                                                <Stack direction={'row'} alignItems={'center'} gap={1}>
                                                    <Typography variant={'body2'}>{s.label}</Typography>

                                                    {
                                                        !s.valid ? <ErrorOutline color={'error'} /> :
                                                            <CheckCircleOutline color={'success'} />
                                                    }




                                                </Stack>

                                            </StepButton>
                                        </Step>
                                    );
                                })}
                            </Stepper>

                            <Divider/>

                            <React.Fragment>
                                <Box
                                    sx={{height: formHeight, padding: "30px 0", position: "re"}}
                                >
                                    {!isLoading && (
                                        <ActiveComponent
                                            styles={this.styles}
                                            errors={this.state.errors}
                                            onError={this.handleError.bind(this)}
                                            onUpdate={this.handleUpdate.bind(this)}
                                            company={this.state.company}
                                            {...activeProps}
                                        />
                                    )}
                                    {isLoading && (
                                        <Box
                                            sx={{
                                                width: "100%",
                                                height: "70%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <CircularProgress/>
                                        </Box>
                                    )}
                                </Box>

                                <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
                                    <Button
                                        color="inherit"
                                        disabled={this.state.activeStep === 0}
                                        onClick={this.handleBack}
                                        sx={{mr: 1}}
                                    >
                                        Back
                                    </Button>
                                    <Box sx={{flex: "1 1 auto"}}/>

                                    <Button
                                        sx={{
                                            color: (theme) => theme.palette.text.primary,
                                            opacity: ".7",
                                            marginRight: 2,
                                        }}
                                        disabled={isLoading}
                                        onClick={() => {
                                            this.props.onClose(null);
                                            this.setState({open: false})
                                        }}
                                    >
                                        cancel
                                    </Button>

                                    {this.state.activeStep === steps.length - 1 ? (
                                        <LoadingButton
                                            size="small"
                                            color="primary"
                                            onClick={this.handleOnSubmit.bind(this)}
                                            loading={this.state.submitting}
                                            loadingPosition="end"
                                            endIcon={<SaveIcon/>}
                                            variant="contained"
                                            disabled={
                                                (this.state.activeStep === steps.length - 1 &&
                                                    Object.keys(
                                                        this.state.errors as { [key: string]: any }
                                                    ).filter((key) => {
                                                        return (
                                                            this.state.errors[
                                                                key as keyof typeof state.errors
                                                                ] !== null
                                                        );
                                                    }).length > 0) ||
                                                this.state.submitting
                                            }
                                        >
                                            <span>Save</span>
                                        </LoadingButton>
                                    ) : (
                                        <Button
                                            disabled={
                                                (this.state.activeStep !== steps.length - 1 &&
                                                    !steps[this.state.activeStep].valid) ||
                                                this.state.submitting ||
                                                isLoading
                                            }
                                            onClick={this.handleNext.bind(this)}
                                        >
                                            Next
                                        </Button>
                                    )}
                                </Box>
                            </React.Fragment>
                        </DialogContent>
                    </Dialog>
                </Box>
            </Container>
        );
    }
}
