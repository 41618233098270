import React from "react";
import {ListView} from "../../../../../Common/ListView/ListView";
import {SharedViewAnalysisChildrenProps} from "../ViewAnalysis";


interface ViewSourcesProps extends SharedViewAnalysisChildrenProps {
    doesEntryExistInText: (value: string) => boolean;
}

interface ViewSourcesState {
}

export default class ViewSources extends React.Component<
    ViewSourcesProps,
    ViewSourcesState
> {
    constructor(props: ViewSourcesProps) {
        super(props);
    }

    render(): React.ReactNode {

        return <ListView entries={this.props.entries} styles={this.props.styles}/>;
    }
}
