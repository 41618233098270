import React, {ReactNode} from "react";
import {StixSector} from "../../../../../../../services/stixx/stixx.model";
import {ReportAnalysis} from "../../../../../../../services/ThreatAnalysis/threatAnalysis.model";
import {SectorSelector} from "../../../../../../Common/Selectors/SectorSelector";

interface ThisProps {
    analysis: ReportAnalysis;
    sectors: { pending: boolean; value: StixSector[] };

    onUpdate: (key: keyof ReportAnalysis, value: any[]) => void;
    onDelete: (key: keyof ReportAnalysis, value: any) => void;
    doesEntryExistInText: (text: string) => boolean;
}

interface ThisState {
    input: StixSector | null;
    reset: string;
}

export class TargetSectors extends React.Component<ThisProps, ThisState> {
    private _key: keyof ReportAnalysis = "target_industry_sectors";

    constructor(props: ThisProps) {
        super(props);
        this.state = {
            input: null,
            reset: new Date().toISOString(),
        };
    }

    render(): ReactNode {
        return (
            <SectorSelector
                includeAdhoc={true}
                choices={this.props.sectors}
                sectors={this.props.analysis?.target_industry_sectors}
                onUpdate={(v: any[]) => this.props.onUpdate(this._key, v)}
                onDelete={(v: number) => this.props.onDelete(this._key, v)}
                doesEntryExistInText={this.props.doesEntryExistInText.bind(this)}
            />
        );
    }
}
