import React from "react";
import {lighten, Stack} from "@mui/system";
import {ThreatActor} from "../../../../../services/ThreatActor/threatActor.model";
import {Button, Checkbox, Collapse, Paper, Typography, useTheme} from "@mui/material";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from "recharts";
import toTitleCase from "../../../../Common/Utils/AutoCompleteOptions/toTitleCase";
import Grid from "@mui/material/Unstable_Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {TransitionGroup} from "react-transition-group";
import ListItemText from "@mui/material/ListItemText";

interface ThisProps {
    threatActor: ThreatActor
}


const COLORS = [
    '#FF6E40',  // Coral
    '#FFD460',  // Golden Poppy
    '#4CAF50',  // Medium Sea Green
    '#00BFFF',  // Deep Sky Blue
    '#FF4081',  // Pink
    '#9575CD',  // Medium Purple
    '#FF9800',  // Orange
    '#E040FB',  // Purple
    '#03A9F4',  // Light Blue
    '#CDDC39'   // Lime
];


const CustomLegend: React.FC<{ payload: any[] }> = ({payload}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginLeft: 20,
            alignItems: 'center',
            marginBottom: '30px'
        }}>
            {payload.map((entry, index) => {
                return <div key={`item-${index}`} style={{
                    marginRight: 15, fontSize: '13px',
                }}>
                    <span style={{
                        display: 'inline-block',
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: entry.color
                    }}></span>
                    {toTitleCase(entry.payload.sector)}
                </div>
            })}
        </div>
    );
};


const ThreatActorSectors: React.FC<ThisProps> = (props) => {
    const theme = useTheme();

    return (
        <Grid container component={Paper} height={'100%'} width={'100%'} elevation={6}>
            <Grid xs={12}>
                <Paper sx={{flexGrow: 1}}>
                    <Stack paddingY={2} paddingX={1} spacing={2} direction={'row'} alignItems={'center'}>
                        <Typography variant={'h6'}>Target Industry Sectors</Typography>
                    </Stack>
                </Paper>
            </Grid>
            <Grid sx={{height: '100%'}} component={Paper} xs={12}>
                <List sx={{overflow: 'hidden', overflowY: 'auto', height: `calc(100% - ${theme.spacing(17)})`}}>
                    <ListItem divider/>
                    {
                        props.threatActor.target_industry_sectors?.map((sector, index) =>
                            <ListItem
                                key={index}
                                sx={{
                                    backgroundColor: index % 2 === 1 ? theme.palette.background.default : lighten(theme.palette.background.paper, .1),
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: theme.palette.action.hover
                                    }
                                }}
                                divider
                            >
                                <ListItemText sx={{width: '100%'}}>{toTitleCase(sector.name)}</ListItemText>

                            </ListItem>
                        )
                    }

                    {
                        props.threatActor.target_industry_sectors?.length == 0 &&
                        <ListItem
                            sx={{
                                width: '100%',
                                height: '100%',
                                textAlign: 'center',
                                color: theme.palette.text.secondary,
                            }}
                            divider
                        >
                            <ListItemText sx={{width: '100%',}}>
                                <Typography fontWeight={600}>No Known Target Sectors</Typography>
                            </ListItemText>

                        </ListItem>
                    }
                </List>
            </Grid>
        </Grid>
    );
}

export default ThreatActorSectors