import React from "react";
import CompanyParametersView from "./CompanyParametersView";
import {CompanyParameterModel} from "../../../services/company/company-parameter.model";
import {Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import {Stack} from "@mui/system";
import {Close} from "@mui/icons-material";

interface ThisProps {
    parameters: CompanyParameterModel[];
    onRowSelect: (row: CompanyParameterModel) => void;
    onClose: () => void;
}

interface ThisState {
}

export class CompanyParameterSelector extends React.Component<ThisProps, ThisState> {
    constructor(props: ThisProps) {
        super(props);
        this.state = {}
    }


    render() {
        return (
            <Dialog open={true} maxWidth={'lg'} fullWidth>
                <DialogTitle>
                    <Stack direction={'row'}>
                        <Typography variant={'h5'} flexGrow={1}>Select Parameter</Typography>
                        <IconButton color={'error'} onClick={() => this.props.onClose()}>
                            <Close/>
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <CompanyParametersView
                        asSelector
                        parameters={this.props.parameters}
                        onRowSelect={(row: CompanyParameterModel) => this.props.onRowSelect(row)}
                        disableActionButtons={true}
                    />
                </DialogContent>
            </Dialog>
        )
    }

}