import {ApiService, PaginationRequest} from "../api-service";
import {PaginationResult} from "../globals";
import {
    MissingAttackerModel,
    MissingVictimModel,
    ReportedAttackModel,
    ReportedAttackStats
} from "./reportedAttack.model";


export default class ReportedAttackService extends ApiService {

    public async getReportedAttacks(pagination: PaginationRequest, days: number | null): Promise<PaginationResult<ReportedAttackModel>> {
        let query_params: any = {
            offset: pagination.page * pagination.rowsPerPage,
            limit: pagination.rowsPerPage,
            days: days
        };

        return super.get<PaginationResult<ReportedAttackModel>>(`reported-attack/`, query_params);
    }


    public async getMissingAttackers(pagination: PaginationRequest): Promise<PaginationResult<string>> {
        let query_params: any = {
            offset: pagination.page * pagination.rowsPerPage,
            limit: pagination.rowsPerPage
        };

        return super.get<PaginationResult<string>>(`reported-attack/attacker/`, query_params);
    }


    public async getMissingCompanies(pagination: PaginationRequest): Promise<PaginationResult<MissingVictimModel>> {
        let query_params: any = {
            offset: pagination.page * pagination.rowsPerPage,
            limit: pagination.rowsPerPage
        };

        return super.get<PaginationResult<MissingVictimModel>>(`reported-attack/victim/`, query_params);
    }


    public async getReportedAttackStats(days: number | null): Promise<ReportedAttackStats> {
        let queryParams = {
            days: days
        }

        return super.get<ReportedAttackStats>(`reported-attack/stats/`, queryParams);
    }


}
