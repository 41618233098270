import React from "react";
import {Stack} from "@mui/system";
import {CompanyRisk} from "../../../../services/company/company.model";
import CompanyRiskTable from "./RiskTable";
import {RiskHighCount} from "./RiskHighCount";

interface ThisProps {
    companyRisk: { value: CompanyRisk[], pending: boolean },
    selectedCompanyRisk: CompanyRisk | null;
    setSelectedCompanyRisk: (r: CompanyRisk | null) => void;
}

export type Order = 'asc' | 'desc';


const RiskThreats: React.FC<ThisProps> = (props: ThisProps) => {

    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof CompanyRisk>('threat_actor_relationship_identified');

    return (
        <Stack sx={{height: theme => `calc(100vh - ${theme.spacing(16)})`}} gap={1}>
            <RiskHighCount setOrderBy={(k: keyof CompanyRisk) => {
                setOrderBy(k);
                setOrder('desc')
            }} companyRisk={props.companyRisk}/>
            <CompanyRiskTable order={order} setOrder={setOrder} setOrderBy={setOrderBy} orderBy={orderBy}
                              companyRisk={props.companyRisk}
                              selectedCompanyRisk={props.selectedCompanyRisk}
                              setSelectedCompanyRisk={(i) => props.setSelectedCompanyRisk(i)}/>
        </Stack>
    );
}

export {RiskThreats};
