import {CompanyThreatRansomwarePrediction} from "../../../../services/company/company.model";
import React, {useEffect} from "react";
import {lighten, Stack} from "@mui/system";
import {Card, CardContent, darken, IconButton, ListItemText, Theme, Typography, useTheme} from "@mui/material";
import {HelpOutline, PersonSearch} from "@mui/icons-material";
import toTitleCase from "../../../Common/Utils/AutoCompleteOptions/toTitleCase";
import {useModal} from "mui-modal-provider";
import ThreatActorDialog from "../ThreatActorDialog/ThreatActorDialog";
import {TopRansomwareGroupsExplainedDialog} from "./TopRansomwareGroupsExplained";
import {getOptionLabelForHML, TenPointScaleNumerical} from "../../../../services/globals";
import {AppChip} from "../../../Common/common";

interface Props {
    predictions: CompanyThreatRansomwarePrediction[]
}

export const TopRansomwareGroupsTableComponent = ({predictions}: Props) => {
    const {showModal} = useModal();
    const theme = useTheme();

    const viewAdversary = (actorId: string) => {
        showModal(ThreatActorDialog, {
            threatActorID: actorId,
            cachedActor: null
        })
    }

    const viewReasoning = (prediction: CompanyThreatRansomwarePrediction) => {
        showModal(TopRansomwareGroupsExplainedDialog, {
            prediction: prediction,
        })
    }

    return (
        <Stack spacing={2}>
            {
                predictions.map((prediction, index) => {
                    return (
                        <Card
                            elevation={1}
                            key={index}
                            sx={{
                                // cursor: "pointer",
                                "&:hover": {
                                    background: (theme: Theme) =>
                                        lighten(theme.palette.background.default, 0.15),
                                },
                                borderLeft: (theme) =>  '2px solid ' + theme.palette[prediction.safe ? 'success' : 'error'].main
                            }}>
                            <CardContent sx={{paddingBottom: 0, paddingY: theme.spacing(1), paddingX: theme.spacing(2)}}>
                                <Stack gap={2} direction={'column'}>
                                    <Stack alignItems='center' direction={'row'}>
                                        <Typography flex={1} gutterBottom fontSize={16} fontWeight={600} component="div">
                                            {toTitleCase(prediction.threat_actor)}
                                        </Typography>

                                        <IconButton onClick={() => viewReasoning(prediction)} size={'small'} color={'primary'}>
                                            <HelpOutline />
                                        </IconButton>
                                        <IconButton onClick={() => viewAdversary(prediction.threat_actor_id)}
                                                     size={'small'} color={'primary'}>
                                            <PersonSearch />
                                        </IconButton>
                                    </Stack>

                                    <CustomProgressBarWithLabels value={prediction.scale} />

                                </Stack>
                            </CardContent>
                        </Card>
                    )
                })
            }
            {
                predictions.length == 0 &&
                <Card sx={{
                    opacity: .75,
                    borderLeft: (theme) => '2px solid ' + theme.palette.primary.main
                }}>
                    <CardContent>
                        <ListItemText>
                            No Ransomware Predictions
                        </ListItemText>
                    </CardContent>
                </Card>
            }
        </Stack>
    );
}


const interpolateColor = (value: number, max: number, theme: Theme): string => {
    // Calculate the ratio of value
    const ratio = value / max;
    const hex = (color: number): string => {
        const hexValue = color.toString(16);
        return hexValue.length === 1 ? `0${hexValue}` : hexValue;
    };
    // Interpolate red and green values
    const red = Math.ceil((255 * ratio) + (0 * (1 - ratio)));
    const green = Math.ceil((0 * ratio) + (155 * (1 - ratio)));
    const blue = 0; // Keeping blue constant

    return `#${hex(red)}${hex(green)}${hex(blue)}`;
};

const CustomProgressBarWithLabels = ({ value }: { value: number }) => {
    const theme = useTheme();
    const totalBlocks = 9;

    const delayInMs = 250

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
            {/* Container for AppChips */}
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '4px'}}>
                {Array.from({ length: totalBlocks }, (_, i) => (
                    <div key={i} style={{
                        textAlign: 'center'}}>
                        {/* Render AppChip for "Extremely Low" at the start, but account for "None" differently */}
                        {(value > 0 && i === value - 1) && (
                            <AppChip
                                sx={{ background: interpolateColor(value - 1, totalBlocks - 1, theme) }}
                                size={'small'}
                                label={<Typography variant={'caption'}>{getOptionLabelForHML(value)}</Typography>}
                            />
                        )}

                        {value === 0 && i === 0 && <AppChip
                            size={'small'}
                            label={<Typography variant={'caption'}>No Threat</Typography>}
                        />}
                    </div>
                ))}
            </div>
            {/* Container for blocks */}
            <div style={{ display: 'flex', gap: '2px', justifyContent: 'space-between' }}>
                {Array.from({ length: totalBlocks }, (_, i) => (
                    <div
                        key={i}
                        style={{
                            width:  Math.floor(100 / totalBlocks) + '%',
                            height: '12px',
                            background: `linear-gradient(to right, ${ i < value ? interpolateColor(i, totalBlocks - 1, theme) : theme.palette.action.hover} 50%, ${theme.palette.action.hover} 0)`,
                            borderRadius: '15px',
                            display: 'block',
                            backgroundSize: '200% 100%',
                            backgroundPosition: 'right',
                            animation: i < value ?  `makeItfadeIn ${delayInMs}ms 1s forwards` : undefined,
                            animationDelay: `${(delayInMs * i )}ms`
                        }}
                    />
                ))}
            </div>
        </div>
    );
};