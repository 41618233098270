import {useModal} from "mui-modal-provider";
import SimpleSnackbar from "../components/Common/SnackBar/SnackBar";


export const useClipboard = () => {
    const {showModal} = useModal();

    const copy = (content: any) => {

        if (typeof content == 'object')
            content = JSON.stringify(content);

        navigator.clipboard.writeText(content)

        showModal(SimpleSnackbar, {message: "Copied to clipboard"})

    }


    return {copy}
}