import React from "react";
import {ListView} from "../../../../../Common/ListView/ListView";
import {SharedViewAnalysisChildrenProps} from "../ViewAnalysis";
import {Box} from "@mui/material";

interface ViewThreatActorProps extends SharedViewAnalysisChildrenProps {

}

interface ViewThreatActorState {
}

export default class ViewThreatActor extends React.Component<
    ViewThreatActorProps,
    ViewThreatActorState
> {
    constructor(props: ViewThreatActorProps) {
        super(props);

        this.state = {};
    }

    render(): React.ReactNode {
        return <ListView entries={this.props.entries} styles={this.props.styles}/>;
    }
}
