import {
    MissingAttackerModel,
    MissingVictimModel,
    ReportedAttackModel, ReportedAttackStats
} from "../../../../services/ReportedAttacks/reportedAttack.model";
import {Box} from "@mui/system";
import {Card, Paper, Stack, Typography, useTheme} from "@mui/material";
import React from "react";
import {PeopleOutline, Person, PestControl, Public} from "@mui/icons-material";


interface Props {
    data:{pending: boolean, value: ReportedAttackStats | null, days: null | number}
}

export const ReportedAttackStatsComponent = (props: Props) => {
    return (
        <Stack spacing={2} direction={'row'}>
            <StatCard loading={props.data.pending} title={props.data?.value?.total_attacks.toString() || '0'} subTitle={"Total Attacks"} Icon={PestControl} color={'error'}/>
            <StatCard loading={props.data.pending} title={props.data?.value?.unique_adversaries.toString() || '0'} subTitle={"Unique Adversaries"} Icon={PeopleOutline} color={'error'}/>
            <StatCard loading={props.data.pending} title={props.data?.value?.most_active_adversary?._id?.toString() || '----'} subTitle={"Most Active Adversary"} Icon={Person} color={'error'}/>
            <StatCard loading={props.data.pending} title={props.data?.value?.most_targeted_country?._id?.toString() || '0'} subTitle={"Most Targeted Country"} Icon={Public} color={'error'}/>
        </Stack>
    )
}





interface InfoCardProps {
    title: string;
    Icon: any;
    color: string;
    loading: boolean;
    subTitle: string;
}
const StatCard = ({title, Icon, loading, color, subTitle}: InfoCardProps) => {
    const theme = useTheme();
    return (
        <Box sx={{flex: 1, opacity: loading ? .5: 1,
            transition: '500ms opacity ease-in-out',
            animation: loading ? 'pulse 1.5s ease-in-out infinite' : 'none', // Apply animation when loading
            '@keyframes pulse': { // Define keyframes for pulsing animation
                '0%, 100%': { opacity: .8 },
                '50%': { opacity: 0.5 }
            }}}
             component={Paper}>
            <Stack alignItems={'center'} spacing={2} direction={'row'} sx={{paddingY: theme.spacing(2), paddingX: theme.spacing(1)}}>
                <Icon fontSize={'large'} color={color} />
                <Stack spacing={.5}>
                    <Typography variant={'h6'}>{title}</Typography>
                    <Typography variant={'caption'} color={'text.secondary'}>{subTitle}</Typography>
                </Stack>
            </Stack>

        </Box>
    )
}