import ReportIncidentService from "./reportIncident.service";
import {StixDict, StixObject} from "../stixx/stixx.sdo.model";
import {StixModel} from "../stixx/stixx.model";

export interface ReportedIncidentModel {
    _id: string;
    stix_type: string;
    value: string;
    stix: StixModel;
    added_on: string;
    status: ReportIncidentStatus;
    investigation_notes: string;
    investigation_status_changed_by: string;
    investigation_status_updated_on: string;
}


export enum ReportIncidentStatus {
    Pending = 'Pending',
    Open = 'Open',
    Closed = 'Closed'
}