import {MissingVictimModel} from "../../../../services/ReportedAttacks/reportedAttack.model";
import {PaginatedList, PaginatedListRow, PaginatedListText} from "../../../Common/PaginatedList";
import {Button, IconButton, Typography} from "@mui/material";
import toTitleCase from "../../../Common/Utils/AutoCompleteOptions/toTitleCase";
import React from "react";
import {Box, Stack} from "@mui/system";
import {AppChip} from "../../../Common/common";
import LinearProgress from "@mui/material/LinearProgress";
import {useClipboard} from "../../../../hooks/useClipboard";
import {Company} from "../../../../services/company/company.model";
import SimpleSnackbar from "../../../Common/SnackBar/SnackBar";
import {AddBusiness, UploadFile} from "@mui/icons-material";
import {useModal} from "mui-modal-provider";
import ThreatAnalysisDialog, {ThreatAnalysisDialogActions} from "../ThreatAnalysisDialog";
import {Routes, useNavigate} from "react-router-dom";
import {AppLinks} from "../../SideNav/SideNav";

interface Props {
    data: {pending: boolean, value: string[], fetching: boolean}
    pagination: { page: number, rowsPerPage: number, totalRecords: number };
    loading: boolean;
    setPagination: (page: number, rowsPerPage: number) => void;
}

export const ReportedAttackAttackerTable = (props: Props) => {
    const {showModal} = useModal();
    const navigate = useNavigate();
    const clipboard = useClipboard();
    const columns: PaginatedListRow<string>[] = [
        {
            label: 'Adversary',
            render: (row: string) => {
                return (
                    <PaginatedListText>{toTitleCase(row)}</PaginatedListText>
                )
            },
            width: '90%'
        },
        {
            label: 'Btn',
            render: (row: MissingVictimModel) => {
                return (
                    <IconButton onClick={(e) => {
                        e.stopPropagation();
                        showModal(ThreatAnalysisDialog, {
                            action: ThreatAnalysisDialogActions.CREATE,
                            onClose: (report) => {
                                if (report) {

                                    showModal(SimpleSnackbar, {message: (
                                        <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'} spacing={2}>
                                            <Typography>Successfully started report</Typography>
                                            <Button size={'small'} onClick={() => {
                                                navigate(`/${AppLinks.THREAT_ANALYSIS}/${report._id}`)
                                            }}>
                                                View
                                            </Button>
                                        </Stack>
                                    )})
                                }
                            }
                        })
                    }} color={'primary'} size={'small'}>
                        <UploadFile />
                    </IconButton>
                )
            },
            width: '10%'
        }
    ]

    return (
        <Stack spacing={1} sx={{height: '100%'}} >
            <Stack gap={1}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant={'h6'} fontWeight={600}>Missing Adversaries</Typography>
                    <AppChip size={'small'} color={'primary'} label={props.pagination.totalRecords.toString() || '0'}/>
                </Stack>
                <LinearProgress value={props.data.pending || props.data.fetching ? 100 : 0}
                                variant={props.data.pending || props.data.fetching ? 'indeterminate' : 'determinate'} color="secondary"/>

            </Stack>
            <PaginatedList
                data={props.data}
                columns={columns}
                onRowClick={(row) => {
                    clipboard.copy(row)
                }}
                loading={props.loading}
                pagination={props.pagination}
                setPagination={props.setPagination}
            />
        </Stack>
    )

}