import {ApiService, PaginationRequest} from "../api-service";
import {ReportAnalysis, ReportApprovalStatus, ReportModel} from "./threatAnalysis.model";
import {PaginationResult} from "../globals";


export default class ThreatAnalysisService extends ApiService {
    constructor() {
        super();
    }

    public async getReport(reportID: string): Promise<ReportModel> {
        return super.get<ReportModel>(`report/${reportID}`);
    }

    public async getReports(pagination: PaginationRequest, approvalStatus?: ReportApprovalStatus[] | null, name?: string, recent?: boolean): Promise<PaginationResult<ReportModel>> {
        let query_params: any = {
            offset: pagination.page * pagination.rowsPerPage,
            limit: pagination.rowsPerPage,
            recent: recent,
            name: name
        };


        if (approvalStatus?.length) {
            query_params['approvalStatus'] = approvalStatus.join(',')
        }

        return super.get<PaginationResult<ReportModel>>(`report`, query_params);
    }

    public async createReport(request: {
        name: string;
        website_url: string | null;
        file: any;
        _id: string | null;
        clear_cache: boolean;
        report_date: string;
    }): Promise<ReportModel> {
        return super.post<ReportModel>(`report`, request, {
            "Content-Type": "multipart/form-data",
        });
    }

    public async runPartialReport(request: {
        report_id: string;
        analysis_id: string;
        attributes: string;
    }): Promise<ReportModel> {
        return super.put<ReportModel>(`report`, request);
    }

    public async updateReport(report: ReportModel): Promise<ReportModel> {
        return super.put<ReportModel>(`report/${report._id}`, report);
    }

    public async toggleApprovalStatus(
        analysis_id: string,
        message: string,
        status: ReportApprovalStatus
    ): Promise<void> {
        return super.put<void>(`analysis/${analysis_id}/status/${status}`, {
            approval_message: message,
        });
    }

    public async updateAnalysis(
        analysis: ReportAnalysis,
        rational: string
    ): Promise<ReportAnalysis> {
        return super.put<ReportAnalysis>(`analysis/${analysis._id}`, {analysis: analysis, rationale: rational});
    }

    public async deleteAnalysis(analysis_id: string): Promise<void> {
        return super.delete<void>(`report/${analysis_id}`);
    }

    public async getAnalyses(approvalStatus?: ReportApprovalStatus): Promise<ReportAnalysis> {
        let query_params = null;

        if (approvalStatus) {
            query_params = {approvalStatus: approvalStatus};
        }

        return super.get('analysis', query_params)
    }



    public async lockThreatReport(report_id: string): Promise<void> {
        return super.put(`report/${report_id}/lock/`, null);
    }

    public async acquireThreatReportLock(report_id: string): Promise<void> {
        return super.post(`report/${report_id}/lock/`, null);
    }

    public async removeThreatReportLock(report_id: string): Promise<void> {
        return super.delete(`report/${report_id}/lock/`);
    }




    public async pollReportLock(report_id: string): Promise<ReportModel> {
        // only return metadata about lock
        return super.get(`report/${report_id}/lock/`);
    }
}
