import { Backdrop, CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { AuthProvider } from "../provider/AuthProvider";

const ProtectedRoute = ({ children }: any) => {
 const {user} = useContext(AuthContext);

 if (user === null) {
    return <Navigate to="/login" />;
 }

  
  return children;
};



export default ProtectedRoute;