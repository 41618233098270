class MitreAttackService {
    mitreAttackURL = 'https://attack.mitre.org/';

    routeToGroupPage(groupID: string): void {
        const url = this.mitreAttackURL + 'groups/' + groupID;
        window.open(url, '_blank')
    }

    routeToTacticPage(id: string): void {
        const tactic = id.split(".");
        let url = this.mitreAttackURL + 'techniques/'
        for (let t of tactic) {
            url += `${t.toUpperCase()}/`
        }

        window.open(url, '_blank')
    }

    routeToMitigationPage(id: string): void {
        const tactic = id.split(".");
        let url = this.mitreAttackURL + 'mitigations/'
        for (let m of tactic) {
            url += `${m.toUpperCase()}/`
        }

        window.open(url, '_blank')
    }


}


export default MitreAttackService;