import {Box, Button, darken, Stack, Toolbar, Typography, useTheme} from "@mui/material";
import UserService from "../../../services/User/user.service";
import React, {useContext, useEffect, useState} from "react";
import {useModal} from "mui-modal-provider";
import {User} from "../../../services/User/user.model";
import {ErrorDialog} from "../../Common/ErrorDialog/ErrorDialog";
import {UserTableComponent} from "./UserTable";
import {AppToolbar} from "../../Common/Drawer/NestedDrawer";
import {AppChip, AppTitle} from "../../Common/common";
import {Add, Delete, DeleteOutline, EditOutlined, Remove} from "@mui/icons-material";
import {DebouncedTextField} from "../../Common/DebouncedTextField";
import CompanyProfilerTable from "../CompanyProfiler/CompanyProfilerTable";
import DrawerStateContext from "../../../context/DrawerContext";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {AddUserDialog} from "./AddUserDialog";
import SimpleSnackbar from "../../Common/SnackBar/SnackBar";
import {DeleteUserDialog} from "./DeleteUserDialog";
import {ResetUserPasswordDialog} from "./ResetUserPasswordDialog";

export const UserViewComponent = () => {
    const userService = new UserService();
    const appContext = useContext(DrawerStateContext);

    const {showModal} = useModal();

    const [users, setUsers] =
        useState<{value: User[], pending: boolean}>({value: [], pending: true})

    const [filter, setFilter] = useState('')
    const [selected, setSelected] = useState<string[]>([])

    useEffect(() => {
        getUsers();
    }, []);


    const getUsers = () => {
        userService.getUsers()
            .then(res => setUsers({value: res, pending: false}))
            .catch(err => {
                showModal(ErrorDialog, {error: err})
                setUsers({pending: false, value: []})
            })
    }


    const handleAfterUserClosed = (created: boolean, result: null | User) => {
        if (result) {
            showModal(SimpleSnackbar, {message: `${created ? "Added" : "Updated"} user ${result.email}`})

            const index = users.value.findIndex((u) => u.firebase_id === result?.firebase_id);
            if (index === -1) {
                setUsers({...users, value: [result, ...users.value]})
            } else {
                const copy = [...users.value]
                copy[index] = result
                setUsers({...users, value: copy})
            }

        }
    }


    const onDelete = (user: User) => {
        showModal(DeleteUserDialog, {user: user, onClose: (removed) => {
            if (removed) {
                showModal(SimpleSnackbar, {message: `Removed ${user.email}`})
                setUsers({...users, value: users.value.filter(u => u.firebase_id !== user.firebase_id)})
            }
        }})

    }

    const onEdit = (user: User) => {
        showModal(AddUserDialog, {user: user, onClose:  (res) => handleAfterUserClosed(false, res)})
    }

    const onResetPassword = (user: User) => {
        showModal(ResetUserPasswordDialog, {user: user, onClose: (wasReset: boolean) => {
                wasReset && showModal(SimpleSnackbar, {message: `Password reset link sent to ${user.email}`})
        }})
    }


    return (
        <Box display={'flex'} flexDirection={'column'} sx={{overflow: 'hidden'}}>
            <AppToolbar open={appContext.isDrawerOpen}>
                <Toolbar disableGutters>
                    <Stack width={'100%'} alignItems={'center'} justifyContent={'space-between'} direction={"row"}>
                        <AppTitle variant={'h5'}>
                            User Management
                        </AppTitle>
                        <Stack gap={2} direction={'row'} alignItems={'center'}>


                            <Button
                                onClick={() => showModal(AddUserDialog, {onClose: (res) => handleAfterUserClosed(true, res)})}
                                size={'small'}
                                disabled={users.pending}
                                color="secondary"
                                variant={'contained'}
                                endIcon={<Add/>}
                            > Add</Button>
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppToolbar>


            <Stack
                sx={{
                    mt: theme => theme.spacing(8),
                    height: theme => `calc(100vh - ${theme.spacing(12)})`
                }}
                spacing={2}>
                <DebouncedTextField disabled={users.pending}
                                    onValueChanged={(f) => setFilter(f)}
                                    filter={filter}
                                    debounce={0}
                                    placeholder={'Search for users'}
                                    onSearch={(value) => {
                                        // setFilter(value)
                                    }}
                />

                <UserTableComponent users={users}
                                    filter={filter}
                                    onDelete={onDelete}
                                    onResetPassword={onResetPassword}
                                    onEdit={onEdit}

                                    />


            </Stack>
        </Box>
    )
}