import { Backdrop, CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AnonymousAuthContext from "./context/AnonymousAuthContext";

const AnonProtectedRoute = ({ children }: any) => {
    const {token} = useContext(AnonymousAuthContext);

    if (!token.pending && !token.value)
     return <Navigate to="/login" />;

    return children;
};



export default AnonProtectedRoute;