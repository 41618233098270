import {User} from "../../../services/User/user.model";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme} from "@mui/material";
import React, {useState} from "react";
import UserService from "../../../services/User/user.service";
import {LoadingButton} from "@mui/lab";
import {Add, DeleteOutline, LockReset} from "@mui/icons-material";
import {Stack} from "@mui/system";
import {useModal} from "mui-modal-provider";
import {ErrorDialog} from "../../Common/ErrorDialog/ErrorDialog";

interface Props {
    user: User;
    onClose: (wasReset: boolean) => void;
}

export const ResetUserPasswordDialog = ({user, onClose}: Props) => {
    const [resetting, setResetting] = useState(false)
    const [open, setOpen] = useState(true)
    const userService = new UserService()
    const {showModal} = useModal();

    const handleClose = (wasReset: boolean) => {
        onClose(wasReset)
        setOpen(false);

    }


    const onReset = () => {
        setResetting(true);

        userService.resetPasswordById(user.firebase_id)
            .then(() => handleClose(true))
            .catch((err) => {
                showModal(ErrorDialog, {error: err})
                setResetting(false);
            })

    }


    const theme = useTheme();

    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle>Reset User Password?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This process will send a reset password link to the user's email. They can click the link to reset their password.
                    Please note, it may take a moment for the email to appear, and users should check their junk mail if it's not immediately visible.
                </DialogContentText>

            </DialogContent>

            <DialogActions>
                <Stack direction={'row'} gap={2}>
                    <Button onClick={() => handleClose(false)} size={'small'} sx={{color: theme.palette.text.primary}} > Cancel</Button>
                    <LoadingButton
                        onClick={onReset}
                        endIcon={<LockReset />}
                        loading={resetting}
                        loadingPosition={'end'}
                        variant={'contained'}
                        color={'secondary'}
                        size={'small'}>
                        Confirm
                    </LoadingButton>
                </Stack>


            </DialogActions>
        </Dialog>
    )

}