import {autocompleteClasses, ListSubheader, Popper, styled, Typography, useMediaQuery, useTheme,} from "@mui/material";
import React, {ReactNode} from "react";
import {ReportAnalysis} from "../../../../../../../services/ThreatAnalysis/threatAnalysis.model";
import {TargetRegionData} from "./TargetEntitesForm";
import {ListChildComponentProps, VariableSizeList} from "react-window";
import {RegionSelector} from "../../../../../../Common/Selectors/RegionSelector";

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
    const {data, index, style} = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
    };

    if (dataSet.hasOwnProperty("group")) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    return (
        <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
            {`${dataSet[1].region} (${dataSet[1].region_iso_code})`}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
    const {children, ...other} = props;
    const itemData: React.ReactChild[] = [];
    (children as React.ReactChild[]).forEach(
        (item: React.ReactChild & { children?: React.ReactChild[] }) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        }
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactChild) => {
        if (child.hasOwnProperty("group")) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
});

interface ThisProps {
    analysis: ReportAnalysis;
    regions: TargetRegionData[];

    onUpdate: (key: keyof ReportAnalysis, value: any[]) => void;
    onDelete: (key: keyof ReportAnalysis, value: any) => void;
}

interface ThisState {
}

export class TargetRegions extends React.Component<ThisProps, ThisState> {
    private _key: keyof ReportAnalysis = "target_regions";

    constructor(props: ThisProps) {
        super(props);
        this.state = {};
    }

    render(): ReactNode {
        return (
            <RegionSelector
                regions={this.props.analysis?.target_regions}
                choices={this.props.regions}
                onUpdate={(v: string[]) => this.props.onUpdate(this._key, v)}
                onDelete={(v: number) => this.props.onDelete(this._key, v)}
            />
        );
    }
}
