import React, {useEffect, useState} from 'react';
import {CompanyModel} from "../services/company/company.model";
import PublicAuthContext from "../context/PublicAuthContext";
import AnonymousAuthContext from "../context/AnonymousAuthContext";

interface DrawerStateProviderProps {
    children: React.ReactNode; // Define children as a prop
}

export const AnonymousAuthProvider: React.FC<DrawerStateProviderProps> = ({children}) => {
    const [token, setToken] = useState<{value: string | null, pending: boolean}>({value: localStorage.getItem('token'), pending: true})


    const TOKEN = 'token';

// Effect for updating localStorage when token.value changes
    useEffect(() => {
        console.log(token);
        if (token.value) {
            localStorage.setItem(TOKEN, token.value);
        } else {
            localStorage.removeItem(TOKEN);
        }
    }, [token.value]); // Only react to changes in token.value


    const clearToken = () => {
        localStorage.removeItem(TOKEN);
    }

    return (
        <AnonymousAuthContext.Provider value={{token, setToken, clearToken}}>
            {children} {/* Render children */}
        </AnonymousAuthContext.Provider>
    );
};
