export interface CompanyParameterModel {
    _id?: string;
    name: string;
    description: string;
    default_value: string;
    added_on?: string;
    added_by?: string;
}

export interface CompanyParameterXrefModel {
    _id?: string;
    name?: string;
    description?: string;
    default_value?: string;
    value: string;
    parameter: string;
    company: string;
}

export class CompanyParameter implements CompanyParameterModel {
    name: string;
    description: string;
    default_value: string;

    constructor() {
        this.name = ''
        this.default_value = ''
        this.description = ''
    }

    to_json = (): CompanyParameterModel => {
        return {
            name: '',
            default_value: '',
            description: ''
        }
    }

}