import {TenPointScale, TenPointScaleNumerical} from "../globals";
import {StixModel, StixSector} from "../stixx/stixx.model";
import {CompanyParameterXrefModel} from "./company-parameter.model";
import {
    ThreatActorIntent,
    ThreatActorMotivation,
    ThreatActorResourceLevel, ThreatActorSophistication
} from "../ThreatAnalysis/threatAnalysis.model";

export enum CompanyType {
    NonProfit = 'non-profit',
    ForProfit = 'for-profit',
    School = 'school',
    StateGovernment = 'government',
    CityGovernment = 'government-local',
    Hospital = 'hospital'
}


export interface CompanyModel {
    id: string;
    name: string;
    description: string;
    contact_information: string;

    is_incomplete?: boolean;

    sectors: StixSector[];



    x_employee_count: number;
    x_organization_id: string;
    x_country_of_origin: string;
    x_populations: string[];
    x_regions: string[];
    x_organization_type: CompanyType;

    x_annual_revenue: TenPointScaleNumerical;
    x_asset_value: TenPointScaleNumerical;

    x_information_resources: DataCollection[];
    x_strategic_importance: TenPointScaleNumerical;
    x_public_profile: TenPointScaleNumerical;
    x_regulatory_environment: TenPointScaleNumerical;
    x_security_posture: TenPointScaleNumerical;

    stix_attributes?: CompanyStixAttributes;
    parameters?: CompanyParameterXrefModel[]
    created?: string;
}

export interface CompanyStixAttributes {
    cyber_objects: { [key: string]: StixModel[] };
    domain_objects: { [key: string]: StixModel[] };
}

export class Company implements CompanyModel {
    id: string;
    name: string;
    description: string;

    contact_information: string;
    x_organization_type: CompanyType;
    x_employee_count: number;
    x_organization_id: string;
    x_country_of_origin: string;
    x_populations: string[];
    x_regions: string[];
    x_annual_revenue: TenPointScaleNumerical;
    x_asset_value: TenPointScaleNumerical;
    x_strategic_importance: TenPointScaleNumerical;
    x_public_profile: TenPointScaleNumerical;
    x_regulatory_environment: TenPointScaleNumerical;
    x_security_posture: TenPointScaleNumerical;
    x_information_resources: DataCollection[];
    created: string;
    sectors: StixSector[];

    constructor(name: string = "") {
        this.id = "";
        this.name = name;
        this.contact_information = "";
        this.description = "";
        this.x_organization_id = "";
        this.x_country_of_origin = "";
        this.x_populations = [];
        this.x_regions = [];
        this.x_employee_count = 0;
        this.created = "";
        this.sectors = [];
        this.x_organization_type = CompanyType.ForProfit
        this.x_annual_revenue = TenPointScaleNumerical.NONE;
        this.x_asset_value = TenPointScaleNumerical.NONE;
        this.x_strategic_importance = TenPointScaleNumerical.NONE;
        this.x_public_profile = TenPointScaleNumerical.NONE;
        this.x_security_posture = TenPointScaleNumerical.NONE;
        this.x_regulatory_environment = TenPointScaleNumerical.NONE;
        this.x_information_resources = [];
    }

    toJSON = (): CompanyModel => {
        return {
            id: "",
            name: "",
            description: "",
            contact_information: "",
            x_organization_type: CompanyType.ForProfit,
            x_organization_id: "",
            x_country_of_origin: "",
            x_employee_count: 0,
            x_annual_revenue: TenPointScaleNumerical.LOW,
            x_asset_value: TenPointScaleNumerical.LOW,
            x_populations: [],
            x_regions: [],
            created: "",
            sectors: [],
            x_strategic_importance: TenPointScaleNumerical.LOW,
            x_public_profile: TenPointScaleNumerical.LOW,
            x_regulatory_environment: TenPointScaleNumerical.LOW,
            x_security_posture: TenPointScaleNumerical.LOW,
            x_information_resources: [],
        };
    };
}

export enum DataCollection {
    USER_IDENTIFICATION = "user identification",
    CONTACT_INFORMATION = "contact information",
    DEVICE_INFORMATION = "device information",
    TRANSACTIONAL_DATA = "transactional data",
    FINANCIAL_DATA = "financial data",
    EMPLOYMENT_DATA = "employment data",
    EDUCATIONAL_DATA = "educational data",
    HEALTH_DATA = "health data",
    BEHAVIORAL_DATA = "behavioral data",
    SOCIAL_MEDIA_DATA = "social media data",
    LEGAL_DATA = "legal data",
    PREFERENCES = "preferences",
    COMMUNICATION_LOGS = "communication logs",
    USAGE_STATISTICS = "usage statistics",
    BROWSING_HISTORY = "browsing history",
    MARKETING_DATA = "marketing data",
    TECHNICAL_DATA = "technical data",
    LOCATION_DATA = "location data",
    FEEDBACK = "feedback",
    SECURITY_DATA = "security data",
    PURCHASE_HISTORY = "purchase history",
    BIOGRAPHIC_DATA = "biographic data",
    DEMOGRAPHIC_DATA = "demographic data",
}



class NetworkConfig implements NetworkConfigModel {
    dns: string;
    asn: string;

    constructor() {
        this.dns = "";
        this.asn = "";
    }

    toJSON = () => {
        return {
            dns: "",
            asn: "",
        };
    };
}

export interface NetworkConfigModel {
    dns: string;
    asn: string;
}


export interface CompanyRisk {
    financial_risk: TenPointScale;
    impact_type: TenPointScale;
    legal_compliance_risk: TenPointScale;
    likelihood_of_attack: TenPointScale;
    operational_risk: TenPointScale;
    reputational_risk: TenPointScale;
    threat_actor: string;
    threat_actor_id: string;
    threat_actor_capabilities: number;
    threat_actor_consequences: string;
    threat_actor_intent: string[];
    threat_actor_motives: string[];
    threat_actor_relationship_target: string;
    threat_actor_relationship_identified: string;
    threat_actor_last_seen: string;
    threat_actor_resource_level: string[];
    threat_actor_sophistication: string;
    threat_actor_target_country: string;
    threat_actor_target_region: string;
    threat_actor_target_sector: string;

    threat_actor_targets_country: boolean;
    threat_actor_targets_region: boolean;
    threat_actor_targets_sector: boolean;
    threat_actor_type: string;
}

export interface CompanyThreatRansomware {
    total_safe: number;
    total_unsafe: number;
    total: number;
    predictions: CompanyThreatRansomwarePrediction[];
}

export interface CompanyThreatRansomwarePrediction {
    threat_actor: string;
    threat_actor_capabilities: number;
    threat_actor_intent: ThreatActorIntent[];
    threat_actor_motives: ThreatActorMotivation[];
    threat_actor_resource_level: ThreatActorResourceLevel[];
    threat_actor_sophistication: ThreatActorSophistication;
    threat_actor_type: string;
    threat_actor_last_seen: string | null;
    threat_actor_id: string;
    safe: boolean;
    scale: TenPointScaleNumerical;
    safe_confidence: number;
    unsafe_confidence: number;
    rationale: Record<string, {type: RansomwareFeatureType, explanation: string, value: number}>;
}


export enum RansomwareFeatureType {
    Activity = "Activity",
    Location = "Location",
    IndustrySector = "Industry Sector",
    EmployeeCount = "Employee Count",
    CompanyRevenue = "Company Revenue",
    CompanyType = "Company Type",
    AdversaryProfile = "Adversary Profile"
}
