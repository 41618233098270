import {StixSector} from "../stixx/stixx.model";

export interface ReportModel {
    _id: string;
    name: string;
    file_type: FileType | null;
    file_name: string | null;
    file_size: string | null;

    website_url: string | null;
    website_title: string | null;

    content: string | null;
    status: ReportStatus;
    message: string | null;
    analysis: ReportAnalysis[];
    report_type: ReportType;
    prompt_type: PromptType;


    report_date: string;
    added_on: string;
    added_by: string;
    added_by_display_name: string;

    updated_on: string;
    updated_by: string;
    updated_by_display_name: string;

    locked_on: string | null;
    locked_by: string | null;
    locked_by_display_name: string | null;
}

export enum ReportApprovalStatus {
    PENDING = "PENDING APPROVAL",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
}

export enum ReportType {
    FILE = "FILE",
    WEBSITE = "WEBSITE",
    MANUAL = "MANUAL"
}

export enum PromptType {
    THREAT_REPORT = "THREAT REPORT",
    CAMPAIGN_REPORT = "CAMPAIGN REPORT"
}

export interface ReportAnalysis {
    _id: string;
    report: string;
    raw_serialized_analysis: string;
    raw_openAI_analysis: string;
    threat_actor: string;
    threat_actor_overview: string;

    threat_actor_type: ThreatActorType;
    // mitre_attack_id: string | null;
    threat_actor_sophistication: ThreatActorSophistication;
    threat_actor_motives: ThreatActorMotivation[];
    threat_actor_intent: ThreatActorIntent[];
    threat_actor_resources: ThreatActorResourceLevel[];
    aliases: string[];
    country_of_origin: string | null;

    target_countries: string[];
    target_regions: string[];
    target_populations: string[];
    target_industry_sectors: StixSector[];

    attack_mitigations: string[];
    attack_consequences: string[];
    rationale: any | null;
    vulnerabilities: string[];
    threat_actor_capabilities: string[];
    sources: string[];

    change_log: ReportHistory[];

    approval_status: ReportApprovalStatus;
    approval_status_changed_on: string;
    approval_status_changed_by: string;
    approval_status_changed_by_display_name: string;
    approval_message: string | null;
}

export interface ReportRationale {

}

export interface ReportHistory {
    _id: string;
    updated_by: string;
    updated_on: string;
    updated_value: string | null;
    change: ReportChanges;
}

export interface ReportChanges {
    field: string;
    rationale: string;
    previous_value: string | null;
    updated_value: string;
}

export enum FileType {
    PDF = "application/pdf",
    HTML = "text/html"
}

export enum ReportStatus {
    SUBMITTED = "SUBMITTED",
    COMPLETE = "COMPLETE",

    CANCELLED = "CANCELLED",

    EXTRACTING = "EXTRACTING",
    ANALYZING = "ANALYZING",
    SUBMITTING = "SUBMITTING",

    FAILED_TO_SUBMIT = "FAILED TO SUBMIT",
    FAILED_TO_EXTRACT = "FAILED TO EXTRACT",
    FAILED_TO_ANALYZE = "FAILED TO ANALYZE",
    FAILED_TO_REANALYZE = "FAILED TO REANALYZE",

    UNKOWN_FAILURE = "UNKNOWN FAILURE",
}

export const FailedReportStatus = [
    ReportStatus.FAILED_TO_ANALYZE,
    ReportStatus.FAILED_TO_EXTRACT,
    ReportStatus.FAILED_TO_SUBMIT,
    ReportStatus.UNKOWN_FAILURE,
    ReportStatus.FAILED_TO_REANALYZE
];
export const ProccessingReportStatuses = [
    ReportStatus.SUBMITTING,
    ReportStatus.ANALYZING,
    ReportStatus.EXTRACTING,
];

export const handleGetReportStatusColor = (
    report: ReportModel
):
    | any
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined => {
    if (report.status == ReportStatus.COMPLETE) return "success";
    if (report.status == ReportStatus.SUBMITTED) return "warning";
    else if (ProccessingReportStatuses.includes(report.status))
        return "info";
    else if (report.status == ReportStatus.CANCELLED) {
        return "#6b9899";
    }

    return "error";
};

export const handleGetReportApprovalStatusColor = (
    analysis: ReportAnalysis | null, status: ReportApprovalStatus | null = null
): "error" | "success" | "warning" | undefined => {


    status = analysis?.approval_status ? analysis.approval_status : status

    if (status) {
        if (status.toUpperCase() == ReportApprovalStatus.APPROVED.toUpperCase())
            return "success";
        else if (status.toUpperCase() == ReportApprovalStatus.REJECTED.toUpperCase())
            return "error";
        else {
            return "warning";
        }
    }
};

export class ISICClassifier {
    private classificationMap: {
        [key: string]: string
    } = {
        "01-03": "Agriculture, forestry and fishing",
        "05-09": "Mining and quarrying",
        "10-33": "Manufacturing",
        "35": "Electricity, gas, steam and air conditioning supply",
        "36-39": "Water supply; sewerage, waste management and remediation",
        "41-43": "Construction",
        "45-47":
            "Wholesale and retail trade; repair of motor vehicles and motorcycles",
        "49-53": "Transportation and storage",
        "55-56": "Accommodation and food service activities",
        "58-63": "Information and communication",
        "64-66": "Financial and insurance activities",
        "68": "Real estate activities",
        "69-75": "Professional, scientific and technical activities",
        "77-82": "Administrative and support service activities",
        "84": "Public administration and defence; compulsory social security",
        "85": "Education",
        "86-88": "Human health and social work activities",
        "90-93": "Arts, entertainment and recreation",
        "94-96": "Other service activities",
        "97-98":
            "Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use",
        "99": "Activities of extraterritorial organizations and bodies",
    };

    getClassification(code: string): string | undefined {
        for (const range in this.classificationMap) {
            const [start, end] = range.split("-");

            if ((code >= start && code <= end) || start == code) {
                return this.classificationMap[range];
            }
        }

        return undefined;
    }
}

export enum ThreatActorIntent {
    INFORMATION_THEFT = "Information Theft",
    FINANCIAL_THEFT = "Financial Theft",
    DISRUPTION_OF_SERVICE = "Disruption of Service",
    DAMAGE_AND_DESTRUCTION = "Damage and Destruction",
    ESPIONAGE = "Espionage",
    DISINFORMATION = "Disinformation",
    CREDENTIAL_HARVESTING = "Credential Harvesting",
    CREATING_BOTNETS = "Creating Botnets",
    CYBER_STALKING = "Cyber Stalking",
    RESOURCE_HIJACKING = "Resource Hijacking",
}

export enum ThreatActorMotivation {
    FINANCIAL_GAIN = "Financial Gain",
    ESPIONAGE = "Espionage",
    HACKTIVISM = "Hacktivism",
    CYBERWARFARE = "Cyberwarfare",
    NOTORIETY = "Notoriety",
    TERRORISM = "Terrorism",
    COMPETITIVE_ADVANTAGE = "Competitive Advantage",
    PERSONAL_VENGEANCE = "Personal Vengeance",
    INTELLECTUAL_CURIOSITY = "Intellectual Curiosity",
}

export enum ThreatActorType {
    ACTIVIST = "activist",
    COMPETITOR = "competitor",
    CRIME_SYNDICATE = "crime_syndicate",
    CRIMINAL = "criminal",
    HACKER = "hacker",
    INSIDER_ACCIDENTAL = "insider accidental",
    INSIDER_DISGRUNTLED = "insider disgruntled",
    NATION_STATE = "nation_state",
    SENSATIONALIST = "sensationalist",
    SPY = "spy",
    TERRORIST = "terrorist",
    UNKNOWN = "unknown",
}

export enum ThreatActorSophistication {
    Unknown = "unknown",
    MINIMAL = "minimal",
    INTERMEDIATE = "intermediate",
    ADVANCED = "advanced",
    EXPERT = "expert",
    INNOVATOR = "innovator",
    STRATEGIC = "strategic",
}

export enum ThreatActorResourceLevel {
    INDIVIDUAL = "individual",
    CLUB = "club",
    CONTEST = "contest",
    TEAM = "team",
    ORGANIZATION = "organization",
    GOVERNMENT = "government",
}


export enum ThreatReportHotKeyActions {
    ShowHelpDialog,
    ToggleReportStatus,
    ToggleReportLock,
    ToggleMetadata,
    EditReport,
    RerunReport,
    DeleteReport,
    GoToReports
}

export const ThreatReportHotKeys =  {
    [ThreatReportHotKeyActions.RerunReport]: {
        label: 'Rerun Report',
        cmd: 'Shift + R',
        keySequence: (event: KeyboardEvent) => (event.metaKey || event.ctrlKey) && event.shiftKey && event.key.toLowerCase() === 'r'
    },
    [ThreatReportHotKeyActions.DeleteReport]: {
        label: 'Delete Report',
        cmd: 'Shift + D',
        keySequence: (event: KeyboardEvent) => (event.metaKey || event.ctrlKey) && event.shiftKey && event.key.toLowerCase() === 'd'
    },
    [ThreatReportHotKeyActions.EditReport]: {
        label: 'Edit Report',
        cmd: 'Shift + E',
        keySequence: (event: KeyboardEvent) => (event.metaKey || event.ctrlKey) && event.shiftKey && event.key.toLowerCase() === 'e'
    },
    [ThreatReportHotKeyActions.GoToReports]: {
        label: 'Go to Reports',
        cmd: 'Shift + B',
        keySequence: (event: KeyboardEvent) => (event.metaKey || event.ctrlKey) && event.shiftKey && event.key.toLowerCase() === 'b'
    },

    [ThreatReportHotKeyActions.ToggleReportLock]: {
        label: 'Toggle Report Lock',
        cmd: 'Shift + L',
        keySequence: (event: KeyboardEvent) => (event.metaKey || event.ctrlKey) && event.shiftKey && event.key.toLowerCase() === 'l'
    },
    [ThreatReportHotKeyActions.ToggleReportStatus]: {
        label: 'Toggle Report Status',
        cmd: 'Shift + S',
        keySequence: (event: KeyboardEvent) => (event.metaKey || event.ctrlKey) && event.shiftKey && event.key.toLowerCase() === 's'
    },
    [ThreatReportHotKeyActions.ToggleMetadata]: {
        label: 'Toggle Metadata',
        cmd: 'Shift + M',
        keySequence: (event: KeyboardEvent) => (event.metaKey || event.ctrlKey) && event.shiftKey && event.key.toLowerCase() === 'm'
    },

    [ThreatReportHotKeyActions.ShowHelpDialog]: {
        label: 'Show Help Dialog',
        cmd: 'Shift + H',
        keySequence: (event: KeyboardEvent) => (event.metaKey || event.ctrlKey) && event.shiftKey && event.key.toLowerCase() === 'h'
    },
}