import React, {useEffect, useState} from "react";
import {ThreatActor} from "../../../../../services/ThreatActor/threatActor.model";
import {lighten, Stack} from "@mui/system";
import {
    Button,
    Checkbox,
    Collapse,
    Divider,
    IconButton,
    InputAdornment,
    Paper,
    styled,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import ThreatActorTargetCountries from "./ThreatActorTargetCountries";
import {Clear} from "@mui/icons-material";
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {TransitionGroup} from "react-transition-group";
import ListItemText from "@mui/material/ListItemText";
import toTitleCase from "../../../../Common/Utils/AutoCompleteOptions/toTitleCase";
import {country, } from "iso-3166-2";
import PublicLoadingScreen from "../../../PublicLoadingScreen";
import ThreatActorTargetRegions from "./ThreatActorTargetRegions";
import Grid from "@mui/material/Unstable_Grid2";


const drawerWidth = 350;

const Main = styled('div', {shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
}>(({theme, open}) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


interface ThisProps {
    threatActor: ThreatActor
}

const stixRegions = "/maps/stix_region_to_countries.json";  // Assuming you've placed it under the maps folder in public

const TargetLocations: React.FC<ThisProps> = (props) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);


    const [filter, setFilter] = useState<string>('');
    const [selectedEntity, setSelectedEntity] = useState<string | null>(null)
    const [currentView, setCurrentView] = useState<'countries' | 'regions'>('countries')
    const [regionToCountries, setRegionToCountries] = useState<Map<string, { name: string, id: string }[]>>(new Map())
    const [loading, setLoading] = useState(true)
    const [countryLookupMap, setCountryLookupMap] = useState<Map<string, string>>(new Map())

    useEffect(() => {
        const countryMap = new Map();

        for (let c of props.threatActor.target_countries || []) {
            try {

                const countryValue = country(c?.toLowerCase());
                if (countryValue)
                    countryMap.set(c?.toLowerCase(), countryValue?.name?.toLowerCase())
            } catch (e) {
                console.warn(`Unknown country: ${c.toLowerCase()}`)
            }

        }

        setCountryLookupMap(countryMap);

        fetch(stixRegions)
            .then(response => response.json())
            .then(data => {
                const map = new Map();

                for (let stix_region of data) {
                    map.set(stix_region['region'], stix_region['countries']);
                }

                setRegionToCountries(map)
                setLoading(false)
            })
    }, [props.threatActor.target_countries, props.threatActor.target_regions]);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const renderListItem = (label: string,  isSelected: boolean, onClickHandler: Function, index: number) => (
        <Collapse key={label}>
            <ListItem
                key={index}
                onClick={(e) => onClickHandler(e)}
                sx={{
                    backgroundColor: index % 2 === 1 ? theme.palette.background.default : lighten(theme.palette.background.paper, .1),
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover
                    }
                }}
                divider
            >
                <ListItemText sx={{width: '100%'}}>{toTitleCase(label)}</ListItemText>
                {/*<ListItemText sx={{width: '40%', textAlign: 'right'}}>{count}</ListItemText>*/}
                {isSelected &&
                    <ListItemText>
                        <Checkbox
                            checked={true}
                            onClick={(e) => {
                                e.stopPropagation();
                                setSelectedEntity(null);
                            }}
                        />
                    </ListItemText>
                }
            </ListItem>
        </Collapse>
    );

    const renderListContent = () => {
        if (currentView === 'countries') {
            return props.threatActor.target_countries?.filter(c => (c.toLowerCase().includes(filter.toLowerCase()) || countryLookupMap.get(c.toLowerCase())?.toLowerCase().includes(filter.toLowerCase()))).map((c, index) => {
                const countryName = countryLookupMap.get(c.toLowerCase());
                return renderListItem(
                    countryName!,
                    selectedEntity == countryName,
                    (e: any) => {
                        if (selectedEntity == countryName)
                            setSelectedEntity(null);
                        else
                            setSelectedEntity(countryName!);
                    },
                    index
                );
            });
        } else if (currentView === 'regions') {
            return props.threatActor.target_regions?.filter(c => c.toLowerCase().includes(filter.toLowerCase())).map((c, index) => {
                const regionName = c;
                return renderListItem(
                    regionName,
                    selectedEntity == regionName,
                    (e: any) => {
                        if (selectedEntity == regionName)
                            setSelectedEntity(null);
                        else
                            setSelectedEntity(regionName!);
                    },
                    index
                );
            });
        }
    };


    return (
        <Grid sx={{height: '100%'}} container component={Paper} elevation={6}>
            <Grid xs={12}>
                <Paper sx={{flexGrow: 1}}>
                    <Stack paddingY={2} paddingX={1} spacing={2} direction={'row'} alignItems={'center'}>
                        <Typography variant={'h6'}
                        >Target {currentView == 'regions' ? 'Regions' : 'Countries'}</Typography>


                        <Button size={'small'} onClick={() => {
                            setCurrentView(currentView == 'regions' ? 'countries' : 'regions')
                            setFilter('')
                            setSelectedEntity(null)
                        }}>
                            {currentView == 'regions' ? 'View Countries' : 'View Regions'}
                        </Button>

                    </Stack>
                </Paper>
            </Grid>
            <Grid sx={{height: '100%'}} component={Paper} xs={4}>
                <Stack paddingX={theme.spacing(1)} spacing={1}>


                    <TextField size={'small'} variant={'outlined'} value={filter}
                               fullWidth
                               placeholder={`Filter`}
                               onChange={(e) => setFilter(e.target.value)}
                               InputProps={{
                                   endAdornment: (
                                       <InputAdornment position="end">
                                           <IconButton
                                               size="small"
                                               onClick={() => setFilter('')}
                                           >
                                               <Clear color={'error'} fontSize="small"/>
                                           </IconButton>
                                       </InputAdornment>
                                   ) && filter.trim() !== ''
                               }}

                    />


                </Stack>
                <Divider sx={{mt: theme.spacing(2), mb: theme.spacing(1)}}/>

                {/*8 for tool bar, 8 for text field, 1 for good measures*/}
                <List sx={{overflow: 'hidden', overflowY: 'auto', height: `calc(100% - ${theme.spacing(17)})`}}>
                    <ListItem divider/>
                    <TransitionGroup>
                        {renderListContent()}
                    </TransitionGroup>
                </List>
            </Grid>
            <Grid xs={8}>

                {
                    loading ?
                        <PublicLoadingScreen text={'Generating Maps'}/> :
                        currentView == 'countries' ?
                            <ThreatActorTargetCountries threatActor={props.threatActor}
                                                        countryLookupMap={countryLookupMap}
                                                        selectedCountry={selectedEntity}
                                                        setCurrentView={() => setCurrentView('regions')}/> :
                            <ThreatActorTargetRegions threatActor={props.threatActor}
                                                      data={regionToCountries}
                                                      selectedRegion={selectedEntity}
                                                      setCurrentView={() => setCurrentView('countries')}/>
                }
            </Grid>
        </Grid>
    )
}

export default TargetLocations;