import {styled} from "@mui/material/styles";
import MuiTypography from "@mui/material/Typography";
import MuiChip from "@mui/material/Chip";

import {Theme} from "@mui/material";

export const AppTitle = styled(MuiTypography)<{ theme?: Theme, open?: boolean }>(({theme, open}) => ({
    fontWeight: 600
}));

export const AppChip = styled(MuiChip)<{ theme?: Theme }>(({theme}) => ({
    fontWeight: 600
}));
