import React from "react";
import {ListView} from "../../../../../Common/ListView/ListView";
import {SharedViewAnalysisChildrenProps} from "../ViewAnalysis";

interface ViewAMCProps extends SharedViewAnalysisChildrenProps {
    doesEntryExistInText: (value: string) => boolean;
}

interface ViewAMCState {
}

export default class ViewAMC extends React.Component<
    ViewAMCProps,
    ViewAMCState
> {
    constructor(props: ViewAMCProps) {
        super(props);
    }

    render(): React.ReactNode {
        return <ListView entries={this.props.entries} styles={this.props.styles}/>;
    }
}
