import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {AnalysisSharableTable, IconButtonColumn, StringColumn} from "../../../../../Common/TableHelper/SharableTable";
import {OpenInNew} from "@mui/icons-material";

interface ThisProps {
    title: string;
    data: string[];
    headers: string[];
    onClick: (value: string) => void;
}

const ThreatActorCapabilitiesDialog: React.FC<ThisProps> = (props: ThisProps) => {
    const [open, setOpen] = useState(true)


    return (
        <Dialog open={open} fullWidth maxWidth={'md'}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <AnalysisSharableTable rows={[new StringColumn(null), new IconButtonColumn(
                    <OpenInNew color="secondary"/>,
                    (row_index) => {
                        props.onClick(props.data[row_index])
                    },
                    {float: "right"}
                ),]} headers={props.headers} data={props.data}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => setOpen(false)}>close</Button>
            </DialogActions>
        </Dialog>
    )
}


export default ThreatActorCapabilitiesDialog;