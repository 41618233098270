import {Container} from "@mui/system";
import React from "react";
import {Add, DeleteOutline} from "@mui/icons-material";

import {Box, Divider, IconButton, Stack, TextField, Typography,} from "@mui/material";
import {ReportAnalysis} from "../../../../../../../services/ThreatAnalysis/threatAnalysis.model";

import {
    AnalysisSharableTable,
    IconButtonColumn,
    StringColumn,
} from "../../../../../../Common/TableHelper/SharableTable";
import _ from "lodash";
import {SharedAnalysisFormProps} from "../AnalysisFormWrapperDialog";

interface ThisState {
    input: string;
}

interface ThisProps extends SharedAnalysisFormProps {
    analysis: ReportAnalysis;
    onUpdate: (analysis: ReportAnalysis) => void;
}

export class ReportSources extends React.Component<ThisProps, ThisState> {
    private _key: keyof ReportAnalysis = "sources";

    constructor(props: ThisProps) {
        super(props);
        this.state = {input: ""};
    }

    onUpdate(): void {
        this.props.onUpdate({
            ...this.props.analysis,
            [this._key]: [this.state.input, ...this.props.analysis.sources],
        });
        this.setState({input: ""});
    }

    onDelete(row_index: number): void {
        const copy = _.cloneDeep(this.props.analysis.sources);

        this.props.onUpdate({
            ...this.props.analysis,
            [this._key]: copy.filter((value, index) => index !== row_index),
        });

        this.setState({input: ""});
    }

    render(): React.ReactNode {
        const isDup = this.props.analysis.sources.includes(this.state.input);
        return (
            <Stack spacing={1}>
                <Box sx={{width: "100%", padding: "0 8px"}}>
                    <Stack
                        sx={{mb: "12px"}}
                        direction="row"
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Typography fontSize={"1.25rem"} fontWeight={600}>
                            Add Source
                        </Typography>
                        <IconButton
                            sx={{
                                padding: 0,
                                borderRadius: "50%",
                                border: (theme) =>
                                    "1px solid " + theme.palette.secondary.main,
                            }}
                            size="small"
                            color="secondary"
                            disabled={this.state.input.trim() == "" || isDup}
                            onClick={this.onUpdate.bind(this)}
                        >
                            <Add/>
                        </IconButton>
                    </Stack>
                    <TextField
                        fullWidth
                        size={"small"}
                        color={"secondary"}
                        label="Source"
                        value={this.state.input}
                        helperText={isDup ? "Source already exist" : " "}
                        error={isDup}
                        onChange={(event) => this.setState({input: event.target.value})}
                    />
                </Box>
                <Divider/>
                <AnalysisSharableTable
                    headers={["Source", ""]}
                    data={this.props.analysis.sources}
                    rows={[
                        new StringColumn(null),
                        new IconButtonColumn(
                            <DeleteOutline color="error"/>,
                            (row_index) => {
                                this.onDelete(row_index);
                            },
                            {float: "right"}
                        ),
                    ]}
                />
            </Stack>
        );
    }
}
