import {useContext, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {UserGroup} from "../../services/User/user.model";

const UserNotAssignedDialog = () => {
    const {user} = useContext(AuthContext);
    const [open, setOpen] = useState(true)

    const onClose = () => setOpen(false);

    return (
        <Dialog open={open}>
            <DialogTitle>Failed to Login</DialogTitle>
            <DialogContent>

                <DialogContentText>
                    {
                        !user?.group && (
                            "You have gained access into the application, however, you have not been assigned a role. Please contact support, or try again momentarily."
                        )
                    }

                    {
                        user?.group === UserGroup.Entity && (
                            "You have gained access into the application, however, you have not been assigned to an entity. Please contact support, or try again momentarily."
                        )
                    }

                    {
                        user?.group === UserGroup.NonAttributable && (
                            "You have gained access into the application, however, anonymous authentication is not currently supported. Please contact support."
                        )
                    }
                </DialogContentText>

            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} onClick={onClose}>Okay</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserNotAssignedDialog;