import {
    ReportedIncidentModel,
    ReportIncidentStatus
} from "../../../../services/ReportedIncident/reportedIncident.model";
import {Card, Box, CardContent, Skeleton, Stack, Tooltip, Typography, useTheme, Fade} from "@mui/material";
import {AppChip} from "../../../Common/common";
import {DebouncedTextField} from "../../../Common/DebouncedTextField";
import toTitleCase from "../../../Common/Utils/AutoCompleteOptions/toTitleCase";
import React, {useEffect} from "react";
import {defaultPagination} from "../../../../services/api-service";
import {format} from "date-fns";
import {useModal} from "mui-modal-provider";
import {ViewReportedIncidentDialog} from "./ViewReportedIncident";

interface Props {
    data: {pending: boolean, value: ReportedIncidentModel[], fetching :boolean}
    filter: {value: string, setFilter: (f: string) => void};
    pagination: { page: number, rowsPerPage: number, totalRecords: number };
    setPagination: (page: number, rowsPerPage: number) => void;
    loading: boolean;
}

export const CommunityIncidentsView = ({data, filter, pagination, setPagination, loading}: Props) => {
    const theme = useTheme();
    const {showModal} = useModal();

    // useEffect(() => {
    //     thisSetPagination(defaultPagination.page, defaultPagination.rowsPerPage)
    // }, []);


    const cardsPerRow = 4

    useEffect(() => {
        // Get height of container and row
        const container = document.querySelector(".paginated-list") as HTMLElement;
        const containerHeight = container?.offsetHeight;

        // Calculate number of rows that can fit
        const numRows = Math.floor(containerHeight / (8 * 18) as number);


        // Now you have the numRows, you can adjust your pagination or fetch mechanism to get this number

        // set cards per row + an additional roll so scrolling can start
        setPagination(0, numRows * (cardsPerRow + 1));
    }, []);

    const handleChangePage = (
        // event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        thisSetPagination(newPage, pagination?.rowsPerPage || defaultPagination.rowsPerPage);
    };

    const handleScroll = (e: any) => {
        let bottom =false;


        if (Math.ceil(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight || Math.floor(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight) {
            bottom = true;
        }

        if (bottom && pagination) {
            const totalPages = Math.ceil(pagination.totalRecords / pagination.rowsPerPage);



            if (pagination.page < totalPages - 1) { // Subtract 1 because page index starts at 0
                handleChangePage(pagination.page + 1);
            }
        }
    }

    const thisSetPagination =(pageNumber: number, numRows: number) =>  {
        if (pagination && setPagination) {
            setPagination(pageNumber, numRows);
        }
    }

    return (
        <Stack sx={{
            height: '100%',
            overflow: 'hidden'
        }} spacing={2}>
            <DebouncedTextField
                showSpinner={loading && filter.value?.toString().trim() !== ''}
                placeholder={'Search for incident'}
                filter={filter.value}
                disabled={true}
                onValueChanged={(newFilterStr) => filter.setFilter(newFilterStr)}
                onSearch={(value) => {}}
            />

            {/* Scrollable list container */}
            <Box className={'paginated-list'} onScroll={handleScroll} sx={{
                overflowY: 'auto', // Enable vertical scroll
                maxHeight: '100%', // Constrain height to prevent overflow
                flex: 1, // Take available space
            }}>
                <Stack position={'relative'} gap={2} rowGap={3} flexWrap="wrap" direction="row">
                    {data.value.map(obs => (
                        <Card key={obs._id} sx={{
                            height: theme.spacing(18),
                            cursor: 'pointer',
                            transition: 'all ease-in-out 250ms',
                            ':hover': {
                                transform: 'translateY(-4px)',
                                boxShadow: theme.shadows[10],
                            },
                            borderTop: '2px solid ' + theme.palette.primary.main,
                            width: `calc(100% / ${cardsPerRow} - ${theme.spacing(2)})`
                        }} onClick={() => { showModal(ViewReportedIncidentDialog, { incident: obs }) }}>
                            <CardContent sx={{ height: '100%' }}>
                                <Stack height={'100%'} justifyContent={'space-between'}>
                                    <Stack justifyContent={'space-between'} direction={'row'}>
                                        <Stack sx={{ overflow: 'hidden' }}>
                                            <Tooltip title={obs.value}>
                                                <Typography sx={{ paddingRight: theme.spacing(2) }} className={'overflow'} fontWeight={600} variant="body1" fontSize={'1rem'}>{toTitleCase(obs.value.toString())}</Typography>
                                            </Tooltip>
                                            <Typography fontWeight={600} variant="caption" color={'text.secondary'}>{toTitleCase(obs.stix_type)}</Typography>
                                        </Stack>

                                        <Stack direction={'row'} gap={.5}>
                                            <AppChip size={'small'} label={obs.status} color={obs.status === ReportIncidentStatus.Open ? 'success' : obs.status === ReportIncidentStatus.Pending ? 'warning' : 'error'} />
                                            <AppChip size={'small'} label={'New'} color={'success'} />
                                        </Stack>
                                    </Stack>

                                    <Stack>
                                        <Typography fontWeight={600} variant="caption" color={'text.secondary'}>Added On: {format(new Date(obs.added_on), "MM/dd/yyyy H:mm:ss")}</Typography>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                    ))}

                    {data.pending && Array(16).fill(null).map((value, index) =>
                        <Skeleton key={index} variant={'rounded'} width={`calc(25% - ${theme.spacing(2)})`} height={theme.spacing(18)} />)
                    }

                    {
                        data.value?.length === 0 && !data.pending && (
                            <Stack paddingY={theme.spacing(5)} width={'100%'} alignItems={'center'} justifyContent={'center'} direction={'column'} height={'100%'}>
                                <Fade in={true} unmountOnExit>
                                    <Typography color={'text.secondary'} variant={'h6'}>
                                        No Incidents Reported
                                    </Typography>
                                </Fade>
                            </Stack>
                        )
                    }
                </Stack>
            </Box>
        </Stack>
    )

}