import React, {useEffect, useState} from "react";
import {Box, Stack} from "@mui/system";
import {CompanyRisk} from "../../../../services/company/company.model";
import {CrisisAlert, FlashOn, Gavel, LocationCity, MonetizationOn, Public} from "@mui/icons-material";
import {InfoCard, YLabels} from "../HomeCommon";

interface ThisProps {
    setOrderBy: (k: keyof CompanyRisk) => void;
    companyRisk: { value: CompanyRisk[], pending: boolean }
}

const RiskHighCount: React.FC<ThisProps> = (props: ThisProps) => {
    const [highRiskScores, setHighRiskScores] = useState<{
        likelihood_of_attack: number,
        impact_type: number,
        operational_risk: number,
        financial_risk: number,
        legal_compliance_risk: number,
        reputational_risk: number
    }>({
        likelihood_of_attack: 0,
        impact_type: 0,
        operational_risk: 0,
        financial_risk: 0,
        legal_compliance_risk: 0,
        reputational_risk: 0
    })


    useEffect(() => {
        calculateHighRiskScores();
    }, []);


    const calculateHighRiskScores = () => {

        const highRiskScores = {
            likelihood_of_attack: 0,
            impact_type: 0,
            operational_risk: 0,
            financial_risk: 0,
            legal_compliance_risk: 0,
            reputational_risk: 0
        };

        for (let record of props.companyRisk.value) {

            for (let label of YLabels) {
                if ((record[label as keyof CompanyRisk] as string).toLowerCase().includes("high")) {
                    highRiskScores[label as keyof typeof highRiskScores] += 1;
                }
            }
        }

        setHighRiskScores(highRiskScores)
    }


    return (
        <Stack gap={1}>
            {/*<Typography variant={'h6'} fontWeight={500}>High Risks</Typography>*/}
            <Box
                sx={{
                    display: 'flex',
                    gap: theme => theme.spacing(2),
                    padding: theme => theme.spacing(1),
                    width: '100%',
                }}
            >
                <InfoCard onClick={() => props.setOrderBy('likelihood_of_attack')} icon={CrisisAlert}
                          iconColor={'error'} mainText={`${highRiskScores.likelihood_of_attack}`}
                          subText="Likelihood of Attack"/>
                <InfoCard onClick={() => props.setOrderBy('impact_type')} icon={FlashOn} iconColor={'error'}
                          mainText={highRiskScores.impact_type}
                          subText="Impact Type"/>
                <InfoCard onClick={() => props.setOrderBy('operational_risk')} icon={LocationCity}
                          iconColor={'error'} mainText={highRiskScores.operational_risk}
                          subText="Operational Risk"/> {/* Default number */}
                <InfoCard onClick={() => props.setOrderBy('financial_risk')} icon={MonetizationOn}
                          iconColor={'error'} mainText={highRiskScores.financial_risk}
                          subText="Financial Risk"/> {/* Default number */}
                <InfoCard onClick={() => props.setOrderBy('legal_compliance_risk')} icon={Gavel} iconColor={'error'}
                          mainText={highRiskScores.legal_compliance_risk}
                          subText="Legal Compliance Risk"/> {/* Default number */}
                <InfoCard onClick={() => props.setOrderBy('reputational_risk')} icon={Public} iconColor={'error'}
                          mainText={highRiskScores.reputational_risk}
                          subText="Reputational Risk"/> {/* Default number */}
            </Box>
        </Stack>
    );
}

export {RiskHighCount};
