import React, {ReactElement, useContext} from "react";
import withRouter from "../../Common/Utils/WithRouter/WithRouter";
import {Avatar, Box, Button, darken, lighten, SvgIcon, Toolbar,} from "@mui/material";
import ThreatAnalysisService from "../../../services/ThreatAnalysis/threatAnaylsis.service";

import {ErrorDetails, ErrorDialog} from "../../Common/ErrorDialog/ErrorDialog";
import {Stack} from "@mui/system";
import {Html} from "@mui/icons-material";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import ThreatAnalysisTable from "./ThreatAnalysisTable";
import PublicIcon from "@mui/icons-material/Public";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ThreatAnalysisDialog, {ThreatAnalysisDialogActions,} from "./ThreatAnalysisDialog";
import SimpleSnackbar from "../../Common/SnackBar/SnackBar";
import {
    FileType,
    ReportApprovalStatus,
    ReportModel,
    ReportType
} from "../../../services/ThreatAnalysis/threatAnalysis.model";
import {AppToolbar} from "../../Common/Drawer/NestedDrawer";
import DrawerStateContext, {DrawerStateContextType} from "../../../context/DrawerContext";
import {AppChip, AppTitle} from "../../Common/common";
import {ConfirmationDialog} from "../../Common/ConfirmationDialog/ConfirmationDialog";
import {DebouncedTextField} from "../../Common/DebouncedTextField";

interface ThreatAnalysisProps {
    router?: any;
    drawerContext?: DrawerStateContextType;
}

interface ThreatAnalysisState {
    analysis: {
        value: ReportModel[],
        pending: boolean
    };
    errorDialog: {
        opened: boolean;
        details: ErrorDetails | null
    };
    filter: string;
    exporting: boolean;
    dialogOpen: boolean;
    deleteConfirmationDialog: {
        open: boolean,
        toDelete: string | null
    };
    recentAnalysis: { value: ReportModel[], pending: boolean }
    approvalStatusFilter: ReportApprovalStatus[];
    snackbar: {
        open: boolean;
        message: string;
    };


    pagination: {
        page: number,
        rowsPerPage: number,
        totalRecords: number
    }
    fetchingReports: boolean;
}

enum ReportTypeColor {
    PDF = "#821cff",
    WEBSITE = "#ff25a8",
    HTML_FILE = "#007BFF",

    UNKNOWN = "#d60020",

    //ffe017
    //00a5dc
    // ff25a8
}

class ThreatAnalysisView extends React.Component<
    ThreatAnalysisProps,
    ThreatAnalysisState
> {
    threatAnalysisService: ThreatAnalysisService = new ThreatAnalysisService();

    constructor(props: ThreatAnalysisProps) {
        super(props);

        this.state = {
            analysis: {pending: true, value: []},
            recentAnalysis: {pending: false, value: []},
            deleteConfirmationDialog: {open: false, toDelete: null},
            errorDialog: {opened: false, details: null},
            filter: "",
            dialogOpen: false,
            exporting: false,
            approvalStatusFilter: [ReportApprovalStatus.PENDING],
            snackbar: {
                open: false,
                message: "",
            },

            pagination: {
                rowsPerPage: 0,
                totalRecords: 0,
                page: 0
            },
            fetchingReports: false
        };

    }

    componentDidMount() {
        const { searchParams } = this.props.router;
        const filter = searchParams.get('filter') || ""; // Default to an empty string if not found
        let approvalStatusFilter = searchParams.get('approvalStatus') ? searchParams.get('approvalStatus').split(',') : []; // Assume it's comma-separated

        if (searchParams.get('approvalStatus') === null && this.state.approvalStatusFilter.length > 0) {
            approvalStatusFilter = this.state.approvalStatusFilter;
        }

        this.setState({
            filter: filter,
            approvalStatusFilter: approvalStatusFilter
        }, () => this.addFilterToQueryParameter());
    }


    handleSearchAnalysis(): void {
        // this handles filtering / searching the analysis. This replaces the results
        // rather than adding the search result to the existing array.
        this.setState({fetchingReports: true});
        this.threatAnalysisService
            .getReports(this.state.pagination, this.state.approvalStatusFilter, this.state.filter)
            .then((res) => {
                this.setState({
                    analysis: {pending: false, value: res.results},
                    fetchingReports: false,
                    pagination: {...this.state.pagination, totalRecords: res.count, page: 0}
                });
            })
            .catch((err) => {
                this.setState({
                    fetchingReports: false,
                    analysis: {pending: false, value: []},
                    errorDialog: {opened: true, details: err},
                });
            });
    }


    handleFetchThreatAnalysis(): void {
        // this handles fetching the reports and pushing them on to the existing one.
        this.setState({fetchingReports: true});
        this.threatAnalysisService
            .getReports(this.state.pagination, this.state.approvalStatusFilter, this.state.filter)
            .then((res) => {
                this.setState({
                    analysis: {pending: false, value: [...this.state.analysis.value, ...res.results]},
                    fetchingReports: false,
                    pagination: {...this.state.pagination, totalRecords: res.count}
                })
            })
            .catch((err) => {
                this.setState({
                    fetchingReports: false,
                    analysis: {pending: false, value: []},
                    errorDialog: {opened: true, details: err},
                });
            });
    }

    handleSearch(event: React.ChangeEvent<HTMLInputElement>): void {
        const value = event.target.value.trim().toLowerCase();
        this.setState({filter: value});
    }

    handleViewReport(report_id: string): void {
        this.props.router.navigate(`${report_id}`);
    }

    handleDialogClose(report: ReportModel | null): void {
        const newState: any = {dialogOpen: false};
        if (report) {
            newState["open"] = false;
            newState["analysis"] = {
                pending: false,
                value: [report, ...this.state.analysis.value],
            };

            this.handleViewReport(report._id);
        }

        this.setState(newState);
    }

    handleGetReportTypeIcon(report: ReportModel): ReactElement<any, any> {
        let icon = null;
        let color: any = "";
        if (report.report_type === ReportType.WEBSITE) {
            icon = PublicIcon;
            color = ReportTypeColor.WEBSITE;
        } else if (report.report_type === ReportType.FILE) {
            if (report.file_type === FileType.PDF) {
                icon = PictureAsPdfIcon;
                color = ReportTypeColor.PDF;
            } else if (report.file_type === FileType.HTML) {
                icon = Html;
                color = ReportTypeColor.HTML_FILE;
            }

        }

        if (icon == null || color == '') {
            icon = HelpOutlineIcon;
            color = ReportTypeColor.UNKNOWN;
        }

        return (
            <Avatar
                sx={{
                    backgroundColor: lighten(color, 0.8),
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <SvgIcon sx={{color: darken(color, 0.4)}} component={icon}/>
            </Avatar>
        );
    }

    exportApprovedAnalysis(): void {
        this.setState({exporting: true})
        this.threatAnalysisService.getAnalyses(ReportApprovalStatus.APPROVED)
            .then((res) => {
                const blob = new Blob([JSON.stringify(res, null, 2)], {type: 'application/json'});
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                const currentDate = new Date();

                a.href = url;
                a.download = `${currentDate}_approved_threat_analysis.json`;
                a.click();
                URL.revokeObjectURL(url);

                this.setState({exporting: false})
            })
            .catch((e) => {
                this.setState({errorDialog: {opened: true, details: e}, exporting: false})
            })
    }


    filterOutRecentAnalysis(): ReportModel[] {
        const recentReports = this.state.recentAnalysis.value.length > 3 ? this.state.recentAnalysis.value.slice(0, 3) : this.state.recentAnalysis.value
        const recentIDS = recentReports.map(v => v._id);
        return this.state.analysis.value.filter(v => !recentIDS.includes(v._id))
    }


    addFilterToQueryParameter(): void {
        const currentUrl = this.props.router.location.pathname;
        const newUrl = `${currentUrl}?filter=${encodeURIComponent(this.state.filter)}&approvalStatus=${this.state.approvalStatusFilter.join(',')}`;

        this.props.router.navigate(newUrl)
    }

    render(): React.ReactNode {
        return (
            <Box display={'flex'} flexDirection={'column'} sx={{overflow: 'hidden'}}>
                <AppToolbar open={this.props.drawerContext?.isDrawerOpen}>
                    <Toolbar disableGutters>
                        <Stack width={'100%'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <AppTitle variant={'h5'}>
                                Threat Reports ({this.state.pagination.totalRecords || 0})
                            </AppTitle>
                            <Button
                                onClick={() => this.setState({dialogOpen: true})}
                                disabled={this.state.analysis.pending || this.state.fetchingReports}
                                color="secondary"
                                variant={'contained'}
                                size={'small'}
                                endIcon={<TroubleshootIcon/>}
                            >Add</Button>
                        </Stack>
                    </Toolbar>
                </AppToolbar>

                <Stack
                    sx={{mt: theme => theme.spacing(8), height: theme => `calc(100vh - ${theme.spacing(12)})`}}
                    spacing={2}>

                    <Stack width={'100%'} justifyContent={'space-between'} alignItems={'center'} direction={'row'}
                           spacing={1}>

                        <DebouncedTextField disabled={this.state.analysis.pending || this.state.fetchingReports}
                                            showSpinner={this.state.fetchingReports && this.state.filter?.trim() !== ''}
                                            placeholder={'Search by name or id'}
                                            sx={{width: '60%'}}
                                            filter={this.state.filter}
                                            onValueChanged={(filter) => this.setState({filter: filter})}
                                            onSearch={(value) => {
                                                this.setState({
                                                    filter: value,
                                                    pagination: {...this.state.pagination, page: 0}
                                                }, () => {
                                                    this.addFilterToQueryParameter();
                                                    this.handleSearchAnalysis();
                                                })
                                            }}
                        />

                        <Stack gap={1} direction={'row'}>
                            {Object.values(ReportApprovalStatus).map((name) => {
                                let color: 'warning' | 'success' | 'error';
                                let nameOverride = name;
                                if (name === ReportApprovalStatus.PENDING) {
                                    color = 'warning';
                                    nameOverride = 'PENDING' as ReportApprovalStatus;
                                } else if (name === ReportApprovalStatus.APPROVED) {
                                    color = 'success';
                                } else {
                                    color = 'error'
                                }

                                return (
                                    <AppChip variant={(this.state.approvalStatusFilter.includes(name)) ? 'filled' : 'outlined'}
                                             color={color} key={name} label={nameOverride} size={'small'}
                                             onClick={() => {
                                                 let currentFilter = [...this.state.approvalStatusFilter];
                                                 if (currentFilter.includes(name)) {
                                                     currentFilter = currentFilter.filter((v) => v !== name);
                                                 } else {
                                                     currentFilter.push(name);
                                                 }

                                                 this.setState({
                                                     approvalStatusFilter: currentFilter,
                                                     pagination: {...this.state.pagination, page: 0}
                                                 }, () => {
                                                     this.addFilterToQueryParameter();
                                                     this.handleSearchAnalysis()
                                                 })
                                             }}/>
                                )

                            })}
                        </Stack>

                    </Stack>

                    <ThreatAnalysisTable
                        analysis={{
                            pending: this.state.analysis.pending,
                            value: this.filterOutRecentAnalysis()
                        }}
                        fetchingReports={this.state.fetchingReports}
                        onDelete={(id) => this.setState({deleteConfirmationDialog: {open: true, toDelete: id}})}
                        onClick={this.handleViewReport.bind(this)}
                        handleGetReportTypeIcon={this.handleGetReportTypeIcon}
                        setPagination={(page, rowsPerPage) => this.setState({
                            pagination: {
                                ...this.state.pagination,
                                page: page,
                                rowsPerPage: rowsPerPage
                            }
                        }, () => this.handleFetchThreatAnalysis())}
                        pagination={this.state.pagination}
                    />
                </Stack>

                {this.state.dialogOpen && (
                    <ThreatAnalysisDialog
                        open={this.state.dialogOpen}
                        action={ThreatAnalysisDialogActions.CREATE}
                        onClose={(company: ReportModel | null) =>
                            this.handleDialogClose(company)
                        }
                    />
                )}

                {this.state.errorDialog.opened && (
                    <ErrorDialog
                        error={this.state.errorDialog.details!}
                        onClose={() =>
                            this.setState({errorDialog: {opened: false, details: null}})
                        }
                    />
                )}

                {
                    (this.state.snackbar.open || this.state.fetchingReports) &&
                    <SimpleSnackbar
                        onClose={() =>
                            this.setState({snackbar: {message: "", open: false}})
                        }

                        open={this.state.snackbar.open || this.state.fetchingReports}
                        message={this.state.fetchingReports ? 'Fetching Reports!' : this.state.snackbar.message}
                    />
                }

                {
                    this.state.deleteConfirmationDialog.open && (
                        <ConfirmationDialog onClose={(confirmed) => {
                            const newState = {deleteConfirmationDialog: {open: false, toDelete: null}};
                            if (confirmed) {
                                this.threatAnalysisService
                                    .deleteAnalysis(this.state.deleteConfirmationDialog.toDelete!)
                                    .then(() => {
                                        this.setState({
                                            snackbar: {
                                                open: true,
                                                message: "Deleted report!",
                                            },
                                            analysis: {
                                                ...this.state.analysis,
                                                value: this.state.analysis.value.filter(
                                                    (report) => report._id !== this.state.deleteConfirmationDialog.toDelete!
                                                )
                                            },
                                            ...newState
                                        });
                                    })
                                    .catch((err) =>
                                        this.setState({errorDialog: {opened: true, details: err}, ...newState})
                                    );
                            } else {
                                this.setState(newState)
                            }
                        }}/>
                    )
                }
            </Box>
        );
    }
}


const WrappedComponent = withRouter(ThreatAnalysisView);
export default function ThreatAnalysis(props: ThreatAnalysisProps) {
    const appContext = useContext(DrawerStateContext);
    return <WrappedComponent {...props} drawerContext={appContext}/>
};