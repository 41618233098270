import {format} from "date-fns";
import React, {ReactElement} from "react";
import {
    FailedReportStatus,
    handleGetReportStatusColor, ReportAnalysis,
    ReportApprovalStatus,
    ReportModel,
    ReportStatus,
} from "../../../services/ThreatAnalysis/threatAnalysis.model";
import InfiniteTable, {InfiniteTableTableColumn} from "../../Common/TableHelper/InfiniteTable";
import {Stack} from "@mui/system";
import {AppChip} from "../../Common/common";
import {CircularProgress, IconButton, Tooltip, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportIcon from "@mui/icons-material/Report";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {DeleteOutline} from "@mui/icons-material";
import {PaginationRequest} from "../../../services/api-service";
import LockIcon from '@mui/icons-material/Lock';

interface ThreatAnalysisTableProps {
    analysis: { pending: boolean; value: ReportModel[] };
    fetchingReports: boolean;
    onDelete: (_id: string) => void;
    onClick: (_id: string) => void;
    handleGetReportTypeIcon: (report: ReportModel) => ReactElement<any, any>;
    pagination: PaginationRequest;
    setPagination: (page: number, rowsPerPage: number) => void;
}

interface ThreatAnalysisTableState {
}

export default class ThreatAnalysisTable extends React.Component<
    ThreatAnalysisTableProps,
    ThreatAnalysisTableState
> {
// this is based on your empty rows height setting



    render() {

        // Extract columns for the ReusableTable
        const columns: InfiniteTableTableColumn<ReportModel>[] = [
            {
                key: 'type' as keyof ReportModel,
                label: 'Type',
                render: (row: ReportModel) => this.props.handleGetReportTypeIcon(row),
                width: "5%"
            },
            {
                key: 'name',
                label: 'Name',
                render: (row: ReportModel) => row.name,
                width: "15%"
            },
            {
                key: 'status',
                label: 'Status',
                render: (row: ReportModel) => {
                    // Display logic for the status based on your existing code
                    return (
                        <Stack direction={"row"} spacing={1}>
                            <Typography fontSize={"15px"}>
                                {row.status.toLocaleLowerCase()}
                            </Typography>
                            {row.status == ReportStatus.COMPLETE ? (
                                <CheckCircleIcon
                                    color={handleGetReportStatusColor(row)}
                                />
                            ) : FailedReportStatus.includes(row.status) ? (
                                <ReportIcon
                                    color={handleGetReportStatusColor(row)}
                                />
                            ) : row.status == ReportStatus.SUBMITTED ? (
                                <CloudUploadIcon
                                    color={handleGetReportStatusColor(row)}
                                />
                            ) : (
                                <CircularProgress
                                    thickness={5}
                                    size={20}
                                    color={handleGetReportStatusColor(row)}
                                />
                            )}
                        </Stack>
                    );
                },
                width: "15%"
            },
            {
                key: 'approval_status' as keyof ReportModel,
                label: 'Approval Status',
                render: (row: ReportModel) => {
                    const pendingAnalysis = [];
                    const rejectedAnalysis = [];
                    const approvedAnalysis = [];

                    row.analysis?.forEach(analysis => {
                        if (analysis.approval_status == ReportApprovalStatus.PENDING) {
                            pendingAnalysis.push(analysis)
                        } else if (analysis.approval_status == ReportApprovalStatus.REJECTED) {
                            rejectedAnalysis.push(analysis)
                        } else {
                            approvedAnalysis.push(analysis)
                        }
                    });

                    if (row.status !== ReportStatus.EXTRACTING && row.status !== ReportStatus.ANALYZING && (pendingAnalysis.length || rejectedAnalysis.length || approvedAnalysis.length)) {
                        return (
                            <Stack>
                                {
                                    pendingAnalysis?.length > 0 && <AppChip
                                        sx={{maxWidth: '70%'}}
                                        size={"small"}
                                        // label={`${pendingAnalysis.length} pending approval`}
                                        label={`pending`}
                                        color={'warning'}
                                    />
                                }
                                {
                                    approvedAnalysis?.length > 0 && <AppChip
                                        sx={{maxWidth: '70%'}}
                                        size={"small"}
                                        // label={`${approvedAnalysis.length} approved`}
                                        label={`approved`}
                                        color={'success'}
                                    />
                                }
                                {
                                    rejectedAnalysis?.length > 0 && <AppChip
                                        sx={{maxWidth: '70%'}}
                                        size={"small"}
                                        // label={`${rejectedAnalysis.length} rejected`}
                                        label={`rejected`}
                                        color={'error'}
                                    />
                                }
                            </Stack>
                        )
                    }
                    return null;

                },
                width: "15%"
            },
            {
                key: 'approval_status_changed_by_display_name' as keyof ReportModel,
                label: 'Reviewed By',
                render: (row: ReportModel) => row.analysis?.length > 0 ? row.analysis[0].approval_status_changed_by_display_name : null,
                width: "15%"
            },
            {
                key: 'added_by_display_name',
                label: 'Ran By',
                render: (row: ReportModel) => row.added_by_display_name,
                width: "15%"
            },
            {
                key: 'added_on',
                label: 'Ran On',
                render: (row: ReportModel) => format(new Date(row?.added_on as string), "MM/dd/yyyy"),
                width: "10%"
            },
            {
                key: 'delete' as keyof ReportModel,
                label: '',
                render: (row: ReportModel) => {

                    if (row.locked_by !== null)
                        return (
                            <Tooltip title={`Locked by ${row.locked_by_display_name}`}>
                                <LockIcon color={'info'} fontSize={'small'}  />
                            </Tooltip>
                        )



                    return (
                        <IconButton
                            sx={{padding: 0}}
                            disabled={
                                row.status === ReportStatus.ANALYZING ||
                                row.status === ReportStatus.EXTRACTING
                            }
                            color="error"
                            onClick={(event) => {
                                event.stopPropagation();
                                this.props.onDelete(row._id);
                            }}
                        >
                            <DeleteOutline/>
                        </IconButton>
                    )



                },
                width: "5%"
            }
        ];

        return (
            <InfiniteTable
                data={this.props.analysis.value}
                columns={columns}
                pagination={this.props.pagination}
                loading={this.props.fetchingReports || this.props.analysis.pending}
                setPagination={this.props.setPagination}
                onClick={(report: ReportModel) => this.props.onClick(report._id)}
                onDelete={this.props.onDelete}
                noDataText={'No Reports'}
            />
        );
    }
}
