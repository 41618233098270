const toTitleCase = (value: string | undefined): string => {

    if (value) {
        if (value.includes("_")) {
            value = value.split("_").join(' ')
        }

        if (value.includes("-")) {
            value = value.split("-").join(' ')
        }

        return value.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    } else {
        return ''
    }
}

export default toTitleCase