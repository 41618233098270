import {ContentCopy} from "@mui/icons-material";
import {Box, CircularProgress, IconButton, Paper, Skeleton, Stack, Tab, Tabs, Typography,} from "@mui/material";
import React, {useState} from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportIcon from "@mui/icons-material/Report";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";

import {
    FailedReportStatus,
    handleGetReportStatusColor,
    ProccessingReportStatuses,
    ReportAnalysis,
    ReportApprovalStatus,
    ReportModel,
    ReportStatus,
} from "../../../../services/ThreatAnalysis/threatAnalysis.model";
import {ErrorDetails} from "../../../Common/ErrorDialog/ErrorDialog";
import {AnalysisWrapperDialog} from "./ReportAnalysis/Dialogs/AnalysisFormWrapperDialog";
import ViewAnalysis, {AnalysisInterfaces,} from "./ReportAnalysis/ViewAnalysis";
import toTitleCase from "../../../Common/Utils/AutoCompleteOptions/toTitleCase";
import {useModal} from "mui-modal-provider";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{width: "100%", m: 0, marginTop: "20px"}}>{children}</Box>
            )}
        </div>
    );
}

interface ThisProps {
    isMetadataExpanded: boolean;
    onSelectAnalysis: (analysis: ReportAnalysis) => void;
    selectedAnalysis: ReportAnalysis | null;
    onCopy: (event: any, value: any) => void;
    onUpdate: (analysis: ReportAnalysis) => void;
    onError: (error: ErrorDetails) => void;
    report: { value: ReportModel | null; pending: boolean };
    lockedByUser: boolean;
    selectedAnalysisAttributes: {
        value: {
            label: string,
            attributes: (keyof ReportAnalysis)[],
        }[],
        setAttributes: (label: string, attributes: (keyof ReportAnalysis)[]) => void
    }
}

interface ThisState {
    tabIndex: number;
    updateAnalysisDialog: { opened: boolean; interfaceType: AnalysisInterfaces };
}

const ViewAnalysisWrapper = (props: ThisProps)  => {
    const [tabIndex, setTabIndex] = useState(0);
    const {showModal} = useModal();
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        // setTabIndex(newValue);

        setTabIndex(newValue);


        // this.setState({tabIndex: newValue}, () => {
        //     if (this.props.report.value) {
        //         this.props.onSelectAnalysis(
        //             this.props.report.value!.analysis[this.state.tabIndex]
        //         );
        //     }
        // });
    };

    const doesEntryExistInText = (value: any): boolean => {
        if (value && props.report.value?.content) {
            const pattern = new RegExp(value.toString().trim(), "gi");
            return pattern.test(props.report.value?.content)
        }

        return false;
    }

    const handleEditAnalysis = (name: AnalysisInterfaces, key: keyof ReportAnalysis): void => {
        // this.setState({
        //     updateAnalysisDialog: {opened: true, interfaceType: name},
        // });
        //
        // <AnalysisWrapperDialog
        //     doesEntryExistInText={doesEntryExistInText.bind(this)}
        //     onClose={onClose.bind(this)}
        //     onError={onError.bind(this)}
        //     interfaceType={state.updateAnalysisDialog.interfaceType}
        //     analysis={props.selectedAnalysis!}
        // />


        showModal(AnalysisWrapperDialog, {doesEntryExistInText, onClose: (a) => onClose(a), onError, interfaceType: name, analysis: props.selectedAnalysis!, fieldKey: key })
    }

    const onError = (err: ErrorDetails): void => {
        props.onError(err);
    }

    const onClose = (analysis: ReportAnalysis | undefined): void => {
        if (analysis) {
            props.onUpdate(analysis);
        }
    }
    
    return (
        <Box key={JSON.stringify(props.isMetadataExpanded)} sx={{width: '100%', height: '100%'}}>
            {props.report.pending && <Skeleton height={"100vh"}/>}

            {
                !props.report.pending &&
                props.report.value?.status == ReportStatus.COMPLETE && (
                    // props.report.value?.analysis.map((analysis, index) => (
                    <Box sx={{width: '100%', flexGrow: 1}}>
                        {/*<Tabs*/}
                        {/*    value={tabIndex}*/}
                        {/*    onChange={handleTabChange}*/}
                        {/*    variant={'fullWidth'}*/}
                        {/*>*/}
                        {/*    {props.report.value?.analysis.map((a) => (*/}
                        {/*        <Tab*/}
                        {/*            key={a._id}*/}
                        {/*            label={*/}
                        {/*                <Stack*/}
                        {/*                    direction={"row"}*/}
                        {/*                    alignItems={"center"}*/}
                        {/*                    spacing={1}*/}
                        {/*                >*/}
                        {/*                    <Typography letterSpacing={1} fontSize={18}>*/}
                        {/*                        {a.threat_actor}*/}
                        {/*                    </Typography>*/}

                        {/*                    {a.approval_status == ReportApprovalStatus.PENDING ? (*/}
                        {/*                        <SdCardAlertIcon*/}
                        {/*                            fontSize={"small"}*/}
                        {/*                            color={"warning"}*/}
                        {/*                        />*/}
                        {/*                    ) : a.approval_status ==*/}
                        {/*                    ReportApprovalStatus.APPROVED ? (*/}
                        {/*                        <CheckCircleIcon*/}
                        {/*                            fontSize={"small"}*/}
                        {/*                            color={"success"}*/}
                        {/*                        />*/}
                        {/*                    ) : (*/}
                        {/*                        <ReportIcon fontSize={"small"} color={"error"}/>*/}
                        {/*                    )}*/}
                        {/*                </Stack>*/}
                        {/*            }*/}
                        {/*        />*/}
                        {/*    ))}*/}
                        {/*</Tabs>*/}
                        <Paper elevation={0}>
                            {props.report.value?.analysis.map((a, index) => (
                                <TabPanel
                                    key={a._id}
                                    value={tabIndex}
                                    index={index}
                                >
                                    <ViewAnalysis
                                        isMetadataExpanded={props.isMetadataExpanded}
                                        lockedByUser={props.lockedByUser}
                                        selectedAnalysisAttributes={props.selectedAnalysisAttributes}
                                        report={props.report}
                                        doesEntryExistInText={doesEntryExistInText.bind(this)}
                                        analysis={a}
                                        onCopy={props.onCopy.bind(this)}
                                        handleEditAnalysis={handleEditAnalysis.bind(this)}
                                    />
                                </TabPanel>
                            ))}
                        </Paper>
                    </Box>
                )
                // ))
            }

            {!props.report.pending &&
                [...ProccessingReportStatuses, ReportStatus.SUBMITTED].includes(
                    props.report?.value!.status
                ) && (
                    <Stack
                        height={"60vh"}
                        direction="row"
                        alignItems={"center"}
                        justifyContent={"center"}
                        spacing={3}
                    >
                        <Typography variant={"h4"}>
                            {props.report.value?.status}
                        </Typography>
                        <CircularProgress
                            color={handleGetReportStatusColor(props.report.value!)}
                        />
                    </Stack>
                )}

            {!props.report.pending &&
                FailedReportStatus.includes(props.report.value!.status) && (
                    <Stack
                        direction="column"
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{width: '100%'}}
                        spacing={3}
                    >
                        <Stack alignItems={'center'} direction="row" spacing={1}>
                            <Typography color={'error'} variant={"h6"}>
                                {toTitleCase(props.report.value?.status)}
                            </Typography>
                            <IconButton
                                onClick={(e) =>
                                    props.onCopy(e, props.report.value?.message)
                                }
                                className={"copyBTN"}
                                color="secondary"
                            >
                                <ContentCopy/>
                            </IconButton>
                        </Stack>

                        <Paper elevation={1} sx={{width: '100%'}}>
                            <Box
                                height={"80vh"}
                                width={'100%'}
                                overflow={"hidden"}
                                sx={{overflowY: "auto", textAlign: 'center'}}
                            >
                                <Typography
                                    color={'text.secondary'}
                                    padding={"15px 20px"}
                                    variant={"body1"}
                                    paragraph
                                >
                                    {props.report.value?.message}
                                </Typography>
                            </Box>
                        </Paper>
                    </Stack>
                )}
        </Box>
    );

}

export default ViewAnalysisWrapper;
// export class ViewAnalysisWrapper extends React.Component<ThisProps, ThisState> {
//     constructor(props: ThisProps) {
//         super(props);
//
//         this.state = {
//             tabIndex: 0,
//             updateAnalysisDialog: {
//                 opened: false,
//                 interfaceType: AnalysisInterfaces.ScopeOfAttack,
//             },
//         };
//     }
//
//     handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
//         // setTabIndex(newValue);
//         this.setState({tabIndex: newValue}, () => {
//             if (this.props.report.value) {
//                 this.props.onSelectAnalysis(
//                     this.props.report.value!.analysis[this.state.tabIndex]
//                 );
//             }
//         });
//     };
//
//     doesEntryExistInText = (value: any): boolean => {
//         if (value && this.props.report.value?.content) {
//             const pattern = new RegExp(value.toString().trim(), "gi");
//             return pattern.test(this.props.report.value?.content)
//         }
//
//         return false;
//     }
//
//     handleEditAnalysis(name: AnalysisInterfaces): void {
//         this.setState({
//             updateAnalysisDialog: {opened: true, interfaceType: name},
//         });
//     }
//
//     onError(err: ErrorDetails): void {
//         this.props.onError(err);
//     }
//
//     onClose(analysis: ReportAnalysis | undefined): void {
//         if (analysis) {
//             this.props.onUpdate(analysis);
//         }
//
//         this.setState({
//             updateAnalysisDialog: {
//                 ...this.state.updateAnalysisDialog,
//                 opened: false,
//             },
//         });
//     }
//
//     render(): React.ReactNode {
//         return (
//             <Box key={JSON.stringify(this.props.isMetadataExpanded)} sx={{width: '100%', height: '100%'}}>
//                 {this.props.report.pending && <Skeleton height={"100vh"}/>}
//
//                 {
//                     !this.props.report.pending &&
//                     this.props.report.value?.status == ReportStatus.COMPLETE && (
//                         // this.props.report.value?.analysis.map((analysis, index) => (
//                         <Box sx={{width: '100%', flexGrow: 1}}>
//                             <Tabs
//                                 value={this.state.tabIndex}
//                                 onChange={this.handleTabChange}
//                                 variant={'fullWidth'}
//                             >
//                                 {this.props.report.value?.analysis.map((a) => (
//                                     <Tab
//                                         key={a._id}
//                                         label={
//                                             <Stack
//                                                 direction={"row"}
//                                                 alignItems={"center"}
//                                                 spacing={1}
//                                             >
//                                                 <Typography letterSpacing={1} fontSize={18}>
//                                                     {a.threat_actor}
//                                                 </Typography>
//
//                                                 {a.approval_status == ReportApprovalStatus.PENDING ? (
//                                                     <SdCardAlertIcon
//                                                         fontSize={"small"}
//                                                         color={"warning"}
//                                                     />
//                                                 ) : a.approval_status ==
//                                                 ReportApprovalStatus.APPROVED ? (
//                                                     <CheckCircleIcon
//                                                         fontSize={"small"}
//                                                         color={"success"}
//                                                     />
//                                                 ) : (
//                                                     <ReportIcon fontSize={"small"} color={"error"}/>
//                                                 )}
//                                             </Stack>
//                                         }
//                                     />
//                                 ))}
//                             </Tabs>
//                             <Paper elevation={0}>
//                                 {this.props.report.value?.analysis.map((a, index) => (
//                                     <TabPanel
//                                         key={a._id}
//                                         value={this.state.tabIndex}
//                                         index={index}
//                                     >
//                                         <ViewAnalysis
//                                             isMetadataExpanded={this.props.isMetadataExpanded}
//                                             lockedByUser={this.props.lockedByUser}
//                                             selectedAnalysisAttributes={this.props.selectedAnalysisAttributes}
//                                             report={this.props.report}
//                                             doesEntryExistInText={this.doesEntryExistInText.bind(this)}
//                                             analysis={a}
//                                             onCopy={this.props.onCopy.bind(this)}
//                                             handleEditAnalysis={this.handleEditAnalysis.bind(this)}
//                                         />
//                                     </TabPanel>
//                                 ))}
//                             </Paper>
//                         </Box>
//                     )
//                     // ))
//                 }
//
//                 {!this.props.report.pending &&
//                     [...ProccessingReportStatuses, ReportStatus.SUBMITTED].includes(
//                         this.props.report?.value!.status
//                     ) && (
//                         <Stack
//                             height={"60vh"}
//                             direction="row"
//                             alignItems={"center"}
//                             justifyContent={"center"}
//                             spacing={3}
//                         >
//                             <Typography variant={"h4"}>
//                                 {this.props.report.value?.status}
//                             </Typography>
//                             <CircularProgress
//                                 color={handleGetReportStatusColor(this.props.report.value!)}
//                             />
//                         </Stack>
//                     )}
//
//                 {!this.props.report.pending &&
//                     FailedReportStatus.includes(this.props.report.value!.status) && (
//                         <Stack
//                             direction="column"
//                             alignItems={"center"}
//                             justifyContent={"center"}
//                             sx={{width: '100%'}}
//                             spacing={3}
//                         >
//                             <Stack alignItems={'center'} direction="row" spacing={1}>
//                                 <Typography color={'error'} variant={"h6"}>
//                                     {toTitleCase(this.props.report.value?.status)}
//                                 </Typography>
//                                 <IconButton
//                                     onClick={(e) =>
//                                         this.props.onCopy(e, this.props.report.value?.message)
//                                     }
//                                     className={"copyBTN"}
//                                     color="secondary"
//                                 >
//                                     <ContentCopy/>
//                                 </IconButton>
//                             </Stack>
//
//                             <Paper elevation={1} sx={{width: '100%'}}>
//                                 <Box
//                                     height={"80vh"}
//                                     width={'100%'}
//                                     overflow={"hidden"}
//                                     sx={{overflowY: "auto", textAlign: 'center'}}
//                                 >
//                                     <Typography
//                                         color={'text.secondary'}
//                                         padding={"15px 20px"}
//                                         variant={"body1"}
//                                         paragraph
//                                     >
//                                         {this.props.report.value?.message}
//                                     </Typography>
//                                 </Box>
//                             </Paper>
//                         </Stack>
//                     )}
//
//                 {this.state.updateAnalysisDialog.opened && (
//                     <AnalysisWrapperDialog
//                         doesEntryExistInText={this.doesEntryExistInText.bind(this)}
//                         onClose={this.onClose.bind(this)}
//                         onError={this.onError.bind(this)}
//                         interfaceType={this.state.updateAnalysisDialog.interfaceType}
//                         analysis={this.props.selectedAnalysis!}
//                     />
//                 )}
//             </Box>
//         );
//     }
// }
