import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Autocomplete from '@mui/material/Autocomplete'
import {styled, Theme} from "@mui/material/styles";
import {CircularProgress, Stack, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";

import {debounce} from 'lodash';
import Paper from "@mui/material/Paper";
import {FormFieldMeta} from "./FormValidators";
import ErrorToolTip from "./TooltipError";
import {lighten, SxProps} from "@mui/system";

interface Props {
    placeholder?: string;
    formKey: string;
    readOnly?: boolean
    disabled?: boolean
    helpText?: string;
    type?: string;
    formField: FormFieldMeta;
    required?: boolean
    handleInputChange: (key: string, value: string) => void;
    endAdornment?: JSX.Element;
}

export const FormGroupField = styled(Stack)(({theme}) => ({
    gap: theme.spacing(1),
    color: theme.palette.secondary.main,
    flexDirection: 'row'
}));

export const FormGroup = styled(Stack)(({theme}) => ({
    gap: theme.spacing(2)
}));

export const FormField = styled(Stack)(({theme}) => ({
    gap: theme.spacing(1),
    width: '100%'
}));


interface FormFieldsProps {
    theme?: any;
    gap?: number;
}

export const FormFields = styled(Stack, {
    shouldForwardProp: (prop) => prop !== 'theme' && prop !== 'gap',
})<FormRowProps>(({theme, gap}) => ({
    gap: theme.spacing(gap || 2),
    padding: theme.spacing(2)
}));

export const FormPaper = styled(Paper)(({theme}) => ({
    borderRadius: '15px'
}));


interface FormRowProps {
    gap?: number;
    theme?: any;

    alignItems?: string;
}

export const FormRow = styled(Stack, {
    shouldForwardProp: (prop) => prop !== 'theme' && prop !== 'gap' && prop !== 'alignItems',
})<FormRowProps>(({theme, gap, alignItems}) => ({
    width: '100%',
    flexDirection: 'column', // Set the default value to 'column'
    '@media (min-width: 600px)': {
        flexDirection: 'column', // Set the value for xs and sm screens
    },
    '@media (min-width: 960px)': {
        flexDirection: 'row', // Set the value for md (medium) and larger screens
    },
    gap: theme.spacing(gap || 1),
    alignItems: alignItems || 'center'
}));


export const StyledFormGroupLabel = styled(Typography)(({theme}) => ({
    fontWeight: 'bold',
    fontSize: '1.1em'
}));

export const StyledFormFieldLabel = styled(Typography)(({theme}) => ({
    fontSize: '.95em',
    fontWeight: 'bold'
}));

export const StyledFormTextField = styled(TextField)(({theme}) => ({
    width: '100%',
    required: true,
    input: {
        background: lighten(theme.palette.background.paper, .05)
    },
    p: {
        color: `${theme.palette.text.secondary} !important`
    }
}));


export const FormTextField = (props: Props) => {
    const {formKey, formField, handleInputChange} = props;
    const [inputFocused, setInputFocused] = useState(false);


    return (
        <ErrorToolTip
            open={!!formField.error && formField.initialized && !inputFocused}
            message={formField.error}
            children={<StyledFormTextField value={formField.value}
                                           placeholder={props.placeholder}
                                           type={props.type}
                                           onFocus={() => setInputFocused(true)}
                                           onBlur={() => setInputFocused(false)}
                                           error={!!formField.error}
                                           helperText={props.helpText}
                                           disabled={props.disabled}
                                           InputProps={{readOnly: props.readOnly, endAdornment: props.endAdornment}}
                                           onChange={(e) =>
                                               handleInputChange(formKey, e.target.value)}
                                           size={'small'}
                                           variant={'outlined'}/>}/>
    )
}


interface IMultiTextField {
    placeholder?: string;
    formKey: string;
    readOnly?: boolean
    disabled?: boolean
    type?: string;
    formField: FormFieldMeta;
    required?: boolean
    handleInputChange: (key: string, value: string) => void;
    rows?: number;
}


export const FormMuliTextField = (props: IMultiTextField) => {
    const {formKey, formField, handleInputChange} = props;
    const [inputFocused, setInputFocused] = useState(false);

    return (
        <ErrorToolTip
            open={!!formField.error && formField.initialized && !inputFocused}
            message={formField.error}
            children={<StyledFormTextField value={formField.value}
                                           placeholder={props.placeholder}
                                           type={props.type}
                                           onFocus={() => setInputFocused(true)}
                                           onBlur={() => setInputFocused(false)}
                                           error={!!formField.error}
                                           rows={props.rows || 5}
                                           multiline
                                           disabled={props.disabled}
                                           InputProps={{readOnly: props.readOnly}}
                                           onChange={(e) =>
                                               handleInputChange(formKey, e.target.value)}
                                           size={'small'}
                                           variant={'outlined'}/>}/>
    )
}

interface IFormAutocompleteProps extends Props {
    sx?: SxProps<Theme>;
    options: any[],
    getOptionLabel?: (option: any) => string
    renderOption?: (props: any, option: any) => React.ReactElement
    onSelection?: (value: any) => void;
    helpText?: string;
    // validation fn to see if input string is valid
    inputValue?: string;
    inputValueValidation?: (value: string) => boolean;
    setInputValue?: (v: string) => void;
    open?: boolean;
    readOnly?: boolean;

    loading?: boolean;
    disableSelectBtn?: boolean;
    freeSolo?: boolean;

}

/**
 *
 * Loading indicator will only be shown IF loading is present in the props.
 *
 * @param props
 * @constructor
 */

export const FormAutocompleteField: React.FC<IFormAutocompleteProps> = (props: IFormAutocompleteProps) => {
    const [inputFocused, setInputFocused] = useState(false);

    /**
     *
     * This could be optimized by not making an extra query search when the option the user is typing is right there
     *
     */

    const [userIsTyping, setUserIsTyping] = useState(false);

    const {formKey, formField, handleInputChange} = props;
    const [input, setInput] = useState(props.inputValue || '');


    const debouncedOnChange = useRef(
        debounce((value: string) => {
            props.setInputValue!(value);
            setUserIsTyping(false);
        }, 500)
    ).current;


    useEffect(() => {
        return () => {
            debouncedOnChange.cancel();
        };
    }, [debouncedOnChange]);


    const loading = (userIsTyping || props.loading) && props.loading !== undefined;
    return (
        <ErrorToolTip
            open={!!formField.error && formField.initialized && !inputFocused}
            message={formField.error}

            children={
                <Autocomplete
                    sx={{...props.sx}}
                    onFocus={() => setInputFocused(true)}
                    onBlur={() => setInputFocused(false)}
                    value={formField.value}
                    onChange={(event: any, newValue: any | null) => {
                        handleInputChange(formKey, newValue || '');

                        if (props.onSelection) {
                            props.onSelection(newValue);
                        }

                        setUserIsTyping(false);

                    }}
                    inputValue={input}
                    autoHighlight
                    freeSolo={props.freeSolo}
                    readOnly={props.readOnly}
                    disableClearable
                    open={props.open}
                    disabled={props.disabled}
                    options={props.options}
                    onInputChange={(e, newInputValue) => {
                        // Input value is passed in when we're actively making API calls.
                        // there is no need to track if user is typing if no input value is
                        // passed in.
                        if (!userIsTyping && props.inputValue)
                            setUserIsTyping(true); // Set loading state when input changes

                        if (props.inputValueValidation) {
                            const isValid = props.inputValueValidation(newInputValue);

                            if (isValid && props.onSelection) {
                                props.onSelection(newInputValue);
                                setUserIsTyping(false);
                            } else if ((isValid || newInputValue?.trim() === '') || props.freeSolo) {
                                handleInputChange(formKey, newInputValue);
                            }

                        } else if (props.freeSolo)
                            debouncedOnChange(newInputValue)

                        if (props.setInputValue) {
                            debouncedOnChange(newInputValue)
                        }

                        setInput(newInputValue)
                    }}
                    loading={loading}
                    renderInput={(params) => {
                        return (
                            <StyledFormTextField
                                placeholder={props.placeholder}
                                error={!!formField.error}
                                type={props.type}
                                {...params}
                                disabled={props.disabled}
                                InputProps={{
                                    ...params.InputProps,
                                    inputProps: {
                                        ...params.inputProps,
                                        readOnly: props.readOnly,
                                    },
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color={'info'} size={15}/> : null}
                                            {!props.disableSelectBtn && params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                size="small"
                                variant="outlined"

                            />
                        )
                    }}

                />
            }
        />
    );
};