import React from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {User} from "../services/User/user.model";


export interface AuthProps {
    user: User | null | undefined,
    userPending: boolean;
    isAdminOrAnalyst: boolean,
    isCompany: boolean,
    isAdmin: boolean,
}

/**
 * has three states
 * undefined -> firebase is checking
 * null -> user is signed out
 * user -> active user
 */
export const AuthContext = React.createContext<AuthProps>({user: undefined, userPending: false, isAdmin: false, isCompany: false, isAdminOrAnalyst: false});