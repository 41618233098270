import {ReportedIncidentModel} from "../../../../services/ReportedIncident/reportedIncident.model";
import React, {useState} from "react";
import {
    darken,
    Dialog,
    DialogContent,
    DialogTitle, Divider,
    IconButton,
    ListItem,
    ListItemText,
    Paper,
    Theme,
    Typography, useTheme
} from "@mui/material";
import {Box, Stack} from "@mui/system";
import {JSONTree} from "react-json-tree";
import {Clear, ContentCopy} from "@mui/icons-material";
import {useClipboard} from "../../../../hooks/useClipboard";
import {format} from "date-fns";

interface Props {
    incident: ReportedIncidentModel
}



const jsonTreeTheme = {
    base00: "transparent",
};


export const ViewReportedIncidentDialog = ({incident}: Props) => {
    const [open, setOpen] = useState(true);

    const clipboard = useClipboard();

    const theme = useTheme();
    return (
        <Dialog open={open} fullWidth maxWidth={'lg'}>
            <DialogTitle>
                <Stack gap={2}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        Reported Incident
                        <IconButton onClick={() => setOpen(false)} color={'error'} size={'small'}><Clear /></IconButton>
                    </Stack>



                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-around'}>
                        <Stack>
                            <Typography variant={'subtitle2'} fontWeight={600}>{incident.status}</Typography>
                            <Typography variant={'caption'} color={'text.secondary'}>Status</Typography>
                        </Stack>

                        <Divider orientation={'vertical'} flexItem sx={{height: '35px'}}/>

                        <Stack>
                            <Typography variant={'subtitle2'} fontWeight={600}>{format(new Date(incident.added_on), "MM/dd/yyyy H:mm:ss")}</Typography>
                            <Typography variant={'caption'} color={'text.secondary'}>Added On</Typography>
                        </Stack>

                        <Divider orientation={'vertical'} flexItem sx={{height: '35px'}}/>

                        <Stack>
                            <Typography variant={'subtitle2'} fontWeight={600}>{incident.stix_type}</Typography>
                            <Typography variant={'caption'} color={'text.secondary'}>Incident Type</Typography>
                        </Stack>

                        <Divider orientation={'vertical'} sx={{height:'35px'}} flexItem/>

                        <Stack>
                            <Typography variant={'subtitle2'} fontWeight={600}>{incident.investigation_status_updated_on ? format(new Date(incident.investigation_status_updated_on), "MM/dd/yyyy H:mm:ss") : '---'}</Typography>
                            <Typography variant={'caption'} color={'text.secondary'}>Last Updated</Typography>
                        </Stack>

                    </Stack>

                </Stack>
            </DialogTitle>

            <Divider />

            <DialogContent>
                <Stack gap={2} sx={{ width: '100%'}} direction={'row'}>
                    <Stack sx={{width: '100%'}} spacing={1}>
                        <Typography fontSize={'1rem'} fontWeight={600}>Stix Object</Typography>
                        <Paper sx={{width: '100%'}}>
                            <Box sx={{position: 'relative'}}>

                                <JSONTree
                                    getItemString={(type, data, itemType, itemString, keyPath) =>
                                        <span>{incident.stix.id}</span>}
                                    theme={jsonTreeTheme} data={incident.stix}/>

                                <IconButton
                                    onClick={(e) => clipboard.copy(incident.stix)}
                                    sx={{
                                        position: "absolute",
                                        top: '0',
                                        right: "0",
                                        background: (theme: Theme) => darken(theme.palette.secondary.main, .8)
                                    }}
                                    color="secondary"
                                >
                                    <ContentCopy/>
                                </IconButton>
                            </Box>
                        </Paper>
                    </Stack>

                    <Stack sx={{width: '100%'}} spacing={1}>
                        <Typography fontSize={'1rem'} fontWeight={600}>Observations</Typography>
                        <Paper sx={{width: '100%', height: '100%', borderRadius: '15px'}} elevation={3}>
                            <Box sx={{padding: theme.spacing(2)}}>
                                <Typography flex={1}>No observation has been made.</Typography>
                            </Box>
                        </Paper>
                    </Stack>
                </Stack>

            </DialogContent>

        </Dialog>
    )
}