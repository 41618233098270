import React, {useCallback, useState} from 'react';
import DrawerStateContext from '../context/DrawerContext';

interface DrawerStateProviderProps {
    children: React.ReactNode; // Define children as a prop
}

export const DrawerStateProvider: React.FC<DrawerStateProviderProps> = ({children}) => {
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(true);
    const contentPadding = .85

    const toggleDrawer = useCallback(() => {
        setDrawerOpen(prevState => !prevState);
    }, []);

    return (
        <DrawerStateContext.Provider value={{isDrawerOpen, toggleDrawer, contentPadding}}>
            {children} {/* Render children */}
        </DrawerStateContext.Provider>
    );
};
