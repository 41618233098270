import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import {CompanyThreatRansomwarePrediction} from "../../../../services/company/company.model";
import {Stack} from "@mui/system";
import {Card, CardContent, Typography, useTheme} from "@mui/material";
import { CardActionArea } from '@mui/material';
import toTitleCase from "../../../Common/Utils/AutoCompleteOptions/toTitleCase";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {AppChip} from "../../../Common/common";


interface Props {
    prediction: CompanyThreatRansomwarePrediction
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const TopRansomwareGroupsExplainedDialog = ({prediction}: Props) => {
    const [open, setOpen] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const theme = useTheme();
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            maxWidth={'md'}
            fullWidth
            onClose={handleClose}
        >
            <DialogTitle sx={{mb: theme.spacing(1)}}>
                <Stack justifyContent={'space-between'} alignItems={'center'} width={'100%'}  direction={'row'}>
                    <Typography variant={'h6'}>{toTitleCase(prediction.threat_actor)} Prediction</Typography>
                    <AppChip  color={prediction.safe ? 'success' : 'error'} label={prediction.safe ? 'Safe' : 'Unsafe'} />
                </Stack>
            </DialogTitle>


            <DialogContent>
                <Stack gap={2}>
                    <DialogContentText id="alert-dialog-slide-description">
                        These top three reasons behind our model's prediction regarding your company's safety status.
                        It aims to provide insights into why your company was deemed safe or not based on our analysis.
                    </DialogContentText>
                    <Stack gap={1} direction={'row'}>

                        {
                            Object.keys(prediction.rationale).map((key, index) =>
                                <Card key={index}>
                                    <CardActionArea>
                                        <CardContent>
                                            <Stack alignItems={'center'} justifyContent={'space-between'} sx={{width: '100%'}} direction={'row'}>
                                                <Typography gutterBottom variant="body1" fontWeight={600} component="div">
                                                    {prediction.rationale[key].type}
                                                </Typography>
                                                <Typography sx={{color: prediction.rationale[key].value > 0 ? theme.palette.success.main : theme.palette.error.main}} gutterBottom variant="body1" fontWeight={600} component="div">
                                                    {prediction.rationale[key].value > 0 && '+'}{(prediction.rationale[key].value * 100).toFixed(0)}%
                                                </Typography>

                                            </Stack>
                                            <Typography variant="body2" color="text.secondary">
                                                {prediction.rationale[key].explanation}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            )
                        }


                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}