import {DeleteOutline, EditOutlined,} from "@mui/icons-material";
import {IconButton,} from "@mui/material";
import {format} from "date-fns";
import React from "react";
import {CompanyModel} from "../../../services/company/company.model";
import withRouter from "../../Common/Utils/WithRouter/WithRouter";
import {Stack} from "@mui/system";
import {AppChip} from "../../Common/common";
import InfiniteTable, {InfiniteTableTableColumn} from "../../Common/TableHelper/InfiniteTable";
import {ReportModel} from "../../../services/ThreatAnalysis/threatAnalysis.model";
import {UserGroup} from "../../../services/User/user.model";

interface CompanyProfilerTableProps {
    _companies: { pending: boolean; value: CompanyModel[] };
    filter: string;
    router: any;
    onDelete: (id: string) => void;
    onEdit: (c: CompanyModel) => void;
    fetchingCompanies: boolean;
    pagination: { page: number, rowsPerPage: number, totalRecords: number };
    setPagination: (page: number, rowsPerPage: number) => void;
}



class CompanyProfilerTable extends React.Component<
    CompanyProfilerTableProps,
    any
> {

    constructor(props: CompanyProfilerTableProps) {
        super(props);
        this.state = {};
    }

    handleTableRowClick = (
        row: CompanyModel
    ) => {
        this.props.router.navigate(row.id);
    };


    render(): React.ReactNode {


        const columns: InfiniteTableTableColumn<CompanyModel>[] = [
            {
                key: 'name',
                label: 'Name',
                width: '35%'
            },
            {
                key: 'sectors',
                label: 'Sectors',
                width: '40%',
                render: (row: CompanyModel) => {
                    let sectorColumn = row.sectors?.map(sector =>
                        <AppChip key={sector.id} sx={{
                            mr: 1,
                            fontWeight: 600,
                        }} color={'secondary'}
                                 size={'small'}
                                 label={sector.name}/>
                    )


                    if (sectorColumn.length > 3) {
                        sectorColumn = sectorColumn.splice(0, 2);
                        sectorColumn.push(
                            <AppChip key={-1} sx={{mr: 1, fontWeight: 600, borderWidth: '2px'}}
                                     color={'warning'}
                                     size={'small'}
                                     label={' + ' + (row.sectors?.length - 2).toString() + ' more'}/>
                        )

                    } else if (sectorColumn.length === 0) {
                        sectorColumn.push(
                            <AppChip key={0} sx={{
                                mr: 1,
                                fontWeight: 600,
                            }} color={'secondary'}
                                     size={'small'}
                                     label={'No Sectors'}/>
                        )
                    }

                    return (
                        <Stack direction={'row'} alignItems={'center'}>
                            {
                                sectorColumn
                            }
                        </Stack>
                    )
                }
            },
            {
                key: 'created',
                label: 'Added On',
                width: '20%',
                render: (row) => format(
                    new Date(row?.created as string),
                    "MM/dd/yyyy"
                )
            },


            {
                key: 'delete' as keyof CompanyModel,
                label: '',
                render: (row: CompanyModel) => (
                    <Stack direction={'row'}>
                        <IconButton
                            sx={{padding: 0}}
                            color="error"
                            onClick={(event) => {
                                event.stopPropagation();
                                this.props.onDelete(row.id);
                            }}
                        >
                            <DeleteOutline/>
                        </IconButton>
                    </Stack>
                ),
                width: "5%"
            },
        ]

        return (
            <InfiniteTable
                data={this.props._companies.value}
                columns={columns}
                pagination={this.props.pagination}
                setPagination={this.props.setPagination}
                loading={this.props.fetchingCompanies || this.props._companies.pending}
                onClick={(id) => this.handleTableRowClick(id)}
                onDelete={this.props.onDelete}
                noDataText={'No Companies'}
            />
        );
    }
}

export default withRouter(CompanyProfilerTable);
