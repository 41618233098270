import React, {ReactNode} from "react";
import {StixSector} from "../../../../../services/stixx/stixx.model";
import {SectorSelector} from "../../../../Common/Selectors/SectorSelector";
import {CommonChildProps} from "../CompanyDialog";

interface ThisProps extends CommonChildProps {
    sectors: { pending: boolean; value: StixSector[] };
}

interface ThisState {
}

export class SectorForm extends React.Component<ThisProps, ThisState> {
    constructor(props: ThisProps) {
        super(props);

        this.state = {};
    }

    componentDidMount(): void {
        this.validate();
    }

    validate = () => {
        let error = null;
        if (this.props.company.sectors.length == 0) {
            error = "Atleast one sector is required";
        }

        this.props.onError({...this.props.errors, sectors: error});
    };

    render(): ReactNode {
        return (
            <SectorSelector
                includeAdhoc={false}
                choices={this.props.sectors}
                error={this.props.errors.sectors}
                sectors={this.props.company.sectors}
                helperText={
                    '"Industry Sectors" here refer to the sectors of the economy that this organization belongs to, as classified under the STIX (Structured Threat Information Expression) 2.1 Open Vocab standards.'
                }
                onUpdate={(value: StixSector[]) => {
                    const company = {...this.props.company};
                    company.sectors = [...value, ...company.sectors];
                    this.props.onUpdate(company, this.validate);
                }}
                onDelete={(index: number) => {
                    const company = {...this.props.company};

                    company.sectors.splice(index, 1);
                    this.props.onUpdate(company, this.validate);
                }}
            />
        );
    }
}
