import {Box, Container, Tab, Tabs} from "@mui/material";
import {data} from "iso-3166-2";
import React from "react";
import {ReportAnalysis,} from "../../../../../../../services/ThreatAnalysis/threatAnalysis.model";
import {TargetCountryData} from "../TargetEntites/TargetEntitesForm";
import {ThreatActorAliases} from "./ThreatActorAliases";
import {ThreatActorDetails} from "./ThreatActorDetails";
import {ThreatActorIntentForm} from "./ThreatActorIntent";
import {ThreatActorMotivationForm} from "./ThreatActorMotivation";
import {ThreatActorResourcesForm} from "./ThreatActorResources";
import ThreatActorService from "../../../../../../../services/ThreatActor/threatActor.service";
import {ErrorDetails} from "../../../../../../Common/ErrorDialog/ErrorDialog";
import {SharedAnalysisFormProps} from "../AnalysisFormWrapperDialog";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{width: "100%", m: 0, marginTop: "20px"}}>{children}</Box>
            )}
        </div>
    );
}

interface ThisProps extends SharedAnalysisFormProps {
    onUpdate: (analysis: ReportAnalysis) => void;
    onError: (error: ErrorDetails) => void;
}

interface ThisState {
    tabIndex: number;
    countries: TargetCountryData[];
    threatActors: { pending: boolean, value: string[] }
    loading: boolean;
}

export class ThreatActorForm extends React.Component<ThisProps, ThisState> {
    private threatActorService: ThreatActorService = new ThreatActorService()

    constructor(props: ThisProps) {
        super(props);
        this.state = {
            tabIndex: 0,
            countries: [],
            loading: true,
            threatActors: {pending: true, value: []}
        };
    }

    componentDidMount(): void {
        this.generateCountries();
        this.fetchThreatActors();
        this.setState({loading: false});
    }

    generateCountries(): void {
        const countries = [];
        for (let country_iso_code of Object.keys(data)) {
            countries.push({
                country: data[country_iso_code].name,
                iso_code: country_iso_code,
            });
        }

        this.setState({countries: countries});
    }

    fetchThreatActors(): void {
        this.threatActorService.getThreatActors()
            .then(res => {
                this.setState({threatActors: {pending: false, value: res.map(v => v.name)}})
            })
            .catch(err => {
                this.setState({threatActors: {pending: false, value: []}})
                this.props.onError(err);
            })
    }


    onUpdate(value: ReportAnalysis): void {
        this.props.onUpdate({...value});
    }



    render(): React.ReactNode {
        const generalFields: (keyof ReportAnalysis)[] = ['threat_actor', 'threat_actor_sophistication', 'threat_actor_type', 'country_of_origin']

        return (
                <Box>
                    {
                        generalFields.includes(this.props.fieldKey) &&
                        <ThreatActorDetails
                            fieldKey={this.props.fieldKey}
                            threatActors={this.state.threatActors}
                            analysis={this.props.analysis}
                            onUpdate={this.onUpdate.bind(this)}
                            countries={this.state.countries}
                        />
                    }
                    {
                        this.props.fieldKey === 'aliases' &&
                        <ThreatActorAliases
                            threatActors={this.state.threatActors}
                            analysis={this.props.analysis}
                            onUpdate={this.onUpdate.bind(this)}
                        />
                    }

                    {
                        this.props.fieldKey === 'threat_actor_intent' &&
                        <ThreatActorIntentForm
                            analysis={this.props.analysis}
                            onUpdate={this.onUpdate.bind(this)}
                        />
                    }

                    {
                        this.props.fieldKey === 'threat_actor_motives' &&
                        <ThreatActorMotivationForm
                            analysis={this.props.analysis}
                            onUpdate={this.onUpdate.bind(this)}
                        />
                    }

                    {
                        this.props.fieldKey === 'threat_actor_resources' &&
                        <ThreatActorResourcesForm
                            analysis={this.props.analysis}
                            onUpdate={this.onUpdate.bind(this)}
                        />
                    }
                </Box>
        );
    }
}
