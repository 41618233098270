import React, {useEffect, useState} from "react";
import {Box, Stack} from "@mui/system";
import {
    emailValidator,
    FormType,
    handleValidation,
    requiredValidator,
    ValidationFn
} from "../Common/FormBuilder/NEW/FormValidators";
import {FormField, FormFields, FormTextField, StyledFormFieldLabel} from "../Common/FormBuilder/NEW/FormField";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {ReportGmailerrorred} from "@mui/icons-material";
import {CyberObservable} from "./AnonymousLandingPage";
import toTitleCase from "../Common/Utils/AutoCompleteOptions/toTitleCase";
import List from "@mui/material/List";
import ReportIncidentService from "../../services/ReportedIncident/reportIncident.service";
import {ErrorDialog} from "../Common/ErrorDialog/ErrorDialog";
import {useModal} from "mui-modal-provider";


interface Props {
    type: CyberObservable;
    onClose: (reported: boolean) => void;
}



interface FormProps {
    form: {value: FormType, setForm: (form: FormType) => void},
    submitting: boolean;
    handleInputChange: (key: string, value: string) => void;
    handleInitialization: (form: FormType) => void;
}



export const AnonymousIncidentReportDialog = (props: Props) => {
    const reportService = new ReportIncidentService(true);
    const {showModal} = useModal();

    const [open, setOpen] = useState(true)
    const [submitting, setSubmitting] = useState(false);
    const [form, setForm] = useState<FormType>({})




    const handleInputChange = (key: string, value: string) => {
        const validationError = handleValidation(key, value, form)
        setForm({...form, [key]: {...form[key], value: value, error: validationError, initialized: true}})
    }

    const handleClose = (wasReported = false) => {
        props.onClose(wasReported);
        setOpen(false)
    }

    const handleInitialization = (localForm: FormType) => {
        for (const key of Object.keys(localForm)) {
            localForm[key].error = handleValidation(key, localForm[key].value, localForm);
        }

        setForm(localForm)
    }



    const handleSubmit = () => {
        setSubmitting(true);


        const obj: any = {type: props.type}
        for (let key of Object.keys(form)) {
            obj[key] = form[key].value
        }

        reportService.reportIncident(props.type, obj)
            .then(() => {
                handleClose(true)
            })
            .catch((err) => {
                setSubmitting(false)
                showModal(ErrorDialog, {error: err})
            })



    }

    const theme = useTheme()
    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth>
            <DialogTitle>Report {toTitleCase(props.type)} Incident</DialogTitle>

            <DialogContent>
                {
                    props.type === 'autonomous-system' &&
                    <AutonomousSystemForm handleInitialization={(f: FormType) => handleInitialization(f)} form={{value: form, setForm: setForm}} submitting={submitting} handleInputChange={handleInputChange}/>
                }
                {
                    props.type === 'domain-name' &&
                    <DomainNameForm handleInitialization={(f: FormType) => handleInitialization(f)} form={{value: form, setForm: setForm}} submitting={submitting} handleInputChange={handleInputChange}/>
                }

                {
                    props.type === 'ipv6-addr' &&
                    <IPv6Form handleInitialization={(f: FormType) => handleInitialization(f)} form={{value: form, setForm: setForm}} submitting={submitting} handleInputChange={handleInputChange}/>
                }

                {
                    props.type === 'ipv4-addr' &&
                    <IPv4Form handleInitialization={(f: FormType) => handleInitialization(f)} form={{value: form, setForm: setForm}} submitting={submitting} handleInputChange={handleInputChange}/>
                }

                {
                    props.type === 'email-addr' &&
                    <EmailAddrForm handleInitialization={(f: FormType) => handleInitialization(f)} form={{value: form, setForm: setForm}} submitting={submitting} handleInputChange={handleInputChange}/>
                }


                {
                    props.type === 'mac-addr' &&
                    <MacAddressForm handleInitialization={(f: FormType) => handleInitialization(f)} form={{value: form, setForm: setForm}} submitting={submitting} handleInputChange={handleInputChange}/>
                }
            </DialogContent>

            <DialogActions>
                <Button sx={{color: theme.palette.text.primary}} onClick={() => handleClose(false)} size={'small'}>Cancel</Button>
                <LoadingButton                         disabled={Object.values(form).map(v => v.error).filter((err) => err !== null).length > 0}
                                                       variant={'contained'} onClick={handleSubmit} loading={submitting} endIcon={<ReportGmailerrorred />} loadingPosition={'end'} size={'small'}>Report</LoadingButton>
            </DialogActions>

        </Dialog>
    )
    
}





const AutonomousSystemForm = ({form, submitting, handleInitialization, handleInputChange}: FormProps) => {
    const numberKey = 'number';
    const nameKey = 'name';
    const rirKey = 'rir';

    const asnValidator = (): ValidationFn => (value, k: keyof FormType, form: FormType) => {
        const regex = /^(?:[1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;
        if (!regex.test(value.trim())) {
            return 'Invalid ASN Number';
        }
        return null;
    };

    useEffect(() => {
        handleInitialization({
            [numberKey]: {
                value: '',
                error: null,
                initialized: false,
                validators: [requiredValidator, asnValidator()]
            },
            [nameKey]: {
                value: '',
                error: null,
                initialized: false,
                validators: []
            },
            [rirKey]: {
                value: '',
                error: null,
                initialized: false,
                validators: []
            },
        })
    }, []);

    return (
        <Box>
            {
                Object.keys(form.value).length > 0 &&
                <FormFields gap={3}>
                    <FormField>
                        <StyledFormFieldLabel>ASN Number</StyledFormFieldLabel>
                        <FormTextField
                            required={true}
                            placeholder={'ASN Number'}
                            formKey={numberKey}
                            disabled={submitting}
                            helpText={'Specifies the number assigned to the AS. Such assignments are typically performed by a Regional Internet Registry (RIR).'}
                            handleInputChange={(key, value) => handleInputChange(key, value)}
                            formField={form.value[numberKey]}/>
                    </FormField>

                    <FormField>
                        <StyledFormFieldLabel>ASN Name</StyledFormFieldLabel>
                        <FormTextField
                            required={true}
                            placeholder={'ASN Name'}
                            formKey={nameKey}
                            disabled={submitting}
                            helpText={'Specifies the name of the AS'}
                            handleInputChange={(key, value) => handleInputChange(key, value)}
                            formField={form.value[nameKey]}/>
                    </FormField>

                    <FormField>
                        <StyledFormFieldLabel>Regional Internet Registry</StyledFormFieldLabel>
                        <FormTextField
                            required={true}
                            placeholder={'RIR'}
                            formKey={rirKey}
                            disabled={submitting}
                            helpText={'Specifies the name of the Regional Internet Registry (RIR) that assigned the number to the AS.'}
                            handleInputChange={(key, value) => handleInputChange(key, value)}
                            formField={form.value[rirKey]}/>
                    </FormField>
                </FormFields>
            }
        </Box>
    )

}

const EmailAddrForm = ({form, submitting, handleInitialization, handleInputChange}: FormProps) => {
    const emailKey = 'value';
    const displayName = 'display_name';

    useEffect(() => {
        handleInitialization({
            [emailKey]: {
                value: '',
                error: null,
                initialized: false,
                validators: [requiredValidator, emailValidator]
            },
            [displayName]: {
                value: '',
                error: null,
                initialized: false,
                validators: []
            }
        })
    }, []);

    return (
        <Box>
            {
                Object.keys(form.value).length > 0 &&
                <FormFields gap={3}>
                    <FormField>
                        <StyledFormFieldLabel>Email Address</StyledFormFieldLabel>
                        <FormTextField
                            required={true}
                            placeholder={'Email Address'}
                            formKey={emailKey}
                            disabled={submitting}
                            helpText={'Specifies the value of the email address. This MUST NOT include the display name. This property corresponds to the addr-spec construction in section 3.4 of [RFC5322], for example, jane.smith@example.com.'}
                            handleInputChange={(key, value) => handleInputChange(key, value)}
                            formField={form.value[emailKey]}/>
                    </FormField>

                    <FormField>
                        <StyledFormFieldLabel>Display Name</StyledFormFieldLabel>
                        <FormTextField
                            required={true}
                            placeholder={'Display Name'}
                            formKey={displayName}
                            disabled={submitting}
                            helpText={'Specifies a single email display name, i.e., the name that is displayed to the human user of a mail application. This property corresponds to the display-name construction in section 3.4 of [RFC5322], for example, Jane Smith.'}
                            handleInputChange={(key, value) => handleInputChange(key, value)}
                            formField={form.value[displayName]}/>
                    </FormField>
                </FormFields>
            }
        </Box>
    )

}

const MacAddressForm = ({form, submitting, handleInitialization, handleInputChange}: FormProps) => {
    const macKey = 'value';

    const macValidator = (): ValidationFn => (value, k: keyof FormType, form: FormType) => {
        const regex = /^([0-9a-f]{2}:){5}[0-9a-f]{2}$/;
        if (!regex.test(value.trim().toLowerCase())) {
            return 'Invalid Mac Address';
        }
        return null;
    };

    useEffect(() => {
        handleInitialization({
            [macKey]: {
                value: '',
                error: null,
                initialized: false,
                validators: [requiredValidator, macValidator()]
            }
        })

        // handleInitialization()
    }, []);




    return (
        <Box>
            {
                Object.keys(form.value).length > 0 &&
                <FormFields gap={3}>
                    <FormField>
                        <StyledFormFieldLabel>Mac Address</StyledFormFieldLabel>
                        <FormTextField
                            required={true}
                            placeholder={'Mac Address'}
                            formKey={macKey}
                            disabled={submitting}
                            helpText={'Specifies the value of a single MAC address. The MAC address value MUST be represented as a single colon-delimited, lowercase MAC-48 address, which MUST include leading zeros for each octet.'}
                            handleInputChange={(key, value) => handleInputChange(key, value)}
                            formField={form.value[macKey]}/>
                    </FormField>
                </FormFields>
            }
        </Box>
    )

}


const DomainNameForm = ({form, submitting, handleInitialization, handleInputChange}: FormProps) => {
    const domainNameKey = 'value';


    useEffect(() => {
        handleInitialization({
            [domainNameKey]: {
                value: '',
                error: null,
                initialized: false,
                validators: [requiredValidator]
            }

        })

    }, []);

    return (
        <Box>
            {
                Object.keys(form.value).length > 0 &&
                <FormFields gap={3}>
                    <FormField>
                        <StyledFormFieldLabel>Domain Name</StyledFormFieldLabel>
                        <FormTextField
                            required={true}
                            placeholder={'Domain Name'}
                            formKey={domainNameKey}
                            disabled={submitting}
                            helpText={'Specifies the value of the domain name. The value of this property MUST conform to [RFC1034], and each domain and sub-domain contained within the domain name MUST conform to [RFC5890].'}
                            handleInputChange={(key, value) => handleInputChange(key, value)}
                            formField={form.value[domainNameKey]}/>
                    </FormField>
                </FormFields>
            }
        </Box>
    )

}

const IPv4Form = ({form, submitting, handleInitialization, handleInputChange}: FormProps) => {
    const ipKey = 'value';

    const ipv4Validator = (): ValidationFn => (value, k: keyof FormType, form: FormType) => {
        const regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){2}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        if (!regex.test(value.trim())) {
            return 'Invalid IPv4 Address';
        }
        return null;
    };



    useEffect(() => {
        handleInitialization({
            [ipKey]: {
                value: '',
                error: null,
                initialized: false,
                validators: [requiredValidator, ipv4Validator()]
            }

        })

    }, []);

    return (
        <Box>
            {
                Object.keys(form.value).length > 0 &&
                <FormFields gap={3}>
                    <FormField>
                        <StyledFormFieldLabel>IPv4 Address</StyledFormFieldLabel>
                        <FormTextField
                            required={true}
                            placeholder={'IPv4 Address'}
                            formKey={ipKey}
                            disabled={submitting}
                            helpText={'Specifies the values of one or more IPv4 addresses expressed using CIDR notation. If a given IPv4 Address object represents a single IPv4 address, the CIDR /32 suffix MAY be omitted.'}
                            handleInputChange={(key, value) => handleInputChange(key, value)}
                            formField={form.value[ipKey]}/>
                    </FormField>
                </FormFields>
            }
        </Box>
    )

}


const IPv6Form = ({form, submitting, handleInitialization, handleInputChange}: FormProps) => {
    const ipKey = 'value';

    const ipv6Validator = (): ValidationFn => (value, k: keyof FormType, form: FormType) => {
        const regex = /^((([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4}|:))|(([0-9a-fA-F]{1,4}:){1,6}:([0-9a-fA-F]{1,4}|:))|(([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2})|(([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3})|(([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4})|(([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5})|([0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6}))|(:((:[0-9a-fA-F]{1,4}){1,7}|:))|(((2[0-4][0-9]|25[0-5]|1[0-9][0-9]|[1-9]?[0-9])(\.(2[0-4][0-9]|25[0-5]|1[0-9][0-9]|[1-9]?[0-9])){3})|([0-9a-fA-F]{1,4}:){1,7}:))$/;
        if (!regex.test(value.trim().toLowerCase())) {
            return 'Invalid IPv6 Address';
        }
        return null;
    };


    useEffect(() => {
        handleInitialization({
            [ipKey]: {
                value: '',
                error: null,
                initialized: false,
                validators: [requiredValidator, ipv6Validator()]
            }

        })

    }, []);

    return (
        <Box>
            {
                Object.keys(form.value).length > 0 &&
                <FormFields gap={3}>
                    <FormField>
                        <StyledFormFieldLabel>IPv6 Address</StyledFormFieldLabel>
                        <FormTextField
                            required={true}
                            placeholder={'IPv6 Address'}
                            formKey={ipKey}
                            disabled={submitting}
                            helpText={'\t\n' +
                                'Specifies the values of one or more IPv6 addresses expressed using CIDR notation. If a given IPv6 Address object represents a single IPv6 address, the CIDR /128 suffix MAY be omitted.'}
                            handleInputChange={(key, value) => handleInputChange(key, value)}
                            formField={form.value[ipKey]}/>
                    </FormField>
                </FormFields>
            }
        </Box>
    )

}


interface ResolvesToRefsProps {
    availableTypes: string[];
    values: {value: string; type: string}[]
}
export const ResolvesToRefs = ({availableTypes}: ResolvesToRefsProps) => {

    return (
        <List>



        </List>
    )

}