import {CompanyThreatRansomware} from "../../../../services/company/company.model";
import {Stack, Typography} from "@mui/material";
import React from "react";
import {TopRansomwareGroupsTableComponent} from "./TopRansomwareGroupsTable";
import {AppChip} from "../../../Common/common";
import {Box} from "@mui/material";

interface Props {
    risk: { pending: boolean, value: CompanyThreatRansomware | null }
}

export const TopRansomwareGroupsComponent = ({risk}: Props) => {
    return (
        <Stack height={'100%'} gap={1}>


            <Stack gap={1} direction={'row'}>
                <Typography variant={'h6'} flexGrow={1}>
                    Top Ransomware Threats
                </Typography>

                <AppChip size={'small'} color={'success'} label={`${risk.value?.total_safe || 0} Safe`} />
                <AppChip size={'small'} color={'error'} label={`${risk.value?.total_unsafe || 0} Unsafe`} />
            </Stack>

            <Box sx={{overflowY: 'auto'}}>
                <TopRansomwareGroupsTableComponent predictions={risk.value?.predictions || []} />
            </Box>

        </Stack>
    )
}