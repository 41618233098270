// todo convert all methods using this to TenPointSccale
export enum TenPointScaleNumerical {
    NONE = 0,
    EXTREMELY_LOW = 1,
    VERY_LOW = 2,
    LOW = 3,
    SOMEWHAT_LOW = 4,
    MEDIUM = 5,
    SOMEWHAT_HIGH = 6,
    HIGH = 7,
    VERY_HIGH = 8,
    EXTREMELY_HIGH = 9
}


export enum TenPointScale {
    NONE = "None",
    EXTREMELY_LOW = "Extremely Low",
    VERY_LOW = "Very Low",
    LOW = "Low",
    SOMEWHAT_LOW = "Somewhat Low",
    MEDIUM = "Medium",
    SOMEWHAT_HIGH = "Somewhat High",
    HIGH = "High",
    VERY_HIGH = "Very High",
    EXTREMELY_HIGH = "Extremely High"
}



export type TenPointScaleKeys = keyof typeof TenPointScale;



function formatEnumKey(key: string): string {
    return key.split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

export function getOptionLabelForHML(value: TenPointScaleNumerical | undefined): string {
    if (value == undefined)
        return "";

    return formatEnumKey(TenPointScaleNumerical[value]);
}


export interface PaginationResult<T> {
    count: number,
    next: null | string,
    previous: null | number,
    results: T[]
}