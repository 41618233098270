import {ThreatActor} from "../../../../../services/ThreatActor/threatActor.model";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import ThreatActorDetailsBanner from "./ThreatActorDetailsBanner";
import ThreatActorTargetBanner from "./ThreatActorTargetBanners";
import ThreatActorAttributes from "./ThreatActorAttributes/ThreatActorAttributes";
import ThreatActorCapabilities from "./ThreatActorCapabilities/ThreatActorCapabilities";

interface ThisProps {
    threatActor: ThreatActor
}

const listItemLabelStyle = {
    width: '35%',
    fontWeight: '600 !important',
    alignSelf: 'flex-start !important',
};

const listItemStyle = {
    opacity: '.8'
};

// const listBulletPointStyle = {
//     padding: "4px",
//     display: "list-item",
//     color: (theme: Theme) => theme.palette.primary.main,
// };

const nestedListStyle = {
    padding: 1,
    // maxHeight: "175px",
    maxHeight: '100%',
    overflow: "hidden",
    overflowY: "auto",
};

const nestedListValueStyle = {
    width: '75%'
}


const ThreatActorOverview: React.FC<ThisProps> = (props: ThisProps) => {

    return (
        <Grid container sx={{height: '100%', width: '100%'}}>
            {/* This Grid contains the two stacked children */}
            <Grid xs={6} sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <Grid sx={{flex: 1}} container direction="column" spacing={1}>
                    <Grid xs={12}>
                        <ThreatActorDetailsBanner threatActor={props.threatActor}/>
                    </Grid>
                    <Grid sx={{flex: 1}} xs={12}>
                        <ThreatActorCapabilities threatActor={props.threatActor}/>
                    </Grid>
                    {/*<Grid xs={12}>*/}
                    {/*    <ThreatActorTargetBanner threatActor={props.threatActor}/>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>

            <Grid xs={6} sx={{height: '100%'}} container>
                <ThreatActorAttributes threatActor={props.threatActor}/>
            </Grid>
        </Grid>
    );
}

export default ThreatActorOverview;