import React, {useContext, useEffect, useState} from "react";
import {Box, lighten, Stack} from "@mui/system";
import {
    Autocomplete,
    Backdrop,
    Button,
    IconButton,
    TextField,
    Theme,
    Toolbar,
    Typography,
    useTheme
} from "@mui/material";
import AppBar from '@mui/material/AppBar';
import {NavLink, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import CompanyService from "../../services/company/company.service";
import {Company, CompanyModel} from "../../services/company/company.model";
import {useModal} from "mui-modal-provider";
import {ErrorDetails, ErrorDialog} from "../Common/ErrorDialog/ErrorDialog";
import PublicAuthContext from "../../context/PublicAuthContext";
import PublicLoadingScreen from "./PublicLoadingScreen";
import PublicHome from "./PublicHome";
import PublicSettings from "./PublicSettings";
import PublicHelp from "./PublicHelp";
import {AuthContext} from "../../context/AuthContext";
import {UserGroup} from "../../services/User/user.model";
import {ArrowBack, BusinessCenterOutlined, ExitToApp} from "@mui/icons-material";
import BusinessIcon from "@mui/icons-material/Business";
import {CompanyDialog} from "../internal-ui/CompanyProfiler/CompanyDialog/CompanyDialog";
import {auth} from "../../firebase";
import {FormAutocompleteField} from "../Common/FormBuilder/NEW/FormField";
import {defaultPagination, PaginationRequest} from "../../services/api-service";
import {FormType} from "../Common/FormBuilder/NEW/FormValidators";

interface ThisProps {
}

enum PublicURLS {
    home = '',
    settings = 'settings',
    help = 'help'
}

interface AppLink {
    label: string;
    url: PublicURLS;
}

const routes: AppLink[] = [
    // {
    //     label: "Home",
    //     url: PublicURLS.home,
    // },
    // {
    //     label: "Settings",
    //     url: PublicURLS.settings
    // }
];

const companyFormKey = 'company';

const PublicContentLandingPage: React.FC<ThisProps> = () => {
    const {showModal} = useModal();
    const theme = useTheme();
    const {user, isAdminOrAnalyst, isCompany} = useContext(AuthContext);

    const {company, setCompany} = useContext(PublicAuthContext);
    const [companies, setCompanies] = useState<{ pending: boolean, value: CompanyModel[] }>({pending: false, value: []})
    const companyService = new CompanyService();


    const [form, setForm] = useState<FormType>({
        [companyFormKey]: {
            value: '',
            error: null,
            initialized: false,
            validators: []
        },
    });


    useEffect(() => {
        if (user) {
            if (isAdminOrAnalyst) {
                fetchCompanies()
            } else if (isCompany && user?.company) {
                fetchCompany(user!.company)
            }
        }
    }, [user]);




    const [companyInputValue, setCompanyInputValue] = React.useState('');

    useEffect(() => {
        fetchCompanies();
    }, [companyInputValue]);


    const validateCompany = (company: CompanyModel) => {
        // This will continue showing the user the dialog until
        // they have completed all the values.
        setCompany({pending: false, value: company})
        if (company.is_incomplete) {
           showCompanyDialog(company);
        }
    }

    const showCompanyDialog = (company: CompanyModel) => {
        showModal(CompanyDialog, {
            company: company,
            onClose: (updated) => {
                if (updated)
                    setCompany({pending: false, value: updated})
            },
            onError: (err) => {
                showModal(ErrorDialog, {error: err})
            }
        })
    }

    const fetchCompany = (companyId: string) => {
        setCompany({...company, pending: true})
        companyService.getCompany(companyId)
            .then(res => {
                validateCompany(res);
            })
            .catch((err) => {
                showModal(ErrorDialog, {error: err})
                setCompany({value: null, pending: false})
            })
    }



    const fetchCompanies = () => {
        setCompanies({...companies, pending: true})
        companyService.getPaginatedCompanies(defaultPagination, companyInputValue)
            .then(res => {
                setCompanies({pending: false, value: res.results})
            })
            .catch((err: ErrorDetails) => {
                setCompanies({pending: false, value: []})
                showModal(ErrorDialog, {
                    error: err
                })
            })
    }



    //
    // useEffect(() => {
    //     if (company.value) {
    //         console.log("HERE");
    //     }
    //
    // }, [company.value])


    const navigate = useNavigate();
    return (
        <Box sx={{overflow: 'hidden', height: '100vh'}}>
            <AppBar position="fixed">
                <Toolbar>
                    <Stack direction={'row'} gap={2} alignItems={'center'}>
                        {
                            isAdminOrAnalyst && (
                                <IconButton sx={{padding: 0}} size={'small'} onClick={() => navigate('/')}>
                                    <ArrowBack  />
                                </IconButton>
                            )
                        }

                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{display: {xs: 'none', sm: 'block'}}} // flexGrow: 1
                        >
                            Threat View
                        </Typography>
                    </Stack>


                    {
                        isAdminOrAnalyst &&

                        <FormAutocompleteField
                            sx={{width: '60%', mr: 'auto', ml: 'auto'}}
                            required={true}
                            options={companies.value?.map(c => c.name) || []}
                            inputValueValidation={(coName: string) => {
                                return (
                                    companies.value
                                        ?.map((co: CompanyModel) => co.name.toLowerCase())
                                        .some((lowercaseCo) => lowercaseCo === coName.toLowerCase())
                                ) || false;
                            }}
                            freeSolo={true}
                            inputValue={companyInputValue}
                            setInputValue={setCompanyInputValue}
                            // disabled={createApplicant.isPending}
                            placeholder={'University of Arkansas At Little Rock'}
                            onSelection={(value: string) => {
                                const newCompany = companies.value?.find(co => co.name.toLowerCase().trim() === value.toLowerCase().trim());

                                fetchCompany(newCompany!.id!)
                            }}
                            formKey={companyFormKey}
                            loading={companies.pending}
                            handleInputChange={(key, value) => {
                                // handleInputChange(key, value);
                                if ((value?.trim() === '' && company.value !== null) || value?.trim()?.toLowerCase() !== company.value?.name?.trim()?.toLowerCase())
                                    setCompany({...company, value: null});
                            }}
                            formField={form[companyFormKey]}/>
                    }


                        <Stack sx={{ml: 'auto'}} direction={'row'} spacing={3}>
                            {
                                routes.map(route => (
                                    <Typography key={route.label} fontWeight={500} variant={'body1'} component={Box}>
                                        <NavLink
                                            className={({isActive}) => "nav-link" + (isActive ? "-active" : "")}
                                            end
                                            to={route.url}
                                        >
                                            {route.label}
                                        </NavLink>
                                    </Typography>
                                ))
                            }

                            <Button disabled={company.value === null} onClick={() => {
                                showCompanyDialog(company.value!);
                            }} size={'small'} sx={{color: theme.palette.text.primary}}>
                                Profile
                            </Button>

                            <Button onClick={() => {
                                showModal(PublicHelp)
                            }} size={'small'} sx={{color: theme.palette.text.primary}}>
                                Help
                            </Button>

                            <Button endIcon={<ExitToApp />} onClick={() => auth.signOut()}
                                    size={'small'} sx={{color: theme.palette.text.primary}}>
                                Logout
                            </Button>


                        </Stack>

                </Toolbar>
            </AppBar>
            {
                company.value ?
                    <Box key={company?.value?.id}
                         sx={{marginTop: theme.spacing(8), height: theme => `calc(100vh - ${theme.spacing(8)})`}}
                         paddingX={theme.spacing(2)}>

                        {
                            company.value?.is_incomplete &&
                            <Box sx={{
                                position: 'fixed', // Or 'absolute' depending on your layout
                                top: '64px', // Adjust this value based on your toolbar's height
                                left: 0,
                                width: '100%',
                                height: 'calc(100vh - 64px)', // Adjust this value to match the top offset
                                zIndex:  theme.zIndex.drawer + 1,
                                color: '#fff',
                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <Stack sx={{p: 2}} justifyContent={'center'} alignItems={'center'}>
                                    <Box sx={{background: theme.palette.background.paper, p: 3, width: '60%'}}>
                                        <Typography fontWeight={500}  variant={'body1'} textAlign="center">
                                            To view your threats, please complete the onboarding process for {company.value?.name} by clicking "profile" in the toolbar. For any questions
                                            or concerns, feel free to reach out.
                                        </Typography>
                                    </Box>

                                </Stack>
                            </Box>
                        }

                        <Routes>
                            <Route index element={<PublicHome/>}/>
                            <Route path={'/company-view/settings'} element={<PublicSettings/>}/>
                        </Routes>

                    </Box> :

                    isAdminOrAnalyst ?
                    <Box sx={{height: theme => `calc(100vh - ${theme.spacing(8)})`}} display={'flex'}
                         alignItems={'center'} justifyContent={'center'}>
                        {
                            (companies.pending) ?
                                <PublicLoadingScreen text={'Initializing'}/>
                                :
                                <Typography variant={'h5'} color={'text.secondary'}>
                                    Select Company
                                </Typography>
                        }
                    </Box> : <Box />
            }
        </Box>

    );
}

export default PublicContentLandingPage;
