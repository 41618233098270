import React from "react"
import {ComposableMap, Geographies, Geography} from "react-simple-maps"
import {ThreatActor} from "../../../../../services/ThreatActor/threatActor.model";

import 'iso-3166-2';
import {Fade, useTheme} from "@mui/material";

interface ThisProps {
    threatActor: ThreatActor,
    setCurrentView: () => void;
    countryLookupMap: Map<string, string>
    selectedCountry: string | null;

}

const geoUrl = "/maps/country_map.json";  // Assuming you've placed it under the maps folder in public

const calculateOpacity = (count: number) => {
    const maxCount = 10; // Adjust this value as needed
    return Math.min(count / maxCount, 1);
};

const ThreatActorTargetCountries: React.FC<ThisProps> = (props: ThisProps) => {
    const theme = useTheme();


    return (
        <Fade in={true} unmountOnExit>
            <ComposableMap projectionConfig={{
                scale: 125,
                center: [0, -30]
            }}>
                <Geographies geography={geoUrl}>
                    {/*@ts-ignore*/}
                    {({geographies}) =>
                        geographies.map((geo: any) => {
                            const countryData = props.threatActor.target_countries?.find(c => {
                                const countryName = props.countryLookupMap.get(c.toLowerCase());
                                const doesExist = countryName === geo.properties.name.toLowerCase();
                                if (doesExist && props.selectedCountry !== null) {
                                    return countryName == props.selectedCountry;
                                }

                                return doesExist;

                            });

                            let fillColor = "gray"; // Default color
                            if (!props.selectedCountry) {
                                if (countryData) {
                                    fillColor = `rgba(0, 0, 255, 1)`; // Using RGBA to set opacity
                                }
                            } else if (geo.properties.name.toLowerCase() == props.selectedCountry) {
                                fillColor = theme.palette.primary.main
                            }

                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill={fillColor}
                                    stroke="#EAEAEC"
                                    strokeWidth={0.5}
                                />
                            );
                        })
                    }
                </Geographies>

            </ComposableMap>
        </Fade>
    );
}

export default ThreatActorTargetCountries