import React from "react";
import {CircularProgress, IconButton, InputAdornment, TextField} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {ClearOutlined} from "@mui/icons-material";
import debounce from "lodash.debounce";

import {DebouncedFunc} from 'lodash';

interface ThisState {
    filter: string;
}

interface ThisProps {
    filter: string;
    onValueChanged: (filter: string) => void;
    onSearch: (filter: string) => void;
    disabled?: boolean;
    placeholder?: string;
    showSpinner?: boolean;
    fullWidth?: boolean;
    sx?: object;
    debounce?: number
}

export class DebouncedTextField extends React.Component<ThisProps, ThisState> {
    debouncedSearch: DebouncedFunc<(filter: string) => void>;
    textRef: any;

    constructor(props: ThisProps) {
        super(props);

        this.textRef = React.createRef();
        this.debouncedSearch = debounce(this.props.onSearch.bind(this), this.props.debounce || 1000); // Debounce of 300ms
    }


    onKeyPress = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // this.setState({filter: event.target.value}, () => {
        //     this.debouncedSearch(this.props.filter);
        // });
        //
        this.props.onValueChanged(event.target.value)
        this.debouncedSearch(event.target.value)
    }

    handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            this.props.onSearch(this.props.filter)
        }
    };

    componentDidUpdate(prevProps: ThisProps) {
        if (!this.props.disabled && prevProps.disabled !== this.props.disabled && this.props.filter.trim() !== '') {
            this.textRef.current.focus();
        }
    }

    render() {
        return (
            <TextField
                sx={{...this.props.sx}}
                disabled={this.props.disabled}
                variant="outlined"
                fullWidth={this.props.fullWidth ? this.props.fullWidth : false}
                size={'small'}
                onChange={(e) => this.onKeyPress(e)}
                onKeyDown={(e) => this.handleKeyDown(e)}
                inputRef={this.textRef}
                value={this.props.filter}
                color="primary"
                id="input-with-icon-textfield"
                placeholder={this.props.placeholder}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchOutlinedIcon color="primary"/>
                        </InputAdornment>
                    ),
                    endAdornment: (this.props.filter?.trim() !== '' || this.props.showSpinner) && (
                        <InputAdornment position="end">
                            {
                                this.props.filter.trim() !== '' && this.props.showSpinner ?
                                    <CircularProgress size={15}/>
                                    :

                                    <IconButton
                                        onClick={() => {
                                            this.props.onValueChanged('');
                                            this.props.onSearch('');
                                        }}
                                        color="primary"
                                    >
                                        <ClearOutlined/>
                                    </IconButton>
                            }
                        </InputAdornment>
                    ),
                }}
            />
        )
    }
}