import {ContentCopy, PersonSearch} from "@mui/icons-material";
import {
    Box,
    Button, CircularProgress,
    darken,
    Divider,
    IconButton,
    Paper,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import {format} from "date-fns";
import React, {useCallback, useEffect, useState} from "react";
import {JSONTree} from "react-json-tree";
import {
    handleGetReportApprovalStatusColor,
    handleGetReportStatusColor,
    ReportAnalysis,
    ReportApprovalStatus,
    ReportModel,
    ReportStatus,
    ReportType,
} from "../../../../services/ThreatAnalysis/threatAnalysis.model";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import {ErrorDetails, ErrorDialog} from "../../../Common/ErrorDialog/ErrorDialog";
import {ReportApprovalDialog} from "./ReportApprovalDialog";
import {lighten} from "@mui/system";
import {AppChip} from "../../../Common/common";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LockIcon from "@mui/icons-material/Lock";
import {ThreatActor} from "../../../../services/ThreatActor/threatActor.model";
import ThreatActorDialog from "../../../public-ui/PublicHomeComponents/ThreatActorDialog/ThreatActorDialog";
import {useModal} from "mui-modal-provider";

interface ViewRawReportProps {
    analysis: {
        pending: boolean;
        value: ReportModel | null
    };
    adversary: {
        pending: boolean;
        value: ThreatActor | null
    }
    lockedByUser: boolean;
    selectedAnalysis: null | ReportAnalysis;
    onCopy: (event: any, value: any) => void;
    onError: (error: ErrorDetails) => void;
    onUpdateReportContent: () => void;
    onAnalysisUpdate: (analysis: ReportAnalysis) => void;
    setShouldWiggle: () => void;
}

interface ViewRawReportState {
    tabIndex: number;
    snackbar: {
        open: boolean;
        message: string;
    };
    approvalDialog: {
        status: ReportApprovalStatus | null;
        message: string | null;
        opened: boolean;
    };
    threatActorDialog: {
        open: boolean,
    }
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const theme = {
    base00: "transparent",
};

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{width: "100%", m: 0, marginTop: "20px"}}>{children}</Box>
            )}
        </div>
    );
}


export default function ViewRawReport (props: ViewRawReportProps) {
    const summaryStyle = {
        lineHeight: "1.75rem",
        padding: ".625rem 1.25rem",
        color: (theme: Theme) => `${theme.palette.text.secondary}`,
    };

    const copySXItem = {
        position: "relative",
        "&:hover > .copyBTN": {
            opacity: 1,
        },
    };

    const copyButton = {
        position: "absolute",
        top: '.5rem',
        right: "1rem",
        background: (theme: Theme) => darken(theme.palette.secondary.main, .8)
    };


    const {showModal} = useModal();
    const [tabIndex, setTabIndex] = useState(0);


    // Event handler method, useCallback will ensure the function identity is stable
    const handleKeyDown = useCallback((event: any) => {
        if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key === 's') {
            event.preventDefault();
            if (props.lockedByUser) {
                handleToggleApprovalStatus();
            } else {
                props.setShouldWiggle();
            }
        }
    }, [props.lockedByUser, props.setShouldWiggle]);

    // Equivalent to componentDidMount and componentWillUnmount lifecycle methods
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        // Cleanup function analogous to componentWillUnmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]); // The dependencies array includes handleKeyDown


    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        // setTabIndex(newValue);
        setTabIndex(newValue)
    };

    const afterApprovalStatusDialogClose = ( status: ReportApprovalStatus | null, approval_message: string | null) => {
        if (status || approval_message) {
            const copy = {
                ...props.selectedAnalysis!,
                approval_status: status!,
                approval_message: approval_message,
            };

            props.onAnalysisUpdate(copy);
        }
    }

    const handleToggleApprovalStatus = (status: ReportApprovalStatus | null = null): void => {
        showModal(ReportApprovalDialog, {
            status: props.selectedAnalysis!.approval_status,
            message: props.selectedAnalysis!.approval_message,
            analysis: props.selectedAnalysis!,
            onError: (err) => showModal(ErrorDialog, {error: err}),
            onClose: (status: ReportApprovalStatus | null, message: string | null) => {
                afterApprovalStatusDialogClose(status, message)
            }})

    }

    const  generateMetaRow = (columnName: string, value: string | null | undefined) => {
        return (
            <Box
                onClick={(e) => props.onCopy(e, value)}
                sx={{
                    "&:hover": {
                        background: (theme) => lighten(theme.palette.background.default, 0.15),
                    },
                    cursor: 'copy',
                }}
                overflow={"hidden"}
            >
                <Tooltip title={value} placement="bottom">
                    <Stack
                        className={"row"}
                        padding={"5px"}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                    >
                        <Typography
                            className={'overflow'}
                            minWidth={"100px"}

                            fontSize={".9rem"}
                            lineHeight={'2rem'}
                            color={'text.secondary'}
                            sx={{mr: "10px", fontWeight: "600"}}
                        >
                            {columnName}:
                        </Typography>

                        <Typography
                            className={'overflow'}
                            fontSize={".9rem"}
                        >
                            {value ? value : "---"}
                        </Typography>
                    </Stack>
                </Tooltip>

                <Divider/>
            </Box>
        );
    }

        return (
            <Box sx={{padding: '5px 25px 25px 25px'}}>
                <Box sx={{mt: "30px"}}>
                    {props.analysis.pending ? (
                        <Box height={"100%"}>
                            <Skeleton height={"20vh"}/>

                            <Skeleton height={"80vh"}/>
                        </Box>
                    ) : (
                        <Box>
                            <Box sx={{mb: "30px"}}>
                                <Stack sx={{mb: "10px"}} direction={"row"}>
                                    <Typography
                                        sx={{mb: "10px", fontSize: "0.875rem"}}
                                        color={"primary"}
                                        fontWeight={"500"}
                                    >
                                        REPORT METADATA
                                    </Typography>
                                    <AppChip
                                        variant={"filled"}
                                        sx={{ml: "10px"}}
                                        size={"small"}
                                        label={props.analysis.value?.status}
                                        color={handleGetReportStatusColor(
                                            props.analysis.value!
                                        )}
                                    />
                                </Stack>
                                <Paper elevation={6}>
                                    <Box>
                                        <Stack
                                            direction={"row"}
                                            justifyContent={"space-between"}
                                            overflow={"hidden"}
                                        >
                                            <Stack width={"50%"}>
                                                {props.analysis.value &&
                                                    generateMetaRow(
                                                        "Report ID",
                                                        props.analysis.value?._id
                                                    )
                                                }

                                                {props.analysis.value &&
                                                    generateMetaRow("Ran By",
                                                        props.analysis.value?.added_by_display_name ?
                                                            props.analysis.value?.added_by_display_name
                                                            : props.analysis.value?.added_by
                                                    )
                                                }

                                                {
                                                    props.analysis.value &&
                                                    generateMetaRow(
                                                        'Locked By',
                                                        props.analysis.value.locked_by_display_name || '---'
                                                    )
                                                }

                                                {
                                                    props.analysis.value &&
                                                    generateMetaRow(
                                                        props.selectedAnalysis?.approval_status == ReportApprovalStatus.REJECTED ? 'Rejected By' : 'Reviewed By',
                                                        props.selectedAnalysis?.approval_status_changed_by_display_name ?
                                                            props.selectedAnalysis?.approval_status_changed_by_display_name :
                                                            props.selectedAnalysis?.approval_status_changed_by ? props.selectedAnalysis?.approval_status_changed_by :
                                                                '---'
                                                    )
                                                }


                                                {props.analysis.value?.report_type === ReportType.WEBSITE && (
                                                    <Stack>
                                                        {generateMetaRow(
                                                            "Website Title",
                                                            props.analysis.value?.website_title
                                                        )}
                                                    </Stack>
                                                )}

                                                <Stack>
                                                    {
                                                        props.analysis.value?.report_type === ReportType.FILE &&
                                                        generateMetaRow(
                                                            "File Name",
                                                            props.analysis.value?.file_name
                                                        )
                                                    }

                                                </Stack>
                                            </Stack>

                                            <Divider flexItem orientation="vertical"/>

                                            <Stack width={"50%"}>

                                                {props.analysis.value &&
                                                    generateMetaRow(
                                                        "Analysis ID",
                                                        props.selectedAnalysis?._id
                                                    )
                                                }

                                                {props.analysis.value &&
                                                    generateMetaRow(
                                                        "Ran On",
                                                        format(
                                                            new Date(
                                                                props.analysis.value?.added_on as string
                                                            ),
                                                            "MM/dd/yyyy HH:mm:ss"
                                                        )
                                                    )
                                                }


                                                {
                                                    props.analysis.value &&
                                                    generateMetaRow(
                                                        'Locked On',
                                                        props.analysis.value.locked_on ? format(
                                                            new Date(
                                                                props.analysis.value.locked_on as string
                                                            ),
                                                            "MM/dd/yyyy HH:mm:ss"
                                                        ): '---'
                                                    )
                                                }

                                                {props.analysis.value &&
                                                    generateMetaRow(
                                                        'Reviewed On',
                                                        props.selectedAnalysis?.approval_status_changed_on ? format(
                                                            new Date(
                                                                props.selectedAnalysis?.approval_status_changed_on as string
                                                            ),
                                                            "MM/dd/yyyy HH:mm:ss"
                                                        ) : '---'
                                                    )}



                                                {props.analysis.value?.website_url && (
                                                    <Stack>
                                                        {props.analysis.value?.report_type === ReportType.WEBSITE &&
                                                            generateMetaRow(
                                                                "Website URL",
                                                                props.analysis.value?.website_url
                                                            )}
                                                    </Stack>
                                                )}

                                                {
                                                    props.analysis.value?.report_type === ReportType.FILE &&
                                                    <Stack>
                                                        {generateMetaRow(
                                                            "File Type",
                                                            props.analysis.value?.file_type
                                                        )}
                                                    </Stack>
                                                }
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Paper>
                            </Box>

                            <Divider/>
                            <Box margin={"25px 0 0 0 "}>
                                {props.analysis.value?.status == ReportStatus.COMPLETE && (
                                    <Stack
                                        mb={"10px"}
                                        direction={"row"}
                                        justifyContent={"space-between"}
                                        alignItems={"center"}
                                    >
                                        <Typography fontSize={20} fontWeight={"600"}>
                                            {props.selectedAnalysis?.threat_actor} Analysis
                                        </Typography>

                                        {props.selectedAnalysis?.approval_status !==
                                            ReportApprovalStatus.PENDING && (
                                                <Tooltip
                                                    disableHoverListener={
                                                        !props.selectedAnalysis?.approval_message
                                                    }
                                                    title={
                                                        <Typography>
                                                            {props.selectedAnalysis?.approval_message}
                                                        </Typography>
                                                    }
                                                >
                                                    <AppChip
                                                        sx={{ml: "20px", mr: "auto"}}
                                                        size={"small"}
                                                        label={props.selectedAnalysis?.approval_status}
                                                        onClick={() => props.lockedByUser && handleToggleApprovalStatus()}
                                                        color={handleGetReportApprovalStatusColor(
                                                            props.selectedAnalysis
                                                        )}
                                                    />
                                                </Tooltip>
                                            )}
                                        {props.selectedAnalysis?.approval_status ==
                                            ReportApprovalStatus.PENDING && (
                                                <Stack
                                                    sx={{mr: "20px"}}
                                                    height={"70%"}
                                                    direction="row"
                                                    spacing={1}
                                                >
                                                    <Button
                                                        onClick={() =>
                                                            handleToggleApprovalStatus(
                                                                ReportApprovalStatus.APPROVED
                                                            )
                                                        }
                                                        disabled={!props.lockedByUser}
                                                        size={"small"}
                                                        color={"success"}
                                                        variant="outlined"
                                                        startIcon={<ThumbUpOffAltIcon/>}
                                                    >
                                                        approve
                                                    </Button>

                                                    <Divider flexItem orientation="vertical"/>

                                                    <Button
                                                        onClick={() =>
                                                            handleToggleApprovalStatus(
                                                                ReportApprovalStatus.REJECTED
                                                            )
                                                        }
                                                        disabled={!props.lockedByUser}
                                                        size={"small"}
                                                        color={"error"}
                                                        variant="outlined"
                                                        startIcon={<ThumbDownOffAltIcon/>}
                                                    >
                                                        reject
                                                    </Button>
                                                </Stack>
                                            )}
                                    </Stack>
                                )}

                                <Tabs
                                    variant={'scrollable'}
                                    value={tabIndex}
                                    onChange={handleTabChange}
                                >
                                    <Tab label="Overview" {...a11yProps(0)} />
                                    <Tab label="Content" {...a11yProps(1)} />
                                    <Tab label={"Rational"} {...a11yProps(2)} />
                                    <Tab label={"Serialized Response"} {...a11yProps(3)} />
                                    <Tab label={"Raw Response"} {...a11yProps(4)} />
                                    <Tab label={"Adversary"} {...a11yProps(5)} />
                                </Tabs>

                                <Box
                                    sx={{
                                        maxHeight: "calc(100vh - 275px)",
                                        overflowY: "scroll",
                                        overflowX: 'auto'
                                    }}
                                >
                                    <Paper sx={copySXItem} elevation={6}>
                                        <TabPanel value={tabIndex} index={0}>
                                            <Typography
                                                paragraph
                                                variant={"body1"}
                                                sx={summaryStyle}
                                            >
                                                {props.selectedAnalysis?.threat_actor_overview
                                                    ? props.selectedAnalysis?.threat_actor_overview
                                                    : "No Content"}
                                            </Typography>
                                            <IconButton
                                                disabled={!props.selectedAnalysis?.threat_actor_overview}
                                                sx={copyButton}
                                                size={'large'}
                                                color="secondary"
                                                onClick={(e) =>
                                                    props.onCopy(
                                                        e,
                                                        props.selectedAnalysis?.threat_actor_overview
                                                    )
                                                }
                                            >
                                                <ContentCopy/>
                                            </IconButton>
                                        </TabPanel>
                                        <TabPanel value={tabIndex} index={1}>
                                            <Typography paragraph variant={"body1"} sx={summaryStyle}
                                            >
                                                {props.analysis.value?.content
                                                    ? props.analysis.value?.content

                                                    : "No Content"}
                                            </Typography>

                                            <IconButton
                                                size={'large'}
                                                disabled={!props.analysis.value?.content ||!props.lockedByUser}
                                                sx={{...copyButton, top: '4rem'}}
                                                color="primary"
                                                onClick={(e) =>
                                                    props.onUpdateReportContent()
                                                }
                                            >
                                                <EditOutlinedIcon/>
                                            </IconButton>

                                            <IconButton
                                                size={'large'}
                                                disabled={!props.analysis.value?.content}
                                                sx={copyButton}
                                                color="secondary"
                                                onClick={(e) =>
                                                    props.onCopy(
                                                        e,
                                                        props.analysis.value?.content
                                                    )
                                                }
                                            >
                                                <ContentCopy/>
                                            </IconButton>
                                        </TabPanel>
                                        <TabPanel value={tabIndex} index={2}>
                                            {props.selectedAnalysis?.rationale ? (
                                                <Box sx={{
                                                    width: '100%',      /* or a fixed width, depending on your requirements */
                                                    overflowX: 'auto',
                                                    whiteSpace: 'nowrap'
                                                }}>
                                                    <JSONTree
                                                        shouldExpandNodeInitially={() => true}
                                                        theme={theme}
                                                        data={props.selectedAnalysis.rationale}
                                                    />
                                                </Box>
                                            ) : (
                                                <Typography
                                                    paragraph
                                                    variant={"body1"}
                                                    sx={summaryStyle}
                                                >
                                                    No Content
                                                </Typography>
                                            )}
                                            <IconButton
                                                size={'large'}
                                                sx={copyButton}
                                                color="secondary"
                                                disabled={!props.selectedAnalysis?.rationale}
                                                onClick={(e) =>
                                                    props.onCopy(e, props.selectedAnalysis?.rationale)
                                                }
                                            >
                                                <ContentCopy/>
                                            </IconButton>
                                        </TabPanel>
                                        <TabPanel value={tabIndex} index={3}>
                                            {props.selectedAnalysis ? (
                                                <Box sx={{
                                                    width: '100%',      /* or a fixed width, depending on your requirements */
                                                    overflowX: 'auto',
                                                    whiteSpace: 'nowrap'
                                                }}>
                                                    <JSONTree
                                                        shouldExpandNodeInitially={() => true}
                                                        theme={theme}
                                                        data={JSON.parse(
                                                            props.selectedAnalysis!.raw_serialized_analysis
                                                        )}
                                                    />
                                                </Box>
                                            ) : (
                                                <Typography
                                                    paragraph
                                                    variant={"body1"}
                                                    sx={summaryStyle}
                                                >
                                                    No Content
                                                </Typography>
                                            )}
                                            <IconButton
                                                size={'large'}
                                                sx={copyButton}
                                                color="secondary"
                                                disabled={!props.selectedAnalysis?.raw_serialized_analysis}
                                                onClick={(e) =>
                                                    props.onCopy(
                                                        e,
                                                        props.selectedAnalysis?.raw_serialized_analysis
                                                    )
                                                }
                                            >
                                                <ContentCopy/>
                                            </IconButton>
                                        </TabPanel>
                                        <TabPanel value={tabIndex} index={4}>
                                            {props.selectedAnalysis ? (
                                                <Box sx={{
                                                    width: '100%',      /* or a fixed width, depending on your requirements */
                                                    overflowX: 'auto',
                                                    whiteSpace: 'nowrap'
                                                }}>
                                                    <JSONTree
                                                        shouldExpandNodeInitially={() => true}
                                                        theme={theme}
                                                        data={JSON.parse(
                                                            props.selectedAnalysis!.raw_openAI_analysis
                                                        )}
                                                    />
                                                </Box>
                                            ) : (
                                                <Typography
                                                    paragraph
                                                    variant={"body1"}
                                                    sx={summaryStyle}
                                                >
                                                    No Content
                                                </Typography>
                                            )}
                                            <IconButton
                                                size={'large'}
                                                sx={copyButton}
                                                color="secondary"
                                                disabled={!props.selectedAnalysis?.raw_openAI_analysis}
                                                onClick={(e) =>
                                                    props.onCopy(
                                                        e,
                                                        props.selectedAnalysis?.raw_openAI_analysis
                                                    )
                                                }
                                            >
                                                <ContentCopy/>
                                            </IconButton>
                                        </TabPanel>
                                        <TabPanel value={tabIndex} index={5}>
                                            {props.adversary.value || props.adversary.pending ? (
                                                <Box sx={{
                                                    width: '100%',      /* or a fixed width, depending on your requirements */
                                                    overflowX: 'auto',
                                                    whiteSpace: 'nowrap'
                                                }}>
                                                    {
                                                        props.adversary.pending ?
                                                            <CircularProgress /> :
                                                            <JSONTree
                                                                shouldExpandNodeInitially={() => true}
                                                                theme={theme}
                                                                data={props.adversary.value}
                                                            />
                                                    }
                                                </Box>
                                            ) : (
                                                <Typography
                                                    paragraph
                                                    variant={"body1"}
                                                    sx={summaryStyle}
                                                >
                                                    No Content
                                                </Typography>
                                            )}
                                            {
                                                props.adversary.value &&
                                                <IconButton
                                                    size={'large'}
                                                    disabled={!props.adversary.value}
                                                    sx={{...copyButton, top: '4rem'}}
                                                    color="primary"
                                                    onClick={(e) =>{
                                                        showModal(ThreatActorDialog, {cachedActor: props.adversary.value!, threatActorID: null})

                                                    }}
                                                >
                                                    <PersonSearch/>
                                                </IconButton>
                                            }
                                            <IconButton
                                                size={'large'}
                                                sx={copyButton}
                                                color="secondary"
                                                disabled={!props.adversary.value}
                                                onClick={(e) =>
                                                    props.onCopy(
                                                        e,
                                                        props.adversary.value
                                                    )
                                                }
                                            >
                                                <ContentCopy/>
                                            </IconButton>

                                        </TabPanel>
                                    </Paper>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>



            </Box>
        );
}