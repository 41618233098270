import React, {useEffect, useState} from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import {auth} from "../firebase";
import {AuthContext} from "../context/AuthContext";
import UserService from "../services/User/user.service";
import {useModal} from "mui-modal-provider";
import {User, UserGroup} from "../services/User/user.model";
import {ErrorDialog} from "../components/Common/ErrorDialog/ErrorDialog";

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [user, setUser] = useState<User | null | undefined>(undefined);
    const userService = new UserService();
    const {showModal} = useModal();
    const [userPending, setUserPending] = useState(false);

    useEffect(() => {
        const handleTokenChange = async (firebaseUser: firebase.User | null) => {
            if (firebaseUser) {
                getAppUser(firebaseUser.uid);
            } else {
                setUser(null);
            }
        };

        // const unsubscribeAuth = auth.onAuthStateChanged(handleTokenChange);
        const unsubscribeToken = auth.onIdTokenChanged(handleTokenChange);

        return () => {
            // unsubscribeAuth();
            unsubscribeToken();
        };
    }, []);

    useEffect(() => {
        const handleTokenChange = async (firebaseUser: firebase.User | null) => {
            if (firebaseUser) {
                getAppUser(firebaseUser.uid);
            } else {
                setUser(null);
            }
        };

        // const unsubscribeAuth = auth.onAuthStateChanged(handleTokenChange);
        const unsubscribeToken = auth.onIdTokenChanged(handleTokenChange);

        return () => {
            // unsubscribeAuth();
            unsubscribeToken();
        };
    }, []);



    const getAppUser = (userId: string) => {
        setUserPending(true)
        userService.getUser(userId)
            .then(res => {
                setUser(res)
                setUserPending(false)
            })
            .catch((err) => {
                setUser(null)
                setUserPending(false)
                showModal(ErrorDialog, {error: err})
            })
    }

    const isAdmin = () => {
        return user?.group === UserGroup.Admin;
    }
    const isAdminOrAnalyst = () => {
        return !!user?.group && [UserGroup.Admin, UserGroup.Analyst].includes(user.group);
    }

    const isCompany = () => {
        return !!user?.group && [UserGroup.Entity, UserGroup.NonAttributable].includes(user.group);
    }

    return <AuthContext.Provider value={{user, userPending, isAdmin: isAdmin(), isAdminOrAnalyst: isAdminOrAnalyst(), isCompany: isCompany()}}>{children}</AuthContext.Provider>;
};