import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import {visuallyHidden} from '@mui/utils';
import {CompanyRisk} from "../../../../services/company/company.model";
import toTitleCase from "../../../Common/Utils/AutoCompleteOptions/toTitleCase";
import {darken, styled, TableCellProps, TablePagination, Tooltip, useTheme} from "@mui/material";
import {lighten} from "@mui/system";
import {TenPointScale, TenPointScaleNumerical} from "../../../../services/globals";
import {Order} from "./RiskThreats";
import {format} from "date-fns";


interface ThisProps {
    order: Order;
    orderBy: string;

    setOrderBy: (o: keyof CompanyRisk) => void;
    setOrder: (o: Order) => void;

    companyRisk: { pending: boolean, value: CompanyRisk[] }
    selectedCompanyRisk: CompanyRisk | null;
    setSelectedCompanyRisk: (r: CompanyRisk | null) => void;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    // Check if the orderBy refers to the date field
    if (orderBy === 'threat_actor_relationship_identified') { // replace 'dateFieldName' with the actual name of your date field
        const dateA = new Date(a[orderBy] as string);
        const dateB = new Date(b[orderBy] as string);
        return dateB.getTime() - dateA.getTime();
    }

    // If it refers to the TenPointScale fields
    else if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
        const aVal = TenPointScaleNumerical[a[orderBy] as keyof typeof TenPointScaleNumerical];
        const bVal = TenPointScaleNumerical[b[orderBy] as keyof typeof TenPointScaleNumerical];
        return bVal - aVal;
    }

    // Default comparison
    else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
    }

    return 0;
}


function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    id: keyof CompanyRisk;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'threat_actor',
        numeric: false,
        label: 'Threat Actor',
    },
    {
        id: 'threat_actor_relationship_identified',
        numeric: false,
        label: 'Last Seen',
    },
    {
        id: 'likelihood_of_attack',
        numeric: false,
        label: 'Likelihood of Attack',
    },
    {
        id: 'impact_type',
        numeric: false,
        label: 'Impact'
    },
    {
        id: 'operational_risk',
        numeric: false,
        label: 'Operational',
    },
    {
        id: 'financial_risk',
        numeric: false,
        label: 'Financial',
    },
    {
        id: 'legal_compliance_risk',
        numeric: false,
        label: 'Legal Compliance',
    },
    {
        id: 'reputational_risk',
        numeric: false,
        label: 'Reputational',
    }
];

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof CompanyRisk) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {order, orderBy, rowCount, onRequestSort} =
        props;
    const createSortHandler =
        (property: keyof CompanyRisk) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


const StyledTableRow = styled(TableRow)(({theme, selected}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface StyledTableCellProps extends TableCellProps {
    riskLevel?: string;
}


const rowStyle = {
    "threat_actor": "12%",
    "likelihood_of_attack": "12%",
    "legal_compliance_risk": "12%",
    "operational_risk": "10.67%",
    "financial_risk": "10.67%",
    "reputational_risk": "10.67%",
    "impact_type": "10.67%",
    "threat_actor_relationship_identified": "10.67%"
}

export default function CompanyRiskTable(props: ThisProps) {

    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    const theme = useTheme();

    const riskColorIntensity = {
        [TenPointScale.NONE]: theme.palette.text.primary, // Default text color
        [TenPointScale.EXTREMELY_LOW]: lighten(theme.palette.info.main, 0.7),
        [TenPointScale.VERY_LOW]: lighten(theme.palette.info.main, 0.5),
        [TenPointScale.LOW]: theme.palette.info.main,
        [TenPointScale.SOMEWHAT_LOW]: darken(theme.palette.info.main, 0.2),
        [TenPointScale.MEDIUM]: lighten(theme.palette.error.main, 0.5),
        [TenPointScale.SOMEWHAT_HIGH]: theme.palette.error.main,
        [TenPointScale.HIGH]: darken(theme.palette.error.main, 0.2),
        [TenPointScale.VERY_HIGH]: darken(theme.palette.error.main, 0.3),
        [TenPointScale.EXTREMELY_HIGH]: darken(theme.palette.error.main, 0.4)
    };


    const StyledTableCell = styled(TableCell, {
        shouldForwardProp: (prop) => prop !== 'riskLevel',
    })<StyledTableCellProps>(({theme, riskLevel}) => {

        return {
            fontSize: 14,
            '&.MuiTableCell-head': {
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
            },
            color: riskLevel ? riskColorIntensity[toTitleCase(riskLevel) as TenPointScale] : theme.palette.text.primary,
            fontWeight: riskLevel && riskLevel.toLowerCase().includes('high') ? 600 : 'normal'
        }
    });

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof CompanyRisk,
    ) => {
        const isAsc = props.orderBy === property && props.order === 'asc';
        props.setOrder(isAsc ? 'desc' : 'asc');
        props.setOrderBy(property);
    };

    const handleClick = (event: React.MouseEvent<unknown>, index: number) => {
        props.setSelectedCompanyRisk(visibleRows[index] as unknown as CompanyRisk)
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (index: number) => props.selectedCompanyRisk == visibleRows[index] as unknown as CompanyRisk;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.companyRisk.value.length) : 0;


    const visibleRows = React.useMemo(
        () =>
            // @ts-ignore
            stableSort(props.companyRisk.value, getComparator(props.order, props.orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [props.order, props.orderBy, page, rowsPerPage],
    );


    return (
        <Paper sx={{width: '100%'}}>
            <TableContainer sx={{maxHeight: '68vh'}}>
                <Table
                    stickyHeader
                    sx={{minWidth: 750, tableLayout: 'fixed'}}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <EnhancedTableHead
                        order={props.order}
                        orderBy={props.orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={props.companyRisk.value.length}
                    />
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            const isItemSelected = isSelected(index);
                            return (
                                <StyledTableRow
                                    hover
                                    onClick={(event) => handleClick(event, index)}
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={index}
                                    selected={isItemSelected}
                                    sx={{cursor: 'pointer'}}
                                >
                                    <Tooltip title={toTitleCase(row.threat_actor as string)}>
                                        <StyledTableCell width={rowStyle.threat_actor} className={'overflow'}>
                                            {toTitleCase(row.threat_actor as string)}
                                        </StyledTableCell>
                                    </Tooltip>
                                    <StyledTableCell width={rowStyle.threat_actor_relationship_identified}
                                                     className={'overflow'}>{row.threat_actor_last_seen ? format(new Date(row.threat_actor_last_seen as string), "MM/dd/yyyy") : '--------'}
                                    </StyledTableCell>
                                    <StyledTableCell width={rowStyle.likelihood_of_attack} className={'overflow'}
                                                     riskLevel={row.likelihood_of_attack as string}>{toTitleCase(row.likelihood_of_attack as string)}</StyledTableCell>
                                    <StyledTableCell width={rowStyle.impact_type} className={'overflow'}
                                                     riskLevel={row.impact_type as string}>{toTitleCase(row.impact_type as string)}</StyledTableCell>
                                    <StyledTableCell width={rowStyle.operational_risk} className={'overflow'}
                                                     riskLevel={row.operational_risk as string}>{toTitleCase(row.operational_risk as string)}</StyledTableCell>
                                    <StyledTableCell width={rowStyle.financial_risk} className={'overflow'}
                                                     riskLevel={row.financial_risk as string}>{toTitleCase(row.financial_risk as string)}</StyledTableCell>
                                    <StyledTableCell width={rowStyle.legal_compliance_risk} className={'overflow'}
                                                     riskLevel={row.legal_compliance_risk as string}>{toTitleCase(row.legal_compliance_risk as string)}</StyledTableCell>
                                    <StyledTableCell width={rowStyle.reputational_risk} className={'overflow'}
                                                     riskLevel={row.reputational_risk as string}>{toTitleCase(row.reputational_risk as string)}</StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6}/>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={props.companyRisk.value.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}